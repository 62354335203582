import React from 'react'
import MainNav from '../../components/MainNav'
import MainFooter from '../../components/MainFooter'
import Breadcrumb from '../../components/Breadcrumb'
import { toast } from 'react-toastify'
import api from '../../services/api'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'

export default function Elencos({ name }) {
  const [elenco, setElenco] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get('/elenco')
        setElenco(response.data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar as informações do elenco.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      <div className="min-h-100 d-flex flex-column">
        {loading && <Loading delayTime={200} />}
        <MainNav />
        <div className="container-lg">
          <Breadcrumb pageName={name} />

          <main className="lg pt-5">
            {elenco?.length > 0 ? (
              <div className="row">
                {elenco?.map((elencos) => (
                  <div
                    key={elencos?._id}
                    className="col-12 col-md-3 elencos-grande-container mt-4"
                  >
                    <Link to={`/futebol/elencos/jogador/${elencos?._id}`}>
                    <div
                      className="position-relative flex-wrap jogadorCard "
                      style={{
                        backgroundImage: `url(${elencos?.img.url})`,
                        backgroundSize: 'cover',
                        minHeight: 350,
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <div className="position-absolute w-100 h-100 gradiente-leitura ">
                        <div className="capsule">
                          <div className="z-index-99">
                            <div className="num">
                              <span className="elencos-data-tipo-separador font-weight-bold">
                                {elencos.numero}
                              </span>
                              <span className="num2 font-italic ml-2">
                                {elencos.posicao}
                              </span>
                              <span className="line"></span>
                            </div>
                            <span className="num2 text-uppercase font-weight-bold">
                              {elencos?.nome}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <div className="ADD">Esta página está vazia</div>
            )}
          </main>
        </div>

        <div className="container-lg pb-5">
          <MainFooter />
        </div>
      </div>
    </>
  )
}

import * as yup from 'yup'

export const schemaCheckPatrocinadores = yup.object().shape({
  nome: yup
    .string()
    .max(25, 'Máximo de 20 caracteres')
    .required('Campo é obrigatório.')
    .matches(/[abcdefghijklmnopqrstuvwxyz]+/, 'Não está no formato correto'),
  patrocinadorId: yup.string().required('Campo é obrigatório.'),
  reservas: yup.number().required('Campo é obrigatório.').typeError('Campo é obrigatório.').integer().nullable(),
})

import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Cards from '@snowpak/react-credit-cards'
import Cleave from 'cleave.js/react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import api from '../../services/api'

import HeaderSocioDesktop from '../../components/HeaderSocioDesktop'
import CustomInput from '../../components/CustomInput'
import Loading from '../../components/Loading'

export default function AlterarCartao() {
  const history = useHistory()
  const [card, setCard] = React.useState('')
  const [name, setName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [validThru, setValidThru] = React.useState('')
  const [cvc, setCvc] = React.useState('')
  const [focus, setFocus] = React.useState('')
  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
    shouldFocusError: false,
    reValidateMode: 'onBlur',
    defaultValues: {
      nome: '',
      numero: '',
      cvc: '',
      validade: '',
    },
  })

  function handleChangeCC(e) {
    setCard(e.target.value)
  }

  function handleChangeName(e) {
    setName(e.target.value)
  }

  function handleChangeCVC(e) {
    setCvc(e.target.value)
  }

  function handleChangeValidThru(e) {
    setValidThru(e.target.value)
  }

  function handleChangeFocus(e) {
    setFocus(e.target.dataset.label)
  }

  async function handleSubmitForm(data) {
    try {
      setLoading(true)
      const response = await api.patch('/area-socio/alterar-cartao', data)

      if (!response?.data?.aprovado) {
        toast.error('Pagamento não aprovado, verifique seus dados.')
      }

      setTimeout(() => {
        toast.success('Cartão de crédito atualizado com sucesso!')
        history.push('/area-socio/ajustes')
      }, 50)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro interno, provavelmente não foi culpa sua.',
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading && <Loading delayTime={0} />}
      <div className="socio-wrapper-secondary">
        <HeaderSocioDesktop className="socio-header-secondary" />

        <main>
          <div className="socio-container">
            <section className="alterar-cartao">
              <h1>Alterar cartão de crédito</h1>

              <div>
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <label className="label-input" htmlFor="numero">
                    Número do cartão
                    <CustomInput
                      id="numero"
                      inputType="tel"
                      as={Cleave}
                      name="numero"
                      dataLabel="number"
                      className="login-input"
                      placeholder="0000 0000 0000 0000"
                      icon="credit-card"
                      iconColor="#969CB2"
                      htmlRef={register}
                      options={{
                        creditCard: true,
                      }}
                      onChange={handleChangeCC}
                      onFocus={handleChangeFocus}
                    />
                  </label>

                  <label className="label-input" htmlFor="nome">
                    Nome no cartão
                    <CustomInput
                      id="nome"
                      name="nome"
                      dataLabel="name"
                      className="login-input"
                      placeholder="Ex. João da Silva"
                      icon="signature"
                      iconColor="#969CB2"
                      inputRef={register}
                      onChange={handleChangeName}
                      onFocus={handleChangeFocus}
                    />
                  </label>

                  <div className="double-input">
                    <label className="label-input" htmlFor="valid-thru">
                      Validade
                      <CustomInput
                        dataLabel="expiry"
                        id="valid-thru"
                        name="validade"
                        inputType="tel"
                        as={Cleave}
                        className="login-input"
                        placeholder="mm/aa"
                        icon="calendar-day"
                        iconColor="#969CB2"
                        htmlRef={register}
                        options={{
                          date: true,
                          datePattern: ['m', 'y'],
                        }}
                        onChange={handleChangeValidThru}
                        onFocus={handleChangeFocus}
                      />
                    </label>

                    <label className="label-input" htmlFor="cvc">
                      CVC
                      <CustomInput
                        dataLabel="cvc"
                        id="cvc"
                        name="cvc"
                        as={Cleave}
                        className="login-input"
                        placeholder="000"
                        icon="key"
                        inputType="tel"
                        iconColor="#969CB2"
                        htmlRef={register}
                        options={{
                          numericOnly: true,
                          uppercase: true,
                          blocks: [3],
                        }}
                        onChange={handleChangeCVC}
                        onFocus={handleChangeFocus}
                      />
                    </label>
                  </div>
                  <div className="socio-warnings">
                    <p>
                      *Sujeito a cobrança em caso de inatividade ou
                      cancelamento.
                    </p>
                  </div>

                  <div className="buttons-group">
                    <Link to="/area-socio/ajustes" className="button-cancel">
                      Cancelar
                    </Link>
                    <button type="submit" disabled={loading} className="button-yellow">
                      Confirmar
                    </button>
                  </div>
                </form>

                <Cards
                  cvc={cvc}
                  expiry={validThru}
                  number={card}
                  name={name}
                  locale={{
                    valid: 'Validade',
                  }}
                  placeholders={{
                    name: 'Seu nome aqui',
                  }}
                  focused={focus}
                />
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  )
}

import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import CustomInput from '../../components/CustomInput'
import ElencoCard from '../../components/ElencoCard'
import Icon from '../../components/Icon'
import Loading from '../../components/Loading'
import api from '../../services/api'


export default function Elencos() {
  const { path } = useRouteMatch()
  const history = useHistory()
  const { search } = useLocation()
  const [elencosList, setElencosList] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [modal, setModal] = React.useState(false)
  const [itemToDelete, setItemToDelete] = React.useState('')
  const searchRef = React.useRef()
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(0)

  async function removeItem() {
    await api.delete(`/elencos/${itemToDelete}`)
    setElencosList((oldState) => {
      return oldState.filter((item) => item?._id !== itemToDelete)
    })
    handleClose()
    toast.success('Elenco deletado com sucesso!')
  }

  const handleClose = () => setModal(false)
  const handleShow = () => setModal(true)

  const handleSearch = React.useCallback(
    (e) => {
      e.preventDefault()
      const value = searchRef?.current?.value?.trim()

      const data = Object.assign({}, value ? { busca: value } : null)

      history.push({
        pathname: path,
        search: new URLSearchParams(data).toString(),
      })
    },
    [history, path],
  )

  const urlQuery = React.useMemo(() => {
    const queries = new URLSearchParams(search)

    const query = {}

    const busca = queries.get('busca') || ''

    if (busca) {
      query.busca = busca
    }

    return query
  }, [search])

  const hasMore = React.useMemo(() => page <= pages, [page, pages])

  React.useEffect(() => {
    ;(async () => {
      try {
        setPage(1)
        setLoading(true)
        const response = await api.get('/elencos', {
          params: {
            search: urlQuery?.busca,
          },
        })

        setElencosList(response?.data?.list)
        setPages(response?.data?.pages || 1)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista do elenco, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
        setPage((p) => p + 1)
      }
    })()
  }, [urlQuery])

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className="wrapper bg-verm" id="scrollableWrapper">
        <div className="container mt-3 p-0 pt-3 pb-4 position-relative">
          <div className="col-12 mb-4">
            <div className="card border-0 p-4">
              <Link to={`${path}/cadastrar`} className="text-decoration-none btn-adicionar">
                <Icon icon="plus" color="#fff" />
              </Link>
              <form className="form-pesquisa" onSubmit={handleSearch}>
                <CustomInput
                  value={urlQuery?.busca}
                  id="input-pesquisa"
                  placeholder="Pesquisar por elenco"
                  icon="search"
                  sendIcon="paper-plane"
                  inputRef={searchRef}
                  hasMore={hasMore}
                  scrollableTarget="scrollableWrapper"
                />
              </form>
            </div>
          </div>
          <Link to={`${path}/cadastrar/`} className="text-decoration-none btn-adicionar mt-3 mr-3">
            <Icon icon="plus" color="#fff" />
          </Link>
          <Modal
            show={modal}
            onHide={handleClose}
            keyboard={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">Remover Jogador?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
              Tem certeza que deseja remover este jogador? Esta ação não será
              reversível.
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-verm"
                type="button"
                onClick={removeItem}
              >
                Remover
              </button>
            </Modal.Footer>
          </Modal>
          <div className="d-flex flex-wrap row-gap-30">
            {elencosList.length > 0 ? (
              elencosList?.map((elenco) => (
                <ElencoCard
                  handleShow={handleShow}
                  setItemToDelete={setItemToDelete}
                  elenco={elenco}
                  key={elenco?._id}
                />
              )) || <div></div>
            ) : (
              <div className="jogos_">Não há jogadores cadastrados.</div>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

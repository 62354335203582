import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Cleave from 'cleave.js/react';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers';

import api from '../../services/api';

import { schemaEdicaoPaymentMethod } from '../../validators/formEdicao';

import Select from '../Select';

import paymentOptions from '../../utils/paymentOptions';

export default function FormaPagamento({ state }) {
  const { id } = useParams();
  const [payment, setPayment] = React.useState(state?.fields?.forma_pagamento || '');
  const [isLoading, setIsLoading] = React.useState(false)
  const history = useHistory();
  const { handleSubmit: handleSubmitform, register, errors, control } = useForm({
    context: { forma_pagamento: payment },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    resolver: yupResolver(schemaEdicaoPaymentMethod),
    defaultValues: {
      forma_pagamento: state?.fields?.forma_pagamento,
      numero: '',
      cvc: '',
      nome: '',
      validade: '',
    },
  });

  async function handleSubmit(data) {
    setIsLoading(true)
    try {
      await api.patch(`/socios/${id}/payment-method`, data);

      toast.success('Informações atualizadas com sucesso!');
      history.push(state?.redirect);
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao enviar suas informações.')
    } finally {
      setIsLoading(false)
    }
  }

  function changePayment(e) {
    setPayment(e.target.value);
  }

  return (
    <form className="d-flex flex-wrap color-black" onSubmit={handleSubmitform(handleSubmit)}>
      <div className="form-group col-12">
        <label htmlFor="forma-pagamento" className="control-label">Forma de pagamento</label>
        <Select
          id="forma-pagamento"
          className="custom-select"
          name="forma_pagamento"
          options={paymentOptions}
          selected={state?.fields?.forma_pagamento}
          label="tipo de pagamento"
          register={register}
          onChange={changePayment}
        />
        <div className="retorno-invalido">{errors?.forma_pagamento?.message}</div>
      </div>

      {payment === 'cc' && (
        <>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="numero" className="control-label">Número do cartão</label>
            <Controller
              className="form-control"
              id="numero"
              name="numero"
              as={<Cleave ref={register} />}
              control={control}
              options={{
                creditCard: true,
              }}
            />
            <div className="retorno-invalido">{errors?.numero?.message}</div>
          </div>

          <div className="form-group col-12 col-md-6">
            <label htmlFor="nome-titular" className="control-label">Nome impresso no cartão</label>
            <input
              id="nome-titular"
              name="nome"
              type="text"
              className="form-control"
              placeholder="Nome do titular"
              ref={register}
            />
            <div className="retorno-invalido">{errors?.nome?.message}</div>
          </div>

          <div className="form-group col-12 col-md-6">
            <label htmlFor="validade" className="control-label">Data de validade do cartão</label>
            <Controller
              className="form-control"
              id="validade"
              name="validade"
              as={<Cleave ref={register} />}
              control={control}
              placeholder="mm/aa"
              options={{
                date: true,
                datePattern: ['m', 'y'],
              }}
            />
            <div className="retorno-invalido">{errors?.validade?.message}</div>
          </div>

          <div className="form-group col-12 col-md-6">
            <label htmlFor="cvc" className="control-label">Código de segurança</label>
            <Controller
              className="form-control"
              id="cvc"
              name="cvc"
              as={<Cleave ref={register} />}
              control={control}
              placeholder="000"
              options={{
                numericOnly: true,
                blocks: [3],
              }}
            />
            <div className="retorno-invalido">{errors?.cvc?.message}</div>
          </div>

          <div className="col-12">
            <p className="warning mb-2">Caso o sócio esteja com atraso no pagamento, uma cobraça será efetuada</p>
          </div>
        </>
      )}

      <div className="d-flex justify-content-between col-12">
        <button type="button" className="btn btn-light border" onClick={() => history.push(state?.redirect)}>Voltar</button>
        <button type="submit" disabled={isLoading} className="btn btn-verde">Avançar</button>
      </div>
    </form>
  );
}

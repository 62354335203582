import React from 'react'
import { motion, AnimateSharedLayout } from 'framer-motion'

const defaultFilters = [
  { id: 'Todos', color: '#9D704D' },
  { id: 'Ativos', color: '#00A783' },
  { id: 'Inadimplentes', color: '#F9AA3B' },
  { id: 'Cancelados', color: '#DE002C' },
]

export default function Filter({
  selected,
  setSelected,
  filters = defaultFilters,
  className = '',
}) {
  return (
    <AnimateSharedLayout>
      <div className={`filter ${className}`}>
        {filters.map(({ id, color, label }) => (
          <button
            key={id}
            className="filter-item cursor-pointer"
            onClick={() => setSelected(id?.toLowerCase())}
          >
            <div>
              <motion.div
                className="filter-text"
                initial={false}
                animate={{
                  color:
                    selected?.toLowerCase() === id?.toLowerCase()
                      ? color
                      : '#333',
                }}
                transition={{
                  type: 'spring',
                  stiffness: 400,
                  damping: 30,
                }}
              >
                {label || id}
              </motion.div>
              {selected?.toLowerCase() === id?.toLowerCase() ? (
                <motion.div
                  layoutId="underline"
                  className="filter-underline"
                  initial={false}
                  animate={{ backgroundColor: color }}
                  transition={{
                    type: 'spring',
                    stiffness: 500,
                    damping: 30,
                  }}
                />
              ) : (
                <div className="filter-underline"></div>
              )}
            </div>
          </button>
        ))}
      </div>
    </AnimateSharedLayout>
  )
}

import React from 'react'

import bannerCamiseta from '../assets/other/loja_01.jpg'

export default function SmallBanner() {
  return (
    <a
      className="mt-auto"
      href="https://api.whatsapp.com/send?1=pt_br&phone=5555999805668&text=Ol%C3%A1%21%0AGostaria%20de%20saber%20mais%20sobre%20os%20produtos%20do%20Santa%20Rosa%20Sicredi%20Futsal"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="small-banner"
        src={bannerCamiseta}
        alt="Banner Camiseta"
      />
    </a>
  )
}

import React from 'react'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import MainNav from '../components/MainNav'
import MainFooter from '../components/MainFooter'
import Icon from '../components/Icons'
import api from '../services/api'

export default function Noticia() {
  const { id } = useParams()
  const [noticia, setNoticia] = React.useState({})
  const handleClose = () => setModalShow(false)
  //   const handleShow = () => setModalShow(true)
  const [modalShow, setModalShow] = React.useState(false)
  const [imgIdx, setImgIdx] = React.useState(0)

  React.useEffect(() => {
    ; (async () => {
      const response = await api.get(`/noticias/${id}`)

      const data = {
        ...response?.data,
        dateFormatted: moment(response?.data?.created_at).format('DD.MM.YYYY'),
      }

      setNoticia(data)
    })()
  }, [id])

  function nextImage() {
    setImgIdx(imgIdx + 1)
  }
  function prevImage() {
    setImgIdx(imgIdx - 1)
  }

  return (
    <>
      <MainNav />

      <main className="container-lg">
        <div className="coluna-noticia">
          <div className="noticia-imagem">
            <img
              src={noticia?.banner}
              alt={`Banner da notícia ${noticia?.titulo}`}
            />
          </div>

          <div className="noticia-texto">
            <article>
              <div>
                <span className="font-12">
                  <span className="noticia-data-tipo--bold">
                    {noticia?.dateFormatted}
                  </span>
                  <span className="noticia-data-tipo-separador">|</span>
                  <span className="noticia-data-tipo text-uppercase">
                    {noticia?.categoria}
                  </span>
                </span>
              </div>
              <h1 className="noticia-fonte--media font-weight-bold mt-2 mb-0">
                {noticia?.titulo}
              </h1>
              <div
                className="mt-5 text-break"
                dangerouslySetInnerHTML={{ __html: noticia?.corpo }}
              />
            </article>
          </div>
        </div>
        {noticia?.maisImagens && noticia?.maisImagens?.length > 0 && (
          <div className="noticia-imgs-texto mt-5">
            <strong>Mais imagens</strong>
            <div className="noticia-imgs">
              {noticia?.maisImagens?.map((img, idx) => (
                <button
                  key={idx}
                  onClick={() => {
                    setImgIdx(idx)
                    setModalShow(true)
                  }}
                  style={{ cursor: 'pointer' }}
                  className="border-0 bg-transparent"
                >
                  <img
                  style={{ objectFit:"cover" }}
                  width="75"
                  height="75"
                  src={img.url}
                  alt={`Mais imagens ${idx + 1}`}
                  key={idx}
                  />
                </button>
              ))}
            </div>
          </div>
        )}
        <div className="button-centered mt-5">
          <Link className="button--xlarge" to="/noticias">
            VOLTAR
          </Link>
        </div>
      </main>
      <div className="container-lg pb-5">
        <MainFooter />
      </div>

      <Modal
        centered={true}
        show={modalShow}
        animation={true}
        size="lg"
        dialogClassName="modal-100w"
        keyboard={false}
        onHide={handleClose}
        className="teste"
      >
        <Modal.Header className="d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-around align-items-center flex-row w-full">
            <h5 className="modal-title">Imagens das notícias </h5>
            <button
              onClick={handleClose}
              type="button"
              className="btn-close close p-1"
              data-dismiss="modal"
              aria-label="Close"
            >
              <Icon
                icon={'times-circle'}
                size="2x"
                color="#FFF"
                className="d2"
              />
            </button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div
            id="carouselExampleDark"
            className="carousel carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              {noticia?.maisImagens?.map((img, idx) => {
                const active = idx === imgIdx
                return (
                  <div
                    className={`carousel-item ${active ? 'active' : ''}`}
                    key={idx}
                  >
                    <img
                      src={img.url}
                      style={{
                        width: '100%',
                        maxHeight: '60vh',
                        height: 'auto',
                        objectFit: 'contain',
                      }}
                      className="d-block w-100"
                      alt={'imagem'}
                    />
                  </div>
                )
              })}
            </div>
            {imgIdx + 1 > 1 && (
              <button
                style={{ background: 'transparent', border: 0 }}
                onClick={prevImage}
                className="carousel-control-prev"
                type="button"
                data-bs-slide="prev"
              >
                <Icon icon={'arrow-alt-circle-left'} size="2x" color="rgba(0,0,0,0.9)" />
              </button>
            )}
            {noticia?.maisImagens && noticia?.maisImagens?.length > imgIdx + 1 && (
              <button
                style={{ background: 'transparent', border: 0 }}
                onClick={nextImage}
                className="carousel-control-next"
                type="button"
                data-bs-slide="next"
              >
                <Icon icon={'arrow-alt-circle-right'} size="2x" color="rgba(0,0,0,0.9)" />
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Cleave from 'cleave.js/react'
import { yupResolver } from '@hookform/resolvers'

import api from '../../services/api'

import { schemaEdicaoDependent } from '../../validators/formEdicaoSocio'

import HeaderSocioDesktop from '../../components/HeaderSocioDesktop'
import CustomInput from '../../components/CustomInput'
import Loading from '../../components/Loading'

export default function AlterarDependente() {
  const [loading, setLoading] = React.useState(true)
  const history = useHistory()
  const [dependenteData, setDependenteData] = React.useState({})
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaEdicaoDependent),
    shouldFocusError: false,
    reValidateMode: 'onBlur',
  })

  React.useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get('/area-socio/dados/dependente')

        setDependenteData(response?.data?.dependente)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro interno, provavelmente não foi culpa sua.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  async function handleSubmitForm(data) {
    try {
      setLoading(true)
      const response = await api.patch('/area-socio/dados/dependente', {
        ...data,
        nome: data?.nome?.trim(),
      })

      setDependenteData({ ...dependenteData, ...response?.data })

      toast.success('Dados atualizados com sucesso!')
      history.push('/area-socio/ajustes')
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro interno, provavelmente não foi culpa sua.',
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <div className="socio-wrapper-secondary">
        <HeaderSocioDesktop className="socio-header-secondary" />

        <main>
          <div className="socio-container">
            <section className="alterar-dados-form">
              <h1>Alterar dependente</h1>

              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <label className="label-input" htmlFor="nome">
                  Nome completo
                  <CustomInput
                    id="nome"
                    name="nome"
                    className="login-input"
                    icon="signature"
                    iconColor="#969CB2"
                    inputRef={register}
                    placeholder="Ex. João da Silva"
                    value={dependenteData?.nome}
                  />
                  <span className="retorno-invalido">
                    {errors?.nome?.message}
                  </span>
                </label>

                <label className="label-input" htmlFor="cpf">
                  CPF
                  <CustomInput
                    id="cpf"
                    as={Cleave}
                    name="cpf"
                    inputType="tel"
                    className="login-input"
                    icon="id-card"
                    iconColor="#969CB2"
                    placeholder="000.000.000-00"
                    value={dependenteData?.cpf}
                    htmlRef={register}
                    options={{
                      numericOnly: true,
                      blocks: [3, 3, 3, 2],
                      delimiters: ['.', '.', '-'],
                      uppercase: true,
                    }}
                  />
                  <span className="retorno-invalido">
                    {errors?.cpf?.message}
                  </span>
                </label>

                <div className="buttons-group">
                  <Link to="/area-socio/ajustes" className="button-cancel">
                    Cancelar
                  </Link>
                  <button type="submit" className="button-yellow">
                    Salvar
                  </button>
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    </>
  )
}

import React from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../components/Loading'
import Lottie from 'lottie-react'
import MainNav from '../../components/MainNav'
import Breadcrumb from '../../components/Breadcrumb'
import * as animationData from '../../assets/man.json'
import api from '../../services/api'
import MainFooter from '../../components/MainFooter'
;(function () {
  return moment.defineLocale('pt-br', {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split(
      '_',
    ),
    weekdays: 'Domingo_Segunda_Terça_Quarta_Quinta_Sexta_Sábado'.split('_'),
  })
})()

export default function Jogos({ name }) {
  const [games, setGames] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const hasMore = React.useMemo(() => page <= pages, [page, pages])

  React.useEffect(() => {
    ;(async () => {
      try {
        setPage(1)
        setLoading(true)
        const response = await api.get('/proximos-jogos?page=1', {
          params: {
            status: 'proximos',
            limit: 20,
          },
        })
        const data = response?.data?.list?.map((item) => ({
          ...item,

          dataHoraFormatted: moment(item?.dataHora).format(
            'DD/MM/YYYY [às] HH:mm',
          ),
          weekDay: moment(item?.dataHora).format('dddd'),
          monthDay:
            `${moment(item?.dataHora).date()}`.length === 1
              ? `0${moment(item?.dataHora).date()}`
              : moment(item?.dataHora).date(),
          monthYear: moment(item?.dataHora).format('MMMM[,] YYYY'),
        }))

        setGames(data)
        setPages(response?.data?.pages || 1)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de jogos, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
        setPage((p) => p + 1)
      }
    })()
  }, [])

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/proximos-jogos?page=${page}`, {
        params: {
          status: 'próximos',
          limit: 20,
        },
      })
      const data = response?.data?.list?.map((item) => ({
        ...item,

        dataHoraFormatted: moment(item?.dataHora).format(
          'DD/MM/YYYY [às] HH:mm',
        ),
        weekDay: moment(item?.dataHora).format('dddd'),
        monthDay:
          `${moment(item?.dataHora).date()}`.length === 1
            ? `0${moment(item?.dataHora).date()}`
            : moment(item?.dataHora).date(),
        monthYear: moment(item?.dataHora).format('MMMM[,] YYYY'),
      }))
      setGames([...games, ...data])
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Erro ao buscar a lista de jogos, por favor, recarregue a página.',
      )
    } finally {
      setLoading(false)
      setPage(page + 1)
    }
  }, [page, games])

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <div className="min-h-100">
        
        <MainNav />
        <div className="container-lg">
          <Breadcrumb pageName={name} />
          <main className="pt-5">
            <h1 className="fs-24 mt-0 mb-6 fw-bold text-yellow">
              Próximos Jogos
            </h1>
            <div className="col-12">
              <ul className="list-group jogos-list row">
                <InfiniteScroll
                  dataLength={games?.length}
                  next={loadMore}
                  hasMore={hasMore}
                  scrollableTarget="scrollableWrapper"
                >
                  {games?.length > 0 ? (
                    games?.map((game) => (
                      <li
                        key={game?._id}
                        className="list-group-item d-flex jogos-list__item"
                      >
                        <div className="jogos-list__item__date-time d-flex col-auto col-sm-8 col-md-3">
                          <strong className="align-self-center">
                            {game?.monthDay}
                          </strong>
                          <div>
                            <span className="d-block jogos-list__item__date-time__day">
                              {game?.weekDay}
                            </span>
                            <span className="d-block jogos-list__item__date-time__year-month">
                              {game?.monthYear}
                            </span>
                          </div>
                        </div>

                        <div className="jogos-list__item__teams d-flex col-5 col-sm-4 col-md-3 align-items-center">
                          <img
                            width="70"
                            height="70"
                            src={
                              game?.emCasa
                                ? game?.timeProprio?.img?.url
                                : game?.timeAdversario?.img?.url
                            }
                            alt={
                              game?.emCasa
                                ? game?.timeProprio?.nome
                                : game?.timeAdversario?.nome
                            }
                          />
                          <span>x</span>
                          <img
                            width="70"
                            height="70"
                            src={
                              game?.emCasa
                                ? game?.timeAdversario?.img?.url
                                : game?.timeProprio?.img?.url
                            }
                            alt={
                              game?.emCasa
                                ? game?.timeAdversario?.nome
                                : game?.timeProprio?.nome
                            }
                          />
                        </div>

                        <div className="jogos-list__item__info col-12 col-md-6 mt-3 mt-md-0">
                          <span className="jogos-list__item__info__teams d-block text-yellow">
                            {game?.amistoso
                              ? 'Amistoso'
                              : game?.competicao?.liga}
                          </span>
                          <span className="jogos-list__item__info__teams d-block">
                            {game?.emCasa
                              ? `${game?.timeProprio?.nome} x ${game?.timeAdversario?.nome}`
                              : `${game?.timeAdversario?.nome} x ${game?.timeProprio?.nome}`}
                          </span>
                          <span className="jogos-list__item__info__details d-block">
                            {game?.dataHoraFormatted}
                          </span>
                          <span className="jogos-list__item__info__details d-block">
                            {game?.local}
                          </span>
                        </div>
                      </li>
                    ))
                  ) : (
                    <>
                      <Lottie
                        isClickToPauseDisabled
                        animationData= { animationData.default }
                        style={{ height: 120, marginTop: '40px', marginBottom: '40px'}}
                        loop = { true }
                        autoplay = { true }
                      />
                      <h5 className="text-center">Não há jogos previstos</h5>
                    </>
                  )}
                </InfiniteScroll>
              </ul>
            </div>
          </main>
        </div>
      </div>

      <div className="container-lg pb-5">
        <MainFooter />
      </div>
    </>
  )
}

import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import Icon from './Icon'

export default function JogoCard({
  handleShow,
  handleShowCheckList,
  jogo,
  setItemToDelete,
  setItemToCheckList,
  buscaListCheckin,
  setItemToPatrocinadorList,
  handleShowPatrocinadorList,
  buscaListPatrocinador,
}) {
  const { path } = useRouteMatch()

  async function handleDelete() {
    try {
      setItemToDelete(jogo?._id)
      handleShow()
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Erro ao remover o jogo.')
    }
  }

  async function handleCheckList() {
    try {
      setItemToCheckList(jogo?._id)
      handleShowCheckList()
      buscaListCheckin(jogo?._id)
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Erro ao buscar lista.')
    }
  }

  async function handlePatrocinadorList() {
    try {
      setItemToPatrocinadorList(jogo?._id)
      handleShowPatrocinadorList()
      buscaListPatrocinador(jogo?._id)
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Erro ao buscar patrocinador.')
    }
  }

  return (
    <div className="col-lg-6 position-relative color-black">
      <div className="card border-0 px-4 py-5 jogos">
        <div className="jogos__info">
          <div className="jogos__info__versus">
            <img
              className="jogos__info__versus__team-logo"
              height={70}
              src={
                jogo?.emCasa
                  ? jogo?.timeProprio.img.url
                  : jogo?.timeAdversario?.img.url
              }
              alt="Logo Santa Rosa Futsal"
              loading="lazy"
            />
            <span className="jogos__info__versus__icon">X</span>
            <img
              className="jogos__info__versus__team-logo"
              height={70}
              src={
                jogo?.emCasa
                  ? jogo?.timeAdversario?.img.url
                  : jogo?.timeProprio.img.url
              }
              alt="Logo Santa Rosa Futsal"
              loading="lazy"
            />
          </div>

          <div className="jogos__info__details">
            <span className="jogos__info__details__teams">
              {jogo?.emCasa
                ? `${jogo?.timeProprio?.nome} X ${jogo?.timeAdversario?.nome}`
                : `${jogo?.timeAdversario?.nome} X ${jogo?.timeProprio?.nome}`}
            </span>

            <span className="jogos__info__details__additional">
              {'Data/Horário: ' + jogo?.dataHoraFormatted}
            </span>

            <span className="jogos__info__details__additional">
              {'Local: ' + jogo?.local}
            </span>

            <span className="jogos__info__details__additional">
              {jogo?.amistoso
                ? 'Amistoso'
                : 'Competição: ' + jogo?.competicao?.liga}
            </span>

            <span className="jogos__info__details__additional">
              {jogo?.emCasa === false
                ? ''
                : 'Check-in: ' +
                  (jogo?.remaning ? jogo.remaning : 0) +
                  ' vagas.'}
            </span>

            <span className="jogos__info__details__additional">
              {jogo?.emCasa === false
                ? ''
                : 'Início check-in: ' + jogo?.dataHoraInFormatted}
            </span>

            <span className="jogos__info__details__additional">
              {jogo?.emCasa === false
                ? ''
                : 'Término check-in: ' + jogo?.dataHoraTeFormatted}
            </span>

            <span className="jogos__info__details__additional">
              {jogo?.emCasa === false
                ? ''
                : 'Check-in realizados: ' + jogo?.reservadas || 0}
            </span>
          </div>
        </div>
      </div>
      <div className="action">
        <div className="d-flex align-items-center">
          <div className="dropdown">
            <button
              className="border-0 btn pt-1 pb-1 custom-btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdownMenuButton"
            >
              <Icon icon="ellipsis-v" color="#BDBDBD" />
            </button>
            <div
              className="dropdown-menu dropdown-menu-right z-index-99"
              aria-labelledby="dropdownMenuButton"
            >
              <Link
                className="dropdown-item custom-dropdown color-black"
                to={`${path}/${jogo?._id}/editar`}
              >
                <Icon icon="pen" color="#333" />
                <span className="ml-3">Editar</span>
              </Link>
              {jogo?.emCasa === !true ? (
                ''
              ) : (
                <>
                  <button
                    className="outline-0 dropdown-item custom-dropdown color-black"
                    onClick={handlePatrocinadorList}
                  >
                    <Icon icon="file" color="#333" />
                    <span className="ml-3"> Reserva patrocinador</span>
                  </button>

                  <button
                    className="outline-0 dropdown-item custom-dropdown color-black"
                    onClick={handleCheckList}
                  >
                    <Icon icon="file" color="#333" />
                    <span className="ml-3"> Lista check-in</span>
                  </button>
                </>
              )}
              <button
                className="outline-0 dropdown-item custom-dropdown color-black"
                onClick={handleDelete}
              >
                <Icon icon="times-circle" color="#de002c" />
                <span className="ml-3">Deletar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import ReactCrop from 'react-image-crop'
import Resizer from 'react-image-file-resizer'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import Icon from '../../components/Icon'
import Loading from '../../components/Loading'
import SunEditor from '../../components/SunEditor'
import api from '../../services/api'

export default function NoticiasCadastro() {
  const { id } = useParams()
  const history = useHistory()
  const [form, setForm] = React.useState({
    corpo: '',
    titulo: '',
    categoria: '',
  })
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [moreImages, setMoreImages] = React.useState([])
  const [toDeleteImages, setToDeleteImages] = React.useState([])
  const [srcMain, setSrcMain] = React.useState('')
  const [disabled, setDisabled] = React.useState(true)
  const [identifiers, setIdentifiers] = React.useState({
    function: 'create',
    buttonText: 'Cadastrar',
    successMessage: 'Cadastro realizado com sucesso!',
  })
  const {
    errors,
    handleSubmit: handleSubmitForm,
    register,
    reset,
    setError,
    control,
  } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: form,
  })

  const [upImg, setUpImg] = React.useState()
  const [editorText, setEditorText] = React.useState('')
  const imgRef = React.useRef(null)
  const previewCanvasRef = React.useRef(null)
  const defaultCrop = { aspect: 16 / 9 }
  const [crop, setCrop] = React.useState(defaultCrop)
  const [completedCrop, setCompletedCrop] = React.useState(null)

  useEffect(() => {
    reset(form)
  }, [reset, form])

  useEffect(() => {
    ;(async () => {
      if (id) {
        try {
          setLoading(true)
          setIdentifiers({
            function: 'edit',
            buttonText: 'Editar',
            successMessage: 'Notícia editada com sucesso!',
          })
          const response = await api.get(`/noticias/${id}`)
          setSrcMain(response?.data?.banner)
          setForm({
            titulo: response?.data?.titulo,
            categoria: response?.data?.categoria,
          })
          setEditorText(response?.data?.corpo)
          if (response?.data?.maisImagens) {
            setMoreImages(response?.data?.maisImagens)
          }
        } catch (err) {
          return toast.error(
            err?.response?.data?.erro || 'Ocorreu um erro ao editar as informações.'
          )
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [id])

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
  }, [completedCrop])

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        576,
        'jpeg',
        85,
        0,
        (uri) => {
          resolve(uri)
        },
        'blob'
      )
    })

  function handleChangeEditor(content) {
    setEditorText(content)
  }

  async function handleSubmit(data) {
    if (!data?.corpo) {
      data.corpo = editorText
    }

    if (identifiers.function === 'edit') {
      try {
        setLoading(true)
        const canvas = previewCanvasRef.current

        if (completedCrop) {
          // ele cai aqui se o user editou o banner
          canvas.toBlob(
            async (blob) => {
              try {
                const formData = new FormData()
                const resizedFile = await resizeFile(blob)

                formData.set('banner', resizedFile, `${data.titulo.replace(' ', '_')}.jpg`)
                formData.set('titulo', data.titulo)
                formData.set('corpo', data.corpo)
                formData.set('categoria', data.categoria)
                for (const imgKey of toDeleteImages) {
                  formData.append('toDeleteImages', imgKey)
                }
                if (moreImages && moreImages?.length > 0) {
                  Array.from(moreImages).forEach((item) => {
                    //isso verifica se o objeto veio do back (é uma url)
                    if (!item.url) formData.append('moreImages', item, item?.name)
                  })
                }
                await api.put(`/noticias/${id}`, formData)
                toast.success(identifiers.successMessage)
                return history.push('/area-admin/noticias')
              } catch (err) {
                return toast.error(
                  err?.response?.data?.erro || 'Ocorreu um erro ao editar as informações.'
                )
              } finally {
                setLoading(false)
              }
            },
            'image/jpeg',
            1
          )

          return
        }
        //se o use não modificar o banner cai aqui
        const anotherFormData = new FormData()

        anotherFormData.set('titulo', data.titulo)
        anotherFormData.set('corpo', data.corpo)
        anotherFormData.set('categoria', data.categoria)
        for (const imgKey of toDeleteImages) {
          anotherFormData.append('toDeleteImages', imgKey)
        }
        if (moreImages && moreImages.length > 0) {
          Array.from(moreImages).forEach((item) => {
            if (!item.url) anotherFormData.append('moreImages', item, item?.name)
          })
        }
        await api.put(`/noticias/${id}`, anotherFormData)
        toast.success(identifiers.successMessage)
        return history.push('/area-admin/noticias')
      } catch (err) {
        console.log(err)
        return toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao editar a notícia.')
      } finally {
        setLoading(false)
      }
    }

    try {
      setLoading(true)
      const canvas = previewCanvasRef.current

      if (!completedCrop) {
        setError('banner', {
          types: {
            required: true,
          },
          message: 'Campo é obrigatório.',
        })
        return
      }

      canvas.toBlob(
        async (blob) => {
          try {
            const resizedFile = await resizeFile(blob)
            const oneMoreFormData = new FormData()

            oneMoreFormData.set('banner', resizedFile, `${data.titulo.replace(' ', '_')}.jpg`)
            oneMoreFormData.set('titulo', data.titulo)
            oneMoreFormData.set('corpo', data.corpo)
            oneMoreFormData.set('categoria', data.categoria)
            if (moreImages && moreImages?.length > 0) {
              Array.from(moreImages).forEach((item) => {
                oneMoreFormData.append('moreImages', item, item?.name)
              })
            }

            await api.post('/noticias', oneMoreFormData)
            toast.success(identifiers.successMessage)
            return history.push('/area-admin/noticias')
          } catch (error) {
            return toast.error(
              error?.response?.data?.erro ||
                'Ocorreu um erro ao criar a notícia, Upload simultâneo de mais de 5 imagens não é permitido.'
            )
          } finally {
            setLoading(false)
          }
        },
        'image/jpeg',
        1
      )
    } catch (err) {
      return toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao criar a notícia.')
    } finally {
      setLoading(false)
    }
  }

  const onLoadImage = (img) => {
    imgRef.current = img
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setDisabled(true)
      handleShow(true)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        return setUpImg(reader.result)
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleClose = () => {
    imgRef.current = null
    setCompletedCrop(null)
    setCrop(defaultCrop)
    setShow(false)
  };

  const handleConclude = () => {
    setCrop(defaultCrop)
    setShow(false)
  };

  const handleShow = () => setShow(true)

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <AdminMenu />
      <main className='wrapper bg-verm'>
        <div className='container mt-3 pt-3'>
          <form onSubmit={handleSubmitForm(handleSubmit)} className='card p-4 border-0'>
            <div className='d-flex flex-wrap color-black'>
              <div className='form-group col-12 mb-5'>
                <label className={identifiers.function === 'create' ? 'control-label' : ''}>
                  Banner
                </label>
                <div>
                  <input
                    ref={register}
                    type='file'
                    name='banner'
                    id='banner'
                    accept='image/*'
                    onChange={onSelectFile}
                    hidden={true}
                    className='form-control-file'
                  />
                  <label htmlFor='banner'>
                    <canvas
                      ref={previewCanvasRef}
                      hidden={!imgRef.current}
                      className='img-input--banner'
                    />
                    {!imgRef.current && srcMain && (
                      <>
                        <img className='img-input--banner main' src={srcMain} alt='' />
                      </>
                    )}
                    {!imgRef.current && !srcMain && (
                      <div className='img-input--banner d-flex'>
                        <Icon className='mx-auto my-auto' color='#969cb2' icon='image'></Icon>
                      </div>
                    )}
                  </label>
                </div>
                <span className='retorno-invalido'>{errors?.banner?.message}</span>
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                dialogClassName='modal-90w'
              >
                <Modal.Header>
                  <Modal.Title className='modal-title'>Cortar a imagem</Modal.Title>
                      <button
                        onClick={handleClose}
                        type="button"
                        className="btn-close close p-1"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <Icon
                          icon={'times-circle'}
                          size="2x"
                          color="#FFF"
                          className="d2"
                        />
                      </button>
                </Modal.Header>
                <Modal.Body className='d-flex align-items-center justify-content-center'>
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoadImage}
                    crop={crop}
                    onChange={(c) => {
                      setDisabled(!(c.width >= 236.5))
                      return setCrop(c)
                    }}
                    onComplete={(c) => {
                      return setCompletedCrop(c)
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    disabled={disabled}
                    className='btn btn-verde'
                    type='button'
                    onClick={handleConclude}
                  >
                    Concluído
                  </button>
                </Modal.Footer>
              </Modal>

              <div className='form-group col-12 col-md-6'>
                <label htmlFor='titulo' className='control-label'>
                  Título
                </label>
                <input id='titulo' name='titulo' className='form-control' ref={register} />
                <span className='retorno-invalido'>{errors?.titulo?.message}</span>
              </div>
              <div className='form-group col-12 col-md-6'>
                <label htmlFor='categoria' className='control-label'>
                  Categoria
                </label>
                <select id='categoria' name='categoria' className='form-control' ref={register}>
                  <option hidden value=''>
                    Selecione uma
                  </option>
                  <option value='geral'>Geral</option>
                  <option value='profissional'>Profissional</option>
                  <option value='institucional'>Institucional</option>
                  <option value='seletivas'>Seletivas</option>
                </select>
                <span className='retorno-invalido'>{errors?.categoria?.message}</span>
              </div>
            </div>

            <div className='form-group col-12'>
              <label htmlFor='corpo' className='control-label'>
                Corpo
              </label>
              <Controller
                name='corpo'
                control={control}
                as={<SunEditor onEditorChange={handleChangeEditor} defaultValue={editorText} />}
              />
              <span className='retorno-invalido'>{errors?.corpo?.message}</span>
            </div>

            <div className='form-group col-12 mb-5'>
              <label>Mais imagens (Máx. 5 imagens)</label>
              <input
                ref={register}
                hidden={true}
                type='file'
                multiple
                className='form-control-file'
                name='moreImages'
                accept='image/*'
                id='moreImages'
                onChange={ async (c) => {
                  if (moreImages.length + c.target.files.length <= 5) {
                    const image = []
                    for (let index = 0; index < c.target.files.length; index++) {
                      const file = c.target.files[index];
                      let t = await resizeFile(file);
                      image.push(t)
                    }
                    setMoreImages([...moreImages, ...image])
                    
                  } else {
                    toast.error('Por favor, selecione até 5 imagens')
                  }
                }}
              />
              <div className='row'>
                {moreImages.length > 0 && (
                  <>
                    {moreImages.map((image, index) => {
                      let file = image.url || image

                      try {
                        new URL(file)
                      } catch {
                        file = URL.createObjectURL(image)
                      }

                      return (
                        <div className='col-sm-2' key={index}>
                          <button
                            className='bg-transparent border-0 p-0'
                            style={{ position: 'relative' }}
                            type='button'
                            onClick={() => {
                              const imgs = moreImages.filter((img, i) => i !== index)
                              if (moreImages[index].key) {
                                setToDeleteImages([...toDeleteImages, moreImages[index].key])
                              }
                              setMoreImages(imgs)
                            }}
                          >
                            <img
                              key={index}
                              style={{
                                objectFit: 'cover',
                                display: 'block',
                              }}
                              className='img-input'
                              width={125}
                              height={125}
                              src={file}
                              alt=''
                            />
                            <Icon
                              // className='mx-auto'
                              icon='times-circle'
                              color='#de002c'
                              size={20}
                            ></Icon>
                          </button>
                        </div>
                      )
                    })}
                  </>
                )}
                {moreImages.length < 5 && (
                  <>
                    <label htmlFor='moreImages' className='col-sm-2'>
                      <div className='img-input d-flex'>
                        <Icon className='mx-auto my-auto' color='#969cb2' icon='images'></Icon>
                      </div>
                    </label>
                  </>
                )}
              </div>
            </div>

            <div className='d-flex justify-content-between col-12'>
              <button
                type='button'
                className='btn btn-light border'
                onClick={() => history.push('/area-admin/noticias')}
              >
                Voltar
              </button>
              <button type='submit' className='btn btn-verde'>
                {identifiers.buttonText}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  )
}

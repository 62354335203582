import React from "react"

import Cards from "@snowpak/react-credit-cards"
import Cleave from "cleave.js/react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import { toast } from "react-toastify"

import { useCadastro } from "../../../Contexts/CadastroContext"

import { schemaCadastroFourthStep } from '../../../validators/formCadastro';

import api from '../../../services/api';

import CustomInput from "../../CustomInput";
import Loading from '../../Loading';

export default function Pagamento ({ navigateOnStep, currentStep }) {
    const { formData } = useCadastro();
    const [ dependente, setDependente ] = React.useState('');
    const [nome, setNome] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [numero, setNumero] = React.useState('');
    const [validade, setValidade] = React.useState('');
    const [focus, setFocus] = React.useState('');
    const [cvc, setCVC] = React.useState('');
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        resolver: yupResolver(schemaCadastroFourthStep),
        defaultValues: {
            ...formData?.pagamento?.cc
        }
    });


    React.useEffect(() => {
        ; (async () => {
          try {
            const planoData = formData.plano
            const planoD = await api.get(`planos/${planoData}`)
            const planoDependente = planoD.data.dependente
        
            setDependente(planoDependente)
          } catch (err) {
              console.log(err)
          } finally {
            setLoading(false)
          }
        })()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onSubmit(data) {
        try {
            setLoading(true);
            const newData = {
                ...formData,
                pagamento: {
                    ...formData?.pagamento,
                    forma_pagamento: 'cc',
                    cc: {
                        ...data
                    }
                }
            }
    
            await api.post('/cadCliPgtoCred', newData);
            navigateOnStep(currentStep + 1);
        } catch (error) {
            toast.error(error?.response?.data?.erro || 'Ocorreu um erro ao criar seu cadastro.');
        } finally {
            setLoading(false);
        }
    }

    function handleChangeCC(e) {
        setNumero(e.target.value);
    }

    function handleChangeName(e) {
        setNome(e.target.value);
    }

    function handleChangeCVC(e) {
        setCVC(e.target.value);
    }

    function handleChangeValidThru(e) {
        setValidade(e.target.value);
    }

    function handleChangeFocus(e) {
        setFocus(e.target.dataset.label);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="form-steps-container">
                {loading && <Loading delayTime={1} />}
                <h1 className="login-title">Dados de pagamento</h1>
                <h2 className="login-subtitle">Santa Rosa Futsal</h2>
                <div className="row flex-wrap-reverse">
                    <div className="col-12 col-lg-6 col-md-12 col-sm-6">
                        <div className="form-group mb-1">
                            <label className="login-label control-label" htmlFor="numero">Número do cartão</label>
                            <CustomInput
                                as={Cleave} 
                                options={{
                                    creditCard: true
                                }} 
                                icon="id-card" 
                                iconColor="#969CB2" 
                                type="tel" 
                                name="numero" 
                                dataLabel="number"
                                className={`login-input ${errors?.numero ? 'socio-input-error' : ''}`}
                                id="numero" 
                                placeholder="0000 0000 0000 0000"
                                htmlRef={register}
                                onChange={handleChangeCC}
                                onFocus={handleChangeFocus}
                            />
                            <div className="retorno-invalido mh-19">{errors?.numero?.message}</div>
                        </div>
                        <div className="form-group mb-1">
                            <label className="login-label control-label" htmlFor="nome">Nome do titular</label>
                            <CustomInput
                                icon="signature" 
                                iconColor="#969CB2" 
                                type="text" 
                                name="nome"
                                dataLabel="name"
                                className={`login-input ${errors?.nome ? 'socio-input-error' : ''}`}
                                id="nome" 
                                placeholder="Ex.: João da Silva"
                                inputRef={register}
                                onChange={handleChangeName}
                                onFocus={handleChangeFocus}
                            />
                            <div className="retorno-invalido mh-19">{errors?.nome?.message}</div>
                        </div>
                    </div>
                            <div className="form-group mb-1 col-12 col-lg-6 col-md-12 col-sm-6">
                            <Cards
                            cvc={cvc}
                            expiry={validade}
                            number={numero}
                            name={nome}
                            focused={focus}
                            locale={{
                                valid: 'Validade'
                            }}
                            placeholders={{
                                name: 'Seu nome aqui',
                            }}
                            />
                        </div>
                </div>
                <div className="row">
                    <div className="form-group mb-1 col-6 d-flex flex-column">
                            <label className="login-label control-label mb-auto" htmlFor="validade">Validade</label>
                            <CustomInput
                                as={Cleave} options={{
                                    date: true,
                                    datePattern: ['m', 'y']
                                }} 
                                icon="calendar-day" 
                                iconColor="#969CB2" 
                                type="tel" 
                                name="validade"
                                dataLabel="expiry"
                                className={`login-input fix-input ${errors?.validade ? 'socio-input-error' : ''}`}
                                id="validade" 
                                placeholder="mm/aa"
                                htmlRef={register}
                                onChange={handleChangeValidThru}
                                onFocus={handleChangeFocus}
                            />
                            <div className="retorno-invalido mh-19">{errors?.validade?.message}</div>
                        </div>
                        <div className="form-group mb-1 col-6">
                            <label className="login-label control-label" htmlFor="cvc">Código de segurança</label>
                            <CustomInput 
                                as={Cleave}
                                options={{
                                    numericOnly: true,
                                    blocks:[3]
                                }}
                                icon="key" 
                                iconColor="#969CB2" 
                                type="tel" 
                                name="cvc"
                                dataLabel="cvc"
                                className={`login-input ${errors?.cvc ? 'socio-input-error' : ''}`} 
                                id="cvc"
                                placeholder="000"
                                htmlRef={register}
                                onChange={handleChangeCVC}
                                onFocus={handleChangeFocus}
                            />
                            <div className="retorno-invalido mh-19">{errors?.cvc?.message}</div>
                        </div>
                </div>
                <div className="row flex-wrap-reverse">
                    <div className="col-12 col-lg-6">
                        <button type="button" className="login-button btn-grey" id="voltar" onClick={() => navigateOnStep(currentStep - (dependente ? 1 : 2))}>Voltar</button>
                    </div>
                    <div className="col-12 col-lg-6">
                        <button type="submit"disabled={loading} id="proximo" className="login-button">Associar-me!</button>
                    </div>
                </div>
            </form>
        </>
    )
}
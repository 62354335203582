import React from 'react'
import { Link } from 'react-router-dom'

export default function Concluido() {
   return(    
        <div className="form-steps-container form-steps-container-concluido w-100 d-flex flex-column flex-1 justify-content-center">
            <h1 className="login-title">Sucesso! Seu cadastro já está ativo!</h1>
            <h2 className="login-subtitle">Santa Rosa Futsal</h2>
            <div className="row flex-wrap-reverse">
                <div className="col-12 col-lg-6">
                    <Link to="/" className="login-button btn-grey" id="voltar" >Tela inicial</Link>
                    </div>
                <div className="col-12 col-lg-6">
                    <Link to="/login" id="proximo" className="login-button" >Entrar</Link>
                </div>
            </div>                 
        </div>
   )
}
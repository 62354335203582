import axios from './../services/api'
import React from 'react'

export default function DashCard({ plano }) {
  const [cardsData, setCardsData] = React.useState({})
  const [, setLoading] = React.useState(true)
  React.useEffect(() => {
    ; (async () => {
      try {
        const [response] = await Promise.all([
          axios.get(`/dash/total`),
        ])
        const totalAtivos = response.data.ativos.reduce(
          (acc, curr) => acc + curr.count,
          0,
        )
        
        let ativos = {}

        response.data.ativos.forEach((item) => (ativos[item._id] = item.count))

        const data = {
          ativos,
          totalAtivos,
          inadimplentes: response.data.total - totalAtivos,
        }
        
        setCardsData(data)      
      } catch (err) {
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <div className="col-lg-3 col-sm-4 col-md-4 mt-3">
    <div className="card p-4 border-0 color-black">
      <strong className="titleCard">Sócios "{plano.descricao}"</strong>
      <label className="card-label">
        {cardsData?.ativos?.[plano._id]|| 0 }
        
      </label>
    </div>
  </div>
  )
}

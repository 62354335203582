import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import QuatrocentosEQuatro from '../views/404';

import Jogos from '../views/Futebol/Jogos';
import Elenco from '../views/Futebol/Elencos'
import Competicoes from '../views/Futebol/Competicoes'
import TabelaClassificacao from '../views/Futebol/tabelaClassificacao'
import DadosJogador from '../views/Futebol/dadosJogador'

const FutebolRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/jogos`} component={() => <Jogos name="jogos" />} />
      <Route exact path={`${path}/elencos`} component={() => <Elenco name="elencos" />} />
      <Route exact path={`${path}/elencos/jogador/:id`} component={() => <DadosJogador name="jogador" />} />
      <Route exact path={`${path}/competicoes`} component={() => <Competicoes name="competicoes" />} />
      <Route exact path={`${path}/tabela-classificacao`} component={() => <TabelaClassificacao name="tabela_classificacao" />} />
      <Route path={`${path}*`} component={QuatrocentosEQuatro} />
    </Switch>
  );
};

export default FutebolRoutes;
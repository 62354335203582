import React from 'react'
import jwt_decode from 'jwt-decode'

import api from '../../services/api'

import { baseURL } from '../../services/api'

const STORAGE_KEY = '@srfutsal'

export default function useAuthContext() {
  const [authenticated, setAuthenticated] = React.useState(false)
  const [admin, setAdmin] = React.useState(false)
  const [loginData, setLoginData] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [userRoles, setUserRoles] = React.useState({})

  React.useEffect(() => {
    const current = JSON.parse(localStorage.getItem(STORAGE_KEY))

    if (current?.token && current?.refreshToken) {
      api.defaults.headers.Authorization = `Bearer ${current?.token}`

      const { admin: isAdmin } = jwt_decode(current?.token)

      setAuthenticated(true)
      setAdmin(isAdmin)
    }

    setLoading(false)
  }, [])

  React.useEffect(() => {
    api
      .get('/socios/userAdmin')
      .then((res) => {
        setUserRoles(res.data)
      })
      .catch((err) => {})
  }, [admin, authenticated])

  React.useEffect(() => {
    const apiListen = api.interceptors.response.use(
      (response) => {
        return response
      },
      async (err) => {
        return new Promise(async (resolve, reject) => {
          const originalReq = err?.config
          const current = JSON.parse(localStorage.getItem(STORAGE_KEY))

          if (
            err?.response?.status === 401 &&
            err?.config &&
            !err?.config?.__isRetryRequest &&
            current?.refreshToken
          ) {
            originalReq._retry = true

            let res = await fetch(`${baseURL}/refresh`, {
              method: 'POST',
              cache: 'no-cache',
              credentials: 'same-origin',
              headers: {
                'Content-type': 'application/json',
              },
              redirect: 'follow',
              referrer: 'no-referrer',
              body: JSON.stringify({
                refresh: current?.refreshToken,
              }),
            }).then((res) =>
              res.json().then((res) => {
                originalReq.headers.Authorization = `Bearer ${res.token}`
                api.defaults.headers.Authorization = `Bearer ${res?.token}`

                localStorage.setItem(
                  STORAGE_KEY,
                  JSON.stringify({
                    token: res?.token,
                    refreshToken: current?.refreshToken,
                    exp: res?.exp,
                    nome: res?.nome,
                  }),
                )

                return api(originalReq)
              }),
            )

            return resolve(res)
          }

          if (err?.response?.data?.erro === 'jwt expired') {
            return reject({
              response: {
                data: {
                  erro: 'Sessão expirada, favor reconectar.',
                },
              },
            })
          }

          return reject(err)
        })
      },
    )

    return () => {
      api.interceptors.response.eject(apiListen)
    }
  }, [])

  function updateLoginData(data) {
    setLoginData({ ...loginData, ...data })
  }

  function getUser() {
    const current = JSON.parse(localStorage.getItem(STORAGE_KEY))
    if (current) {
      return current?.nome?.split(' ')[0]
    }
  }

  async function handleLogin({ cpf, senha }) {
    return new Promise(async (res, rej) => {
      try {
        const response = await api.post('/login', {
          cpf,
          senha,
          admin: loginData?.admin,
        })

        api.defaults.headers.Authorization = `Bearer ${response?.data?.token}`

        setAdmin(loginData?.admin)
        setAuthenticated(true)

        localStorage.setItem(
          STORAGE_KEY,
          JSON.stringify({
            token: response?.data?.token,
            refreshToken: response?.data?.refreshToken,
            exp: response?.data?.exp,
            nome: response?.data?.nome,
          }),
        )

        setLoginData({})

        res(true)
      } catch (err) {
        rej(err)
      }
    })
  }

  async function toggleContext() {
    return new Promise(async (res, rej) => {
      try {
        const response = await api.post('/vToken')

        api.defaults.headers.Authorization = `Bearer ${response?.data?.token}`

        setAdmin(!admin)
        setAuthenticated(true)

        localStorage.setItem(
          STORAGE_KEY,
          JSON.stringify({
            token: response?.data?.token,
            refreshToken: response?.data?.refreshToken,
            exp: response?.data?.exp,
            nome: response?.data?.nome,
          }),
        )

        setLoginData({})
        res(true)
      } catch (err) {
        rej(err)
      }
    })
  }

  function logout() {
    localStorage.removeItem(STORAGE_KEY)
    setAuthenticated(false)
  }

  return {
    handleLogin,
    logout,
    loading,
    userRoles,
    authenticated,
    updateLoginData,
    loginData,
    admin,
    getUser,
    toggleContext,
  }
}

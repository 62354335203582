import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { toast } from 'react-toastify'

import Icon from '../components/Icon'

export default function CompeticoesCard({
  handleShow,
  competicao,
  setItemToDelete,
}) {
  const { path } = useRouteMatch()

  async function handleDelete() {
    try {
      setItemToDelete(competicao?._id)
      handleShow()
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Erro ao remover a competição.')
    }
  }

  return (
    <div className="col-md-6 position-relative color-black">
      <div className="card border-0 px-4 py-5 jogos">
        <div className="jogos__info">
          <div className="jogos__info__details">
            <span className="jogos__info__details__teams">
              {competicao?.liga}
            </span>

            <span className="jogos__info__details__additional">
              {'Fases: ' + competicao?.fases}
            </span>
          </div>
        </div>
      </div>
      <div className="action">
        <div className="d-flex align-items-center">
          <div className="dropdown">
            <button
              className="border-0 btn pt-1 pb-1 custom-btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdownMenuButton"
            >
              <Icon icon="ellipsis-v" color="#BDBDBD" />
            </button>
            <div
              className="dropdown-menu dropdown-menu-right z-index-99"
              aria-labelledby="dropdownMenuButton"
            >
              <Link
                className="dropdown-item custom-dropdown color-black"
                to={`${path}/${competicao?._id}/editar`}
              >
                <Icon icon="pen" color="#333" />
                <span className="ml-3">Editar</span>
              </Link>

              <button
                className="outline-0 dropdown-item custom-dropdown color-black"
                onClick={handleDelete}
              >
                <Icon icon="times-circle" color="#de002c" />
                <span className="ml-3">Deletar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Cleave from 'cleave.js/react';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers';

import api from '../../services/api';

import { schemaEdicaoPersonal } from '../../validators/formEdicao';
import LoadingSpinner from '../LoadingSpinner';

import Select from '../Select';

export default function Pessoal({ state }) {
  const { id } = useParams();
  const history = useHistory();
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [personalInfo, setPersonalInfo] = React.useState({});
  const { handleSubmit: handleSubmitform, register, errors, control, reset, setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: state?.fields || personalInfo,
    resolver: yupResolver(schemaEdicaoPersonal),
  });

  React.useEffect(() => {
    reset(state?.fields || personalInfo);
  }, [state, personalInfo, reset]);

  React.useEffect(() => {
    (async () => {
      try {
        if (!state?.fields) {
          setLoading(true);
          const response = await api.get(`/socios/${id}`, {
            params: {
              info: 'personal'
            }
          })
          setPersonalInfo(response?.data)
        }
      } catch (error) {
        toast.error(error?.response?.data?.erro || 'Ocorreu um erro ao buscar os dados do usuário.');
      } finally {
        setLoading(false);
      }
    })()
  }, [id, state]);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/planos');

        const data = response?.data?.map(opt => { return { value: opt._id, label: opt.descricao, disable: !opt.public } });

        setOptions(data)

        if (state) {
          const socioPersonal = await api.get(`/socios/${id}`, {
            params: {
              info: 'personal',
            }
          });

          const socioPlano = socioPersonal.data.plano;
          setValue('plano', socioPlano)
        } else {
          const socioPlano = await api.get(`/socios/${id}`, {
            params: {
              info: 'personal',
            }
          })
          setValue('plano', socioPlano.data.plano)
        }
      } catch (err) {
        toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao buscar os planos.');
      } finally {
        setLoading(false);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, state]);
  
  async function handleSubmit(data) {
    try {
      await api.patch(`/socios/${id}/personal`, data);

      toast.success('Informações atualizadas com sucesso!');
      history.push(state?.redirect || '/area-admin/socios');
    } catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao enviar suas informações.')
    }
  }

  if (loading) {
    return <LoadingSpinner className="d-flex justify-content-center" />;
  }

  return (
    <form className="d-flex flex-wrap color-black" onSubmit={handleSubmitform(handleSubmit)}>
      <div className="form-group col-12 col-md-6">
        <label htmlFor="plano" className="control-label">Plano</label>
        <Select
          id="plano"
          className="custom-select"
          name="plano"
          label="plano"
          options={options}
          register={register}
        />
        <div className="retorno-invalido">{errors?.plano?.message}</div>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="nome" className="control-label">Nome</label>
        <input
          id="nome"
          name="nome"
          type="text"
          className="form-control"
          ref={register}
          placeholder="Nome do sócio"
        />
        <span className="retorno-invalido">{errors?.nome?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="data-de-nascimento" className="control-label">Data de nascimento</label>
        <Controller
          className="form-control"
          id="data-de-nascimento"
          name="dt_nasc"
          as={<Cleave ref={register} />}
          control={control}
          placeholder="dd/mm/aaaa"
          options={{
            date: true,
            datePattern: ['d', 'm', 'Y'],
          }}
        />
        <span className="retorno-invalido">{errors?.dt_nasc?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="email" className="control-label">E-mail</label>
        <input id="email" type="email" className="form-control" name="email" placeholder="seuemail@dominio.com" ref={register} />
        <span className="retorno-invalido">{errors?.email?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="cpf" className="control-label">CPF</label>
        <Controller
          id="cpf"
          name="cpf"
          className="form-control"
          as={<Cleave ref={register} />}
          control={control}
          placeholder="000.000.000-00"
          options={{
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            uppercase: true,
            numericOnly: true
          }}
        />
        <span className="retorno-invalido">{errors?.cpf?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="phone" className="control-label">Celular</label>
        <Controller
          id="phone"
          name="telefone"
          className="form-control"
          as={<Cleave ref={register} />}
          options={{
            blocks: [0, 2, 5, 4],
            delimiters: ['(', ') ', '-'],
            numericOnly: true
          }}
          control={control}
          placeholder="(00) 00000-0000"
        />
        <span className="retorno-invalido">{errors?.telefone?.message}</span>
      </div>

      <div className="d-flex justify-content-between col-12">
        <button type="button" className="btn btn-light border" onClick={() => history.push(state?.redirect || history.goBack())}>Voltar</button>
        <button type="submit" className="btn btn-verde">Salvar</button>
      </div>
    </form>
  );
}

import React from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import api from '../../services/api'
import HeaderSocioDesktop from '../../components/HeaderSocioDesktop'
import LabelStatus from '../../components/LabelStatus'
import Loading from '../../components/Loading'
import Icon from '../../components/Icon'
import ScrollToTopButton from '../../components/ScrollToTopButton'
import nameCapitalize from '../../utils/nameCapitalize'
import planosIconsColors from '../../utils/planosIconsColors'
import { formatPrice } from '../../utils/formatPrice'
import { Button, Modal } from 'react-bootstrap'

const icons = {
  Cancelado: 'times-circle',
  Ativo: 'check-circle',
  Inativo: 'minus-circle',
}

const statusColors = {
  Cancelado: '#DE002C',
  Ativo: '#00A783',
  Inativo: '#F9AA3B',
}

export default function Dashboard() {
  const dependenteInputRef = React.createRef(null)
  const [displayTopButton, setDisplayTopButton] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [socioInfo, setSocioInfo] = React.useState({})
  const [games, setGames] = React.useState([])
  const [show, setShow] = React.useState(false)
  const [dependenteData, setDependenteData] = React.useState({})
  const [checkInInfo, setCheckInInfo] = React.useState({})
  const [check, setCheck] = React.useState(false)
  const [showModalCancelar, setShowModalCancelar] = React.useState(false)
  const handleClose = () => setShow(false)
  const [, setDisable] = React.useState(false)

  const handleCheckIn = async (game, isButton = false) => {
    try {
      setLoading(true)

      const response = await api.get(`/checkins/${game._id}/`)
      const data = {
        ...response?.data,
        jogo: {
          ...response?.data.jogo,
          dataHoraFormatted: moment(response?.data?.jogo?.dataHora).format(
            'DD/MM/YYYY [às] HH:mm',
          ),
          weekDay: moment(response?.data?.jogo?.dataHora).format('dddd'),
          monthDay:
            `${moment(response?.data?.jogo?.dataHora).date()}`.length === 1
              ? `0${moment(response?.data?.jogo?.dataHora).date()}`
              : moment(response?.data?.jogo?.dataHora).date(),
          monthYear: moment(response?.data?.jogo?.dataHora).format(
            'MMMM[,] YYYY',
          ),
        },
      }
      if (isButton) {
        setCheckInInfo(data)
        setCheck(data.jogo)
        setShow(!response?.data?.checkLiberado)
      }
    } catch (err) {
      if (isButton) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar suas informações, provavelmente não foi culpa sua.',
        )
      }
      setDisable(true)
    } finally {
      setLoading(false)
    }
  }

  async function handleSubmit(data) {
    try {
      setLoading(true)

      await api.post(`/checkins`, {
        jogo_id: checkInInfo?.jogo._id,
        socio_id: socioInfo?._id,
        dependente: dependenteInputRef.current?.checked || false,
      })
      handleClose()
      setGames((oldGames) =>
        oldGames.map((game) => ({
          ...game,
          checkinFeito: game._id === check._id ? true : game.checkinFeito,
        })),
      )
      toast.success('Check in realizado com sucesso!')
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro ao enviar suas informações.',
      )
    } finally {
      setLoading(false)
    }
  }

  async function removeItem(data) {
    try {
      setLoading(true)

      await api.delete(`/checkins/${data._id}/${socioInfo?._id}`)
      toast.success('Check in cancelado com sucesso!')
      setGames((oldGames) =>
        oldGames.map((game) => ({
          ...game,
          checkinFeito: game._id === data._id ? false : game.checkinFeito,
        })),
      )

      setShowModalCancelar(false)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro ao enviar suas informações.',
      )
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    function scrollListener(e) {
      if (
        window.matchMedia('(max-width: 450px)').matches &&
        (document.body.scrollTop > 70 ||
          document.documentElement.scrollTop > 70)
      ) {
        return setDisplayTopButton(true)
      }
      return setDisplayTopButton(false)
    }
    document.addEventListener('scroll', scrollListener)
    return () => {
      document.removeEventListener('scroll', scrollListener)
    }
  }, [])

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)

        const response = await api.get('/area-socio/dashboard')
        const planoId = response?.data.plano;
        const plano = await api.get(`planos/${planoId}`);
        const planoName = plano?.data.descricao;
        const data = {
          ...response?.data,
          formattedPlano: nameCapitalize(planoName),
          formattedPagoate: moment(response?.data?.pagoate)
            .parseZone()
            .format('DD/MM/YY'),
          firstName: response?.data?.nome?.split(' ')[0],
          status: response?.data?.cancelado
            ? 'Cancelado'
            : response?.data?.status,
          pgtos: response?.data?.pgtos?.map((pgto) => ({
            ...pgto,
            forma_pagamento:
              pgto?.forma_pagamento === 'cc' || !pgto?.forma_pagamento
                ? 'Cartão'
                : nameCapitalize(pgto?.forma_pagamento),
            date: moment(pgto?.date).format('DD/MM/YYYY'),
            valor: formatPrice(pgto?.valor),
            status: pgto?.aprovado ? 'Aprovado' : 'Recusado',
          })),
        }
        setSocioInfo(data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar suas informações, provavelmente não foi culpa sua.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  React.useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get('/area-socio/dashboard')
        const planoId = response?.data.plano;
        const plano = await api.get(`planos/${planoId}`);
        const planoDependente = plano?.data?.dependente;
        if (planoDependente) {
          const response = await api.get('/area-socio/dados/dependente')
          setDependenteData(response?.data?.dependente)
        } else {

        }
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro interno, provavelmente não foi culpa sua.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  React.useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get('/proximos-jogos', {
          params: {
            limit: 3,
            checkin: true,
            status: 'proximos',
            // patrocinador: true
          },
        })
        const data = response?.data?.list?.map((item) => ({
          ...item,
          dataHoraFormatted: moment(item?.dataHora).format(
            'DD/MM/YYYY [às] HH:mm',
          ),
          weekDay: moment(item?.dataHora).format('dddd'),
          monthDay:
            `${moment(item?.dataHora).date()}`.length === 1
              ? `0${moment(item?.dataHora).date()}`
              : moment(item?.dataHora).date(),
          monthYear: moment(item?.dataHora).format('MMMM[,] YYYY'),
        }))
        setGames(data)
        if (data?.length) {
          handleCheckIn(data[0])
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar as informações dos jogos.',
        )
      }
    })()
  }, [])

  return (
    <>
      {displayTopButton && <ScrollToTopButton />}
      {loading && <Loading delayTime={200} />}
      <div className="socio-wrapper">
        <HeaderSocioDesktop name={socioInfo?.firstName || null} />
        <main>
          <section>
            <h1 className="socio-container socio-nome-mobile">
              Olá{' '}
              <strong>
                {socioInfo?.firstName && `${socioInfo?.firstName}!`}
              </strong>
            </h1>
            <ul className="socio-cards-list">
              <li>
                <span>Plano</span>
                {planosIconsColors[socioInfo?.formattedPlano] && (
                  <Icon
                    prefix="fad"
                    icon="medal"
                    style={{
                      '--fa-primary-color':
                        planosIconsColors[socioInfo?.formattedPlano],
                      '--fa-secondary-color': '#D06060',
                      '--fa-secondary-opacity': 1,
                    }}
                  />
                )}
                <div>
                  <h1>{socioInfo?.formattedPlano}</h1>
                </div>
              </li>
              <li>
                <span>Situação</span>
                {socioInfo?.status && (
                  <Icon
                    prefix="far"
                    icon={icons[socioInfo?.status]}
                    color={statusColors[socioInfo?.status]}
                  />
                )}
                <h1>{socioInfo?.status}</h1>
              </li>
              <li>
                <span>Pago até</span>
                <h1>{socioInfo?.formattedPagoate}</h1>
              </li>
              <div className="cards-list-space"></div>
            </ul>
          </section>

          <div className="socio-container">
            <section className="check-recorrencia-section">
              <h2> Próximos jogos</h2>
              <section>
                <ul className="check-cards-list">
                  {games?.length > 0
                    ? games?.map((game, index) => {
                        return (
                          <li
                            key={index}
                            className="list-group-item d-flex jogos-list__item2 p-3"
                          >
                            <div className="games__info">
                              <div className="games__info__versus">
                                <img
                                  className="games__info__versus__team-logo_A"
                                  height={70}
                                  src={
                                    game?.emCasa
                                      ? game?.timeProprio.img.url
                                      : game?.timeAdversario?.img.url
                                  }
                                  alt="Logo Santa Rosa Futsal"
                                  loading="lazy"
                                />
                                <span className="games__info__versus__icon_A p-2">
                                  X
                                </span>
                                <img
                                  className="games__info__versus__team-logo_B"
                                  height={70}
                                  src={
                                    game?.emCasa
                                      ? game?.timeAdversario?.img.url
                                      : game?.timeProprio.img.url
                                  }
                                  alt="Logo Santa Rosa Futsal"
                                  loading="lazy"
                                />
                              </div>
                            </div>
                            <strong className="al align-self-center">
                              {game?.emCasa
                                ? `${game?.timeProprio?.nome} x ${game?.timeAdversario?.nome}`
                                : `${game?.timeAdversario?.nome} x ${game?.timeProprio?.nome}`}
                            </strong>
                            <span className="al2">{game?.local}</span>
                            {game?.amistoso ? (
                              <span className="al2">Amistoso</span>
                            ) : (
                              game?.competicao?.liga && (
                                <span className="al2">{`${game?.competicao?.liga}`}</span>
                              )
                            )}
                            {/* <span className="al2">
                              {game?.amistoso
                                ? 'Amistoso'
                                : game?.competicao?.liga
                                ? 
                                : ''}
                            </span> */}
                            {!game?.amistoso && game?.fase && (
                              <span className="al2">{`Fase ${game?.fase}`}</span>
                            )}
                            {/* <span className="al2">
                              {game?.amistoso
                                ? ''
                                : game?.fase
                                ? `Fase: ${game?.fase}`
                                : ''}
                            </span> */}
                            <span className="al2 mb-2">
                              {game?.weekDay} - {game?.dataHoraFormatted}
                            </span>
                            {game?.emCasa === true ? (
                              <div className="d-flex w-100 text-center mt-2">
                                {game?.checkinFeito ? (
                                  <>
                                    <button
                                      className="btn-e ml-auto p-1"
                                      type="button"
                                      variant="primary"
                                      onClick={() => {
                                        setShowModalCancelar(true)
                                        setCheck(game)
                                      }}
                                    >
                                      Cancelar
                                    </button>

                                    <button
                                      className="btn-z ml-auto p-1"
                                      type="button"
                                      variant="primary"
                                      disabled={true}
                                    >
                                      <Icon className="ax" icon="check" />
                                      Check-in realizado
                                    </button>
                                  </>
                                ) : game?.checkLiberado ? (
                                  <button
                                    className="btn-y ml-auto p-1"
                                    type="button"
                                    onClick={() => {
                                      handleCheckIn(game, true)
                                    }}
                                    variant="primary"
                                    disabled={loading}
                                  >
                                    Check-in
                                  </button>
                                ) : (
                                  <button
                                    className="btn-x ml-auto p-1"
                                    type="button"
                                    variant="primary"
                                    disabled={true}
                                  >
                                    {!game?.periodo
                                      ? 'Check-in fora do período'
                                      : 'Check-in desabilitado'}
                                  </button>
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                          </li>
                        )
                      })
                    : 'Não há jogos disponíveis.'}
                </ul>
              </section>
            </section>
          </div>
          <div className="socio-container">
            <section className="socio-recorrencia-section">
              <h2 className="pt-3">Sua recorrência</h2>
              <section>
                <ul>
                  {socioInfo?.pgtos?.map((pgto) => (
                    <li key={pgto?.id}>
                      <div className="socio-grid">
                        <span id="date">{pgto?.date}</span>
                        <span id="forma">{pgto?.forma_pagamento}</span>
                        <span align="right" id="valor">{pgto?.valor}</span>
                      </div>
                      <LabelStatus
                        className="socio-list-label-status"
                        aprovado={pgto?.aprovado}
                        mensagem={pgto?.mensagem || pgto?.descricao}
                      >
                        {pgto?.status}
                      </LabelStatus>
                    </li>
                  ))}
                </ul>
              </section>
            </section>
          </div>
        </main>
      </div>
      <Modal show={show} onHide={handleClose} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Confirma reserva</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <div className="mod">
              <div key={checkInInfo?.jogo?._id} className="">
                <strong className="align-self-center">
                  {checkInInfo?.jogo?.emCasa
                    ? `${checkInInfo?.jogo?.timeProprio?.nome} x ${checkInInfo?.jogo?.timeAdversario?.nome}`
                    : `${checkInInfo?.jogo?.timeAdversario?.nome} x ${checkInInfo?.jogo?.timeProprio?.nome}`}
                </strong>
                <div>Local: {checkInInfo?.jogo?.local}</div>
                <div>
                  {checkInInfo?.jogo?.amistoso
                    ? ''
                    : checkInInfo?.jogo?.fase
                    ? 'Fase: ' + checkInInfo?.jogo?.fase
                    : ''}
                </div>
                <span className="">
                  {checkInInfo?.jogo?.weekDay} -{' '}
                  {checkInInfo?.jogo?.dataHoraFormatted}
                </span>
              </div>
            </div>
            {dependenteData?.nome && (
              <div className="box mt-2">
                <p>Incluir dependente:</p>
                <div>
                  <input
                    ref={dependenteInputRef}
                    type="checkbox"
                    id="dependente"
                    name="dependente"
                  />
                  <label htmlFor="dependente" className="ml-1">
                    {dependenteData?.nome}
                  </label>
                </div>
              </div>
            )}
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDisable(false)
              handleClose()
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            Cancelar
          </Button>
          <Button
            className="btm"
            variant="primary"
            onClick={() => handleSubmit(check)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalCancelar}
        onHide={() => setShowModalCancelar(false)}
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Cancelar Check in?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex">
          Tem certeza que deseja cancelar o check-in?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-verm"
            type="button"
            onClick={() => removeItem(check)}
          >
            Confirmar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

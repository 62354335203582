import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Cleave from 'cleave.js/react';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers';
import moment from 'moment';

import api from '../../services/api';

import { schemaEdicaoDependent } from '../../validators/formEdicao';

export default function Dependente({ state }) {
  const { id } = useParams();
  const history = useHistory();
  const { handleSubmit: handleSubmitform, register, errors, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: state?.fields,
    resolver: yupResolver(schemaEdicaoDependent)
  });
  async function handleSubmit(data) {
    try {
      await api.patch(`/socios/${id}/dependent`, {
        ...data,
        emissao_carteirinha: moment(data?.emissao_carteirinha, 'DD/MM/YYYY').toDate()
      });

      toast.success('Informações atualizadas com sucesso!');
      history.push(state?.redirect);
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao enviar suas informações.');
    }
  }

  return (
    <form className="d-flex flex-wrap color-black" onSubmit={handleSubmitform(handleSubmit)}>
      <div className="form-group col-12 col-md-6">
        <label htmlFor="nome">Nome</label>
        <input
          id="nome"
          name="nome"
          type="text"
          className="form-control"
          placeholder="Nome do dependente"
          ref={register}
        />
        <div className="retorno-invalido">{errors?.nome?.message}</div>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="cpf">CPF</label>
        <Controller
          id="cpf"
          name="cpf"
          className="form-control"
          as={<Cleave ref={register} />}
          options={{
            uppercase: true,
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            numericOnly: true
          }}
          control={control}
          placeholder="000.000.000-00"
        />
        <div className="retorno-invalido">{errors?.cpf?.message}</div>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="rfid">Carteirinha RFID</label>
        <input id="rfid" type="text" name="carteirinha_rfid" ref={register} className="form-control" />
        <div className="retorno-invalido">{errors?.carteirinha_rfid?.message}</div>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="data-carteirinha">Carteirinha emissão</label>
        <Controller
          className="form-control"
          id="data-carteirinha"
          name="emissao_carteirinha"
          as={<Cleave ref={register} />}
          control={control}
          placeholder="dd/mm/aaaa"
          options={{
            date: true,
            datePattern: ['d', 'm', 'Y'],
          }}
        />
        <div className="retorno-invalido">{errors?.emissao_carteirinha?.message}</div>
      </div>

      <div className="d-flex justify-content-between col-12">
        <button type="button" className="btn btn-light border" onClick={() => history.push(state?.redirect)}>Voltar</button>
        <button type="submit" className="btn btn-verde">Avançar</button>
      </div>
    </form>
  );
}

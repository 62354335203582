import React from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import Lottie from 'lottie-react'
import MainNav from '../../components/MainNav'
import Breadcrumb from '../../components/Breadcrumb'
import * as animationData from '../../assets/man.json'
import api from '../../services/api'
import MainFooter from '../../components/MainFooter'
import Icon from '../../components/Icon'
import Loading from '../../components/Loading'

;(function () {
  return moment.updateLocale('pt-br', {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split(
      '_',
    ),
    weekdays: 'Domingo_Segunda_Terça_Quarta_Quinta_Sexta_Sábado'.split('_'),
  })
})()

export default function Competicoes({ name }) {
  const [competicao, setCompeticao] = React.useState([])
  const [listCompeticao, setListCompeticao] = React.useState([])
  const [changeList, setChangeList] = React.useState(null)
  const [navigation, setNavigation] = React.useState({
    prev: false,
    next: false,
  })
  const [loading, setLoading] = React.useState(false)

  const sliderRef = React.useRef(null)

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        if (changeList) {
          const response = await api.get(
            `/competicoes/${changeList?._id}/jogos`,
          )

          const data = response?.data?.map((item) => ({
            ...item,
            jogos: item?.jogos?.map((jogo) => ({
              ...jogo,
              dataHoraFormatted: moment(jogo?.dataHora).format(
                'DD/MM/YYYY [às] HH:mm',
              ),
              weekDay: moment(jogo?.dataHora).format('dddd'),
              monthDay:
                `${moment(jogo?.dataHora).date()}`.length === 1
                  ? `0${moment(jogo?.dataHora).date()}`
                  : moment(jogo?.dataHora).date(),
              monthYear: moment(jogo?.dataHora).format('MMMM[,] YYYY'),
            })),
          }))
          setCompeticao(data)
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar as informações das competições.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [changeList])

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get('/competicoes')

        setListCompeticao(response?.data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar as informações dos jogos.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  React.useEffect(() => {
    if (listCompeticao) {
      resizeFilter()
    }
  }, [listCompeticao])

  function changeCompeticao(competicao) {
    setChangeList(competicao)
  }

  function resizeFilter() {
    const { scrollLeft, offsetWidth, scrollWidth } = sliderRef.current
    setNavigation({
      prev: !!scrollLeft,
      next: !(scrollLeft >= scrollWidth - offsetWidth),
    })
  }

  const goToNextSlide = React.useCallback(() => {
    const { offsetWidth } = sliderRef.current

    return sliderRef.current?.scrollBy({
      left: offsetWidth,
      behavior: 'smooth',
    })
  }, [])

  const goToPrevSlide = React.useCallback(() => {
    const { offsetWidth } = sliderRef.current

    return sliderRef.current?.scrollBy({
      left: -offsetWidth,
      behavior: 'smooth',
    })
  }, [])

  return (
    <>
    {loading && <Loading delayTime={200} />}
      <div className="min-h-100">
        
        <MainNav />

        <div className="container-lg">
          <Breadcrumb pageName={name} />
          <div className="filter-carousel">
            <button
              hidden={!navigation?.prev}
              className="filter-carousel__arrow--prev"
              onClick={goToPrevSlide}
            >
              <Icon icon="chevron-left" />
            </button>

            <ul
              ref={sliderRef}
              className="filter-carousel__list"
              onScroll={resizeFilter}
            >
              {listCompeticao?.map((competicao, idx) => (
                <li
                  key={competicao?._id}
                  className="filter-carousel__list__item"
                >
                  <button
                    className={`${
                      competicao?._id === changeList?._id
                        ? 'button--xsmall'
                        : 'button--outline--xsmall'
                    }`}
                    onClick={() => changeCompeticao(competicao)}
                  >
                    {competicao?.liga}
                  </button>
                </li>
              ))}
            </ul>

            <button
              hidden={!navigation.next}
              className="filter-carousel__arrow--next"
              onClick={goToNextSlide}
            >
              <Icon icon="chevron-right" />
            </button>
          </div>

          <main className="pt-5 ">
            <h1 className="fs-24 mt-0 mb-6 fw-bold text-yellow">
              {changeList?.liga}
            </h1>
            <div className="fd">
              {competicao.length > 0 ? (
                competicao.map((fase) => (
                  <>
                    <p className="fd mb-2 text-yellow text-uppercase font-weight-light">
                      {fase?.nome}
                    </p>
                    <ul className="mt-0 mb-6 list-group jogos-list">
                      {fase?.jogos?.map((game) => (
                        <li
                          key={game?._id}
                          className="list-group-item d-flex jogos-list__item"
                        >
                          <div className="jogos-list__item__date-time d-flex col-auto col-sm-8 col-md-3">
                            <strong className="align-self-center">
                              {game?.monthDay}
                            </strong>
                            <div>
                              <span className="d-block jogos-list__item__date-time__day">
                                {game?.weekDay}
                              </span>
                              <span className="d-block jogos-list__item__date-time__year-month">
                                {game?.monthYear}
                              </span>
                            </div>
                          </div>

                          <div className="jogos-list__item__teams d-flex col-5 col-sm-4 col-md-3 align-items-center">
                            <img
                              width="70"
                              height="70"
                              src={
                                game?.emCasa
                                  ? game?.timeProprio?.img?.url
                                  : game?.timeAdversario?.img?.url
                              }
                              alt={
                                game?.emCasa
                                  ? game?.timeProprio?.nome
                                  : game?.timeAdversario?.nome
                              }
                            />
                            <span>x</span>
                            <img
                              width="70"
                              height="70"
                              src={
                                game?.emCasa
                                  ? game?.timeAdversario?.img?.url
                                  : game?.timeProprio?.img?.url
                              }
                              alt={
                                game?.emCasa
                                  ? game?.timeAdversario?.nome
                                  : game?.timeProprio?.nome
                              }
                            />
                          </div>

                          <div className="jogos-list__item__info col-12 col-md-6 mt-3 mt-md-0">
                            <span className="jogos-list__item__info__teams d-block">
                              {game?.emCasa
                                ? `${game?.timeProprio?.nome} x ${game?.timeAdversario?.nome}`
                                : `${game?.timeAdversario?.nome} x ${game?.timeProprio?.nome}`}
                            </span>
                            <span className="jogos-list__item__info__details d-block">
                              {game?.dataHoraFormatted}
                            </span>
                            <span className="jogos-list__item__info__details d-block">
                              {game?.local}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                ))
              ) : (
                <>
                  <Lottie
                    isClickToPauseDisabled
                    animationData= { animationData.default }
                    style={{ height: 120, marginTop: '80px', marginBottom: '40px'}}
                    loop = { true }
                    autoplay = { true }
                  />
                  <h5 className="text-center">
                    {changeList
                      ? 'Não há jogos previstos'
                      : 'Selecione uma competição para ver os jogos!'}
                  </h5>
                </>
              )}
            </div>
          </main>
        </div>
      </div>

      <div className="container-lg pb-5">
        <MainFooter />
      </div>
    </>
  )
}

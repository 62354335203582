import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import LogoBrand from '../assets/svg/logo_fundo_claro.svg'
import FacebookIcon from '../assets/svg/facebook_fundo_escuro.svg'

import TwitterIcon from '../assets/svg/twitter_fundo_branco.svg'
import TwitterIconDark from '../assets/svg/twitter_fundo_escuro.svg'

import InstagramIcon from '../assets/svg/instagram_fundo_branco.svg'
import InstagramIconDark from '../assets/svg/instagram_fundo_escuro.svg'

import YoutubeIcon from '../assets/svg/youtube_fundo_branco.svg'
import YoutubeIconDark from '../assets/svg/youtube_fundo_escuro.svg'

import WhatsappIcon from '../assets/svg/whatsapp_fundo_branco.svg'
import WhatsappIconDark from '../assets/svg/whatsapp_fundo_escuro.svg'

import { whatsappNumber } from '../utils/numberWhatsapp'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function MainNav() {
  const history = useHistory()
  const [hamburgerOpen, setHamburgerOpen] = React.useState(false)
  // const [scrolled, setScrolled] = React.useState(false)
  const [size, reSize] = React.useState(getWindowDimensions().width < 1200)

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      reSize(getWindowDimensions().width < 1200)
    })

    // const listener = document.addEventListener('scroll', () => {
    //   if (window.scrollY > 80) {
    //     return setScrolled(true)
    //   }

    //   return setScrolled(false)
    // })

    // const historyListener = history.listen(() => {
    //   setHamburgerOpen(false)
    // })

    // return () => {
    //   document.removeEventListener('scroll', listener)
    //   historyListener()
    // }
  }, [history])

  function handleHamburgerChange() {
    setHamburgerOpen(!hamburgerOpen)
  }

  return (
    <header className={`main-nav`}>
      <div className="container-lg main-nav__container">
        <NavLink to="/">
          <img
            src={LogoBrand}
            height="70"
            alt="Logo Santa Rosa Futsal"
            className="main-nav__brand"
            loading="lazy"
          />
          {/* <img src="/assets/logo_branco.png" alt="Logo" /> */}
        </NavLink>

        <nav className={`main-nav__nav${hamburgerOpen ? ' is-open' : ''}`}>
          <div style={{ alignItems: 'center' }}>
            <NavLink to="/" exact className="main-nav__nav__link--bold">
              Início
            </NavLink>
            <div className="has-submenu">
              <button className="main-nav__nav__link--bold">Futebol</button>
              <div className="has-submenu__submenu">
                <div>
                  <NavLink
                    className="has-submenu__submenu__link"
                    to="/futebol/competicoes"
                  >
                    Competições
                  </NavLink>
                  <NavLink
                    className="has-submenu__submenu__link"
                    to="/futebol/elencos"
                  >
                    Elenco
                  </NavLink>
                  <NavLink
                    className="has-submenu__submenu__link"
                    to="/futebol/jogos"
                  >
                    Jogos
                  </NavLink>
                  <NavLink
                    className="has-submenu__submenu__link"
                    to="/futebol/tabela-classificacao"
                  >
                    Tabela de Classificação
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="has-submenu">
              <button className="has-submenu__button--bold">Clube</button>
              <div className="has-submenu__submenu">
                <div>
                  <NavLink
                    className="has-submenu__submenu__link"
                    to="/clube/historia"
                  >
                    História
                  </NavLink>
                  <NavLink
                    className="has-submenu__submenu__link"
                    to="/clube/diretoria"
                  >
                    Diretoria
                  </NavLink>
                  <NavLink
                    className="has-submenu__submenu__link"
                    to="/clube/midia_kit"
                  >
                    Mídia Kit
                  </NavLink>
                  {/* <NavLink className="has-submenu__submenu__link" to="/clube/ProjetosDeCaptacao">Projetos de Captação</NavLink> */}
                </div>
              </div>
            </div>
            {/* <button className="main-nav__nav__link--bold">Ingressos</button> */}

            <NavLink to="/noticias" className="main-nav__nav__link--bold">
              Notícias
            </NavLink>
            <NavLink to="/login" className="main-nav__nav__link--bold">
              Sócio
            </NavLink>

            <a
              href="https://www.loja.santarosafutsal.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              className="main-nav__nav__link--bold"
            >
              Loja
            </a>
            <NavLink
              to="/patrocinadores"
              className="main-nav__nav__link--bold"
            >
              Patrocinadores
            </NavLink>

            {/* <button className="main-nav__nav__link--bold">Loja</button> */}
            {/* <button className="main-nav__nav__link--bold">Notícias</button> */}
            {/* <button className="main-nav__nav__link--bold">FAQ</button> */}


            <div className="flex mr-auto" style={size ? {marginLeft: '0px'} : {marginLeft:'32px'}}
            >
              <a
                href="https://www.facebook.com/futsalsantarosa/"
                // className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={FacebookIcon}
                  alt="Facebook"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
              <a
                href="https://twitter.com/santarosafutsal"
                // className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={size ? TwitterIcon : TwitterIconDark}
                  alt="Twitter"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.instagram.com/santarosa.futsal/"
                // className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={size ? InstagramIcon : InstagramIconDark}
                  alt="Facebook"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCPf4W4YnE-AYeXxTfHSUG8w"
                // className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={size ? YoutubeIcon : YoutubeIconDark}
                  alt="Youtube"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
              <a
                href={`https://wa.me/${whatsappNumber}`}
                // className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={size ? WhatsappIcon : WhatsappIconDark}
                  alt="Whatsapp"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
            </div>
            {/* <NavLink className="has-submenu__submenu__link" to="/clube/ProjetosDeCaptacao">Projetos de Captação</NavLink> */}
          </div>



          <button
            className="hamburger hamburger--boring outline-0 main-nav__nav__hamburger is-active"
            type="button"
            onClick={handleHamburgerChange}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </nav>

        <NavLink
          className="button--large main-nav__nav__button"
          to="/planos"
        >
          SEJA SÓCIO
        </NavLink>

        <button
          className="hamburger outline-0 main-nav__hamburger"
          type="button"
          onClick={handleHamburgerChange}

        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </header>
  )
}

import React from 'react'

import Icon from './Icon';

export default function CustomInput({
  icon,
  sendIcon,
  iconColor,
  inputRef,
  inputType = 'text',
  placeholder,
  as: Component,
  id,
  inputClassName = '',
  className = '',
  iconSize = 16,
  value = '',
  options,
  name,
  htmlRef,
  onChange,
  onFocus,
  dataLabel,
  readOnly,
}) {
  return (
    <div className={className + ' custom-input'}>
      <label htmlFor={id}>
        {icon && (
          <Icon
            icon={icon}
            color={iconColor || '#A0A0A0'}
            size={iconSize}
          />
        )}
      </label>
      {Component ? (
        <Component
          readOnly={readOnly}
          name={name}
          value={value}
          type={inputType}
          id={id}
          placeholder={placeholder}
          htmlRef={htmlRef}
          className={inputClassName}
          options={options}
          onChange={onChange}
          data-label={dataLabel}
          onFocus={onFocus}
        />
      ) : (
        <input
          readOnly={readOnly}
          name={name}
          defaultValue={value}
          id={id}
          type={inputType}
          ref={inputRef}
          placeholder={placeholder}
          className={inputClassName}
          onChange={onChange}
          data-label={dataLabel}
          onFocus={onFocus}
        />
      )}
      {sendIcon && (
        <button className="cursor-pointer send-button" type="submit">
          <Icon
            icon={sendIcon}
            color={iconColor || '#A0A0A0'}
            size={iconSize}
            style={{
                height: '100%'
            }}
          />
        </button>
      )}
    </div>
  )
}

import React from 'react'
import Lottie from 'lottie-react'

import * as animationData from '../assets/ball.json'
export default function Loading({ error, retry, pastDelay, delayTime }) {
  const [delay, setDelay] = React.useState(false)

  React.useEffect(() => {
    if (pastDelay === undefined) {
      const timeout = setTimeout(() => {
        setDelay(true)
      }, delayTime || 200)

      return () => clearTimeout(timeout)
    }
  }, [delayTime, pastDelay])

  if (error) {
    return (
      <div>
        Ops! Ocorreu um erro{' '}
        <button className="btn btn-default" onClick={retry}>
          Tente novamente
        </button>
      </div>
    )
  } else if (pastDelay || delay) {
    return (
      <div className="loader">
        <div>
          <Lottie
          animationData= { animationData.default }
          style={{ height: 200, width: 200 }}
          loop = { true }
          autoplay = { true }
          />
        </div>
      </div>
    )
  } else {
    return null
  }
}

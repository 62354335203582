import React from 'react'
import Icon from './Icon'

export default function ScrollToTopButton() {
  function handleScrollToTop() {
    if (window.matchMedia('(max-width: 450px').matches) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <button
      type="button"
      onClick={handleScrollToTop}
      className="btn-scroll-to-top"
    >
      <Icon icon="chevron-up" />
    </button>
  )
}

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Dashboard from '../views/Admin/Dashboard';
import Admins from '../views/Admin/Admins';
import AdminsCadastro from '../views/Admin/AdminsCadastro';
import Socios from '../views/Admin/Socios';
import SociosCadastro from '../views/Admin/sociosCadastro';
import SociosPerfil from '../views/Admin/sociosPerfil';
import SociosEdicao from '../views/Admin/sociosEdicao';
import Jogos from '../views/Admin/Jogos';
import JogosCadastro from '../views/Admin/jogosCadastro';
import Competicoes from '../views/Admin/Competicoes';
import CompeticoesCadastro from '../views/Admin/competicoesCadastro';
import Noticias from '../views/Admin/Noticias';
import NoticiasCadastro from '../views/Admin/noticiasCadastro';
import Diretorias from '../views/Admin/Diretorias';
import DiretoriasCadastro from '../views/Admin/DiretoriasCadastro';
import ElencosCadastro from '../views/Admin/ElencosCadastro';
import Elencos from '../views/Admin/Elencos';
import PatrocinadoresCadastro from '../views/Admin/patrocinadoresCadastro';
import Patrocinadores from '../views/Admin/Patrocinadores';

import Dispositivos from '../views/Dispositivos/Dispositivos';
import DispositivosCadastro from '../views/Dispositivos/DispositivosCadastro';

import TabelaClassificacoes from '../views/Admin/tabelaClassificacao';

import QuatrocentosEQuatro from '../views/404';
import Planos from '../views/Admin/Planos';
import PlanosCadastro from '../views/Admin/planosCadastro';

import Financeiro from '../views/Admin/Financeiro';

export default function Admin() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />

      <Route exact path={`${path}/socios`} component={Socios} />
      <Route path={`${path}/socios/cadastrar`} component={SociosCadastro} />
      <Route
        exact
        path={`${path}/socios/:id/:route`}
        component={SociosPerfil}
      />
      <Route
        path={`${path}/socios/:id/:form/editar`}
        component={SociosEdicao}
      />

      <Route exact path={`${path}/admins`} component={Admins} />
      <Route path={`${path}/admins/cadastrar`} component={AdminsCadastro} />
      <Route
        exact
        path={`${path}/admins/:id/editar`}
        component={AdminsCadastro}
      />

      <Route exact path={`${path}/jogos`} component={Jogos} />
      <Route exact path={`${path}/jogos/cadastrar`} component={JogosCadastro} />
      <Route path={`${path}/jogos/:id/editar`} component={JogosCadastro} />

      <Route exact path={`${path}/noticias`} component={Noticias} />
      <Route
        exact
        path={`${path}/noticias/cadastrar`}
        component={NoticiasCadastro}
      />
      <Route
        exact
        path={`${path}/noticias/:id/editar`}
        component={NoticiasCadastro}
      />

      <Route exact path={`${path}/diretorias`} component={Diretorias} />
      <Route
        exact
        path={`${path}/diretorias/cadastrar`}
        component={DiretoriasCadastro}
      />
      <Route
        exact
        path={`${path}/diretorias/:id/editar`}
        component={DiretoriasCadastro}
      />

      <Route exact path={`${path}/elencos`} component={Elencos} />
      <Route
        exact
        path={`${path}/elencos/cadastrar`}
        component={ElencosCadastro}
      />
      <Route
        exact
        path={`${path}/elencos/:id/editar`}
        component={ElencosCadastro}
      />

      <Route exact path={`${path}/patrocinadores`} component={Patrocinadores} />
      <Route
        exact
        path={`${path}/patrocinadores/cadastrar`}
        component={PatrocinadoresCadastro}
      />
      <Route
        exact
        path={`${path}/patrocinadores/:id/editar`}
        component={PatrocinadoresCadastro}
      />

      <Route exact path={`${path}/manutencao-planos`} component={Planos} />
      <Route
        exact
        path={`${path}/manutencao-planos/cadastrar`}
        component={PlanosCadastro}
      />
      <Route
        exact
        path={`${path}/manutencao-planos/:id/editar`}
        component={PlanosCadastro}
      />

      <Route
        exact
        path={`${path}/tabela-classificacao`}
        component={TabelaClassificacoes}
      />

      <Route exact path={`${path}/competicoes`} component={Competicoes} />
      <Route
        exact
        path={`${path}/competicoes/cadastrar`}
        component={CompeticoesCadastro}
      />
      <Route
        path={`${path}/competicoes/:id/editar`}
        component={CompeticoesCadastro}
      />

      <Route exact path={`${path}/dispositivos`} component={Dispositivos} />
      <Route
        path={`${path}/dispositivos/cadastrar`}
        component={DispositivosCadastro}
      />
      <Route
        path={`${path}/dispositivos/:id/editar`}
        component={DispositivosCadastro}
      />
      
      <Route exact path={`${path}/financeiro`} component={Financeiro} />

      <Route path={`${path}*`} component={QuatrocentosEQuatro} />
    </Switch>
  );
}

import * as yup from 'yup';

import cpfTest from '../utils/cpfTest';

export const schemaLoginCpf = yup.object().shape({
  cpf: yup.string().test('cpfTest', 'CPF inválido.', cpfTest).required('CPF é obrigatório.'),
});

export const schemaLoginCode = yup.object().shape({
  verification_code: yup.string().matches(/^\d{6}$/, {
    message: 'O código precisa ter 6 dígitos.',
    excludeEmptyString: true,
  }).required('Código é obrigatório')
});

export const schemaLoginPass = yup.object().shape({
  senha: yup.string().required()
});

export const schemaLoginNewPass = yup.object().shape({
  senha: yup.string().min(6, 'Senha precisa ter, no mínimo, 6 caracteres.').required('Campo senha precisa ser preenchido.'),
  confirmSenha: yup.string().oneOf([yup.ref('senha')], 'As senhas não conferem.').min(6, 'Senha precisa ter, no mínimo, 6 caracteres.').required('Você precisa repetir a senha.'),
});

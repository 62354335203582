import * as yup from 'yup';
import cpfTest from '../utils/cpfTest';
import validThruTest from '../utils/validThruTest';
import dateTest from '../utils/dateTest';
import camisetaOptions from '../utils/camisetaOptions';

export const schemaCadastroFirstStep = yup.object().shape({
  termos: yup.boolean().oneOf([true], 'Você precisa aceitar os termos de uso.'),
  // plano: yup.object().shape({
  //   label: yup.string(),
  //   id: yup.string()
  // }).when('$precisaPlano', (precisaPlano, schema) => precisaPlano ? schema?.required('Plano é obrigatório').nullable() : schema.nullable()),
  nome: yup.string().matches(/[a-zA-Zà-úÀ-Ú][ ][a-zA-Zà-úÀ-Ú]+$/, {
    excludeEmptyString: true,
    message: 'Por favor, insira o nome completo.'
  }).required('Nome é obrigatório.'),
  cpf: yup.string().test('cpfTest', 'CPF inválido.', cpfTest).required('CPF é obrigatório.'),
  dt_nasc: yup.string().test('dateTest', 'A data é inválida.', dateTest).required('Data de nascimento é obrigatório.'),
  email: yup.string().email('Insira um e-mail válido.').required('E-mail é obrigatório.'),
  tel: yup.string().matches(/^\(\d{2}\)[ ]\d{5}-\d{4}$/, {
    excludeEmptyString: true,
    message: 'Formato de celular inválido'
  }).required('Celular é obrigatório.'),
});

export const schemaCadastroSecondStep = yup.object().shape({
  cep: yup.string().matches(/^\d{5}-\d{3}/, {
    excludeEmptyString: true,
    message: 'Formato de CEP inválido.'
  }).required('CEP é obrigatório.'),
  cidade: yup.string().required('Cidade é obrigatório.'),
  uf: yup.string().matches(/^[a-zA-Z]{2}$/, {
    excludeEmptyString: true,
    message: 'O estado precisa ter 2 caracteres.'
  }).required('Estado é obrigatório.'),
  logradouro: yup.string().required('Logradouro é obrigatório.'),
  numero: yup.string().required('Número é obrigatório.'),
  bairro: yup.string().required('Bairro é obrigatório.'),
  complemento: yup.string(),
});

export const schemaCadastroThirdStep = yup.object().shape({
  camiseta: yup.string().oneOf(camisetaOptions, ({ values }) => `A camiseta precisa ter um desses tamanhos: ${values}`).when('$isOuro', (isOuro, field) => isOuro ? field.required('Tamanho é obrigatório.') : field),
  nome: yup.string().matches(/[a-zA-Zà-úÀ-Ú][ ][a-zA-Zà-úÀ-Ú]+$/, {
    excludeEmptyString: true,
    message: 'Por favor, insira o nome completo.'
  }).when('cpf', (cpf, field) => cpf ? field.required('Nome é obrigatório.') : field),
  cpf: yup.string().test('cpfTest', 'CPF inválido.', cpfTest).when('nome', (nome, field) => (
    nome ? field.required('CPF é obrigatório.') : field
  )),
}, ['cpf', 'nome']);

export const schemaCadastroFourthStep = yup.object().shape({
  numero: yup.string().required('Número do cartão é obrigatório'),
  nome: yup.string().matches(/[a-zA-Zà-úÀ-Ú][ ][a-zA-Zà-úÀ-Ú]+$/, {
    excludeEmptyString: true,
    message: 'Por favor, insira o nome completo.'
  }).required('Nome do titular é obrigatório.'),
  validade: yup.string().test('validThruTest', 'A data é inválida', validThruTest).required('É obrigatório definir a data de validade.'),
  cvc: yup.string().matches(/^\d{3}$/, {
    excludeEmptyString: true,
    message: 'O código de segurança deve ter o seguinte formato: "000".'
  }).required('Código de segurança é obrigatório.')
});

import React, { useState } from 'react';
import api from '../../services/api';

import { DataGrid } from '@mui/x-data-grid';
import Lottie from 'lottie-react';
import Loading from '../../components/Loading';
import * as animationData from '../../assets/man.json';
import { toast } from 'react-toastify';
import MainNav from '../../components/MainNav';
import MainFooter from '../../components/MainFooter';
import Breadcrumb from '../../components/Breadcrumb';

export default function TabelaClassificacao({ name }) {

  const [title, setTitle] = React.useState('Sem Titulo');

  const [loading, setLoading] = useState(false);
  const columns = [
    { field: 'id', hide: true, sortable: false },
    {
      field: 'rank',
      cellClassName: 'font-weight-bold',
      headerName: '',
      flex: 0.04,
      minWidth: 20,
      sortable: false,
      align: 'center',
    },
    {
      field: 'clube',
      headerName: 'Clube',
      flex: 0.35,
      sortable: false,
    },
    {
      field: 'pontos',
      headerName: 'Pts',
      description: 'Pontos',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'jogos',
      headerName: 'J',
      flex: 0.05,
      minWidth: 5,
      sortable: false,
      description: 'Jogos',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'vitorias',
      headerName: 'V',
      flex: 0.05,
      minWidth: 5,
      sortable: false,
      description: 'Vitórias',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'empates',
      headerName: 'E',
      flex: 0.05,
      minWidth: 5,
      sortable: false,
      description: 'Empates',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'derrotas',
      headerName: 'D',
      flex: 0.05,
      minWidth: 5,
      sortable: false,
      description: 'Derrotas',
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'golsPro',
      headerName: 'GP',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Gols Pró',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'golsContra',
      headerName: 'GC',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Gols Contra',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'saldoGols',
      headerName: 'SG',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Saldo de Gols',
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const [classificacoes, setClassificacoes] = React.useState([]);

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true);
        await api.get('/classificacoes').then((response) => {
          setClassificacoes(response?.data);
        });
      } catch (error) {
        toast.error(
          error?.response?.data?.erro ||
            'Erro ao buscar a lista do elenco, por favor, recarregue a página.'
        );
      } finally {
        setLoading(false);
      }
    })()
  }, []);

  const fetchTableTitle = async () => {
    try {
      const response = await api.get('/classificacoes/titulo');
      setTitle(response.data.title);
    } catch (error) {
      toast.error('Erro ao buscar o título.');
    }
  };

  React.useEffect(() => {
    fetchTableTitle();
  }, []);

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <div className='min-h-100'>
        <MainNav />

        <div className='container-lg'>
          <Breadcrumb pageName={name} />

          <main className='pt-5'>
            {classificacoes.length > 0 ? (
              <>
                <div>
                  <h5 className='fs-24 mb-2 fw-bold text-yellow'>
                    {/* Gauchão - Série B - Grupo A */}
                    {title}
                  </h5>
                </div>
                <div className='d-flex flex-column'>
                  <DataGrid
                    disableSelectionOnClick
                    disableColumnMenu
                    autoHeight
                    hideFooter
                    columns={columns}
                    rows={classificacoes}
                    density='comfortable'
                    getRowId={(row) => row._id}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'rank', sort: 'asc' }],
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <Lottie
                  isClickToPauseDisabled
                  animationData= { animationData.default }
                  style={{ height: 120, marginTop: '80px', marginBottom: '40px'}}
                  loop = { true }
                  autoplay = { true }
                />
                <h5 className='text-center'>
                  A Tabela de Classificação está vazia
                </h5>
              </>
            )}
          </main>
        </div>
      </div>
      <div className='container-lg pb-5'>
        <MainFooter />
      </div>
    </>
  );
}

import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Icon from './Icon'

export default function LabelStatus({
  children,
  aprovado,
  className = '',
  mensagem,
}) {
  return (
    <div id="label-status" className={`label-status-container ${className}`}>
      {aprovado ? (
        <div className="label-status-info"></div>
      ) : (
        <OverlayTrigger
          placement="top"
          delay={{ show: 0, hide: 50 }}
          overlay={<Tooltip>{mensagem}</Tooltip>}
        >
          <div className="label-status-info">
            <Icon prefix="far" icon="question-circle" color="#DE002C" />
          </div>
        </OverlayTrigger>
      )}
      <div
        className={`label-status ${aprovado ? 'label-status-aprovado' : ''}`}
      >
        {children}
      </div>
    </div>
  )
}

import React from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import MainNav from '../components/MainNav';
import MainFooter from '../components/MainFooter';
import CustomInput from '../components/CustomInput';
import Filter from '../components/Filter';

import api from '../services/api';
import Loading from '../components/Loading';

const filters = [{ id: 'Todas', color: '#d69947' }, { id: 'Geral', color: '#d69947' }, { id: 'Profissional', color: '#d69947' }, { id: 'Institucional', color: '#d69947' }, { id: 'Seletivas', color: '#d69947' }];

export default function Noticias() {
  const [noticias, setNoticias] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { path } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();
  const searchRef = React.useRef(null);
  const [page, setPage] = React.useState(1);

  const hasMore = React.useMemo(() => page <= noticias?.pages, [page, noticias.pages]);

  const urlQuery = React.useMemo(() => {
    const queries = new URLSearchParams(search);

    const query = {};

    const busca = queries.get('busca') || '';
    const categoria = queries.get('categoria') || '';

    if (busca) {
      query.busca = busca;
    }

    if (categoria) {
      query.categoria = categoria;
    }

    return query
  }, [search]);

  const handleSearch = React.useCallback((e) => {
    e.preventDefault()
    const value = searchRef?.current?.value?.trim();


    const data = Object.assign({},
      urlQuery?.categoria ? { categoria: urlQuery.categoria } : null,
      value ? { busca: value } : null,
    );

    history.push({
      pathname: path,
      search: new URLSearchParams(data).toString()
    });
  }, [history, urlQuery, path]);

  const handleFilter = React.useCallback((filter) => {
    const data = Object.assign({ categoria: filter },
      urlQuery?.busca ? { busca: urlQuery.busca } : null
    );

    history.push({
      pathname: path,
      search: new URLSearchParams(data).toString()
    });
  }, [path, history, urlQuery]);

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/noticias?page=${page}`, {
        params: {
          categoria: urlQuery?.categoria,
          search: urlQuery?.busca,
        }
      });

      const data = response?.data?.list?.map(item => ({
        ...item,
        dateFormatted: moment(item?.created_at).format('DD/MM/YYYY [às] HH:mm')
      }))

      setNoticias({...noticias, list: [...noticias?.list, ...data] })
      setPage(page + 1);
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Erro ao buscar a lista de sócios, por favor, recarregue a página.');
    } finally {
      setLoading(false);
    }
  }, [noticias, page, urlQuery.busca, urlQuery.categoria]);

  React.useEffect(() => {
    (async () => {
      try {
        setPage(1);
        setLoading(true);
        const response = await api.get('/noticias?page=1', {
          params: {
            categoria: urlQuery?.categoria,
            search: urlQuery?.busca,
          }
        });

        const data = response?.data?.list?.map(item => ({
          ...item,
          dateFormatted: moment(item?.created_at).format('DD.MM.YYYY')
        }))
        setNoticias({ list: data || [], pages: response?.data?.pages, total: response?.data?.total });
        setPage(p => p + 1);
      } catch (err) {
        toast.error(err?.response?.data?.erro || 'Erro ao buscar a lista de sócios, por favor, recarregue a página.');
      } finally {
        setLoading(false);
      }
    })();
  }, [urlQuery]);

  return (
    <div className="min-h-100 d-flex flex-column">
      {loading && <Loading delayTime={200} />}
      
      <MainNav/>
      

      <div className="container-lg">
        <div className="filter-news-container mb-5 p-3">
          <div className="row align-items-center">
            <form onSubmit={handleSearch} className="form-pesquisa-news col-12 col-lg-4 mt-3 mt-lg-0">
              <CustomInput inputRef={searchRef} type="text" className="filter-noticias" placeholder="Pesquisar..." sendIcon="search" iconColor="#d69947"/>
            </form>
            <div className="col-12 col-lg-8">
              <Filter className="w-100 mt-4 mt-lg-0 pl-0 pl-lg-4 filter-noticias-grid" selected={urlQuery?.categoria || 'todas'} filters={filters} setSelected={handleFilter} />
            </div>
          </div>
        </div>
      </div>

      <main className="container-lg pb-5">
        {noticias && noticias.list?.length > 0 ? (
          <div className="row">
            {/* daqui pra baixo sao as 2 grandes */}
            {noticias?.list?.filter((item, idx) => idx < 2)?.map((noticia) => (
              <div className="col-12 col-md-6 noticias-grande-container mt-4" key={noticia?._id}>
                <Link className="no-decoration" to={`/noticias/${noticia?._id}`}>
                  <div className="p-4 noticias--grande position-relative" style={{ backgroundImage: `url(${noticia?.banner})`, backgroundSize: 'cover', minHeight: 328, backgroundRepeat: 'no-repeat' }}>
                    <div className="position-absolute w-100 h-100 gradiente-leitura"></div>
                    <div className="z-index-99">
                      <span className="noticias-data-tipo">{noticia?.dateFormatted}</span><span className="noticias-data-tipo-separador">|</span><span className="noticias-data-tipo text-uppercase">{noticia?.categoria}</span>
                      <h2 className="noticias-link">{noticia?.titulo}</h2>
                    </div>
                  </div>
                </Link>
              </div>

            ))}
            {/* daqui pra baixo sao as pequenas */}
            {noticias?.list?.filter((item, idx) => idx > 1)?.map((noticia) => (
              <div className="col-12 col-lg-6 mt-5 noticia-pequena-container" key={noticia?._id}>
                <Link className="no-decoration" to={`/noticias/${noticia?._id}`}>
                  <div className="d-flex noticias-mobile">
                    <img className="imagem-noticia--pequena" src={noticia?.banner} alt={`Banner Notícia ${noticia?.titulo}`} />
                    <div className="noticia-pequena-texto">
                      <span className="noticias-pequenas-data-tipo noticias-fonte--pequena-bold">{noticia?.dateFormatted}</span><span className="noticias-pequenas-data-tipo-separador">|</span><span className="noticias-pequenas-data-tipo text-uppercase">{noticia?.categoria}</span>
                      <h3 className="noticias-fonte--media line-clamp-2 mt-2 font-weight-bold">{noticia?.titulo}</h3>
                      <div className="noticias-fonte--pequena line-clamp-3" dangerouslySetInnerHTML={{ __html: noticia.corpo }}></div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
            }
          </div>
        ) : (
          <p>Esta página está vazia</p>
        )}

        {hasMore && (
          <div className="button-centered mt-5">
            <button className="button--xlarge" onClick={loadMore}>MAIS NOTÍCIAS</button>
          </div>
        )}

      </main>
      <div className="container-lg pb-5 mt-auto">
        <MainFooter/>
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react'

export default function CustomTable({ columns, data }) {
	const [windowSize, setWindowSize] = useState(getWindowSize())

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize())
		}

		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	}, [])

	return windowSize.innerWidth > 991 ? (
		<table className='custom-table'>
			<thead>
				{columns?.map((column, idx) =>
					idx === 0 ? (
						<th className={`pt-4 pl-4 ${column.class}`}>{column.text}</th>
					) : idx === columns.length - 1 ? (
						<th className={`pt-4 pr-4 ${column.class}`}>{column.text}</th>
					) : (
						<th className={`pt-4 ${column.class}`}>{column.text}</th>
					)
				)}
			</thead>
			<tbody>
				{data?.map(items => (
					<tr>
						{items.map((item, idx) =>
							idx === 0 ? (
								<td className={`py-4 pl-4 ${item.class}`}>{item.value}</td>
							) : idx === items.length - 1 ? (
								<td className={`py-4 pr-4 ${item.class}`}>{item.value}</td>
							) : (
								<td className={`py-4 ${item.class}`}>{item.value}</td>
							)
						)}
					</tr>
				))}
			</tbody>
		</table>
	) : (
		<div>
			<div className='custom-table-card-header mb-3 p-4'>
				{columns?.map(column => (
					<span style={column.styleCard}>{column.text}</span>
				))}
			</div>

			<div className='custom-table-card'>
				{data?.map(items => (
					<div className='border-0 p-4'>
						{items.map(item => (
							<span style={item.styleCard}>{item.value}</span>
						))}
					</div>
				))}
			</div>
		</div>
	)
}

function getWindowSize() {
	const { innerWidth, innerHeight } = window
	return { innerWidth, innerHeight }
}

import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import ReactCrop from 'react-image-crop'
import { Modal } from 'react-bootstrap'
import Resizer from 'react-image-file-resizer'
import api from '../../services/api'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import { yupResolver } from '@hookform/resolvers'
import { schemaPatrocinadores } from '../../validators/formPatrocinadores'
import Loading from '../../components/Loading'
import Icon from '../../components/Icon'
import Select from 'react-select'

export default function PatrocinadoresCadastro() {
  const { id } = useParams()
  const history = useHistory()

  const [form, setForm] = React.useState({
    nome: '',
    tipo: '',
    reservas: '',
  })
  const [show, setShow] = React.useState(false)
  const [disabled, setDisabled] = React.useState(true)
  const [disablede, setDisablede] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [srcMain, setSrcMain] = React.useState('')
  const [identifiers, setIdentifiers] = React.useState({
    function: 'create',
    buttonText: 'Cadastrar',
    successMessage: 'Patrocinador cadastrado com sucesso!',
  })
  const tipos = [
    { label: 'Ouro', value: 'ouro' },
    { label: 'Prata', value: 'prata' },
    { label: 'Bronze', value: 'bronze' },
    { label: 'Apoiador', value: 'apoiador' },
    { label: 'Torcedor', value: 'torcedor' },
  ]
  const {
    errors,
    handleSubmit: handleSubmitForm,
    register,
    setError,
    control,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schemaPatrocinadores),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  })

  const [upImg, setUpImg] = React.useState()
  const imgRef = React.useRef(null)
  const previewCanvasRef = React.useRef(null)

  // const defaultCrop = { aspect: 1 }
  const [crop, setCrop] = React.useState()
  const [completedCrop, setCompletedCrop] = React.useState(null)

  useEffect(() => {
    reset(form)
  }, [reset, form])

  useEffect(() => {
    ;(async () => {
      if (id) {
        setLoading(true)
        setIdentifiers({
          function: 'edit',
          buttonText: 'Editar',
          successMessage: 'Patrocinador editado com sucesso!',
        })

        const response = await api.get(`/patrocinadores/${id}`);
        setSrcMain(response?.data?.img?.url);
        setForm(response?.data);
        setLoading(false)
      }
    })()
  }, [id])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
  }, [completedCrop])

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        'png',
        90,
        0,
        (uri) => {
          resolve(uri)
        },
        'blob'
      )
    })

  async function handleSubmit(data) {
    if (identifiers.function === 'edit') {
      try {
        const canvas = previewCanvasRef.current

        if (completedCrop) {
          canvas.toBlob(
            async (blob) => {
              try {
                const formData = new FormData()
                const resizedFile = await resizeFile(blob)

                formData.set('file', resizedFile, `${data.nome.replace(' ', '_')}.png`)
                formData.set('nome', data.nome)
                formData.set('tipo', data.tipo)
                formData.set('reservas', data.reservas)

                setDisablede(true)
                setLoading(true)

                await api.put(`/patrocinadores/${id}`, formData)
                toast.success(identifiers.successMessage)
                setDisablede(false)
                setLoading(false)
                return history.push('/area-admin/patrocinadores')
              } catch (err) {
                setDisablede(false)
                setLoading(false)
                return toast.error(
                  err?.response?.data?.erro || 'Ocorreu um erro ao editar as informações.'
                )
              }
            },
            'image/png',
            1
          )

          return
        }

        const anotherFormData = new FormData()

        anotherFormData.set('nome', data.nome)
        anotherFormData.set('tipo', data.tipo)
        anotherFormData.set('reservas', data.reservas)
        setDisablede(true)
        setLoading(true)
        await api.put(`patrocinadores/${id}`, anotherFormData)
        toast.success(identifiers.successMessage)
        return history.push('/area-admin/patrocinadores')
      } catch (err) {
        return toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao editar as informações.')
      }
    }

    try {
      const canvas = previewCanvasRef.current

      if (!completedCrop) {
        setError('imagem', {
          types: {
            required: true,
          },
          message: 'Campo é obrigatório.',
        })
        return
      }

      canvas.toBlob(
        async (blob) => {
          try {
            const resizedFile = await resizeFile(blob)
            const oneMoreFormData = new FormData()
            oneMoreFormData.set('file', resizedFile, `${data.nome.replace(' ', '_')}.png`)
            oneMoreFormData.set('nome', data.nome)
            oneMoreFormData.set('tipo', data.tipo)
            oneMoreFormData.set('reservas', data.reservas)
            setDisablede(true)
            setLoading(true)
            await api.post('/patrocinadores', oneMoreFormData)
            toast.success(identifiers.successMessage)
            return history.push('/area-admin/patrocinadores')
          } catch (error) {
            return toast.error(
              error?.response?.data?.erro || 'Ocorreu um erro ao criar o patrocinador.'
            )
          }
        },
        'image/png',
        1
      )
    } catch (err) {
      return toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao criar o patrocinador.')
    }
  }

  const onLoadImage = (img) => {
    imgRef.current = img
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setDisabled(true)
      handleShow(true)
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleClose = () => {
    imgRef.current = null
    setCompletedCrop(null)
    // setCrop(defaultCrop)
    setShow(false)
  };

  const handleConclude = () => {
    // setCrop(defaultCrop)
    setShow(false)
  };

  const handleShow = () => setShow(true);

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <AdminMenu />
      <main className='wrapper bg-verm'>
        <div className='container mt-3 pt-3'>
          <form onSubmit={handleSubmitForm(handleSubmit)} className='card p-4 border-0'>
            <div className='d-flex flex-wrap color-black'>
              <div className='form-group col-12 mb-5'>
                <label className={identifiers.function === 'create' ? 'control-label' : ''}>
                  Logo
                </label>
                <div>
                  <input
                    ref={register}
                    hidden={true}
                    type='file'
                    className='form-control-file'
                    name='imagem'
                    id='imagem'
                    accept='image/*'
                    onChange={onSelectFile}
                  />
                  <label htmlFor='imagem'>
                    <canvas
                      ref={previewCanvasRef}
                      hidden={!imgRef.current}
                      className='img-input'
                    />
                    {!imgRef.current && srcMain && (
                        <img className='img-input' src={srcMain} alt='' />
                    )}
                    {!imgRef.current && !srcMain && (
                      <div className='img-input d-flex'>
                        <Icon className='mx-auto my-auto' color='#969cb2' icon='image'></Icon>
                      </div>
                    )}
                  </label>
                </div>
                <span className='retorno-invalido'>{errors?.imagem?.message}</span>
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                dialogClassName='modal-90w'
              >
                <Modal.Header>
                  <Modal.Title className='modal-title'>Cortar a imagem</Modal.Title>
                    <button
                      onClick={handleClose}
                      type="button"
                      className="btn-close close p-1"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <Icon
                        icon={'times-circle'}
                        size="2x"
                        color="#FFF"
                        className="d2"
                      />
                    </button>
                </Modal.Header>
                <Modal.Body className='d-flex align-items-center justify-content-center'>
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoadImage}
                    crop={crop}
                    onChange={(c) => {
                      setDisabled(!(c.width >= 100))
                      return setCrop(c)
                    }}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    disabled={disabled}
                    className='btn btn-verde'
                    type='button'
                    onClick={handleConclude}
                  >
                    Concluído
                  </button>
                </Modal.Footer>
              </Modal>

              <div className='form-group col-12 col-md-6'>
                <label htmlFor='nome' className='control-label'>
                  Patrocinador
                </label>
                <input
                  id='nome'
                  name='nome'
                  type='text'
                  className='form-control'
                  placeholder='Nome do patrocinador'
                  ref={register}
                />
                <span className='retorno-invalido'>{errors?.nome?.message}</span>
              </div>
              <div className='form-group col-12 col-md-6'>
                <label htmlFor='nome' className='control-label'>
                  Nível
                </label>
                <Controller
                  control={control}
                  id='tipo'
                  name='tipo'
                  // defaultValue={defaultValue}
                  render={({ onChange }) => (
                    <Select
                      value={tipos.find((tipo) => tipo.value === form.tipo)}
                      options={tipos}
                      placeholder='Selecione um tipo'
                      onChange={(e) => {
                        if (e)
                          setForm({
                            ...getValues(),
                            tipo: e.value,
                          })
                        console.log(form)
                        return onChange(e?.value)
                      }}
                    />
                  )}
                />
                <span className='retorno-invalido'>{errors?.tipo?.message}</span>
              </div>
              <div className='form-group col-12 col-md-6'>
                <label htmlFor='numero' className='control-label'>
                  Reservas por jogo
                </label>
                <input
                  id='reservas'
                  name='reservas'
                  type='number'
                  className='form-control'
                  placeholder='0000'
                  ref={register}
                />
                <span className='retorno-invalido'>{errors?.reservas?.message}</span>
              </div>
            </div>

            <div className='d-flex justify-content-between col-12'>
              <button
                type='button'
                className='btn btn-light border'
                onClick={() => history.push('/area-admin/patrocinadores')}
              >
                Voltar
              </button>
              <button type='submit' className='btn btn-verde' disabled={disablede || loading}>
                {identifiers.buttonText}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  )
}

import React from 'react'
import { Nav } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import AdminMenu from '../../components/AdminMenu'
import NavContent, { tabs } from '../../components/NavContent'

export default function SociosEditar() {
  const { route } = useParams()
  const history = useHistory()

  return (
    <>
      <AdminMenu />
      <main className="wrapper bg-verm">
        <div className="container pb-4">
          <div className="row">
            <div className="mt-3 pt-3 col-12 col-lg-3">
              <div className="card p-4 border-0">
                <Nav
                  variant="pills"
                  className="flex-column"
                  defaultActiveKey={tabs[route] ? route : 'pessoal'}
                  onSelect={(selected) => {
                    history.push(selected)
                  }}
                >
                  <Nav.Link
                    className="nav-item-bg"
                    eventKey="pessoal"
                    active={route === 'pessoal'}
                  >
                    Pessoal
                  </Nav.Link>
                  <Nav.Link
                    className="nav-item-bg"
                    eventKey="adicional"
                    active={route === 'adicional'}
                  >
                    Adicional
                  </Nav.Link>
                  <Nav.Link
                    className="nav-item-bg"
                    eventKey="pagamento"
                    active={route === 'pagamento'}
                  >
                    Pagamento
                  </Nav.Link>
                  <Nav.Link
                    className="bg-yellow bg-yellow-hover font-weight-bold text-white"
                    eventKey="novopagamento"
                    active={route === 'novopagamento'}
                  >
                    + Novo pagamento
                  </Nav.Link>
                </Nav>
              </div>
            </div>

            <div className="mt-4 mt-lg-3 pt-lg-3 col-12 col-lg-9">
              <div className="p-4-15 border-0 card">
                <NavContent selected={route} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

import React from 'react';
import { Link } from 'react-router-dom';

import Cpf from '../components/steps/Login/Cpf';
import Method from '../components/steps/Login/Method';
import Password from '../components/steps/Login/Password';
import SendMail from '../components/steps/Login/SendMail';
import Code from '../components/steps/Login/Code';
import NewPassword from '../components/steps/Login/NewPassword';

import Stepper from '../components/Stepper';
import Icon from '../components/Icon';

import LogoVermelho from '../assets/LogoVermelho.svg';

const displayArrow = {
  0: false,
  1: true,
  2: true,
  3: true,
  4: true,
  5: false
}

export default function Login() {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [stepHistory, setStepHistory] = React.useState([]);

  function handleGoBack() {
    const [lastItem] = stepHistory.slice(-1);

    setCurrentStep(lastItem);
    setStepHistory(stepHistory.filter(history => history !== lastItem));
  }

  function setCurrentStepAndSaveHistory(step) {
    if (currentStep === 5) {
      setStepHistory(stepHistory.filter(history => history < 2));
      setCurrentStep(step);
      return;
    }

    setStepHistory([...stepHistory, currentStep]);
    setCurrentStep(step);
  }

  return (
    <main className="d-flex">
      <section className="brand-section">
        <Link to="/">
          <img src="./assets/logo.png" alt="Santa Rosa Futsal" />
        </Link>
      </section>
      <section className="form-login-section">
        {displayArrow[currentStep] && (
          <button type="button" className="login-back" onClick={handleGoBack}>
            <Icon
              icon="arrow-left"
              color="#333333"
            />
          </button>
        )}
        <Link to="/">
          <img src={LogoVermelho} alt="Santa Rosa Futsal" width="120" height="120" />
        </Link>
        <Stepper
          parentCurrentStep={currentStep}
          parentSetCurrentStep={setCurrentStepAndSaveHistory}
          steps={[
            {
              component: (props) => <Cpf {...props} />,
            },
            {
              component: (props) => <Method {...props} />,
            },
            {
              component: (props) => <Password {...props} />,
            },
            {
              component: (props) => <SendMail {...props} />,
            },
            {
              component: (props) => <Code {...props} />,
            },
            {
              component: (props) => <NewPassword {...props} />,
            },
          ]}
        />

        {/* <a
          className="login-nuvoni"
          href="https://www.nuvoni.com.br"
          rel="noopener noreferrer"
          target="_blank"
        >
          NUVONI
        </a> */}
      </section>
    </main>
  );
}

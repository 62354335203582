import React from 'react';
import InputMask from "react-input-mask";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import axios from 'axios';
import Cleave from 'cleave.js/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../services/api';

import { useCadastro } from '../../../Contexts/CadastroContext';

import fieldsToFill from '../../../utils/fieldsToFill';

import LoadingSpinner from '../../LoadingSpinner';
import Select from '../../Select';

import { schemaCadastroFirstStep } from '../../../validators/formCadastroAdmin';

export default function Passo1Cadastro({ navigateOnStep, currentStep }) {
  const history = useHistory();
  const [plano, setPlano] = React.useState();
  const { formData, updateFormData } = useCadastro();
  const { register, handleSubmit, control, errors, getValues, reset, setValue, setError: setErrorForm } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schemaCadastroFirstStep),
    defaultValues: {
      ...formData,
      ...formData.endereco
    },
    context: { plano }
  });

  const [selectOptions, setSelectOptions] = React.useState([]);
  const [currentCep, setCurrentCep] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [amigoOptions, setAmigoOptions] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        if(formData?.plano) {
          await setPlanoByPublic(formData.plano)
        }
      } catch (err) {
        toast.error(err?.response?.data?.erro || 'Erro ao buscar plano selecionado.');
      }

      try {
        const response = await api.get('/planos');

        const data = response?.data?.map((opt) => { return { value: opt?._id, label: opt?.descricao, public: opt?.public } });

        setSelectOptions(data);
      } catch (err) {
        toast.error(err?.response?.data?.erro || 'Erro ao buscar planos.');
      } finally {
        setLoading(false);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSubmitForm({
    bairro,
    cep,
    cidade,
    complemento,
    logradouro,
    numero,
    uf,
    plano,
    nome,
    cpf,
    dt_nasc,
    email,
    telefone,
    amigo,
  }) {
    try {
      const data = {
        plano,
        amigo,
        nome,
        cpf,
        dt_nasc,
        email,
        telefone,
        endereco: {
          cep,
          cidade,
          uf: uf.toUpperCase(),
          logradouro,
          numero,
          bairro,
          complemento
        }
      }

      const response = await api.get(`/socio?cpf=${cpf}`);

      if (response?.data?.cadastro) {
        setErrorForm('cpf', {
          type: 'manual',
          message: 'Usuário com este CPF já está cadastrado.'
        });
        return;
      }

      updateFormData(data);
      setTimeout(() => {
        navigateOnStep(currentStep + 1);
      }, 50);
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Erro ao validar o CPF.');
    }
  }

  async function changeCep(e) {
    if (/^\d{5}-\d{3}$/.test(e.target.value) && currentCep !== e.target.value) {
      try {
        setCurrentCep(e.target.value);
        const { data } = await axios.get(`https://viacep.com.br/ws/${e.target.value}/json/`);

        if (!data?.erro) {
          fieldsToFill.forEach((field) => {
            setValue(field.fieldForm, data[field.fieldResponse]);
          });

          reset({
            ...getValues(),
            numero: '',
            uf: data.uf
          });
        }
      } catch (err) {
        //
      }
    }
  }

  async function handleChangePlano(event) {
    await setPlanoByPublic(event.target.value)
  }

  async function setPlanoByPublic(planoId) {
    const response = await api.get(`planos/${planoId}`)

    setPlano(response.data)

    const isPublic = response.data.public 
    if(!isPublic) {
      const socios = await api.get('/socios/individual')

      let data = socios?.data?.list?.map((opt) => { return { value: opt?._id, label: opt?.nome} })

      data.unshift({ value: "0", label: "Será cadastrado posteriormente" })
      
      setAmigoOptions(data)
    }
  }

  return (
    <div className="mt-4 content-step p-4 border-0 card color-black">
      {loading && (
        <LoadingSpinner className="d-flex justify-content-center" />
      )}

      {!loading && (
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="form-group">
            <label htmlFor="plano" className="control-label">Plano</label>
            <Select onChange={(e) => handleChangePlano(e)} id="plano" className="custom-select" name="plano" options={selectOptions} selected={formData?.plano} label="plano" register={register} />
            <div className="retorno-invalido">{errors?.plano?.message}</div>
          </div>

          {plano?.public === false ? 
            <div className="form-group">
              <label htmlFor="amigo" className="control-label">Selecione o amigo</label>
              <Select className="custom-select" options={amigoOptions} selected={formData?.amigo} name="amigo" label="amigo" register={register} />
              <div className="retorno-invalido">{errors?.amigo?.message}</div>
            </div>
          : null}

          <div className="form-group">
            <label htmlFor="nome" className="control-label">Nome</label>
            <input
              id="nome"
              name="nome"
              type="text"
              className="form-control"
              placeholder="Nome do sócio"
              ref={register}
            />
            <span className="retorno-invalido">{errors?.nome?.message}</span>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-6">
              <label htmlFor="cpf" className="control-label">CPF</label>
              <Controller
                id="cpf"
                name="cpf"
                className="form-control"
                as={InputMask}
                control={control}
                inputRef={register}
                maskChar={null}
                mask="999.999.999-99"
                placeholder="000.000.000-00"
              />
              <span className="retorno-invalido">{errors?.cpf?.message}</span>
            </div>
            <div className="form-group col-lg-6">
              <label htmlFor="data-de-nascimento" className="control-label">Data de nascimento</label>
              <Controller
                className="form-control"
                id="data-de-nascimento"
                name="dt_nasc"
                as={<Cleave ref={register} />}
                control={control}
                placeholder="dd/mm/aaaa"
                options={{
                  date: true,
                  datePattern: ['d', 'm', 'Y'],
                }}
              />
              <span className="retorno-invalido">{errors?.dt_nasc?.message}</span>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="email" className="control-label">E-mail</label>
              <input id="email" type="email" className="form-control" name="email" placeholder="seuemail@dominio.com" ref={register} />
              <span className="retorno-invalido">{errors?.email?.message}</span>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phone" className="control-label">Celular</label>
              <Controller
                id="phone"
                name="telefone"
                className="form-control"
                as={InputMask}
                control={control}
                inputRef={register}
                maskChar={null}
                mask="(99) 99999-9999"
                placeholder="(00) 00000-0000"
              />
              <span className="retorno-invalido">{errors?.telefone?.message}</span>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="cep" className="control-label">CEP</label>
              <Controller
                name="cep"
                id="cep"
                as={InputMask}
                className="form-control"
                mask="99999-999"
                placeholder="00000-000"
                control={control}
                maskChar={null}
                inputRef={register}
                onKeyUp={changeCep}
              />
              <span className="retorno-invalido">{errors?.cep?.message}</span>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="cidade" className="control-label">Cidade</label>
              <input id="cidade" type="text" className="form-control" name="cidade" placeholder="Nome da cidade" ref={register} />
              <span className="retorno-invalido">{errors?.cidade?.message}</span>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="uf" className="control-label">UF</label>
              <Controller
                id="uf"
                name="uf"
                className="form-control"
                as={InputMask}
                maskChar={null}
                mask="aa"
                control={control}
                inputRef={register}
                placeholder="Sigla do estado"
              />
              <span className="retorno-invalido">{errors?.uf?.message}</span>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-8">
              <label htmlFor="logradouro" className="control-label">Logradouro</label>
              <input id="logradouro" type="text" className="form-control" name="logradouro" placeholder="Av. Rio Branco" ref={register} />
              <span className="retorno-invalido">{errors?.logradouro?.message}</span>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero" className="control-label">Número</label>
              <input id="numero" type="text" className="form-control" name="numero" placeholder="Número da casa" ref={register} />
              <span className="retorno-invalido">{errors?.numero?.message}</span>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="bairro" className="control-label">Bairro</label>
              <input id="bairro" type="text" className="form-control" name="bairro" placeholder="Bairro onde mora" ref={register} />
              <span className="retorno-invalido">{errors?.bairro?.message}</span>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="complemento">Complemento</label>
              <input id="complemento" type="text" className="form-control" name="complemento" ref={register} />
              <span className="retorno-invalido">{errors?.complemento?.message}</span>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <button type="button" className="btn btn-light border" onClick={() => history.push('/area-admin/socios')}>Voltar</button>
            <button type="submit" className="btn btn-verde">Avançar</button>
          </div>
        </form>
      )}
    </div>
  );
}

import React from "react"
import Cleave from "cleave.js/react";
import { useLocation, useHistory } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import api from '../../../services/api';

import { useCadastro } from '../../../Contexts/CadastroContext';
import CustomInput from "../../CustomInput";
import { schemaCadastroFirstStep } from '../../../validators/formCadastro';
import nameCapitalize from '../../../utils/nameCapitalize';

export default function Pessoal({ navigateOnStep, currentStep }) {
    const { search } = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(search);
    const [, setLoading] = React.useState(true);
    const { formData, updateFormData } = useCadastro();
    const [planoState, setPlano] = React.useState();
    const { register, handleSubmit, errors, setError: setErrorForm, getValues, setValue } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        resolver: yupResolver(schemaCadastroFirstStep),
        context: {
            precisaPlano: !planoState,
        },
        defaultValues: {
            ...formData,
        }
    });

    async function onSubmit(data) {
        try {
            const response = await api.get('/socio', {
                params: {
                    cpf: data?.cpf
                }
            });

            if (response?.data?.cadastro) {
                setErrorForm('cpf', {
                    type: 'manual',
                    message: 'Usuário com este CPF já está cadastrado.'
                });
                return;
            }

            const planoId = query.get('plano');
            data.plano = planoId;

            updateFormData(data);
            navigateOnStep(currentStep + 1);
        } catch (error) {
            toast.error(error?.response?.data?.erro || 'Ocorreu um erro ao verificar se você já possuí cadastro.')
        }
    }

    React.useEffect(() => {
        ; (async () => {
            try {
                setLoading(true)
                const planoId = query.get('plano');
                const plano = await api.get(`/planos/${planoId}`)
                setPlano(plano.data)

            } catch (err) {
                toast.error(
                    err?.response?.data?.erro ||
                    'Erro ao buscar os planos, por favor, recarregue a página.',
                )
            } finally {
                setLoading(false)
            }
        })()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <form className="form-steps-container" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="login-title">Você escolheu o plano {planoState?.descricao}</h1>
            <h2 className="login-subtitle">Santa Rosa Futsal</h2>
            <div className="row">
                <div className="form-group mb-1 col-12 col-lg-6">
                    <label className="login-label control-label" htmlFor="nome">Nome</label>
                    <CustomInput
                        value={getValues('nome')}
                        icon="signature"
                        iconColor="#969CB2"
                        inputType="text"
                        name="nome"
                        id="nome"
                        className={`login-input ${errors?.nome ? 'socio-input-error' : ''}`}
                        placeholder="Ex.: João da Silva" inputRef={register}
                        onChange={(e) => setValue('nome', nameCapitalize(e.target.value))}
                    />
                    <div className="retorno-invalido mh-19">{errors?.nome?.message}</div>
                </div>
                <div className="form-group mb-1 col-12 col-lg-6">
                    <label className="login-label control-label" htmlFor="cpf">CPF</label>
                    <CustomInput
                        value={formData?.cpf}
                        as={Cleave}
                        options={{
                            delimiters: ['.', '.', '-'],
                            blocks: [3, 3, 3, 2],
                            numericOnly: true
                        }}
                        icon="id-card"
                        iconColor="#969CB2"
                        inputType="tel"
                        name="cpf"
                        id="cpf"
                        className={`login-input ${errors?.cpf ? 'socio-input-error' : ''}`}
                        placeholder="000.000.000-00"
                        htmlRef={register}
                    />
                    <div className="retorno-invalido mh-19">{errors?.cpf?.message}</div>
                </div>
                <div className="form-group mb-1 col-12 col-lg-6">
                    <label className="login-label control-label" htmlFor="email">E-mail</label>
                    <CustomInput
                        inputRef={register}
                        icon="envelope"
                        iconColor="#969CB2"
                        inputType="email"
                        name="email"
                        id="email"
                        className={`login-input ${errors?.email ? 'socio-input-error' : ''}`}
                        placeholder="seu@email.com"
                    />
                    <div className="retorno-invalido mh-19">{errors?.email?.message}</div>
                </div>
                <div className="form-group mb-1 col-12 col-lg-6">
                    <label className="login-label control-label" htmlFor="tel">Celular</label>
                    <CustomInput
                        value={formData?.tel}
                        htmlRef={register}
                        as={Cleave}
                        options={{
                            delimiters: ['(', ') ', '-'],
                            blocks: [0, 2, 5, 4],
                            numericOnly: true
                        }}
                        icon="phone"
                        iconColor="#969CB2"
                        inputType="tel"
                        name="tel"
                        id="tel"
                        className={`login-input ${errors?.tel ? 'socio-input-error' : ''}`}
                        placeholder="(00) 00000-0000"
                    />
                    <div className="retorno-invalido mh-19">{errors?.tel?.message}</div>
                </div>
                <div className="form-group mb-1 col-12 col-lg-6">
                    <label className="login-label control-label" htmlFor="dt_nasc">Data de Nascimento</label>
                    <CustomInput
                        value={formData?.dt_nasc}
                        htmlRef={register}
                        as={Cleave}
                        options={{
                            date: true,
                            datePattern: ['d', 'm', 'Y']
                        }}
                        icon="baby"
                        iconColor="#969CB2"
                        inputType="tel"
                        name="dt_nasc"
                        id="dt_nasc"
                        className={`login-input ${errors?.dt_nasc ? 'socio-input-error' : ''}`}
                        placeholder="dd/mm/aaaa"
                    />
                    <div className="retorno-invalido mh-19">{errors?.dt_nasc?.message}</div>
                </div>
                <div className="form-group col-12 mb-0">
                    <input ref={register} type="checkbox" name="termos" id="termos" />
                    <label className="ml-2 mb-0" htmlFor="termos">Aceito os <a className="text-yellow font-weight-bold" target="_blank" rel="noopener noreferrer" href="/files/termo_aceite.pdf">Termos de uso</a></label>
                    <div className="retorno-invalido mh-19">{errors?.termos?.message}</div>
                </div>
            </div>
            <div className="row flex-wrap-reverse">
                <div className="col-12 col-lg-6">
                    <button type="button" className="login-button btn-grey" id="voltar" onClick={history?.goBack}>Voltar</button>
                </div>
                <div className="col-12 col-lg-6">
                    <button type="submit" className="login-button">Próximo</button>
                </div>
            </div>
        </form >
    )
}


// return (
/* <form className="form-steps-container" onSubmit={handleSubmit(onSubmit)} >
    <h1 className="login-title">Você escolheu o plano {planoState?.descricao}</h1>
    <h2 className="login-subtitle">Santa Rosa Futsal</h2>
    <div className="row">
        {showInput && (
                    <div className="form-group mb-1 col-12 col-lg-6">
                        <label className="login-label control-label" htmlFor="plano">Plano</label>
                        <CustomSelect
                            icon="medal"
                            iconColor="#969CB2"
                            inputType="text"
                            name="plano"
                            id="plano"
                            className={`login-select-input ${errors?.plano ? 'socio-input-error' : ''}`}
                            inputRef={register}
                            options={listPlanos}
                            onChange={(e) => changePlano(e)}
                        />
                        <div className="retorno-invalido mh-19">{errors?.plano?.message}</div>
                    </div>
                )} 
        <div className="form-group mb-1 col-12 col-lg-6">
            <label className="login-label control-label" htmlFor="email">E-mail</label>
            <CustomInput
                inputRef={register}
                icon="envelope"
                iconColor="#969CB2"
                inputType="email"
                name="email"
                id="email"
                className={`login-input ${errors?.email ? 'socio-input-error' : ''}`}
                placeholder="seu@email.com"
            />
            <div className="retorno-invalido mh-19">{errors?.email?.message}</div>
        </div>
        <div className="form-group mb-1 col-12 col-lg-6">
            <label className="login-label control-label" htmlFor="tel">Celular</label>
            <CustomInput
                value={formData?.tel}
                htmlRef={register}
                as={Cleave}
                options={{
                    delimiters: ['(', ') ', '-'],
                    blocks: [0, 2, 5, 4],
                    numericOnly: true
                }}
                icon="phone"
                iconColor="#969CB2"
                inputType="tel"
                name="tel"
                id="tel"
                className={`login-input ${errors?.tel ? 'socio-input-error' : ''}`}
                placeholder="(00) 00000-0000"
            />
            <div className="retorno-invalido mh-19">{errors?.tel?.message}</div>
        </div>
        <div className="form-group mb-1 col-12 col-lg-6">
            <label className="login-label control-label" htmlFor="dt_nasc">Data de Nascimento</label>
            <CustomInput
                value={formData?.dt_nasc}
                htmlRef={register}
                as={Cleave}
                options={{
                    date: true,
                    datePattern: ['d', 'm', 'Y']
                }}
                icon="baby"
                iconColor="#969CB2"
                inputType="tel"
                name="dt_nasc"
                id="dt_nasc"
                className={`login-input ${errors?.dt_nasc ? 'socio-input-error' : ''}`}
                placeholder="dd/mm/aaaa"
            />
            <div className="retorno-invalido mh-19">{errors?.dt_nasc?.message}</div>
        </div>
        <div className="form-group col-12 mb-0">
            <input ref={register} type="checkbox" name="termos" id="termos" />
            <label className="ml-2 mb-0" htmlFor="termos">Aceito os <a className="text-yellow font-weight-bold" target="_blank" rel="noopener noreferrer" href="/files/termo_aceite.pdf">Termos de uso</a></label>
            <div className="retorno-invalido mh-19">{errors?.termos?.message}</div>
        </div>
    </div>
    <div className="row flex-wrap-reverse">
        <div className="col-12 col-lg-6">
            <button type="button" className="login-button btn-grey" id="voltar" onClick={history?.goBack}>Voltar</button>
        </div>
        <div className="col-12 col-lg-6">
            <button type="submit" className="login-button">Próximo</button>
        </div>
    </div>
</form> */
    // )

import React from 'react'
import Breadcrumb from '../../components/Breadcrumb'
import MainNav from '../../components/MainNav'
import { toast } from 'react-toastify'
import api from '../../services/api'
import Loading from '../../components/Loading'

import MainFooter from '../../components/MainFooter'

export default function Diretoria({ name }) {
  const [diretoria, setDiretorias] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get('/diretoria')

        setDiretorias(response.data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar as informações das diretorias.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <div className="min-h-100 d-flex flex-column">
      {loading && <Loading delayTime={200} />}
      <MainNav />

      <div className="container-lg">
        <Breadcrumb pageName={name} />

        <main className="pt-5">
          {diretoria?.length > 0 ? (
            <div className="d-flex justify-content-center flex-wrap gap-3">
              {diretoria?.map((diretorias) => (
                <div key={diretorias?._id} className="fs-141">
                  <img
                    height="170"
                    width="170"
                    src={diretorias?.img?.url}
                    alt="diretorias?.nome"
                  />
                  <strong className="d-block mt-3">{diretorias?.nome}</strong>
                  <p>{diretorias?.cargo}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="ADC">Esta página está vazia</div>
          )}
        </main>
      </div>

      <div className="container-lg pb-5">
        <MainFooter />
      </div>
    </div>
  )
}

import React from 'react'
import MainNav from '../../components/MainNav'
import MainFooter from '../../components/MainFooter'
import api from '../../services/api'
import Loading from '../../components/Loading'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb'
import InstagramIcon from '../../assets/svg/instagram_fundo_branco.svg'


export default function DadosJogador({ name }){
  const { id } = useParams()
  const [jogador, setJogador] = React.useState()
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get(`/elencos/${id}`)
        setJogador(response?.data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar as informações do jogador.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [id])

  return (
    <>
      <div className="min-h-100 d-flex flex-column">
        {loading && <Loading delayTime={200} />}
        <MainNav />
        <div className="container-lg">
        <Breadcrumb pageName={name} />
          <div className="row mt-4 pt-4 dados-jogador">
            <div className="col-md-6 d-flex flex-column img-jogador">
              <div className="col-12 col-md-4 img-jogador-inside">
                <div
                  className="boxshadow"
                  style={{
                    backgroundImage: `url(${jogador?.img?.url})`,
                    backgroundSize: 'cover',
                    minHeight: 507,
                    minWidth: 380,
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                </div>
              </div>
            </div>
            <div className="col-md-6 flex flex-col justify-evenly hist-jogador">
                <div className='inlineBlock flex flex-col'>
                  <p className="fs-24 text-yellow fw-bold tt-upper nowrap">{jogador?.nome}
                  </p>
                </div>
                <div style={{float: 'right'}}  className='flex flex-col inlineBlock'>
                  { (jogador?.instagram) ?
                      <a href={jogador?.instagram}> 
                        <img
                          src={InstagramIcon}
                          alt="Facebook"
                          width="30"
                          height="30"
                          loading="lazy"
                        />
                      </a> : <span></span>
                    }
                </div>
                <div className="flex flex-col">
                  <p className="fs-18 fw-bold nowrap tt-upper">{jogador?.posicao}</p>
                </div>
                <div className="flex flex-col">
                  <p className="fs-18 nowrap tt-upper nome-jogador">Camisa <span className="fw-bold">{jogador?.numero}</span> do Santa Rosa Futsal</p>
                </div>
                
              <h3 style={{paddingTop: "40px"}} className="fs-24 mt-0 fw-bold text-yellow tt-upper mt30">História</h3>
              { (jogador?.descricao) ?
                  <div
                    className="text-break"
                    dangerouslySetInnerHTML={{ __html: jogador?.descricao }}
              /> : <div>A história do jogador ainda não foi cadastrada.</div>
               }
            </div>  
          </div>
          <div className="container-lg pb-5">
          <MainFooter />
          </div>
        </div>
      </div>
    </>
  )
}


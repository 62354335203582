import React from 'react'
import { Link } from 'react-router-dom'
import { formatPrice } from '../utils/formatPrice'
import Icon from './Icon'

export default function PlanoCatalagoCard({ plano }) {

  return (
    <div className="col-12 col-md-6 col-lg-4 col-sm-6 order-1 order-lg-0 padCards">
      <div className="planos-card mt-lg-4 mt-sm-0">
        <div className="title-planos-card">
          <h2 className="plano-type">{plano.descricao}</h2> {/* Nome do plano */}
          <div className="price-planos">
            {plano.valor ?
              <div className="price-planos-top">
                <span className="price-planos-text">R$</span>
                <span className="big-number-card">{formatPrice(plano.valor).replace(',00', '').substring(3)}</span>
                <span className="price-planos-text">/mês</span>
              </div>
              :
              <div className="price-planos-top">
                <span className="price-planos-text">R$</span>
                <span className="big-number-card">{0}</span>
                <span className="price-planos-text">/mês</span>
              </div>}
            {
              <small className="price-planos-bottom">{`1ª de ` + formatPrice(`${(plano.valor_cart ?? 0) + (plano.valor ?? 0)}`)}</small>
            }
          </div>
        </div>
        <div className="content-planos-card">
          {plano?.beneficios?.length > 0 && <ul className="list-unstyled lista-56-mb">
            {plano?.beneficios?.map((beneficio, index) => <li key={beneficio + plano.id}><Icon icon="check" className="mr-2" />{beneficio}</li>)}
          </ul>}
          <Link to={{ pathname: `/cadastro`, state: { id: plano._id },search:`plano=${plano._id}` }} className="button-planos button--large w-100 d-flex justify-content-center align-items-center fs-16 mt-auto">
            Escolher plano <Icon className="fs-20 ml-2" icon="angle-right" />
          </Link>
        </div>
      </div>
    </div>
  )
}

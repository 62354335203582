import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import ReactCrop from 'react-image-crop'
import { Modal } from 'react-bootstrap'
import Resizer from 'react-image-file-resizer'
import api from '../../services/api'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import { yupResolver } from '@hookform/resolvers'
import { schemaElencos } from '../../validators/formElencos'
import Loading from '../../components/Loading'
import nameCapitalize from '../../utils/nameCapitalize'
import Icon from '../../components/Icon'
import SunEditor from '../../components/SunEditor'

export default function ElencosCadastro() {
  const { id } = useParams();
  const history = useHistory();

  const [form, setForm] = React.useState({
    titulo: '',
    categoria: '',
    descricao: '',
    instagram: '',
  });
  const [show, setShow] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [disablede, setDisablede] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [srcMain, setSrcMain] = React.useState('');
  const [editorText, setEditorText] = React.useState('');
  const [identifiers, setIdentifiers] = React.useState({
    function: 'create',
    buttonText: 'Cadastrar',
    successMessage: 'Elenco cadastrado com sucesso!',
  });
  const {
    errors,
    handleSubmit: handleSubmitForm,
    register,
    reset,
    setError,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schemaElencos),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const [upImg, setUpImg] = React.useState();
  const imgRef = React.useRef(null);
  const previewCanvasRef = React.useRef(null);
  const defaultCrop = { aspect: 3 / 4 };
  const [crop, setCrop] = React.useState(defaultCrop);
  const [completedCrop, setCompletedCrop] = React.useState(null);

  useEffect(() => {
    reset(form);
  }, [reset, form]);

  useEffect(() => {
    (async () => {
      if (id) {
        setLoading(true)
        setIdentifiers({
          function: 'edit',
          buttonText: 'Editar',
          successMessage: 'Elenco editado com sucesso!',
        });

        const response = await api.get(`/elencos/${id}`);
        setSrcMain(response?.data?.img?.url);
        setEditorText(response?.data?.descricao)
        setForm(response?.data);
        setLoading(false)
      }
    })();
  }, [id]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        263,
        364,
        'jpeg',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'blob'
      );
    });

  async function handleSubmit(data) {
    if (identifiers.function === 'edit') {
      try {
        const canvas = previewCanvasRef.current;

        if (completedCrop) {
          canvas.toBlob(
            async (blob) => {
              try {
                const formData = new FormData();
                const resizedFile = await resizeFile(blob);

                formData.set(
                  'file',
                  resizedFile,
                  `${data.nome.replace(' ', '_')}.jpg`
                );
                formData.set('nome', data.nome);
                formData.set('posicao', data.posicao);
                formData.set('numero', data.numero);
                formData.set('descricao', data.descricao);
                formData.set('instagram', data.instagram);

                setDisablede(true);
                setLoading(true);

                await api.put(`/elencos/${id}`, formData);
                toast.success(identifiers.successMessage);
                setDisablede(false);
                setLoading(false);
                return history.push('/area-admin/elencos');
              } catch (err) {
                setDisablede(false);
                setLoading(false);
                return toast.error(
                  err?.response?.data?.erro ||
                    'Ocorreu um erro ao editar as informações.'
                );
              }
            },
            'image/jpeg',
            1
          );

          return;
        }

        const anotherFormData = new FormData();

        anotherFormData.set('nome', data.nome);
        anotherFormData.set('posicao', data.posicao);
        anotherFormData.set('numero', data.numero);
        anotherFormData.set('descricao', data.descricao);
        anotherFormData.set('instagram', data.instagram);
        setDisablede(true);
        setLoading(true);

        await api.put(`elencos/${id}`, anotherFormData);
        toast.success(identifiers.successMessage);
        return history.push('/area-admin/elencos');
      } catch (err) {
        return toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao editar as informações.'
        );
      }
    }

    try {
      const canvas = previewCanvasRef.current;

      if (!completedCrop) {
        setError('imagem', {
          types: {
            required: true,
          },
          message: 'Campo é obrigatório.',
        });
        return;
      }

      canvas.toBlob(
        async (blob) => {
          try {
            const resizedFile = await resizeFile(blob);
            const oneMoreFormData = new FormData();

            oneMoreFormData.set(
              'file',
              resizedFile,
              `${data.nome.replace(' ', '_')}.jpg`
            );
            oneMoreFormData.set('nome', data.nome);
            oneMoreFormData.set('posicao', data.posicao);
            oneMoreFormData.set('numero', data.numero);
            oneMoreFormData.set('descricao', data.descricao);
            oneMoreFormData.set('instagram', data.instagram);
            
            setDisablede(true);
            setLoading(true);
            await api.post('/elencos', oneMoreFormData);
            toast.success(identifiers.successMessage);
            return history.push('/area-admin/elencos');
          } catch (error) {
            return toast.error(
              error?.response?.data?.erro ||
                'Ocorreu um erro ao criar o elenco.'
            );
          }
        },
        'image/jpeg',
        1
      );
    } catch (err) {
      return toast.error(
        err?.response?.data?.erro || 'Ocorreu um erro ao criar o elenco.'
      );
    }
  }

  const onLoadImage = (img) => {
    imgRef.current = img;
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setDisabled(true);
      handleShow(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleClose = () => {
    imgRef.current = null
    setCompletedCrop(null)
    setCrop(defaultCrop)
    setShow(false)
  };

  const handleConclude = () => {
    setCrop(defaultCrop)
    setShow(false)
  };

  const handleShow = () => setShow(true);

  function handleChangeEditor(content) {
    setEditorText(content)
  }

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <AdminMenu />
      <main className="wrapper bg-verm">
        <div className="container mt-3 pt-3">
          <form
            onSubmit={handleSubmitForm(handleSubmit)}
            className='card p-4 border-0'
          >
            <div className='d-flex flex-wrap color-black'>
              <div className='form-group col-12 mb-5'>
                <label
                  className={
                    identifiers.function === 'create' ? 'control-label' : ''
                  }
                >
                  Foto do Jogador
                </label>
                <div>
                  <input
                    ref={register}
                    hidden={true}
                    type='file'
                    className='form-control-file'
                    name='imagem'
                    id='imagem'
                    accept='image/*'
                    onChange={onSelectFile}
                  />

                  <label htmlFor='imagem'>
                    <canvas
                      className='img-input--elenco'
                      ref={previewCanvasRef}
                      hidden={!imgRef.current}
                    />
                    {!imgRef.current && srcMain && (
                      <>
                        <img
                          className='img-input--elenco'
                          src={srcMain}
                          alt=''
                        />
                      </>
                    )}
                    {!imgRef.current && !srcMain && (
                      <>
                        <div className='img-input--elenco d-flex'>
                          <Icon
                            className='mx-auto my-auto'
                            color='#969cb2'
                            icon='image'
                          ></Icon>
                        </div>
                      </>
                    )}
                  </label>
                  <span className='retorno-invalido'>
                    {errors?.imagem?.message}
                  </span>
                </div>
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                dialogClassName='modal-90w'
              >
                <Modal.Header>
                  <Modal.Title className='modal-title'>Cortar a imagem</Modal.Title>
                    <button
                      onClick={handleClose}
                      type="button"
                      className="btn-close close p-1"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <Icon
                        icon={'times-circle'}
                        size="2x"
                        color="#FFF"
                        className="d2"
                      />
                    </button>
                </Modal.Header>
                <Modal.Body className='d-flex align-items-center justify-content-center'>
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoadImage}
                    crop={crop}
                    onChange={(c) => {
                      setDisabled(!(c.width >= 50));
                      return setCrop(c);
                    }}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    disabled={disabled}
                    className='btn btn-verde'
                    type='button'
                    onClick={handleConclude}
                  >
                    Concluído
                  </button>
                </Modal.Footer>
              </Modal>

              <div className='form-group col-12 col-md-6'>
                <label htmlFor='nome' className='control-label'>
                  Nome
                </label>
                <input
                  id='nome'
                  name='nome'
                  type='text'
                  className='form-control'
                  ref={register}
                  onChange={(e) => setValue('nome', nameCapitalize(e.target.value))}
                />
                <span className='retorno-invalido'>
                  {errors?.nome?.message}
                </span>
              </div>
              <div className='form-group col-12 col-md-6'>
                <label htmlFor='nome' className='control-label'>
                  Posição do jogador
                </label>
                <input
                  id='posicao'
                  name='posicao'
                  type='text'
                  className='form-control'
                  ref={register}
                />
                <span className='retorno-invalido'>
                  {errors?.posicao?.message}
                </span>
              </div>
              <div className='form-group col-12 col-md-6'>
                <label htmlFor='numero' className='control-label'>
                  Número do jogador
                </label>
                <input
                  id='numero'
                  name='numero'
                  type='number'
                  className='form-control'
                  ref={register}
                />
                <span className='retorno-invalido'>
                  {errors?.numero?.message}
                </span>
              </div>
              <div className='form-group col-12 col-md-6'>
                <label htmlFor='instagram'>
                  Instagram do jogador
                </label>
                <input
                  id='instagram'
                  name='instagram'
                  className='form-control'
                  ref={register}
                />
                <span className='retorno-invalido'>
                  {errors?.instagram?.message}
                </span>
              </div>
              <div className='form-group col-12 col-md-12'>
              <label htmlFor='descricao'>
              História do Jogador
              </label>
              <Controller
                name='descricao'
                control={control}
                as={<SunEditor onEditorChange={handleChangeEditor} defaultValue={editorText} />}
              />
              </div>
            </div>

            <div className='d-flex justify-content-between col-12'>
              <button
                type='button'
                className='btn btn-light border'
                onClick={() => history.push('/area-admin/elencos')}
              >
                Voltar
              </button>
              <button
                type='submit'
                className='btn btn-verde'
                disabled={disablede || loading}
              >
                {identifiers.buttonText}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}

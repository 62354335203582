import { yupResolver } from '@hookform/resolvers'
import { TableBody, TableContainer, TableHead } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Link, useHistory, useLocation,
  useParams, useRouteMatch
} from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import CustomInput from '../../components/CustomInput'
import Filter from '../../components/Filter.jsx'
import Icon from '../../components/Icon'
import JogoCard from '../../components/JogoCard'
import Loading from '../../components/Loading'
import api from '../../services/api'
import nameCapitalize from '../../utils/nameCapitalize'
import { schemaCheckPatrocinadores } from '../../validators/formCheckPatrocinador'

export default function Jogos() {
  const { path } = useRouteMatch()
  const { search } = useLocation()
  const [jogosList, setJogosList] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [modal, setModal] = React.useState(false)
  const [modaldois, setModaldois] = React.useState(false)
  const [modalTres, setModalTres] = React.useState(false)
  const [modalPatrocinador, setModalPatrocinador] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const [itemToDelete, setItemToDelete] = React.useState('')
  const [patrocinadorToDelete, setPatrocinadorToDelete] = React.useState('')
  const [, setItemToCheckList] = React.useState('') //recebe a id do jogo
  const [, setItemToPatrocinadorList] = React.useState('') //recebe a id do jogo
  const [checkins, setCheckinsList] = React.useState([]) //recebe a id do jogo
  const [checkinsPatrocinador, setCheckinsListPatrocinador] = React.useState([]) //recebe a id do jogo
  const [jogoC, setJogoC] = React.useState(null)
  const history = useHistory()
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(0)
  const [patro, setPatro] = React.useState([])
  const [reservasP, setReservasP] = React.useState([])
  const [form, setForm] = React.useState({})
  const selectRef = React.useRef(null)
  const [disable, setDisable] = React.useState(false)
  const searchRef = React.useRef(null)
  const hasMore = React.useMemo(() => page <= pages, [page, pages])
  const handleClose = () => setModal(false)
  const handleCloseT = () => setModalTres(false)
  const handleShow = () => setModal(true)
  const handleCloseCheck = () => setShow(false)
  const handleShowCheckList = () => setShow(true)
  const handleClosePatrocinador = () => setModaldois(false)
  const handleShowPatrocinadorList = () => setModaldois(true)
  const close = () => setModalPatrocinador(false)
  const { id } = useParams()

  const [identifiers, setIdentifiers] = React.useState({
    function: 'create',
    buttonText: 'Check-in',
    successMessage: 'Check-in realizado com sucesso!',
  })
  const {
    errors,
    handleSubmit: handleSubmitForm,
    register,
    reset,
    defaultValue = [],
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(schemaCheckPatrocinadores),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  })

  async function removeItem() {
    await api.delete(`/jogos/${itemToDelete}`)
    setJogosList((oldState) => {
      return oldState.filter((item) => item?._id !== itemToDelete)
    })
    handleClose()
    toast.success('Jogo deletado com sucesso!')
  }

  async function removePatrocinador() {
    try {
      setLoading(true)
      await api.delete(`/checkins/patrocinador/${patrocinadorToDelete}`)
      toast.success('Check in cancelado com sucesso!')
      buscaListPatrocinador(jogoC?._id)
      setModalTres(false)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
        'Ocorreu um erro ao enviar suas informações.',
      )
    } finally {
      setLoading(false)
      await atualizarCard()
    }
  }

  async function handleDeletePatrocinador(id) {
    try {
      setPatrocinadorToDelete(`${id}`)
      setModalTres(true)
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Erro ao cancelar o check-in.')
    }
  }

  async function printDocument() {
    const checktable = document.getElementById('pdfdiv').innerHTML

    var style = '<style>'
    style =
      style +
      'body{font-family: Montserrat, sans-serif;} table {width: 100%;font-size: 20px; margin-top: 10px;margin-botton: 10px;}'
    style =
      style +
      'table, th, div,td {border: solid 1px #DDD; border-collapse: collapse;' +
      'div {border: solid 1px #DDD; border-collapse: collapse; text-align:center;'
    style = style + 'padding: 2px 3px;text-align: left;}'
    style = style + '</style>'

    var win = window.open('', '', 'height=700,width=700')
    win.document.write('<html><head>')
    win.document.write('<title>Lista Check-in</title>') // <title> CABEÇALHO DO PDF.
    win.document.write(`<link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet">`)
    win.document.write(style) // INCLUI UM ESTILO NA TAB HEAD
    win.document.write('</head>')
    win.document.write('<body>')
    win.document.write(checktable) // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write('</body></html>')
    win.document.close() // FECHA A JANELA
    win.print() // IMPRIME O CONTEUDO
  }

  async function buscaListCheckin(jogoId) {
    // const c = await api.get(`/checkins/${jogoId}/todos`)
    // const d = await api.get(`/checkinsP/patrocinador/${jogoId}`)
    const checkins = await api.get(`/checkins/jogo/${jogoId}`)
    const jogo = jogosList.find((j) => j._id === jogoId)
    setJogoC(jogo)
    setCheckinsList(checkins.data)
  }

  async function buscaListPatrocinador(jogoId) {
    if (jogoId) {
      // const c = await api.get(`/checkins/patrocinador/${jogoId}`)
      const checkins = await api.get(`/checkins/jogo/${jogoId}?patrocinador=true`)
      const jogo = jogosList.find((j) => j._id === jogoId)

      setJogoC(jogo)
      setCheckinsListPatrocinador(checkins.data)
    }
  }

  async function ListPatrocinador() {
    try {
      setLoading(true)
      setDisable(true)
      const response = await api.get('/patrocinadores')
      const data = response?.data?.list?.map((item) => ({
        label: item?.nome,
        reservas: item?.reservas,
        value: item?._id,
      }))
      setPatro(data)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
        'Erro ao buscar a lista de patrocinadores, por favor, recarregue a página.',
      )
    } finally {
      setLoading(false)
      setDisable(false)
    }
  }

  React.useEffect(() => {
    if (form?.patrocinadorId) {
      const patrocinador = patro.find(
        (item) => item.value === form?.patrocinadorId,
      )

      api.get(`/checkins/jogo/${jogoC._id}`).then((res) => {
        const checkinsPatrocinador = res.data.filter(c => c.patrocinador._id === patrocinador.value)
        const arr = Array.from(
          {
            length: parseInt(patrocinador.reservas) - checkinsPatrocinador.length
          },
          (_, k) => ({
            value: k + 1,
            label: k + 1
          })
        )
        setReservasP(arr)
      })

      // api
      //   .get(`/checkins/patrocinador/${jogoC._id}/${form?.patrocinadorId}`)
      //   .then((res) => {
      //     const arr = Array.from(
      //       {
      //         length: patrocinador?.reservas - res?.data?.count,
      //       },
      //       (_, k) => ({
      //         value: k + 1,
      //         label: k + 1,
      //       }),
      //     )
      //     console.log(arr);
      //     setReservasP(arr)
      //   })
      //   .catch(() => { })
    }
    if (jogoC?._id)
      buscaListCheckin(jogoC?._id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.patrocinadorId, patro, jogoC, form])

  function getReservas(id) {
    const rPatrocinador = patro.find((item) => item.value === id)

    const data = rPatrocinador?.reservas?.list?.map((item) => ({
      value: item,
      label: item,
    }))
    setReservasP(data)
  }

  useEffect(() => {
    reset(form, {
      errors: true,
    })
  }, [form, reset])

  useEffect(() => {
    ; (async () => {
      if (id) {
        setIdentifiers({
          function: 'edit',
          buttonText: 'Editar',
          successMessage: 'Jogo editado com sucesso!',
        })
        const response = await api.get(`/checkins/${id}`)
        setForm(response?.data?.list)
      }
    })()
  }, [patro, id])

  async function handleSubmit(data) {
    if (identifiers.function === 'edit') {
      try {
        setDisable(true)
        setLoading(true)
        await api.put(`/checkins/patrocinador${id}`, {
          responsavel: data.responsavel,
          reservas: data.reservas,
          patrocinadorId: data.patrocinadorId,
        })
        toast.success(identifiers.successMessage)
        setDisable(false)
        setLoading(false)
        return history.push('/area-admin/jogos?status=proximos')
      } catch (err) {
        setDisable(false)
        setLoading(false)
        return toast.error(
          err?.response?.data?.erro ||
          'Ocorreu um erro ao editar as informações.',
        )
      }
    }

    setDisable(true)
    setLoading(true)
    api
      .post('/checkins/patrocinador/', {
        patrocinadorId: data?.patrocinadorId,
        reservas: data.reservas,
        nome: data.nome,
        jogo_id: jogoC._id,
      })
      .then(() => {

        setDisable(false)
        setLoading(false)
        setModalPatrocinador(false)
        setForm({})
        toast.success(identifiers.successMessage)
        return history.push('/area-admin/jogos?status=proximos')
      })
      .catch((error) => {
        setDisable(false)
        setLoading(false)
        return toast.error(
          error?.response?.data?.erro ||
          'Ocorreu um erro ao fazer o check-in do patrocinador.',
        )
      })
    await atualizarCard()
    // setModaldois(true)
    await buscaListPatrocinador(jogoC?._id)
  }

  const urlQuery = React.useMemo(() => {
    const queries = new URLSearchParams(search)

    const query = {}
    const busca = queries.get('search') || ''
    const status = queries.get('status') || ''

    if (busca) {
      query.search = busca
    }

    if (status) {
      query.status = status
    }

    return query
  }, [search])

  async function atualizarCard() {
    try {
      setPage(1)
      setLoading(true)
      const response = await api.get('/jogos?page=1', {
        params: {
          checkin: true,
          status: urlQuery?.status,
          search: urlQuery?.search,
          limit: 20,
        },
      })
      const data = response?.data?.list?.map((item) => ({
        ...item,
        dataHoraFormatted: moment(item?.dataHora).format(
          'DD/MM/YYYY [às] HH:mm',
        ),
        dataHoraInFormatted: moment(item?.dataHoraIn).format(
          'DD/MM/YYYY [às] HH:mm',
        ),
        dataHoraTeFormatted: moment(item?.dataHoraTe).format(
          'DD/MM/YYYY [às] HH:mm',
        ),
      }))
      setJogosList(data)
      setPages(response?.data?.pages || 1)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
        'Erro ao buscar a lista de jogos, por favor, recarregue a página.',
      )
    } finally {
      setLoading(false)
      setPage((p) => p + 1)
    }
  }

  React.useEffect(() => {
    ; (async () => {
      await atualizarCard()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQuery])

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/jogos?page=${page}`, {
        params: {
          checkin: true,
          status: urlQuery?.status,
          search: urlQuery?.search,
          limit: 20,
        },
      })
      const data = response?.data?.list?.map((item) => ({
        ...item,
        dataHoraFormatted: moment(item?.dataHora).format(
          'DD/MM/YYYY [às] HH:mm',
        ),
        dataHoraInFormatted: moment(item?.dataHoraIn).format(
          'DD/MM/YYYY [às] HH:mm',
        ),
        dataHoraTeFormatted: moment(item?.dataHoraTe).format(
          'DD/MM/YYYY [às] HH:mm',
        ),
      }))

      setJogosList([...jogosList, ...data])
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
        'Erro ao buscar a lista de jogos, por favor, recarregue a página.',
      )
    } finally {
      setLoading(false)
      setPage(page + 1)
    }
  }, [page, jogosList, urlQuery])

  const handleSearch = React.useCallback(
    (e) => {
      e.preventDefault()
      const value = searchRef?.current?.value?.trim()

      const data = Object.assign(
        {},
        urlQuery?.status ? { status: urlQuery.status } : null,
        value ? { search: value } : null,
      )

      history.push({
        pathname: path,
        search: new URLSearchParams(data).toString(),
      })
    },
    [history, urlQuery, path],
  )

  const handleFilter = React.useCallback(
    (filter) => {
      const data = Object.assign(
        { status: filter },
        urlQuery?.search ? { search: urlQuery.search } : null,
      )

      history.push({
        pathname: path,
        search: new URLSearchParams(data).toString(),
      })
    },
    [path, history, urlQuery],
  )

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className="wrapper bg-verm" id="scrollableWrapper">
        <div className="container mt-3 p-0 pt-3 pb-4">
          <div className="col-12 mb-4">
            <div className="card card-orientation border-0 p-4">
              <Link
                to={`${path}/cadastrar/`}
                className="text-decoration-none btn-adicionar"
              >
                <Icon icon="plus" color="#fff" />
              </Link>

              <form className="form-pesquisa" onSubmit={handleSearch}>
                <CustomInput
                  inputRef={searchRef}
                  id="input-pesquisa"
                  value={urlQuery?.search}
                  placeholder="Pesquisar por local"
                  icon="search"
                  sendIcon="paper-plane"
                />
              </form>
              <Filter
                selected={urlQuery?.status || 'todos'}
                setSelected={handleFilter}
                filters={[
                  { id: 'Todos', color: '#9D704D' },
                  { id: 'Proximos', color: '#00A783', label: 'Próximos' },
                  { id: 'Passados', color: '#F9AA3B' },
                ]}
              />
            </div>
          </div>

          <Modal
            show={modal}
            onHide={handleClose}
            keyboard={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">Cancelar jogo?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
              Tem certeza que deseja cancelar este jogo? Esta ação não será
              reversível.
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-verm"
                type="button"
                onClick={removeItem}
              >
                Confirmar
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={modalTres}
            onHide={handleCloseT}
            keyboard={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">Cancelar check-in?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
              Tem certeza que deseja cancelar este check-in? Esta ação não será
              reversível.
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-verm"
                type="button"
                onClick={removePatrocinador}
              >
                Confirmar
              </button>
            </Modal.Footer>
          </Modal>

          {/* Modal exportar */}
          <Modal
            show={show}
            onHide={handleCloseCheck}
            keyboard={false}
            dialogClassName="modal-90w modal-dialog modal-top-centered modal-dialog-scrollable w-90"
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">Lista check-in</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
              <TableContainer id="pdfdiv" className="txt">
                <b>
                  Jogo:{' '}
                  {jogoC?.emCasa
                    ? `${jogoC?.timeProprio?.nome} X ${jogoC?.timeAdversario?.nome}`
                    : `${jogoC?.timeAdversario?.nome} X ${jogoC?.timeProprio?.nome}`}{' '}
                  - dia {jogoC?.dataHoraFormatted}
                </b>
                <Table className="mt-4" aria-label="table">
                  <TableHead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Nome</th>
                      <th scope="col">Tipo</th>
                      <th scope="col">Check-In</th>
                      <th scope="col">Compareceu</th>
                    </tr>
                  </TableHead>
                  <TableBody>
                    {checkins?.map((checkin, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>
                          {checkin?.patrocinador ? checkin?.responsavel : checkin?.socio.nome}
                        </td>
                        <td>
                          {checkin?.dependente
                            ? 'Dependente'
                            : checkin?.plano.descricao
                              ? nameCapitalize(checkin?.plano.descricao)
                              : 'Patrocinador'}
                        </td>
                        <td>{checkin.presenca_sem_checkin ? 'NÃO' : 'SIM'}</td>
                        <td>{checkin.compareceu ? 'SIM' : 'NÃO'}</td>
                      </tr>
                    ))}
                  </TableBody>
                </Table>
                <div>
                  <div colSpan="3" className="c-1 border-0 font-weight-bold">
                    Total check-in: {checkins.length}
                  </div>
                </div>
              </TableContainer>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={printDocument}
                variant="contained"
                color="primary"
                className="btn btn-dour"
                type="button"
              >
                Exportar
              </button>
            </Modal.Footer>
          </Modal>

          {/* Modal patrocinadores */}
          <Modal
            show={modaldois}
            onHide={handleClosePatrocinador}
            keyboard={false}
            dialogClassName="modal-90w modal-dialog modal-top-centered modal-dialog-scrollable w-90"
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">
                Lista check-in patrocinadores
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
              <TableContainer className="txt">
                <b>
                  Jogo:{' '}
                  {jogoC?.emCasa
                    ? `${jogoC?.timeProprio?.nome} X ${jogoC?.timeAdversario?.nome}`
                    : `${jogoC?.timeAdversario?.nome} X ${jogoC?.timeProprio?.nome}`}{' '}
                  - dia {jogoC?.dataHoraFormatted}
                </b>
                <Table className="mt-4" aria-label="table">
                  <TableHead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Patrocinador</th>
                      <th scope="col">Reservas</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </TableHead>
                  <TableBody>
                    {checkinsPatrocinador.map((checkin, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>{checkin?.patrocinador?.nome}</td>
                        <td>
                          {checkin?.patrocinador?.reservas -
                            (checkin?.patrocinador?.reservas - 1)}
                        </td>
                        <td>{checkin?.responsavel}</td>
                        <td>
                          <div>
                            <div className="d-flex align-items-center">
                              <div className="dropdown">
                                <button
                                  className="border-0 btn pt-1 pb-1 custom-btn"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  id="dropdownMenuButton"
                                >
                                  <Icon icon="ellipsis-v" color="#BDBDBD" />
                                </button>
                                <div
                                  className="dropdown-menu dropdown-menu-right z-index-99"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <button
                                    className="outline-0 dropdown-item custom-dropdown color-black"
                                    onClick={() => {
                                      handleDeletePatrocinador(checkin?._id)
                                    }}
                                  >
                                    <Icon icon="times-circle" color="#de002c" />
                                    <span className="ml-3">Cancelar</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </TableBody>
                </Table>
                <div>
                  <div colSpan="3" className="c-1 border-0 font-weight-bold">
                    Total check-in patrocinador: {checkinsPatrocinador.length}
                  </div>
                </div>
              </TableContainer>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-verde border"
                type="button"
                onClick={() => {
                  setModalPatrocinador(true)
                  setModaldois(false)
                  ListPatrocinador()
                }}
                disabled={jogoC?.vagas <= checkins?.length}
              >
                Reservar
              </button>
            </Modal.Footer>
          </Modal>

          {/* modal criação da lista patrocinadores */}
          <Modal
            show={modalPatrocinador}
            onHide={close}
            keyboard={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">
                Criar check-in patrocinador
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="form-group">
                  <label htmlFor="nome" className="control-label">
                    Nome responsável
                  </label>
                  <input
                    id="nome"
                    name="nome"
                    type="text"
                    className="form-control"
                    ref={register}
                  />
                  <span className="retorno-invalido">
                    {errors?.nome?.message}
                  </span>
                </div>

                <div className="form-group">
                  <label htmlFor="patrocinadorId" className="control-label">
                    Patrocinador
                  </label>
                  <Controller
                    control={control}
                    id="patrocinadorId"
                    name="patrocinadorId"
                    defaultValue={defaultValue}
                    render={({ onChange }) => (
                      <Select
                        value={patro.find(
                          (c) => c?.value === form?.patrocinadorId,
                        )}
                        options={patro}
                        placeholder="Selecione um patrocinador"
                        noOptionsMessage={() => 'Não existe patrocinador'}
                        onChange={(e) => {
                          getReservas(e?.value)
                          setForm({
                            ...getValues(),
                            patrocinadorId: e?.value,
                            reservas: '',
                          })
                          return onChange(e.value)
                        }}
                      />
                    )}
                  />
                  <span className="retorno-invalido">
                    {errors?.patrocinadorId?.message}
                  </span>
                </div>

                <div className="form-group">
                  <label htmlFor="reservas" className="control-label">
                    Reservas
                  </label>
                  <Controller
                    control={control}
                    defaultValue={defaultValue}
                    id="reservas"
                    name="reservas"
                    render={({ onChange }) => {
                      return (
                        <Select
                          ref={selectRef}
                          value={{
                            label: form?.reservas,
                            value: form?.reservas,
                          }}
                          options={reservasP}
                          placeholder="Selecione as reservas"
                          noOptionsMessage={() =>
                            'Limite de reservas atingido.'
                          }
                          onChange={(e) => {
                            if (e) {
                              setForm({
                                ...getValues(),
                                reservas: e?.value,
                              })
                            }
                            return onChange(e?.value)
                          }}
                        />
                      )
                    }}
                  />
                  <span className="retorno-invalido">
                    {errors?.reservas?.message}
                  </span>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setModalPatrocinador(false)
                  setModaldois(true)
                }}
              >
                Voltar
              </button>
              <button
                onClick={handleSubmitForm(handleSubmit)}
                className="btn btn-verde"
                disabled={disable || loading}
              >
                {identifiers.buttonText}
              </button>
            </Modal.Footer>
          </Modal>

          <div>
            <InfiniteScroll
              className="d-flex flex-wrap row-gap-30"
              dataLength={jogosList?.length}
              next={loadMore}
              hasMore={hasMore}
              scrollableTarget="scrollableWrapper"
            >
              {jogosList?.length > 0 ? (
                jogosList?.map((jogo, index) => (
                  <JogoCard
                    handleShow={handleShow}
                    setItemToDelete={setItemToDelete}
                    handleShowCheckList={handleShowCheckList}
                    setItemToCheckList={setItemToCheckList}
                    buscaListCheckin={buscaListCheckin}
                    handleShowPatrocinadorList={handleShowPatrocinadorList}
                    setItemToPatrocinadorList={setItemToPatrocinadorList}
                    buscaListPatrocinador={buscaListPatrocinador}
                    jogo={jogo}
                    key={index}
                  />
                )) || <div></div>
              ) : (
                <div className="jogos_">Não há jogos cadastrados.</div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </main>
    </>
  )
}

import * as yup from 'yup';

import cpfTest from '../utils/cpfTest';
import dateTest from '../utils/dateTest';
import validThruTest from '../utils/validThruTest';
import camisetaOptions from '../utils/camisetaOptions';
import moment from 'moment';

export const schemaEdicaoPersonal = yup.object().shape({
  plano: yup.string().required('Plano é obrigatório.'),
  nome: yup.string().matches(/[a-zA-Zà-úÀ-Ú][ ][a-zA-Zà-úÀ-Ú]+$/, {
    excludeEmptyString: true,
    message: 'Por favor, insira o nome completo.'
  }).required('Nome é obrigatório.'),
  cpf: yup.string().test('cpfTest', 'CPF inválido.', cpfTest).required('CPF é obrigatório.'),
  dt_nasc: yup.string().test('dateTest', 'A data é inválida.', dateTest).required('Data de nascimento é obrigatório.'),
  email: yup.string().email('Insira um e-mail válido.').required('E-mail é obrigatório.'),
  telefone: yup.string().matches(/^\(\d{2}\)[ ]\d{5}-\d{4}$/, {
    excludeEmptyString: true,
    message: 'Formato de telefone inválido'
  }).required('Celular é obrigatório.'),
});

export const schemaEdicaoAddress = yup.object().shape({
  cep: yup.string().matches(/^\d{5}-\d{3}/, {
    excludeEmptyString: true,
    message: 'Formato de CEP inválido.'
  }).required('CEP é obrigatório.'),
  cidade: yup.string().required('Cidade é obrigatório.'),
  uf: yup.string().matches(/^[a-zA-Z]{2}$/, {
    excludeEmptyString: true,
    message: 'O UF precisa ter 2 caracteres.'
  }).required('UF é obrigatório.'),
  logradouro: yup.string().required('Logradouro é obrigatório.'),
  numero: yup.string().required('Número é obrigatório.'),
  bairro: yup.string().required('Bairro é obrigatório.'),
  complemento: yup.string(),
});

export const schemaEdicaoPartnerCard = yup.object().shape({
  emissao_carteirinha: yup.string().test('dateTest', 'A data é inválida.', dateTest).when('carteirinha_rfid', (carteirinha_rfid, field) => (
    carteirinha_rfid ? field.required('RFID é obrigatório.') : field
  )),
  carteirinha_rfid: yup.string().when('emissao_carteirinha', (emissao_carteirinha, field) => (
    emissao_carteirinha ? field.required('RFID é obrigatório.') : field
  ))
}, ['emissao_carteirinha', 'carteirinha_rfid']);

export const schemaEdicaoDependent = yup.object().shape({
  nome: yup.string().matches(/[a-zA-Zà-úÀ-Ú][ ][a-zA-Zà-úÀ-Ú]+$/, {
    excludeEmptyString: true,
    message: 'Por favor, insira o nome completo.'
  }).when('cpf', (cpf, field) => cpf ? field.required('Nome é obrigatório.') : field),
  cpf: yup.string().test('cpfTest', 'CPF inválido.', cpfTest).when('nome', (nome, field) => (
    nome ? field.required('CPF é obrigatório.') : field
  )),
  emissao_carteirinha: yup.string().test('dateTest', 'A data é inválida.', dateTest).when('carteirinha_rfid', (carteirinha_rfid, field) => (
    carteirinha_rfid ? field.required('RFID é obrigatório.') : field
  )),
  carteirinha_rfid: yup.string().when('emissao_carteirinha', (emissao_carteirinha, field) => (
    emissao_carteirinha ? field.required('RFID é obrigatório.') : field
  ))
}, [['cpf', 'nome'], ['emissao_carteirinha', 'carteirinha_rfid']]);

export const schemaEdicaoPaymentMethod = yup.object().shape({
  forma_pagamento: yup.string().required('Forma de pagamento é obrigatório.'),
  numero: yup.string().when('$forma_pagamento', (forma_pagamento, field) =>
    forma_pagamento === 'cc' ? field.required('Número do cartão é obrigatório') : field
  ),
  nome: yup.string().matches(/[a-zA-Zà-úÀ-Ú][ ][a-zA-Zà-úÀ-Ú]+$/, {
    excludeEmptyString: true,
    message: 'Por favor, insira o nome completo.'
  }).when('$forma_pagamento', (forma_pagamento, field) =>
    forma_pagamento === 'cc' ? field.required('Nome do titular é obrigatório.') : field
  ),
  validade: yup.string().test('validThruTest', 'A data é inválida', validThruTest).when('$forma_pagamento', (forma_pagamento, field) =>
    forma_pagamento === 'cc' ? field.required('É obrigatório definir a data de validade.') : field
  ),
  cvc: yup.string().matches(/^\d{3}$/, {
    excludeEmptyString: true,
    message: 'O código de segurança deve ter o seguinte formato: "000".'
  }).when('$forma_pagamento', (forma_pagamento, field) =>
    forma_pagamento === 'cc' ? field.required('Código de segurança é obrigatório.') : field
  )
});

function maxTest(value) {
    if (!value) {
        return true;
    }

    if (moment(moment(value, 'DD/MM/YYYY').parseZone().format('YYYY-MM-DD')).isAfter(moment(new Date()).parseZone().format('YYYY-MM-DD'))) {
        return false;
    }

    return true
}

function minTest(value) {
    if (!value) {
        return true;
    }

    if (moment(moment(value, 'DD/MM/YYYY').parseZone().format('YYYY-MM-DD')).isBefore(moment(new Date()).parseZone().format('YYYY-MM-DD'))) {
        return false;
    }

    return true
}

export const schemaEdicaoNewPayment = yup.object().shape({
  forma_pagamento: yup.string().required('Forma de pagamento é obrigatório.'),
  pagoem: yup.string().test('dateTest', 'A data é inválida.', dateTest).test('maxTest', 'A data não pode ser maior que hoje', maxTest).required('É obrigatório definir a data de pagamento.'),
  pagoate: yup.string().test('dateTest', 'A data é inválida.', dateTest).test('minTest', 'A data não pode ser menor que hoje', minTest).required('É obrigatório definir a data de vencimento.'),
  valor: yup.string().required('Defina o valor do pagamento.'),
});

export const schemaEdicaoShirt = yup.object().shape({
  camiseta: yup.string().oneOf(camisetaOptions, ({ values }) => `A camiseta precisa ter um desses tamanhos: ${values}`).required('O campo "camiseta" é obrigatório.')
});

import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers'

import { useAuth } from '../../../Contexts/AuthContext'

import { schemaLoginNewPass } from '../../../validators/formLogin'

import api from '../../../services/api'

import CustomInput from '../../CustomInput'
import Loading from '../../Loading'

export default function NewPassword({ navigateOnStep }) {
  const { loginData } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schemaLoginNewPass),
    defaultValues: {
      senha: '',
    },
  })

  async function handleSubmitForm({ senha, confirmSenha }) {
    try {
      setLoading(true)
      const response = loginData?.pass
        ? await api.patch('/login/nova-senha', {
            cpf: loginData?.cpf,
            verification_code: loginData?.verification_code,
            senha,
            confirmSenha,
          })
        : await api.post('/login/nova-senha', {
            cpf: loginData?.cpf,
            verification_code: loginData?.verification_code,
            senha,
            confirmSenha,
          })

      const { nextStep } = response?.data

      if (!nextStep) {
        toast.error('Ocorreu um erro de comunicação com o servidor.')
        return
      }

      setTimeout(() => {
        navigateOnStep(nextStep)
      }, 50)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro de comunicação com o servidor.',
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      {loading && <Loading delayTime={200} />}
      <h1 className="login-title">Como será a nova senha?</h1>
      <h2 className="login-subtitle">
        Pense em algo seguro e fácil de lembrar
      </h2>

      <CustomInput
        id="senha"
        name="senha"
        inputType="password"
        className={`login-input ${errors?.senha ? 'socio-input-error' : ''}`}
        placeholder="Nova senha"
        icon="lock-open"
        iconSize={14}
        iconColor="#969CB2"
        inputRef={register}
      />
      <CustomInput
        id="confirmSenha"
        name="confirmSenha"
        inputType="password"
        className={`login-input ${
          errors?.confirmSenha ? 'socio-input-error' : ''
        }`}
        placeholder="Repita a nova senha"
        icon="lock-open"
        iconSize={14}
        iconColor="#969CB2"
        inputRef={register}
      />
      <div className="retorno-invalido">{errors?.confirmSenha?.message}</div>

      <button type="submit" className="login-button">
        Confirmar
      </button>
    </form>
  )
}

import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import ReactCrop from 'react-image-crop'
import { Modal } from 'react-bootstrap'
import Resizer from 'react-image-file-resizer'
import api from '../../services/api'
import { schemaJogos } from '../../validators/formJogos'
import moment from 'moment'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import Loading from '../../components/Loading'
import Select from 'react-select'
import Icon from '../../components/Icon'

export default function JogosCadastro() {
  const { id } = useParams();
  const history = useHistory();
  const [form, setForm] = React.useState({});
  const [show, setShow] = React.useState(false);
  const selectRef = React.useRef(null);
  const [amistate, setAmistate] = React.useState(false);
  const [srcMain, setSrcMain] = React.useState('');
  const [gameOut, setGameOut] = React.useState(false);

  const [identifiers, setIdentifiers] = React.useState({
    function: 'create',
    buttonText: 'Cadastrar',
    successMessage: 'Cadastro realizado com sucesso!',
  });
  const {
    errors,
    handleSubmit: handleSubmitForm,
    register,
    reset,
    setError,
    defaultValue = [],
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(schemaJogos),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const [upImg, setUpImg] = React.useState();
  const imgRef = React.useRef(null);
  const previewCanvasRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [competicao, setCompeticao] = React.useState([]);
  const [fases, setFases] = React.useState([]);
  const [dataHora, setDataHora] = React.useState(
    moment().format('YYYY-MM-DD[T]20:00')
  );
  const [dataHoraIn, setDataHoraIn] = React.useState(
    moment().format('YYYY-MM-DD[T]20:00')
  );
  const defaultCrop = { aspect: 1 }
  const [crop, setCrop] = React.useState(defaultCrop)
  const [completedCrop, setCompletedCrop] = React.useState(null);

  useEffect(() => {
    reset(form, {
      errors: true,
    });
  }, [form, reset]);

  useEffect(() => {
    (async () => {
      if (id) {
        setIdentifiers({
          function: 'edit',
          buttonText: 'Editar',
          successMessage: 'Jogo editado com sucesso!',
        });

        const response = await api.get(`/jogos/${id}`);
        setSrcMain(response?.data?.timeAdversario?.img?.url);
        const data = {
          ...response?.data,
          dataHora: moment(response?.data?.dataHora).format(
            'YYYY-MM-DD[T]HH:mm'
          ),
          dataHoraIn: moment(response?.data?.dataHoraIn).format(
            'YYYY-MM-DD[T]HH:mm'
          ),
          dataHoraTe: moment(response?.data?.dataHoraTe).format(
            'YYYY-MM-DD[T]HH:mm'
          ),
          nome: response?.data?.timeAdversario?.nome,
        };
        setGameOut(data.emCasa);
        setAmistate(data.amistoso);
        setDataHora(data.dataHora);
        setDataHoraIn(data.dataHoraIn);
        setForm(data);
      }
    })();
  }, [competicao, id]);

  React.useEffect(() => {
    const fasesCompeticao = competicao.find(
      (item) => item.value === form?.liga
    );

    setFases(
      fasesCompeticao?.fases?.map((fase) => ({
        label: fase,
        value: fase,
      }))
    );
  }, [competicao, form]);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get('/competicoes');
        const data = response?.data?.map((item) => ({
          label: item?.liga,
          fases: item?.fases,
          value: item?._id,
        }));

        setCompeticao(data);
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de competições, por favor, recarregue a página.'
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        'png',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'blob'
      );
    });

  function getFases(id) {
    const fasesCompeticao = competicao.find((item) => item.value === id);

    const data = fasesCompeticao?.fases?.map((item) => ({
      value: item,
      label: item,
    }));

    setFases(data);
  }

  async function handleSubmit(data) {
    if (identifiers.function === 'edit') {
      try {
        const canvas = previewCanvasRef.current;
        setDisabled(true);
        setLoading(true);
        if (completedCrop) {
          canvas.toBlob(
            async (blob) => {
              const formData = new FormData();
              const resizedFile = await resizeFile(blob);

              formData.set(
                'file',
                resizedFile,
                `${data.nome.replace(' ', '_')}.png`
              );
              formData.set('dataHora', data.dataHora);
              formData.set('nome', data.nome);
              formData.set('local', data.local);
              formData.set('emCasa', data.emCasa);
              if (data.emCasa) {
                formData.set('vagas', data.vagas);
                formData.set('dataHoraIn', data.dataHoraIn);
                formData.set('dataHoraTe', data.dataHoraTe);
              }
              formData.set('amistoso', data.amistoso);
              if (!data.amistoso) {
                formData.set('liga', data.liga);
                formData.set('fase', data.fase);
              }

              setDisabled(true);
              setLoading(true);
              await api.put(`/jogos/${id}`, formData);
              toast.success(identifiers.successMessage);
              return history.push('/area-admin/jogos');
            },
            'image/png',
            1
          );
          return;
        }

        const anotherFormData = new FormData();

        anotherFormData.set('dataHora', data.dataHora);
        anotherFormData.set('nome', data.nome);
        anotherFormData.set('local', data.local);
        anotherFormData.set('emCasa', data.emCasa);
        if (data.emCasa) {
          anotherFormData.set('vagas', data.vagas);
          anotherFormData.set('dataHoraIn', data.dataHoraIn);
          anotherFormData.set('dataHoraTe', data.dataHoraTe);
        }
        anotherFormData.set('amistoso', data.amistoso);
        if (!data.amistoso) {
          anotherFormData.append('liga', data.liga);
          anotherFormData.append('fase', data.fase);
        }

        setDisabled(true);
        setLoading(true);
        await api.put(`/jogos/${id}`, anotherFormData);
        toast.success(identifiers.successMessage);
        setDisabled(false);
        setLoading(false);
        return history.push('/area-admin/jogos');
      } catch (err) {
        setDisabled(false);
        setLoading(false);
        return toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao editar as informações.'
        );
      }
    }

    try {
      const canvas = previewCanvasRef.current;

      if (!completedCrop || !canvas) {
        setError('imagem', {
          types: {
            required: true,
          },
          message: 'Campo é obrigatório.',
        });
        return;
      }

      canvas.toBlob(
        async (blob) => {
          const resizedFile = await resizeFile(blob);
          const formData = new FormData();

          formData.append(
            'file',
            resizedFile,
            `${data.nome.replace(' ', '_')}.png`
          );

          formData.append('dataHora', data.dataHora);
          formData.append('nome', data.nome);
          formData.append('local', data.local);
          formData.append('emCasa', data.emCasa);
          if (data.emCasa) {
            formData.append('vagas', data.vagas);
            formData.append('dataHoraIn', data.dataHoraIn);
            formData.append('dataHoraTe', data.dataHoraTe);
          }
          formData.append('amistoso', data.amistoso);
          if (!data.amistoso) {
            formData.append('liga', data.liga);
            formData.append('fase', data.fase);
          }

          setDisabled(true);
          setLoading(true);
          api
            .post('/jogos', formData)
            .then((response) => {
              toast.success(identifiers.successMessage);
              return history.push('/area-admin/jogos');
            })
            .catch((error) => {
              setDisabled(false);
              setLoading(false);
              return toast.error(
                error?.response?.data?.erro ||
                  'Ocorreu um erro ao criar o jogo.'
              );
            });
        },
        'image/png',
        1
      );
    } catch (err) {
      setLoading(false);
      return toast.error(
        err?.response?.data?.erro || 'Ocorreu um erro ao criar o jogo.'
      );
    }
  }

  const onLoadImage = (img) => {
    imgRef.current = img;
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleShow(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleClose = () => {
    imgRef.current = null
    setCompletedCrop(null)
    setCrop(defaultCrop)
    setShow(false)
  };

  const handleConclude = () => {
    setCrop(defaultCrop)
    setShow(false)
  };

  const handleShow = () => setShow(true);

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <AdminMenu />
      <main className="wrapper bg-verm">
        <div className="container mt-3 pt-3">
          <form
            onSubmit={handleSubmitForm(handleSubmit)}
            className='card p-4 border-0'
          >
            <div className='d-flex flex-wrap color-black'>
              <div className='form-group col-12 mb-5'>
                <label
                  className={
                    identifiers.function === 'create' ? 'control-label' : ''
                  }
                >
                  Imagem do time adversário
                </label>
                <div>
                  <input
                    ref={register}
                    hidden={true}
                    type='file'
                    className='form-control-file'
                    name='imagem'
                    id='imagem'
                    accept='image/*'
                    onChange={onSelectFile}
                  />
                  <label htmlFor='imagem'>
                    <canvas
                      ref={previewCanvasRef}
                      hidden={!imgRef.current}
                      className='img-input'
                    />
                    {!imgRef.current && srcMain && (
                      <>
                        <img
                          className='img-input '
                          src={srcMain}
                          alt=''
                        />
                      </>
                    )}
                    {!imgRef.current && !srcMain && (
                      <div className='img-input  d-flex'>
                        <Icon
                          className='mx-auto my-auto'
                          color='#969cb2'
                          icon='image'
                        ></Icon>
                      </div>
                    )}
                  </label>
                </div>
                <span className='retorno-invalido'>
                  {errors?.imagem?.message}
                </span>
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                dialogClassName='modal-90w'
              >
                <Modal.Header>
                  <Modal.Title className='modal-title'>Cortar a imagem</Modal.Title>
                      <button
                        onClick={handleClose}
                        type="button"
                        className="btn-close close p-1"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <Icon
                          icon={'times-circle'}
                          size="2x"
                          color="#FFF"
                          className="d2"
                        />
                      </button>
                </Modal.Header>
                <Modal.Body className='d-flex align-items-center justify-content-center'>
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoadImage}
                    crop={crop}
                    onChange={(c) => {
                      setDisabled(!(c.width >= 50));
                      return setCrop(c);
                    }}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    disabled={disabled}
                    className='btn btn-verde'
                    type='button'
                    onClick={handleConclude}
                  >
                    Concluído
                  </button>
                </Modal.Footer>
              </Modal>

              <div className='form-group col-12 col-md-4'>
                <label htmlFor='dataHora' className='control-label'>
                  Data e hora do jogo
                </label>
                <input
                  id='dataHora'
                  name='dataHora'
                  type='datetime-local'
                  className='form-control'
                  ref={register}
                  onInput={(event) => setDataHora(event.target.value)}
                  defaultValue={moment().format('YYYY-MM-DD[T]20:00')}
                />
                <span className='retorno-invalido'>
                  {errors?.dataHora?.message}
                </span>
              </div>

              <div className='form-group col-12 col-md-4'>
                <label htmlFor='nome' className='control-label'>
                  Nome adversário
                </label>
                <input
                  id='nome'
                  name='nome'
                  type='text'
                  className='form-control'
                  placeholder='Ex.: Fulanos Futsal'
                  ref={register}
                />
                <span className='retorno-invalido'>
                  {errors?.nome?.message}
                </span>
              </div>

              <div className='form-group col-12 col-md-4'>
                <label htmlFor='local' className='control-label'>
                  Local do jogo
                </label>
                <input
                  id='local'
                  name='local'
                  type='text'
                  className='form-control'
                  placeholder='Ex.: Ginásio Moroni - Santa Rosa/RS'
                  ref={register}
                />
                <span className='retorno-invalido'>
                  {errors?.local?.message}
                </span>
              </div>

              <div className='form-group col-12 col-md-4'>
                <input
                  type='checkbox'
                  name='amistoso'
                  id='amistoso'
                  ref={register}
                  onChange={() => setAmistate(!amistate)}
                />
                <label className='AA ml-2' htmlFor='amistoso'>
                  Amistoso?
                </label>
              </div>
            </div>
            {!amistate ? (
              <div className='d-flex flex-wrap color-black'>
                <div className='form-group col-12 col-md-4'>
                  <label htmlFor='liga' className='control-label'>
                    Competição
                  </label>
                  <Controller
                    control={control}
                    id='liga'
                    name='liga'
                    defaultValue={defaultValue}
                    render={({ onChange }) => (
                      <Select
                        value={competicao.find((c) => c?.value === form?.liga)}
                        options={competicao}
                        placeholder='Selecione uma competição'
                        noOptionsMessage={() => 'Não existe competição'}
                        onChange={(e) => {
                          getFases(e.value);
                          setForm({
                            ...getValues(),
                            liga: e?.value,
                            fase: '',
                          });
                          return onChange(e.value);
                        }}
                      />
                    )}
                  />
                  <span className='retorno-invalido'>
                    {errors?.liga?.message}
                  </span>
                </div>

                <div className='form-group col-12 col-md-4'>
                  <label htmlFor='fase' className='control-label'>
                    Fase da competição
                  </label>
                  <Controller
                    control={control}
                    defaultValue={defaultValue}
                    id='fase'
                    name='fase'
                    render={({ onChange }) => {
                      return (
                        <Select
                          ref={selectRef}
                          value={{ label: form?.fase, value: form?.fase }}
                          options={fases}
                          noOptionsMessage={() =>
                            'Selecione uma competição antes de selecionar a fase'
                          }
                          onChange={(e) => {
                            if (e) {
                              setForm({
                                ...getValues(),
                                fase: e?.value,
                              });
                            }
                            return onChange(e?.value);
                          }}
                        />
                      );
                    }}
                  />
                  <span className='retorno-invalido'>
                    {errors?.fase?.message}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className='d-flex flex-wrap color-black'>
              <div className='form-group col-12 col-md-4'>
                <input
                  type='checkbox'
                  name='emCasa'
                  id='emCasa'
                  ref={register}
                  onChange={() => setGameOut(!gameOut)}
                />
                <label className='ml-2' htmlFor='emCasa'>
                  Em casa?
                </label>
              </div>
            </div>
            {gameOut ? (
              <div className='d-flex flex-wrap color-black'>
                <div className='form-group col-12 col-md-4'>
                  <label htmlFor='vagas' className='control-label'>
                    Vagas reservadas
                  </label>
                  <input
                    id='vagas'
                    name='vagas'
                    type='number'
                    className='form-control'
                    ref={register}
                  />
                  <span className='retorno-invalido'>
                    {errors?.vagas?.message}
                  </span>
                </div>

                <div className='form-group col-12 col-md-4'>
                  <label htmlFor='dataHora' className='control-label'>
                    Data de início check-in
                  </label>
                  <input
                    id='dataHoraIn'
                    name='dataHoraIn'
                    type='datetime-local'
                    className='form-control'
                    onInput={(event) => setDataHoraIn(event.target.value)}
                    max={dataHora}
                    ref={register}
                    defaultValue={moment().format('YYYY-MM-DD[T]20:00')}
                  />
                  <span className='retorno-invalido'>
                    {errors?.dataHoraIn?.message}
                  </span>
                </div>

                <div className='form-group col-12 col-md-4'>
                  <label htmlFor='dataHora' className='control-label'>
                    Data de término check-in
                  </label>
                  <input
                    id='dataHoraTe'
                    name='dataHoraTe'
                    type='datetime-local'
                    className='form-control'
                    ref={register}
                    max={dataHora}
                    min={dataHoraIn}
                    defaultValue={moment().format('YYYY-MM-DD[T]20:00')}
                  />
                  <span className='retorno-invalido'>
                    {errors?.dataHoraTe?.message}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className='d-flex justify-content-between col-12'>
              <button
                type='button'
                className='btn btn-light border'
                onClick={() => history.push('/area-admin/jogos')}
              >
                Voltar
              </button>
              <button
                type='submit'
                className='btn btn-verde'
                disabled={disabled || loading}
              >
                {identifiers.buttonText}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}

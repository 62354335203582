import React from 'react'
import { Link } from 'react-router-dom'
import { breadcrumbPagesPath } from '../utils/breadcrumbPagesPath'

export default function Breadcrumb({ pageName, rightElement }) {
  const paths = breadcrumbPagesPath[pageName]

  if (!paths || !Array.isArray(paths)) {
    throw new Error(
      '[Breadcrumb]: Page with Breadcrumb component must have a valid "name" prop. Check breadcrumbPagesPath utils.',
    )
  }

  return (
    <div className="custom-breadcrumb">
      <h1 className="custom-breadcrumb__title">
        {paths[paths.length - 1].name}
      </h1>
      {paths.map((path) => {
        if (path.hasLink) {
          return (
            <Link
              className={`custom-breadcrumb__item${path.last ? '--bold' : ''}`}
              key={path.name}
              to={path.link}
            >
              {path.name}
            </Link>
          )
        }

        return (
          <span
            className={`custom-breadcrumb__item${path.last ? '--bold' : ''}`}
            key={path.name}
          >
            {path.name}
          </span>
        )
      })}
      {rightElement}
    </div>
  )
}

import React from 'react'

import instagramIcon from '../assets/png/instagram_colorful.png'

export default function InstagramFeed({ posts }) {
  return (
    <div className="instagram-feed">
      <div className="instagram-feed__header">
        <a
          href="http://instagram.com/santarosa.futsal"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={instagramIcon}
            alt=""
            className="instagram-feed__header__icon"
          />
        </a>
        <span className="instagram-feed__header__identifier">
          @santarosa.futsal
        </span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/santarosa.futsal/"
          className="instagram-feed__header__button follow-button--instagram"
        >
          SEGUIR
        </a>
      </div>

      <div className="instagram-feed__list">
        {posts?.map((post) => (
          <a
            key={post?.id}
            target="_blank"
            href={post?.permalink || '#'}
            rel="noopener noreferrer"
            className="instagram-feed__list__item"
          >
            <img
              src={post?.thumbnail_url || post?.media_url}
              alt=""
              className="instagram-feed__list__item__img"
            />
          </a>
        ))}
      </div>
    </div>
  )
}

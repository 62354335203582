import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import api from '../../services/api'

import HeaderSocioDesktop from '../../components/HeaderSocioDesktop'
import Loading from '../../components/Loading'
import Icon from '../../components/Icon'

export default function Ajustes() {
  const [, setPlano] = React.useState('')
  const [cancelado, setCancelado] = React.useState(false)
  const [modal, setModal] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [ casal, setCasal ] = React.useState('')

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get('/area-socio/status')

        setPlano(response?.data?.plano)
        setCancelado(response?.data?.cancelado)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar suas informações, provavelmente não foi culpa sua.',
        )
      } finally {
      }
    })()
  }, [])

  async function handleToggleCancelado() {
    try {
      setLoading(true)
      const response = await api.patch('/area-socio/alterar-status', {
        cancelado: !cancelado,
      })

      if (response?.data?.payment && !response?.data?.payment?.aprovado) {
        toast.error(
          'Ocorreu um problema com seu pagamento, verifique seus dados.',
        )
        return
      }

      setCancelado(response?.data?.cancelado)

      toast.success(
        response?.data?.payment?.aprovado
          ? 'Pagamento realizado e cadastro ativo novamente! Obrigado por torcer conosco.'
          : !response?.data?.cancelado
          ? 'Cadastro ativo novamente! Obrigado por torcer conosco.'
          : 'Cadastro cancelado com sucesso, esperamos torcer novamente contigo em breve.',
      )
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro ao buscar suas informações, provavelmente não foi culpa sua.',
      )
    } finally {
      setLoading(false)
      setModal(false)
    }
  }

  function handleToggleModal() {
    setModal(!modal)
  }

  React.useEffect(() => {
    ;(async () => {
      try {
        const responseArea = await api.get('/area-socio/status');
        const planoId = responseArea.data.plano;
        const planoIs = await api.get(`planos/${planoId}`);
        const planoDb = planoIs.data.dependente;

        const response = await api.get('planos');
        const planoCasal = response?.data.filter(d => d.dependente === true);
        const planoMap = planoCasal.map(d => d.dependente)
        if (planoDb === true){
        
        setCasal(planoMap)
        } else {
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar suas informações, provavelmente não foi culpa sua.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <div className="socio-wrapper-secondary">
        <HeaderSocioDesktop className="socio-header-secondary" />

        <main>
          {/* <section className="ajustes-plano-section">
            <div className="socio-container">
              <h1>Alterar plano</h1>
            </div>

            <div className="socio-planos-list">
              {planos?.map(p => {
                if (p === plano) {
                  return (
                    <div key={p} className="plano-selected" to="/">
                      <div>
                        <div>

                        </div>
                      </div>
                      <span>Plano</span>
                      {planosIconsColors[p] && (
                        <FontAwesomeIcon
                          icon={faMedal}
                          style={{
                            '--fa-primary-color': planosIconsColors[p],
                            '--fa-secondary-color': '#D06060',
                            '--fa-secondary-opacity': 1
                          }}
                        />
                      )}
                      <h1>{nameCapitalize(p)}</h1>
                    </div>
                  )
                }

                return (
                  <Link key={p} to={{ pathname: '/area-socio/ajustes/alterar-plano', state: { plano, selected: p } }}>
                    <div>
                      <div>

                      </div>
                    </div>
                    <span>Plano</span>
                    {planosIconsColors[p] && (
                      <FontAwesomeIcon
                        icon={faMedal}
                        style={{
                          '--fa-primary-color': planosIconsColors['default'],
                          '--fa-secondary-color': planosIconsColors['default'],
                          '--fa-secondary-opacity': 1
                        }}
                      />
                    )}
                    <h1>{nameCapitalize(p)}</h1>
                  </Link>
                )
              })}
              <div className="cards-list-space"></div>
            </div>
          </section> */}

          <section className="ajustes-geral-section">
            <div className="socio-container">
              <h1>Alterar dados</h1>

              <div className="socio-ajustes-list">
                <Link
                  className="socio-ajustes-item"
                  to="/area-socio/ajustes/pessoal"
                >
                  <div>
                    <h2>Pessoal</h2>
                    <p>Dados pessoais e de contato</p>
                  </div>

                  <Icon icon="chevron-right" color="#58323B" />
                </Link>

                <Link
                  to="/area-socio/ajustes/cartao"
                  className="socio-ajustes-item"
                >
                  <div>
                    <h2>Pagamento</h2>
                    <p>Alterar os dados do cartão de crédito</p>
                  </div>

                  <Icon icon="chevron-right" color="#58323B" />
                </Link>

                <Link
                  className="socio-ajustes-item"
                  to="/area-socio/ajustes/endereco"
                >
                  <div>
                    <h2>Endereço</h2>
                    <p>Dados residenciais</p>
                  </div>

                  <Icon icon="chevron-right" color="#58323B" />
                </Link>

                {casal ? (
                  <Link
                    className="socio-ajustes-item"
                    to="/area-socio/ajustes/dependente"
                  >
                    <div>
                      <h2>Dependente</h2>
                      <p>Dados pessoais do dependente</p>
                    </div>

                    <Icon icon="chevron-right" color="#58323B" />
                  </Link>
                ) : (
                  <div className="socio-ajustes-item">
                    <div>
                      <h2>Dependente</h2>
                      <p>Dados pessoais do dependente</p>
                    </div>

                    <Icon icon="chevron-right" color="#58323B" />
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="socio-risk-section">
            <div className="socio-container">
              <h1>Área de risco</h1>
              <hr />
              <button
                type="button"
                className={cancelado ? 'valid' : ''}
                onClick={handleToggleModal}
              >
                {cancelado ? (
                  <>
                    <Icon prefix="far" icon="check-circle" mr={20} />
                    Ativar cadastro
                  </>
                ) : (
                  <>
                    <Icon prefix="far" icon="times-circle" mr={8} />
                    Cancelar cadastro
                  </>
                )}
              </button>
            </div>
          </section>

          {modal && (
            <div className="modal-warning">
              <div>
                <Icon icon="exclamation-triangle" color="#f9aa3b" size={24} />
                <div className="socio-warnings">
                  <p>
                    {cancelado
                      ? 'Sujeito a cobrança em caso de inatividade.'
                      : 'Tem certeza que deseja continuar?'}
                  </p>
                </div>

                <button className="continuar" onClick={handleToggleCancelado}>
                  Sim
                </button>
                <button className="cancelar" onClick={handleToggleModal}>
                  Não
                </button>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  )
}

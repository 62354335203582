import React from 'react'

export default function LabelStatusC({
  id,
  children,
  compareceu,
  className = '',
  mensagem,
}) {
  return (
    <div id={id} className={`label-status-container-compareceu ${className}`}>
      {compareceu ? (
        <div className="label-status-info-compareceu"></div>
      ) : (
        <div className="label-status-info-compareceu"></div>
      )}
      <div
        className={`label-status ${
          compareceu ? 'label-status-compareceu' : ''
        }`}
      >
        {children}
      </div>
    </div>
  )
}

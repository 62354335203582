import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../services/api';

import LoadingSpinner from '../LoadingSpinner';
import Icon from '../Icon';

export default function Personal() {
  const history = useHistory();
  const { id } = useParams();
  const [personalInfo, setPersonalInfo] = React.useState({});
  const [plano, setPlano] = React.useState('');
  const [amigo, setAmigo] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`socios/${id}`, {
          params: {
            info: 'personal'
          }
        });

        const planoId = response?.data.plano;
        const plano = await api.get(`planos/${planoId}`);
        const planoName = plano?.data.descricao;

        const planoAmigo = plano?.data.public === false 

        if (response?.data?.amigo) {
          const amigoId = response?.data?.amigo
          const amigo = await api.get(`socios/${amigoId}`, {
            params: {
              info: 'personal'
            }
          })
          const amigoNome = amigo?.data?.nome
          setAmigo(amigoNome)
        }

        if (!response?.data?.amigo && planoAmigo) {
          setAmigo('Não possui amigo')
        }

        setPersonalInfo(response?.data);
        setPlano(planoName)
      } catch (err) {
        toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao buscar os dados do usuário.');
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  function handleEdit({ path, state }) {
    history.push(`/area-admin/socios/${id}/${path}/editar`, state);
  }

  if (loading) {
    return <LoadingSpinner className="d-flex justify-content-center" />;
  }

  return (
    <>
      <div className="col-12 color-black">
        <div className="row">
          <div className="col-10">
            <h3 className={`badge-status ${personalInfo?.cancelado ? 'badge-cancelado' : personalInfo?.ativo ? 'badge-ativo' : 'badge-inativo'}`}>
              {personalInfo?.cancelado ? 'Cancelado' : personalInfo?.ativo ? 'Ativo' : 'Inadimplente'}
            </h3>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-start mb-2">
            <Icon
              className="cursor-pointer"
              icon="edit"
              style={{ fontSize: 24 }}
              onClick={() => handleEdit({
                path: 'pessoal',
                state: {
                  fields: {
                    plano: plano,
                    nome: personalInfo?.nome,
                    dt_nasc: personalInfo?.dt_nasc,
                    email: personalInfo?.email,
                    cpf: personalInfo?.cpf,
                    telefone: personalInfo?.telefone
                  },
                  redirect: `/area-admin/socios/${id}/pessoal`
                }
              })}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-between color-black">
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Nome</small>
          <span className="d-block">{personalInfo?.nome}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2 d-flex">
          <div>
            <small className="font-weight-bold fs-14 d-block">Plano</small>
            <span className="d-block">{plano}</span>
          </div>
        </div>
        {amigo ?
          amigo === 'Não possui amigo' ?
            <div className="col-12 col-sm-6 mt-2">
              <small className="font-weight-bold fs-14 d-block">Amigo</small>
              <span style={{color: '#e60000', fontWeight: 'bold'}} className="d-block">{amigo}</span>
            </div> 
          : 
            <div className="col-12 col-sm-6 mt-2">
              <small className="font-weight-bold fs-14 d-block">Amigo</small>
              <span className="d-block">{amigo}</span>
            </div>
        : null
        }
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Nascimento</small>
          <span className="d-block">{personalInfo?.dt_nasc}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">E-mail</small>
          <span className="d-block">{personalInfo?.email}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">CPF</small>
          <span className="d-block">{personalInfo?.cpf}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Celular</small>
          <span className="d-block">{personalInfo?.telefone}</span>
        </div>
      </div>

      <div className="col-12 mt-4 color-black">
        <div className="row">
          <div className="col-10">
            <h3 className="font-weight-bold">Endereço</h3>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-start mb-2">
            <Icon
              className="cursor-pointer"
              icon="edit"
              style={{ fontSize: 24 }}
              onClick={() => handleEdit({
                path: 'endereco',
                state: {
                  fields: {
                    cep: personalInfo?.endereco?.cep,
                    cidade: personalInfo?.endereco?.cidade,
                    logradouro: personalInfo?.endereco?.logradouro,
                    numero: personalInfo?.endereco?.numero,
                    bairro: personalInfo?.endereco?.bairro,
                    uf: personalInfo?.endereco?.uf,
                    complemento: personalInfo?.endereco?.complemento
                  },
                  redirect: `/area-admin/socios/${id}/pessoal`
                }
              })}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-between color-black">
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">CEP</small>
          <span className="d-block">{personalInfo?.endereco?.cep}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Cidade</small>
          <span className="d-block">{personalInfo?.endereco?.cidade}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Logradouro</small>
          <span className="d-block">{personalInfo?.endereco?.logradouro}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Número</small>
          <span className="d-block">{personalInfo?.endereco?.numero}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Bairro</small>
          <span className="d-block">{personalInfo?.endereco?.bairro}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">UF</small>
          <span className="d-block">{personalInfo?.endereco?.uf}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Complemento</small>
          <span className="d-block">{personalInfo?.endereco?.complemento || 'SEM COMPLEMENTO'}</span>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import { Link } from 'react-router-dom'

import bannerLiga2 from '../assets/other/banner_landing_liga2.jpg'

export default function LargeBanner() {
  return (
    <Link to="/planos">
      <img
        className="large-banner"
        height="121"
        src={bannerLiga2}
        alt="Banner Liga 2"
        loading="lazy"
      />
    </Link>
  )
}

import * as yup from 'yup';

export const schemaPlanos = yup.object().shape({
  valor: yup.string().required('Campo obrigatório!'),
  valor_cart: yup.string().required('Campo obrigatório!'),
  
  recorrencia: yup.string().required('Campo obrigatório!'),
  dependente: yup.boolean().required('Campo obrigatório!'),
  descricao: yup.string().required('Campo obrigatório!').max(20, 'O plano não deve conter mais de 20 caracteres'),//Nome do plano
});

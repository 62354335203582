import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { toast } from 'react-toastify'

import { useAuth } from '../../../Contexts/AuthContext'

import { schemaLoginPass } from '../../../validators/formLogin'

import CustomInput from '../../CustomInput'
import Loading from '../../Loading'

export default function Password({ navigateOnStep }) {
  const { loginData, handleLogin } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schemaLoginPass),
    defaultValues: {
      senha: '',
    },
  })

  async function handleSubmitForm({ senha }) {
    try {
      setLoading(true)
      await handleLogin({ cpf: loginData?.cpf, senha })
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro ao fazer login, tente novamente.',
      )
    } finally {
      setLoading(false)
    }
  }

  function handleForgotPass() {
    navigateOnStep(3)
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      {loading && <Loading delayTime={200} />}
      <h1 className="login-title">Confirme que é você</h1>
      <button
        type="button"
        onClick={handleForgotPass}
        className="login-recuperar-senha"
      >
        Esqueceu a senha?
      </button>

      <CustomInput
        id="senha"
        name="senha"
        inputType="password"
        className={`login-input ${errors?.senha ? 'socio-input-error' : ''}`}
        placeholder="Sua senha"
        icon="lock-open"
        iconSize={14}
        iconColor="#969CB2"
        inputRef={register}
      />

      <button type="submit" className="login-button">
        Entrar
      </button>
    </form>
  )
}

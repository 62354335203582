import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers';

import api from '../../services/api';

import { schemaCadastroDispositivo } from '../../validators/formDispositivo';
import AdminMenu from '../../components/AdminMenu';

export default function UsuariosCadastro() {
  const { id } = useParams();
  const history = useHistory();
  const [ativo, setAtivo] = useState(false);
  const [identifiers, setIdentifiers] = React.useState({
    function: 'create',
    buttonText: 'Cadastrar',
    successMessage: 'Dispositivo cadastrado!',
  });
  const {
    errors,
    register,
    handleSubmit: handleSubmitForm,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaCadastroDispositivo),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      descricao: '',
      dispMac: '',
      ativo: false,
    },
  });

  useEffect(() => {
    (async () => {
      if (id) {
        setIdentifiers({
          function: 'edit',
          buttonText: 'Salvar',
          successMessage: 'Dispositivo editado com sucesso!',
        });

        const res = await api.get(`/dispositivos/${id}`);

        setValue('descricao', res?.data?.descricao);
        setValue('dispMac', res?.data?.dispMac);
        setValue('ativo', res?.data?.ativo);
      }
    })();
  }, [id, setValue]);

  async function handleSubmit(data) {
    if (identifiers.function === 'edit') {
      try {
        await api.put(`/dispositivos/${id}`, data);
        setTimeout(() => {
          toast.success(identifiers.successMessage);
          history.push('/area-admin/dispositivos');
        }, 50);
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao enviar suas informações.'
        );
      }
    } else {
      try {
        await api.post('/dispositivos', data);

        setTimeout(() => {
          toast.success(identifiers.successMessage);
          history.push('/area-admin/dispositivos');
        }, 50);
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao enviar suas informações.'
        );
      }
    }
  }

  return (
    <>
      <AdminMenu />
      <main className='wrapper bg-verm'>
        <div className='container mt-3 pt-3'>
          <form
            onSubmit={handleSubmitForm(handleSubmit)}
            className='card p-4 border-0'
          >
            <div className='d-flex flex-wrap color-black'>
              <div className='form-group col-12'>
                <label htmlFor='nome' className='control-label'>
                  Descrição
                </label>
                <input
                  ref={register}
                  id='descricao'
                  name='descricao'
                  type='text'
                  className='form-control'
                  placeholder='Descrição do dispositivo'
                  maxLength={25}
                />
                <span className='retorno-invalido'>
                  {errors?.descricao?.message}
                </span>
              </div>
              <div className='form-group col-12'>
                <label htmlFor='nome' className='control-label'>
                  MAC
                </label>
                <input
                  ref={register}
                  id='dispMac'
                  name='dispMac'
                  type='text'
                  className='form-control'
                  placeholder='MAC do dispositivo'
                  maxLength={25}
                />
                <span className='retorno-invalido'>
                  {errors?.dispMac?.message}
                </span>
              </div>
              <div className='form-group col-12 col-md-4'>
                <input
                  ref={register}
                  type='checkbox'
                  name='ativo'
                  id='ativo'
                  onChange={() => setAtivo(!ativo)}
                />
                <label className='ml-2' htmlFor='ativo'>
                  Ativo
                </label>
                {/* <div className='status-active-ativo'>
                  <input
                    hidden
                    type='checkbox'
                    name='ativo'
                    id='ativo'
                    onChange={() => console.log(ativo)}
                  />
                  <div className='status-ativo margin-bottom-2 d-flex align-items-center'>
                    <div className='d-inline-block circle-status status-color-ativo'></div>
                    <span className='status-text'>Ativa</span>
                  </div>

                  <input
                    hidden
                    type='checkbox'
                    name='inativo'
                    id='inativo'
                    onChange={() => console.log(ativo)}
                  />
                  <div
                    className='status-inativo margin-bottom-2 d-flex align-items-center'
                    htmlFor='inativo'
                  >
                    <div className='d-inline-block circle-status status-color-inativo'></div>
                    <span className='status-text'>Inativo</span>
                  </div>
                </div> */}
              </div>
            </div>

            <div className='d-flex justify-content-between col-12'>
              <button
                type='button'
                className='btn btn-light border'
                onClick={() => history.push('/area-admin/dispositivos')}
              >
                Voltar
              </button>
              <button type='submit' className='btn btn-verde'>
                {identifiers.buttonText}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}

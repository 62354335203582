import React from "react";
import { useForm } from "react-hook-form";
import Cleave from "cleave.js/react";
import { yupResolver } from "@hookform/resolvers";

import { useCadastro } from "../../../Contexts/CadastroContext";
import { schemaCadastroThirdStep } from '../../../validators/formCadastro';

import CustomInput from "../../CustomInput";

export default function Adicional({ navigateOnStep, currentStep }) {
    const { formData, updateFormData } = useCadastro();
    const { register, handleSubmit, errors, setError } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        resolver: yupResolver(schemaCadastroThirdStep),
        defaultValues: {
            ...formData,
            ...formData?.dependente
        }
    });
    function onSubmit(data) {
        const newData = {
            camiseta: data?.camiseta,
            dependente: {
                nome: data?.nome,
                cpf: data?.cpf
            }
        }
        if (formData.cpf === data.cpf) {
            setError('cpf', { message: 'CPF do dependente não pode ser o mesmo do sócio.', shouldFocus: true })
            return
        }
        updateFormData(newData);
        navigateOnStep(currentStep + 1);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form-steps-container">
            <h1 className="login-title">{formData?.nome?.split(' ')[0]}, agora precisamos de dados adicionais</h1>
            <h2 className="login-subtitle">Santa Rosa Futsal</h2>
            <div className="row">
                {/* <div className="form-group mb-1 col-12 col-lg-4 col-sm-6">
                    <label className="control-label login-label" htmlFor="camiseta">Camiseta</label>
                    <CustomSelect
                        icon="t-shirt"
                        iconColor="#969CB2" 
                        inputType="text" 
                        name="camiseta" 
                        className={`login-select-input ${errors?.camiseta ? 'socio-input-error' : ''}`} 
                        id="camiseta"
                        options={camisetaOptions}
                        inputRef={register}
                    />
                    <div className="retorno-invalido mh-19">{errors?.camiseta?.message}</div>
                </div> */}
                <div className="form-group mb-1 col-12 col-lg-4 col-sm-6">
                    <label className="login-label" htmlFor="nome">Nome dependente</label>
                    <CustomInput
                        icon="signature"
                        iconColor="#969CB2"
                        name="nome"
                        type="text"
                        className={`login-input ${errors?.nome ? 'socio-input-error' : ''}`}
                        id="nome"
                        placeholder="Ex.: João da Silva"
                        inputRef={register}
                    />
                    <div className="retorno-invalido mh-19">{errors?.nome?.message}</div>
                </div>
                <div className="form-group mb-1 col-12 col-lg-4">
                    <label className="login-label" htmlFor="cpf">CPF dependente</label>
                    <CustomInput
                        as={Cleave}
                        options={{
                            delimiters: ['.', '.', '-'],
                            blocks: [3, 3, 3, 2],
                            numericOnly: true
                        }}
                        icon="id-card"
                        iconColor="#969CB2"
                        name="cpf"
                        type="tel"
                        className={`login-input ${errors?.cpf ? 'socio-input-error' : ''}`}
                        id="cpf"
                        placeholder="000.000.000-00"
                        htmlRef={register}
                    />
                    <div className="retorno-invalido mh-19">{errors?.cpf?.message}</div>
                </div>
            </div>
            <div className="row flex-wrap-reverse">
                <div className="col-12 col-lg-6">
                    <button type="button" className="login-button btn-grey" id="voltar" onClick={() => navigateOnStep(currentStep - 1)}>Voltar</button>
                </div>
                <div className="col-12 col-lg-6">
                    <button type="submit" id="proximo" className="login-button">Próximo</button>
                </div>
            </div>
        </form>
    )
}
import React from 'react'
import Loading from '../../components/Loading'
import { toast } from 'react-toastify'
import PlanoCard from '../../components/PlanoCard'
import AdminMenu from '../../components/AdminMenu'
import api from '../../services/api'

export default function Planos() {
  const [loading, setLoading] = React.useState(true)
  const [modal, setModal] = React.useState(false)
  const [planoList, setPlanoList] = React.useState([])
  
  const handleShow = () => setModal(true)

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true) 
        
        const response = await api.get('/planos')

        const planos = response.data.filter(function(v) {
          return v.public !== false
        })

        setPlanoList(planos)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de planos, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className="wrapper bg-verm" id="scrollableWrapper">
        <div className="container mt-3 p-0 pt-3 pb-4 position-relative">
          <div className="d-flex flex-wrap row-gap-30">
            {planoList.length > 0 ? (
              planoList?.map((plano, index) => (
                <PlanoCard
                  show={modal}
                  handleShow={handleShow}
                  plano={plano}
                  key={index + plano._id}
                />
              )) || <div></div>
            ) : (
              <div className="jogos_">Não há planos cadastrados.</div>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

import React from 'react'

import { CadastroProvider } from '../../Contexts/CadastroContext'
import { PlanosProvider } from '../../Contexts/PlanosContext'

import FirstStep from '../../components/steps/Cadastro/Passo1Cadastro'
import SecondStep from '../../components/steps/Cadastro/Passo2Cadastro'
import ThirdStep from '../../components/steps/Cadastro/Passo3Cadastro'
import Stepper from '../../components/Stepper'
import AdminMenu from '../../components/AdminMenu'

export default function SociosCadastro() {
  return (
    <>
      <AdminMenu />
      <main className="wrapper bg-verm">
        <div className="container pt-3 mt-3 pb-4">
          {/* <div className="d-flex pt-3 mb-3">
        <h1 className="mt-0">Novo Sócio</h1>
      </div> */}

          <CadastroProvider>
            <PlanosProvider>
              <Stepper
                showSteps
                steps={[
                  {
                    title: 'Informações pessoais',
                    description: '',
                    component: (props) => <FirstStep {...props} />,
                  },
                  {
                    title: 'Informações adicionais',
                    description: '',
                    component: (props) => <SecondStep {...props} />,
                  },
                  {
                    title: 'Pagamento',
                    description: '',
                    component: (props) => <ThirdStep {...props} />,
                  },
                ]}
              />
            </PlanosProvider>
          </CadastroProvider>
        </div>
      </main>
    </>
  )
}

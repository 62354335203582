import React from 'react';
import AdminMenu from '../../components/AdminMenu'
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading'

import api from '../../services/api';
import { schemaClassificacoes } from '../../validators/formClassificacoes';

export default function TabelaClassificacoes() {
  const [classificacoes, setClassificacoes] = React.useState([]);
  const [editedStatus, setEditedStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [editingTitle, setEditingTitle] = React.useState(false);
  const [title, setTitle] = React.useState('Sem Titulo');

  const columns = [
    { field: '_id', hide: true, sortable: false },
    {
      field: 'actionMove',
      headerName: ' ',
      minWidth: 5,
      flex: 0.03,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            <div className='d-flex flex-column'>
              {params.row.rank === 1 ? (
                <></>
              ) : (
                <>
                  <button
                    className='button border-0 bg-transparent'
                    onClick={() => {
                      handleMove(params, 'up');
                    }}
                  >
                    <Icon
                      icon='arrow-alt-circle-up'
                      color='#d69947'
                      size={20}
                    ></Icon>
                  </button>
                </>
              )}
              {params.row.rank === classificacoes.length ? (
                <></>
              ) : (
                <>
                  <button
                    className='button border-0 bg-transparent'
                    onClick={() => {
                      handleMove(params, 'down');
                    }}
                  >
                    <Icon
                      icon='arrow-alt-circle-down'
                      color='#d69947'
                      size={20}
                    ></Icon>
                  </button>
                </>
              )}
            </div>
          </>
        );
      },
    },
    {
      field: 'rank',
      cellClassName: 'col-rank',
      headerName: '',
      flex: 0.04,
      minWidth: 20,
      maxWidth: 60,
      sortable: false,
      align: 'center',
    },
    {
      field: 'clube',
      headerName: 'Clube',
      flex: 0.35,
      minWidth: 20,
      sortable: false,
      editable: true,
      type: 'string',
      preProcessEditCellProps: (params) => {
        const valid = schemaClassificacoes.fields.clube.isValidSync(
          params.props.value
        );
        if (valid) {
          setValue(params.id, 'clube', params.props.value);
          setEditedStatus(true);
        }
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'pontos',
      headerName: 'Pontos',
      flex: 0.1,
      minWidth: 20,
      sortable: false,
      editable: true,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value);
        const valid = schemaClassificacoes.fields.pontos.isValidSync(value);
        if (valid) {
          setValue(params.id, 'pontos', params.props.value);
          setEditedStatus(true);
        } else {
        }
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'jogos',
      headerName: 'J',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Jogos',
      editable: true,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value);
        const valid = schemaClassificacoes.fields.jogos.isValidSync(value);
        if (valid) {
          setValue(params.id, 'jogos', params.props.value);
          setEditedStatus(true);
        }
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'vitorias',
      headerName: 'V',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Vitórias',
      editable: true,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value);
        const valid = schemaClassificacoes.fields.vitorias.isValidSync(value);
        if (valid) {
          setValue(params.id, 'vitorias', params.props.value);
          setEditedStatus(true);
        }
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'empates',
      headerName: 'E',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Empates',
      editable: true,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value);
        const valid = schemaClassificacoes.fields.empates.isValidSync(value);
        if (valid) {
          setValue(params.id, 'empates', params.props.value);
          setEditedStatus(true);
        }
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'derrotas',
      headerName: 'D',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Derrotas',
      editable: true,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value);
        const valid = schemaClassificacoes.fields.derrotas.isValidSync(value);
        if (valid) {
          setValue(params.id, 'derrotas', params.props.value);

          setEditedStatus(true);
        }
        return { ...params.props, error: !valid };
      },
    },

    {
      field: 'golsPro',
      headerName: 'GP',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Gols Pró',
      editable: true,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value);
        const valid = schemaClassificacoes.fields.golsPro.isValidSync(value);
        if (valid) {
          setValue(params.id, 'golsPro', params.props.value);
          setEditedStatus(true);
        }
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'golsContra',
      headerName: 'GC',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Gols Contra',
      editable: true,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value);
        const valid = schemaClassificacoes.fields.golsContra.isValidSync(value);
        if (valid) {
          setValue(params.id, 'golsContra', params.props.value);
          setEditedStatus(true);
        }
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'saldoGols',
      headerName: 'SG',
      flex: 0.05,
      minWidth: 20,
      sortable: false,
      description: 'Saldo de Gols',
      editable: true,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: (params) => {
        const value = parseFloat(params.props.value);
        const valid = schemaClassificacoes.fields.saldoGols.isValidSync(value);
        if (valid) {
          setValue(params.id, 'saldoGols', value);
          setEditedStatus(true);
        }
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'actionDelete',
      headerName: '',
      maxWidth: 10,
      sortable: false,
      align: 'center',
      description: 'Saldo de Gols',
      renderCell: (params) => {
        return (
          <>
            <div>
              <button
                className='border-0 bg-transparent'
                onClick={() => {
                  handleDeleteRow(params.id, params.row.rank);
                }}
              >
                <Icon icon='times-circle' color='#de002c' size={20} />
              </button>
            </div>
          </>
        );
      },
    },
  ];

    

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await api.get('/classificacoes').then((response) => {
          setClassificacoes(response?.data);
        });
      } catch (error) {
        toast.error(
          error?.response?.data?.erro ||
            'Erro ao buscar a lista de classificações, por favor, recarregue a página.'
        );
      } finally {
        setLoading(false);
      }
    })()
  }, []);

  React.useEffect(() => {}, [classificacoes]);

  async function setValue(id, field, value) {
    let clfcs = classificacoes;
    for await (const clube of clfcs) {
      if (clube._id === id || clube.id === id) {
        if (field !== 'clube') {
          value = value ? parseInt(value) : 0;
        }

        if (clube[field] !== value) {
          clube[field] = value;
          setEditedStatus(true);
        }
      }
    }
  }

  function handleDeleteRow(id, rank) {
    setTimeout(() => {
      setClassificacoes((prevClassificacoes) => {
        for (const c of prevClassificacoes) {
          if (c.rank >= rank) {
            c.rank--;
          }
        }
        return prevClassificacoes.filter((c) => c._id !== id && c.id !== id);
      });
    });

    setEditedStatus(true);
  }

  function handleMove(params, arrow) {
    let clfcs = classificacoes;
    const classificacao = clfcs.find((c) => c.rank === params.row.rank);
    if (arrow === 'up') {
      try {
        const classificacaoUp = clfcs.find(
          (c) => c.rank === params.row.rank - 1
        );
        const rankUp = classificacaoUp.rank;
        classificacaoUp.rank = classificacao.rank;
        classificacao.rank = rankUp;
        setEditedStatus(true);
        setClassificacoes([...clfcs]);
      } catch (error) {
        toast.error('Não há mais posições acima');
      }
    }
    if (arrow === 'down') {
      try {
        const classificacaoDown = clfcs.find(
          (c) => c.rank === params.row.rank + 1
        );
        const rankDown = classificacaoDown.rank;
        classificacaoDown.rank = classificacao.rank;
        classificacao.rank = rankDown;
        setEditedStatus(true);
        setClassificacoes([...clfcs]);
      } catch (error) {
        toast.error('Não há mais posições abaixo');
      }
    }
  }

  const handleSave = async () => {
    if (editedStatus) {
      await api.put('/classificacoes', classificacoes);
      toast.success('Salvo com sucesso!');
      setEditedStatus(false);
    }
  };

  const handleAddClube = () => {
    if (classificacoes) {
      setClassificacoes([
        ...classificacoes,
        {
          id: Date.now(),
          rank: classificacoes.length + 1,
          clube: 'novo clube',
          pontos: 0,
          jogos: 0,
          vitorias: 0,
          empates: 0,
          derrotas: 0,
          golsPro: 0,
          golsContra: 0,
          saldoGols: 0,
        },
      ]);
      setEditedStatus(true);
    } else {
      setClassificacoes([
        {
          id: Date.now(),
          rank: 1,
          clube: 'novo clube',
          pontos: 0,
          jogos: 0,
          vitorias: 0,
          empates: 0,
          derrotas: 0,
          golsPro: 0,
          golsContra: 0,
          saldoGols: 0,
        },
      ]);
    }
    setEditedStatus(true);
  };

  const fetchTableTitle = async () => {
    try {
      const response = await api.get('/classificacoes/titulo');
      setTitle(response.data.title);
    } catch (error) {
      toast.error('Erro ao buscar o título.');
    }
  };

  React.useEffect(() => {
    fetchTableTitle();
  }, []);

  const handleUpdateTitle = async () => {
    try {
      await api.put('/classificacoes/update-title', { title });
      toast.success('Título atualizado com sucesso!');
      setEditingTitle(false);
    } catch (error) {
      toast.error('Erro ao atualizar o título.');
    }
  };

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className='wrapper bg-verm'>
        <div className='container mt-3 p-0 pt-3 pb-4'>
          {classificacoes.length > 0 ? (
            <>
              <div className='d-flex flex-column'>
                {/* A estilização tá na main.scss a partir da linha com .MuiDataGrid-root */}
                <div className='mb-3'>
                  <div className='mb-3'>
                    {/* <h5 className='color-white'>Gauchão - Série B - Grupo A</h5> */}
                    {editingTitle ? (
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          value={title}
                          className="btn-title form-control mr-4"
                          onChange={(e) => setTitle(e.target.value)}
                          maxLength={50}
                        />
                        <button
                          className="btn btn-verde"
                          onClick={handleUpdateTitle}
                        >
                          Salvar
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <h5
                          className="fs-24 mb-2 fw-bold text-yellow mr-4"
                          onChange={(e) => setTitle(e.target.value)}
                        >
                          {title}
                        </h5>
                        <button
                          className="btn mb-2 edit-title"
                          onClick={() => setEditingTitle(true)}
                        >
                           <Icon className="edit-title" icon="pen" color="#ffffff" />
                        </button>
                      </div>
                    )}

                  </div>
                  <DataGrid
                    id='dataGrid'
                    name='dataGrid'
                    columns={columns}
                    rows={classificacoes}
                    disableSelectionOnClick
                    disableColumnMenu
                    autoHeight
                    hideFooter
                    density='comfortable'
                    getRowId={(row) => {
                      return row._id || row.id;
                    }}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'rank', sort: 'asc' }],
                      },
                    }}
                  />
                </div>
                <div className='ml-auto'>
                  <button
                    className='btn btn-verde mr-1'
                    onClick={handleAddClube}
                  >
                    Adicionar
                  </button>
                  <button
                    className='btn btn-verde ml-1'
                    onClick={handleSave}
                    disabled={!editedStatus}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <h5 className='color-white'>
                  A Tabela de Classificação não possui nenhum registro.
                </h5>
                <button className='button--small' onClick={handleAddClube}>
                  Adicionar
                </button>
                {editedStatus ? (
                  <button className='button--small ml-2' onClick={handleSave}>
                    Salvar
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
}

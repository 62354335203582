const paymentOptions = [
  {
    value: 'boleto',
    label: 'Boleto'
  },
  {
    value: 'dinheiro',
    label: 'Dinheiro'
  },
  {
    value: 'cc',
    label: 'Cartão de crédito'
  },
]

export default paymentOptions;
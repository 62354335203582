import React from 'react'
import { useForm } from 'react-hook-form'
import Cleave from 'cleave.js/react'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers'

import api from '../../../services/api'

import { useAuth } from '../../../Contexts/AuthContext'

import { schemaLoginCode } from '../../../validators/formLogin'

import CustomInput from '../../CustomInput'
import Loading from '../../Loading'

export default function Code({ navigateOnStep }) {
  const { updateLoginData, loginData } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schemaLoginCode),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      cpf: '',
    },
  })

  async function handleSubmitForm({ verification_code }) {
    try {
      setLoading(true)
      const response = await api.patch('/login/verificar-codigo', {
        cpf: loginData?.cpf,
        recovery: loginData?.pass,
        verification_code,
      })

      const { nextStep } = response?.data

      if (!nextStep) {
        toast.error('Ocorreu um erro de comunicação com o servidor.')
        return
      }

      setTimeout(() => {
        updateLoginData({ verification_code })

        navigateOnStep(nextStep)
      }, 50)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro de comunicação com o servidor.',
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      {loading && <Loading delayTime={200} />}
      <h1 className="login-title">Certo, agora insira o código</h1>
      <h2 className="login-subtitle tres-pontinhos">
        Enviado para {loginData?.censoredEmail}
      </h2>

      <CustomInput
        id="verification_code"
        name="verification_code"
        as={Cleave}
        inputType="tel"
        className={`login-input ${
          errors?.verification_code ? 'socio-input-error' : ''
        }`}
        placeholder="000000"
        icon="key"
        iconColor="#969CB2"
        htmlRef={register}
        options={{
          blocks: [6],
          uppercase: true,
          numericOnly: true,
        }}
      />

      <button type="submit" className="login-button">
        Continuar
      </button>
    </form>
  )
}

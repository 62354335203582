import React from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
import plugins from 'suneditor/src/plugins'

const Editor = ({ control, name, defaultValue, ...props }) => {
  return (
    <SunEditor
      name={name}
      lang="pt_br"
      width="100%"
      height="200px"
      setContents={defaultValue}
      setDefaultStyle="font-family: arial; font-size: 16px;"
      onChange={props.onChange}
      setOptions={{
        height: 200,
        plugins: plugins,
        buttonList: [
          [
            'undo',
            'redo',
            'font',
            'fontSize',
            'formatBlock',
            'blockquote',
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'fontColor',
            'hiliteColor',
            'removeFormat',
            'outdent',
            'indent',
            'align',
            'list',
            'lineHeight',
            'fullScreen',
          ],
        ],
      }}
    />
  )
}

export default Editor

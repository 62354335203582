import React from 'react'

import newspaperImg from '../assets/svg/newspaper.svg'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default function LatestNews({ news }) {
  const newsFormatted = news?.map((item) => ({
    ...item,
    dateFormatted: moment(item?.created_at).format('DD.MM.YYYY'),
  }))

  return (
    <section className="latest-news col-md-6">
      <div className="row latest-news__header align-items-center">
        <h1 className="col-8 latest-news__title">Últimas notícias</h1>
        <Link className="col-4 text-right latest-news__view-all" to="/noticias">
          Ver todas {'>'}
        </Link>
      </div>

      <div className="row" style={{ flex: 1 }}>
        <div className="col-md-12 col-sm-12 col-lg-12 noticias-home-container">
          {newsFormatted?.length > 0 ? (
            newsFormatted?.map((item) => (
              <div className="mt-4 noticia-pequena-container" key={item?._id}>
                <Link to={`/noticias/${item?._id}`}>
                  <div className="d-flex noticias-mobile">
                    <img
                      className="imagem-noticia--pequena-home"
                      src={item?.banner}
                      alt={`Banner da noticia ${item?.titulo}`}
                    />
                    <div className="noticia-pequena-texto-home">
                      <span className="noticias-pequenas-data-tipo noticias-fonte--pequena-bold">
                        {item?.dateFormatted}
                      </span>
                      <span className="noticias-pequenas-data-tipo-separador">
                        |
                      </span>
                      <span className="noticias-pequenas-data-tipo text-uppercase">
                        {item?.categoria}
                      </span>
                      <h3 className="noticias-fonte--pequena-gold line-clamp-1 mt-2">
                        {item?.titulo}
                      </h3>
                      <div className="font-12  line-clamp-3">
                        <span
                          dangerouslySetInnerHTML={{ __html: item?.corpo }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="latest-news__empty">
              <img
                src={newspaperImg}
                alt="Jornal"
                className="latest-news__empty__img"
                loading="lazy"
              />

              <p className="latest-news__empty__text">
                Em breve teremos notícias por aqui, mas você pode encontrá-las
                na
                <a
                  href="https://www.facebook.com/futsalsantarosa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  página do facebook
                </a>
                .
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

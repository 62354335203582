import Lottie from 'lottie-react'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import * as animationData from '../assets/man.json'
import Breadcrumb from '../components/Breadcrumb'
import Loading from '../components/Loading'
import MainFooter from '../components/MainFooter'
import MainNav from '../components/MainNav'
import api from '../services/api'
export default function Patrocinadores({ name }) {
	const [loading, setLoading] = React.useState(false)

	const [list, setList] = React.useState([])
	const [ouro, setOuro] = React.useState([])
	const [prata, setPrata] = React.useState([])
	const [bronze, setBronze] = React.useState([])
	const [apoio, setApoio] = React.useState([])
	const [torcida, setTorcida] = React.useState([])

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				const response = await api.get('/patrocinadores?page=1')
				const newList = response.data.list
				if (newList && newList.length > 0) {
					setList(newList)

					setOuro(newList.filter(p => p.tipo === 'ouro'))
					setPrata(newList.filter(p => p.tipo === 'prata'))
					setBronze(newList.filter(p => p.tipo === 'bronze'))
					setApoio(newList.filter(p => p.tipo === 'apoiador'))
					setTorcida(newList.filter(p => p.tipo === 'torcedor'))
				}
			} catch (err) {
				toast.error(err?.response?.data?.erro || 'Erro ao buscar a lista de patrocinadores, por favor, recarregue a página.')
			} finally {
				setLoading(false)
			}
		})()
	}, [])
	return (
		<>
			<MainNav />

			<div className='container-lg flex-fill'>
				<Breadcrumb pageName={name} />

				<main className='d-flex flex-column pt-8'>
					{list?.length > 0 ? (
						<>
							{ouro?.length > 0 ? (
								<ul className='list-unstyled d-flex flex-wrap justify-content-center sponsor-list--large'>
									{ouro.map(p => (
										<li className='sponsor-list--large__item m-4'>
											<img src={p.img.url} alt={`Logo ${p.nome}`} />
										</li>
									))}
								</ul>
							) : null}
							{prata?.length > 0 ? (
								<ul className='list-unstyled d-flex flex-wrap justify-content-center sponsor-list--medium'>
									{prata.map(p => (
										<li className='mx-4 sponsor-list--medium__item'>
											<img src={p.img.url} alt={`Logo ${p.nome}`} />
										</li>
									))}
								</ul>
							) : null}
							{bronze?.length > 0 && (
								<ul className='list-unstyled d-flex flex-wrap justify-content-center sponsor-list--small'>
									{bronze.map(p => (
										<li className='mx-5 sponsor-list--small__item'>
											<img src={p.img.url} alt={`Logo ${p.nome}`} />
										</li>
									))}
								</ul>
							)}
							{apoio?.length > 0 || torcida?.length > 0 ? (
								<>
									<h1 className='ml-4 fs-24 fw-bold text-yellow'>Apoiadores</h1>
									{apoio?.length > 0 ? (
										<ul className='p-0 list-unstyled d-flex flex-wrap justify-content-center sponsor-list--small'>
											{apoio.map(p => (
												<li className='mx-5 sponsor-list--small__item'>
													<img src={p.img.url} alt={`Logo ${p.nome}`} />
												</li>
											))}
										</ul>
									) : null}

									{torcida?.length > 0 ? (
										<ul className='p-0 mb-0 list-unstyled d-flex flex-wrap justify-content-center sponsor-list--small'>
											{torcida.map(p => (
												<li className='mx-5 sponsor-list--small__item'>
													<img src={p.img.url} alt={`Logo ${p.nome}`} />
												</li>
											))}
										</ul>
									) : null}
								</>
							) : null}
						</>
					) : loading ? (
						<Loading delayTime={200} />
					) : (
						<>
							<Lottie
								isClickToPauseDisabled
								animationData={animationData.default}
								style={{ height: 120, marginTop: '80px', marginBottom: '40px' }}
								loop={true}
								autoplay={true}
							/>
							<h5 className='text-center'>Não há patrocinadores no momento</h5>
						</>
					)}
				</main>
			</div>

			<div className='container-lg pb-5'>
				<MainFooter />
			</div>
		</>
	)
}

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers';

import api from '../../services/api';

import { schemaEdicaoShirt } from '../../validators/formEdicao';

import camisetaOptions from '../../utils/camisetaOptions';

import Select from '../Select';

export default function Camiseta({ state }) {
  const { id } = useParams();
  const history = useHistory();
  const { handleSubmit: handleSubmitform, register, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: state?.fields,
    resolver: yupResolver(schemaEdicaoShirt)
  });

  async function handleSubmit(data) {
    try {
      await api.patch(`/socios/${id}/shirt`, data);

      toast.success('Informações atualizadas com sucesso!');
      history.push(state?.redirect);
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao enviar suas informações.');
    }
  }

  return (
    <form className="d-flex flex-wrap color-black" onSubmit={handleSubmitform(handleSubmit)}>
      <div className="form-group col-12">
        <label htmlFor="tamanho-camiseta" className="control-label">Tamanho</label>
        <Select id="tamanho-camiseta" className="custom-select" name="camiseta" options={camisetaOptions} selected={state?.camiseta} label="tamanho" register={register} />
        <div className="retorno-invalido">{errors?.camiseta?.message}</div>
      </div>

      <div className="d-flex justify-content-between col-12">
        <button type="button" className="btn btn-light border" onClick={() => history.push(state?.redirect)}>Voltar</button>
        <button type="submit" className="btn btn-verde">Avançar</button>
      </div>
    </form>
  );
}

import React from 'react';
import { useForm } from 'react-hook-form';
import Cleave from 'cleave.js/react';
import { yupResolver } from '@hookform/resolvers';

import { useCadastro } from '../../../Contexts/CadastroContext';
import CustomInput from '../../CustomInput';
import CustomSelect from '../../CustomSelect';
import axios from 'axios';
import api from '../../../services/api';

import { schemaCadastroSecondStep } from '../../../validators/formCadastro';

import ufArray from '../../../utils/ufArray';
import fieldsToFill from '../../../utils/fieldsToFill';

export default function Endereco ({ navigateOnStep, currentStep }) {
    const { formData, updateFormData } = useCadastro();
    const [currentCep, setCurrentCep] = React.useState('');
    const { register, handleSubmit, errors, setValue } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        resolver: yupResolver(schemaCadastroSecondStep),
        defaultValues: {
            ...formData?.endereco
        }
    });

    async function changeCep(e) {
        if (/^\d{5}-\d{3}$/.test(e.target.value) && currentCep !== e.target.value) {
          try {
            setCurrentCep(e.target.value);
            const { data } = await axios.get(`https://viacep.com.br/ws/${e.target.value}/json/`);
    
            if (!data?.erro) {
              fieldsToFill.forEach((field) => {
                setValue(field.fieldForm, data[field.fieldResponse]);
              });
            }
          } catch (err) {
            //
          }
        }
    }

    async function onSubmit(data) {
         const planoData = formData.plano
         const planoD = await api.get(`planos/${planoData}`)
         const planoDependente = planoD.data.dependente

        const newData = {
            endereco: {
                ...data,
            }
        }

        updateFormData(newData);
        navigateOnStep(currentStep + (planoDependente ? 1 : 2));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form-steps-container">
            <h1 className="login-title">Precisamos que informe seu endereço</h1>
            <h2 className="login-subtitle">Santa Rosa Futsal</h2>
            <div className="row">
                    <div className="form-group mb-1 col-12 col-lg-4">
                        <label className="control-label login-label" htmlFor="cep">CEP</label>
                        <CustomInput
                            as={Cleave} 
                            options={{
                                delimiters: ['-'],
                                blocks: [5,3],
                                numericOnly: true
                            }} 
                            icon="location-arrow" 
                            iconColor="#969CB2" 
                            inputType="tel" 
                            name="cep"
                            className={`login-input ${errors?.cep ? 'socio-input-error' : ''}`} 
                            id="cep" 
                            placeholder="00000-000"
                            htmlRef={register}
                            onChange={changeCep}
                        />
                        <div className="retorno-invalido mh-19">{errors?.cep?.message}</div>
                    </div>
                    <div className="form-group mb-1 col-12 col-lg-4">
                        <label className="control-label login-label" htmlFor="cidade">Cidade</label>
                        <CustomInput 
                            icon="building"
                            iconColor="#969CB2" 
                            inputType="text" 
                            name="cidade" 
                            className={`login-input ${errors?.cidade ? 'socio-input-error' : ''}`}
                            id="cidade" 
                            placeholder="Ex.: Santa Rosa"
                            inputRef={register}
                        />
                        <div className="retorno-invalido mh-19">{errors?.cidade?.message}</div>
                    </div>
                    <div className="form-group mb-1 col-12 col-lg-4">
                        <label className="control-label login-label" htmlFor="uf">Estado</label>
                        <CustomSelect
                            icon="map-marked"
                            iconColor="#969CB2" 
                            inputType="text" 
                            name="uf" 
                            className={`login-select-input ${errors?.uf ? 'socio-input-error' : ''}`} 
                            id="uf"
                            options={ufArray}
                            inputRef={register}
                        />
                        <div className="retorno-invalido mh-19">{errors?.uf?.message}</div>
                    </div>
                    <div className="form-group mb-1 col-12 col-lg-6">
                        <label className="control-label login-label" htmlFor="logradouro">Logradouro</label>
                        <CustomInput
                            icon="road" 
                            iconColor="#969CB2" 
                            inputType="text" 
                            name="logradouro" 
                            className={`login-input ${errors?.logradouro ? 'socio-input-error' : ''}`}
                            id="logradouro"
                            placeholder="Ex.: Av. Rio Branco"
                            inputRef={register}
                        />
                        <div className="retorno-invalido mh-19">{errors?.logradouro?.message}</div>
                    </div>
                    <div className="form-group mb-1 col-12 col-lg-6">
                        <label className="control-label login-label" htmlFor="numero">Número</label>
                        <CustomInput
                            icon="sort-numeric-up-alt" 
                            iconColor="#969CB2" 
                            name="numero" 
                            className={`login-input ${errors?.numero ? 'socio-input-error' : ''}`} 
                            id="numero" 
                            placeholder="00000"
                            inputRef={register}
                        />
                        <div className="retorno-invalido mh-19">{errors?.numero?.message}</div>
                    </div>
                    <div className="form-group mb-1 col-12 col-lg-6">
                        <label className="control-label login-label" htmlFor="bairro">Bairro</label>
                        <CustomInput 
                            icon="map-marked" 
                            iconColor="#969CB2" 
                            inputType="text" 
                            name="bairro" 
                            className={`login-input ${errors?.bairro ? 'socio-input-error' : ''}`} 
                            id="bairro" 
                            placeholder="Ex.: Centro"
                            inputRef={register}
                        />
                        <div className="retorno-invalido mh-19">{errors?.bairro?.message}</div>
                    </div>
                    <div className="form-group mb-1 col-12 col-lg-6">
                        <label className="login-label" htmlFor="complemento">Complemento</label>
                        <CustomInput 
                            icon="info-circle" 
                            iconColor="#969CB2" 
                            inputType="text" 
                            name="complemento" 
                            className={`login-input ${errors?.complemento ? 'socio-input-error' : ''}`} 
                            id="complemento" 
                            placeholder="Ex.: Apto. 2"
                            inputRef={register}
                        />
                        <div className="retorno-invalido mh-19">{errors?.complemento?.message}</div>
                    </div>
            </div>
            <div className="row flex-wrap-reverse">
                        <div className="col-12 col-lg-6">
                            <button type="button" className="login-button btn-grey" id="voltar" onClick={() => navigateOnStep(currentStep - 1)}>Voltar</button>
                        </div>
                        <div className="col-12 col-lg-6">
                            <button type="submit" id="proximo" className="login-button">Próximo</button>
                        </div>
            </div>
        </form>
    ) 
}
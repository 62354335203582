import * as yup from 'yup';

import cpfTest from '../utils/cpfTest';

export const schemaEdicaoPersonal = yup.object().shape({
  nome: yup.string().matches(/[a-zA-Zà-úÀ-Ú][ ][a-zA-Zà-úÀ-Ú ]+$/, {
    excludeEmptyString: true,
    message: 'Por favor, insira o nome completo.'
  }).required('Você precisa inserir seu nome.'),
  email: yup.string().email('Insira um e-mail válido.').required('E-mail é obrigatório.'),
  telefone: yup.string().matches(/^\(\d{2}\)[ ]\d{5}-\d{4}$/, {
    excludeEmptyString: true,
    message: 'Formato de celular inválido'
  }).required('Você precisa inserir seu celular.'),
});

export const schemaEdicaoAddress = yup.object().shape({
  cep: yup.string().matches(/^\d{5}-\d{3}/, {
    excludeEmptyString: true,
    message: 'Formato de CEP inválido.'
  }).required('Você precisa inserir seu CEP.'),
  cidade: yup.string().required('Você precisa inserir sua cidade.'),
  uf: yup.string().matches(/^[a-zA-Z]{2}$/, {
    excludeEmptyString: true,
    message: 'O UF precisa ter 2 caracteres.'
  }).required('Você precisa inserir seu UF.'),
  logradouro: yup.string().required('Você precisa inserir a rua da casa.'),
  numero: yup.string().required('Você precisa inserir o número da casa.'),
  bairro: yup.string().required('Você precisa inserir seu bairro.'),
  complemento: yup.string(),
});

export const schemaEdicaoDependent = yup.object().shape({
  nome: yup.string().matches(/[a-zA-Zà-úÀ-Ú][ ][a-zA-Zà-úÀ-Ú ]+$/, {
    excludeEmptyString: true,
    message: 'Por favor, insira o nome completo.'
  }).required('Você precisa inserir o nome.'),
  cpf: yup.string().test('cpfTest', 'CPF inválido.', cpfTest).required('Você precisa inserir o CPF.'),
}, ['cpf', 'nome']);

const planosIconsColors = {
  OURO: '#EFB55A',
  PRATA: '#D1D1D1',
  BRONZE: '#D6873A',

  Ouro: '#EFB55A',
  Prata: '#D1D1D1',
  Bronze: '#D6873A',

  default: '#969CB2'
}

export default planosIconsColors;

import React from 'react'

import { Link } from 'react-router-dom'
import banner from '../assets/other/banner_seja_socio.jpg'

export default function MainBanner() {
  return (
    <div className="main-banner container-lg">

      <Link
        to="/files/regulamento_concurso.pdf"
        target="_blank"
      >
        <img
          className="img-fluid d-block"
          height="555"
          width="100%"
          src={banner}
          alt="Banner Seja Sócio"
          loading="lazy"
        />
      </Link>
    </div>

  )
}

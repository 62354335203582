import moment from 'moment';

export function testValidThru(date) {
  if (!date) {
    return true;
  }

  if (!date.match(/^\d{2}\/\d{2}$/)) {
    return false;
  }

  if (!moment(date, "MM/YY").isValid()) {
    return false;
  }

  return true;
}

export default testValidThru;

import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useAuth } from '../Contexts/AuthContext'
import LogoBranco from '../assets/LogoBranco.svg'

export default function HeaderSocioDesktop({ name = null, className = '' }) {
  const { logout, toggleContext, userRoles } = useAuth()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const listener = history.listen(() => {
      setOpen(false)
    })

    return () => {
      listener()
    }
  }, [history])

  React.useEffect(() => {
    if (open) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      document.body.classList.add('no-scroll')
      return
    }

    document.body.classList.remove('no-scroll')
  }, [open])

  function toggleHamburger() {
    setOpen(!open)
  }

  return (
    <header className={`socio-header ${className}`}>
      <div className="socio-container">
        <div>
          <NavLink to="/area-socio">
            <img
              src={LogoBranco}
              alt="Santa Rosa Futsal"
              width="46"
              height="51"
            />
          </NavLink>
          {name && (
            <h1>
              Olá <strong>{name}!</strong>
            </h1>
          )}
        </div>

        <button
          className={`hamburger hamburger--spring outline-0 z-index-999 hamburger-socio ${
            open ? 'hamburger-black is-active' : ''
          }`}
          type="button"
          onClick={toggleHamburger}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>

        <div
          className="menu-side socio-menu-side"
          style={{ right: open ? '0vw' : '150vw' }}
        >
          <div className="menu-side-wrapper">
            <nav className="menu-side-content">
              <NavLink
                className="nav-link nav-item-side color-black"
                to="/area-socio/meuscheckins"
              >
                Meus check-ins
              </NavLink>
              <NavLink
                className="nav-link nav-item-side color-black"
                to="/area-socio/ajustes"
              >
                Configurações
              </NavLink>
              {userRoles?.admin && (
                <button className="socio-admin" onClick={toggleContext}>
                  Área Admin
                </button>
              )}
              <button
                className="socio-sair"
                onClick={() => {
                  document.body.classList.remove('no-scroll')
                  return logout()
                }}
              >
                Sair
              </button>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

import React from 'react'
import api from '../../services/api'
import HeaderSocioDesktop from '../../components/HeaderSocioDesktop'
import InfiniteScroll from 'react-infinite-scroll-component'
import LabelStatus from '../../components/LabelStatusC'
import Loading from '../../components/Loading'
import ScrollToTopButton from '../../components/ScrollToTopButton'
import moment from 'moment'
import { toast } from 'react-toastify'

export default function MeusCheckins() {
  const [displayTopButton, setDisplayTopButton] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [socioInfo, setSocioInfo] = React.useState({})
  const [games, setGames] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(0)

  React.useEffect(() => {
    function scrollListener(e) {
      if (
        window.matchMedia('(max-width: 450px)').matches &&
        (document.body.scrollTop > 70 ||
          document.documentElement.scrollTop > 70)
      ) {
        return setDisplayTopButton(true)
      }
      return setDisplayTopButton(false)
    }
    document.addEventListener('scroll', scrollListener)
    return () => {
      document.removeEventListener('scroll', scrollListener)
    }
  }, [])

  const hasMore = React.useMemo(() => page <= pages, [page, pages])

  React.useEffect(() => {
    ;(async () => {
      try {
        setPage(1)
        setLoading(true)
        const response = await api.get('/checkins/todos?page=1')
        const data = response?.data?.list?.map((item) => ({
          ...item,
          dataJogo: moment(item?.jogo?.dataHora).format('DD/MM/YYYY'),
        }))

        setGames(data)
        setPages(response?.data?.pages || 1)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de sócios, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
        setPage((p) => p + 1)
      }
    })()
  }, [])

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get('/area-socio/dashboard')
        const data = {
          ...response?.data,
          firstName: response?.data?.nome?.split(' ')[0],
        }
        setSocioInfo(data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao buscar suas informações, provavelmente não foi culpa sua.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/checkins/todos?page=${page}`)
      const data = response?.data?.list?.map((item) => ({
        ...item,
        dataJogo: moment(item?.jogo?.dataHora).format('DD/MM/YYYY'),
      }))
      setGames([...games, ...data])
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Erro ao buscar a lista de sócios, por favor, recarregue a página.',
      )
    } finally {
      setLoading(false)
      setPage(page + 1)
    }
  }, [page, games])

  return (
    <>
      {displayTopButton && <ScrollToTopButton />}
      {loading && <Loading delayTime={200} />}
      <div className="socio-wrapper">
        <HeaderSocioDesktop name={socioInfo?.firstName || null} />
        <main>
          <section>
            <h1 className="socio-container socio-nome-mobile">
              Olá{' '}
              <strong>
                {socioInfo?.firstName && `${socioInfo?.firstName}!`}
              </strong>
            </h1>
          </section>

          <div className="socio-container">
            <section className="check-statL">
              <h2 className="pt-3">Meus check-ins</h2>
              <section>
                <ul>
                  <li>
                    <div className="check-grid font-weight-bold">
                      <span id="date">Data jogo</span>
                      <span id="jogo">Adversário</span>
                      <span id="user">Associado</span>
                      <span className="checkin">Check-in</span>
                      <span className="stat">Presença</span>
                    </div>
                  </li>
                  <div className="check-grid">
                    <InfiniteScroll
                      dataLength={games?.length}
                      next={loadMore}
                      hasMore={hasMore}
                      //   scrollableTarget="scrollableWrapper"
                    >
                      {games?.length > 0 ? (
                        games?.map((check, index) => (
                          <li key={index}>
                            <div className="check-grid">
                              <span id="date">{check?.dataJogo}</span>
                              <span
                                className="statt"
                                id="jogo"
                                title={`${
                                  check?.jogo
                                    ? `${check?.jogo?.timeAdversario?.nome} `
                                    : 'Jogo deletado'
                                }`}
                              >
                                {check?.jogo ? (
                                  <>{check?.jogo?.timeAdversario?.nome}</>
                                ) : (
                                  'Jogo deletado'
                                )}
                              </span>
                              <span id="user">
                                {check?.dependente ? 'Dependente' : 'Sócio'}
                              </span>
                              
                              <LabelStatus
                                id="checkin"
                                className="check-list-label-status"
                                compareceu={!check?.presenca_sem_checkin}
                              >
                                {check?.presenca_sem_checkin
                                  ? 'Não'
                                  : 'Sim'}
                              </LabelStatus>

                              <LabelStatus
                                className="check-list-label-status"
                                compareceu={check?.compareceu}
                              >
                                {check?.compareceu
                                  ? 'Compareceu'
                                  : 'Não compareceu'}
                              </LabelStatus>
                            </div>
                          </li>
                        ))
                      ) : (
                        <h5 className="text-center pt-3">
                          Não possui check-in
                        </h5>
                      )}
                    </InfiniteScroll>
                  </div>
                </ul>
              </section>
            </section>
          </div>
        </main>
      </div>
    </>
  )
}

import React from 'react'

import useAuthContext from './hooks/useAuthContext'

const AuthContext = React.createContext()

function AuthProvider({ children }) {
  const {
    loading,
    authenticated,
    handleLogin,
    logout,
    updateLoginData,
    userRoles,
    loginData,
    toggleContext,
    admin,
    getUser,
  } = useAuthContext()

  return (
    <AuthContext.Provider
      value={{
        loading,
        authenticated,
        handleLogin,
        logout,
        toggleContext,
        updateLoginData,
        loginData,
        userRoles,
        admin,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('[useAuth] Component must be within a provider.')
  }

  const {
    loading,
    authenticated,
    handleLogin,
    logout,
    toggleContext,
    updateLoginData,
    loginData,
    admin,
    getUser,
    userRoles,
  } = context

  return {
    loading,
    authenticated,
    handleLogin,
    logout,
    toggleContext,
    updateLoginData,
    loginData,
    admin,
    userRoles,
    getUser,
  }
}

export { AuthProvider, useAuth }

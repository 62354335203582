import { yupResolver } from '@hookform/resolvers'
import Cleave from 'cleave.js/react'
import moment from 'moment'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import CustomTable from '../../components/CustomTable'
import Loading from '../../components/Loading'
import api from '../../services/api'
import { formatPrice } from '../../utils/formatPrice'
import paymentOptions from '../../utils/paymentOptions'
import { schemaFiltroFinanceiro } from '../../validators/formFinanceiro'

export default function Financeiro() {
	const [page, setPage] = React.useState(1)
	const [pages, setPages] = React.useState(0)
	const [loading, setLoading] = React.useState(true)
	const [payments, setPayments] = React.useState([])
	const [filterInfo, setFilterInfo] = React.useState({
		initialDate: moment(new Date()).subtract(30, 'days').format('DD/MM/YYYY'),
		finalDate: moment(new Date()).format('DD/MM/YYYY')
	})

	const {
		handleSubmit: handleSubmitform,
		register,
		control,
		errors
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		shouldFocusError: false,
		defaultValues: filterInfo,
		resolver: yupResolver(schemaFiltroFinanceiro)
	})

	const mediaQueryList = React.useMemo(() => window.matchMedia('(min-width: 768px)'), [])
	const [matches, setMatches] = React.useState(mediaQueryList.matches)

	const hasMore = React.useMemo(() => page <= pages, [page, pages])

	React.useEffect(() => {
		const listener = event => {
			setMatches(event.matches)
		}

		mediaQueryList.addEventListener('change', listener)

		return () => {
			mediaQueryList.removeEventListener('change', listener)
		}
	}, [mediaQueryList])

	React.useEffect(() => {
		;(async () => {
			try {
				setPage(1)
				setLoading(true)

				const filterInfoAux = {
					initialDate: filterInfo.initialDate ? moment(filterInfo.initialDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
					finalDate: filterInfo.finalDate ? moment(filterInfo.finalDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
				}

				const response = await api.get('/socios/financeiro', { params: filterInfoAux })

				setPayments(preparePayments(response?.data?.list || []))
				setPages(response?.data?.pages || 1)
			} catch (err) {
				console.log(err)
				toast.error(err?.response?.data?.erro || 'Erro ao buscar a lista de pagamentos, por favor, recarregue a página.')
			} finally {
				setLoading(false)
				setPage(p => p + 1)
			}
		})()
	}, [filterInfo])

	const loadMore = React.useCallback(async () => {
		try {
			setLoading(true)

			const filterInfoAux = {
				initialDate: filterInfo.initialDate ? moment(filterInfo.initialDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
				finalDate: filterInfo.finalDate ? moment(filterInfo.finalDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
			}

			const response = await api.get('/socios/financeiro', { params: { page, ...filterInfoAux } })

			setPayments([...payments, ...preparePayments(response?.data?.list || [])])
		} catch (err) {
			console.log(err)
			toast.error(err?.response?.data?.erro || 'Erro ao buscar a lista de pagamentos, por favor, recarregue a página.')
		} finally {
			setLoading(false)
			setPage(page + 1)
		}
	}, [filterInfo, page, payments])

	async function handleSubmit(data) {
		setFilterInfo(data)
	}

	async function exportXLSX() {
		try {
			setLoading(true)

			const filterInfoAux = {
				initialDate: filterInfo.initialDate ? moment(filterInfo.initialDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
				finalDate: filterInfo.finalDate ? moment(filterInfo.finalDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
			}

			const response = await api.get('/socios/financeiro/downloadXLSX', { params: filterInfoAux })

			const bytes = new Uint8Array(response.data.data)
			const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = 'Relatório Financeiro.xlsx'

			link.click()
		} catch (err) {
			console.log(err)
			toast.error(err?.response?.data?.erro || 'Erro ao exportar os pagamentos, por favor, recarregue a página.')
		} finally {
			setLoading(false)
		}
	}

	function preparePayments(payments) {
		const data = []

		payments.forEach(payment => {
			payment.forma_pagamento = payment.forma_pagamento
				? paymentOptions.find(paymentOp => paymentOp.value === payment?.forma_pagamento).label
				: ''
			payment.data_pagamento = payment.data_pagamento ? moment(payment?.data_pagamento).format('DD/MM/YYYY') : ''
			payment.valor = payment.valor ? formatPrice(payment?.valor) : formatPrice(0)

			const items = []
			items.push({ value: payment.data_pagamento, class: 'text-center', styleCard: {} })
			items.push({ value: payment.socio, class: '', styleCard: { gridColumn: 'span 2' } })
			items.push({ value: payment.forma_pagamento, class: '', styleCard: { gridColumn: 'span 2' } })
			items.push({ value: payment.valor, class: 'text-right', styleCard: { textAlign: 'right' } })

			data.push(items)
		})

		return data
	}

	return (
		<>
			<AdminMenu />
			{loading && <Loading delayTime={200} />}
			<main className='wrapper bg-verm' id='scrollableWrapper'>
				<div className='container mt-3 p-0 pt-3 pb-4'>
					<div className='col-12 mb-4'>
						<div className='card border-0 p-4'>
							<form onSubmit={handleSubmitform(handleSubmit)}>
								<div className='row'>
									<div className='form-group col-12 col-md-4 mb-md-0'>
										<label htmlFor='initial-date' className='control-label'>
											Data Inicial
										</label>

										<Controller
											className='form-control'
											id='initial-date'
											name='initialDate'
											placeholder='dd/mm/aaaa'
											as={<Cleave ref={register} />}
											control={control}
											options={{
												date: true,
												datePattern: ['d', 'm', 'Y']
											}}
										/>
										<span className='retorno-invalido'>{errors?.initialDate?.message}</span>
									</div>

									<div className='form-group col-12 col-md-4 mb-md-0'>
										<label htmlFor='final-date' className='control-label'>
											Data Final
										</label>
										<Controller
											className='form-control'
											id='final-date'
											name='finalDate'
											placeholder='dd/mm/aaaa'
											as={<Cleave ref={register} />}
											control={control}
											options={{
												date: true,
												datePattern: ['d', 'm', 'Y']
											}}
										/>
										<span className='retorno-invalido'>{errors?.finalDate?.message}</span>
									</div>

									<div
										className='col-12 col-md-4 d-flex justify-content-end align-items-end'
										style={{
											marginBottom: matches && (errors?.initialDate?.message || errors?.finalDate?.message) ? '25px' : '0'
										}}
									>
										<button type='submit' className='btn btn-verde mr-3'>
											Buscar
										</button>
										<button onClick={exportXLSX} variant='contained' color='primary' className='btn btn-dour' type='button'>
											Exportar
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div className='col-12'>
						{payments?.length > 0 ? (
							<InfiniteScroll dataLength={payments?.length} next={loadMore} hasMore={hasMore} scrollableTarget='scrollableWrapper'>
								<CustomTable
									columns={[
										{ text: 'Data Pgto.', class: 'text-center', styleCard: {} },
										{ text: 'Sócio', class: '', styleCard: { gridColumn: 'span 2' } },
										{ text: 'Forma Pgto.', class: '', styleCard: { gridColumn: 'span 2' } },
										{ text: 'Valor', class: 'text-right', styleCard: { textAlign: 'right' } }
									]}
									data={payments}
								/>
							</InfiniteScroll>
						) : (
							<div className='empty-result-text'>Nenhum pagamento encontrado.</div>
						)}
					</div>
				</div>
			</main>
		</>
	)
}

import React from 'react'

import youtubeIcon from '../assets/png/youtube_colorful.png'

export default function YoutubeVid({ video }) {
  return (
    <div className="youtube-video">
      <div className="youtube-video__header">
        <a
          href="https://www.youtube.com/channel/UCPf4W4YnE-AYeXxTfHSUG8w?sub_confirmation=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={youtubeIcon}
            alt=""
            className="youtube-video__header__icon"
          />
        </a>
        <span className="youtube-video__header__identifier">
          /tvsantarosafutsal
        </span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCPf4W4YnE-AYeXxTfHSUG8w?sub_confirmation=1"
          className="youtube-video__header__button follow-button--youtube"
        >
          Inscreva-se
        </a>
      </div>

      <div className="youtube-video__video-wrapper">
        {video && (
          <iframe
            title="Último vídeo"
            id="ytplayer"
            type="text/html"
            width="640"
            height="360"
            src={`https://www.youtube.com/embed/${video?.videoId}`}
            frameBorder="0"
            allowFullScreen
          />
        )}
      </div>
    </div>
  )
}

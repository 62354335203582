import * as yup from 'yup';

export const schemaClassificacoes = yup.object().shape({
  clube: yup
    .string()
    .max(30, 'Máximo de 30 caracteres.')
    // .matches(/[a-zA-Zà-úÀ-Ú]+$/, 'AAA')
    .required(),
  pontos: yup.number().integer().max(99).min(0).required(),
  jogos: yup.number().integer().max(99).min(0).required(),
  vitorias: yup.number().integer().max(99).min(0).required(),
  empates: yup.number().integer().max(99).min(0).required(),
  derrotas: yup.number().integer().max(99).min(0).required(),
  golsPro: yup.number().integer().max(99).min(0).required(),
  golsContra: yup.number().integer().max(99).min(0).required(),
  saldoGols: yup.number().integer().max(99).min(-99).required(),
});

import * as yup from 'yup'

export const schemaPatrocinadores = yup.object().shape({
  nome: yup
    .string()
    .max(25, 'Máximo de 25 caracteres')
    .required('Campo é obrigatório.'),
  tipo: yup
    .string()
    .required('Campo é obrigatório.'),
  reservas: yup
    .number()
    .max(9999, 'Número máximo de 4 dígitos.')
    .required('Campo é obrigatório.')
    .min(0, 'Número igual ou maior que 0.')
    .integer('O número deve ser inteiro')
    .typeError('Campo é obrigatório.'),
})

import React from 'react'
import Icon from './Icon'

export default function CustomSelect({
  icon,
  iconColor,
  inputRef,
  id,
  className = '',
  iconSize = 16,
  name,
  options = [],
  onChange,
}) {
  return (
    <div className={'d-flex custom-select-input ' + className}>
      <label htmlFor={id}>
        {icon && (
          <Icon
            icon={icon}
            color={iconColor || '#A0A0A0'}
            size={iconSize}
          />
        )}
      </label>

      <select ref={inputRef} name={name} id={id} onChange={onChange}>
        <option hidden value="">
          Selecione
        </option>
        {options?.map((item, idx) => (
          <option key={idx} value={item?.value || item}>
            {item?.label || item}
          </option>
        ))}
      </select>
    </div>
  )
}

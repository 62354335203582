import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import api from '../../../services/api'

import { useAuth } from '../../../Contexts/AuthContext'

import envelopeEmail from '../../../assets/envelope-email.svg'

import Loading from '../../Loading'

export default function SendMail({ navigateOnStep }) {
  const { updateLoginData, loginData } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const { handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      cpf: '',
    },
  })

  const title = loginData?.pass
    ? 'Redefinir senha da conta'
    : 'Você ainda não tem senha'

  async function handleSubmitForm() {
    try {
      setLoading(true)
      const response = await api.patch('/login/enviar-codigo', {
        cpf: loginData?.cpf,
        recovery: loginData?.pass,
      })

      const { nextStep, email } = response?.data

      if (!nextStep) {
        toast.error('Ocorreu um erro de comunicação com o servidor.')
        return
      }

      setTimeout(() => {
        updateLoginData({ censoredEmail: email })
        navigateOnStep(nextStep)
      }, 50)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro de comunicação com o servidor.',
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <form
      className="d-flex flex-column"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      {loading && <Loading delayTime={200} />}
      <img
        className="login-email-icon"
        src={envelopeEmail}
        alt="Envelope e-email"
        width="99"
        height="66"
      />

      <h1 className="login-title">{title}</h1>
      <h2 className="login-subtitle subtitle-mail">
        Um código será enviado para seu e-mail
      </h2>

      <button type="submit" className="login-button">
        Enviar e-mail
      </button>
    </form>
  )
}

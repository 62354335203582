import React from 'react'
import { useForm } from 'react-hook-form'
import Cleave from 'cleave.js/react'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers'

import api from '../../../services/api'

import { useAuth } from '../../../Contexts/AuthContext'

import { schemaLoginCpf } from '../../../validators/formLogin'

import CustomInput from '../../CustomInput'
import Loading from '../../Loading'

export default function Cpf({ navigateOnStep }) {
  const { updateLoginData } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    shouldFocusError: false,
    reValidateMode: 'onBlur',
    resolver: yupResolver(schemaLoginCpf),
    defaultValues: {
      cpf: '',
    },
  })

  async function handleSubmitForm({ cpf }) {
    try {
      setLoading(true)
      const response = await api.post('/login/cpf', {
        cpf,
      })

      const { nextStep, ...rest } = response?.data

      setTimeout(() => {
        updateLoginData({ cpf, ...rest })

        if (!nextStep) {
          toast.error('Ocorreu um erro de comunicação com o servidor.')
          return
        }

        navigateOnStep(nextStep)
      }, 50)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro de comunicação com o servidor.',
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      {loading && <Loading delayTime={200} />}
      <h1 className="login-title">Bem-vindo de volta!</h1>
      <h2 className="login-subtitle">Santa Rosa Futsal</h2>

      <CustomInput
        id="cpf"
        name="cpf"
        as={Cleave}
        className={`login-input ${errors?.cpf ? 'socio-input-error' : ''}`}
        inputType="tel"
        placeholder="Seu CPF"
        icon="id-card"
        iconColor="#969CB2"
        htmlRef={register}
        options={{
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
          numericOnly: true,
        }}
      />

      <button type="submit" className="login-button">
        Continuar
      </button>
    </form>
  )
}

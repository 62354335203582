import React from 'react'
import { Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import InfiniteScroll from 'react-infinite-scroll-component'
import api from '../../services/api'
import nameCapitalize from '../../utils/nameCapitalize'
import CustomInput from '../../components/CustomInput'
import Loading from '../../components/Loading'
import AdminMenu from '../../components/AdminMenu'
import Icon from '../../components/Icon'
import { Modal } from 'react-bootstrap'

export default function Usuarios() {
  const history = useHistory()
  const { search } = useLocation()
  const { path } = useRouteMatch()

  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const [users, setUsers] = React.useState([])
  const searchRef = React.useRef()
  const [modal, setModal] = React.useState(false)
  const [itemToDelete, setItemToDelete] = React.useState('')
  const handleClose = () => setModal(false)
  const handleShow = () => setModal(true)

  const urlQuery = React.useMemo(() => {
    const queries = new URLSearchParams(search)

    const query = {}

    const busca = queries.get('busca') || ''

    if (busca) {
      query.busca = busca
    }

    return query
  }, [search])

  const hasMore = React.useMemo(() => page <= pages, [page, pages])

  React.useEffect(() => {
    ;(async () => {
      try {
        setPage(1)
        setLoading(true)
        const response = await api.get('/admins', {
          params: {
            search: urlQuery?.busca,
          },
        })

        setUsers(response?.data?.list || [])
        setPages(response?.data?.pages || 1)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de admin, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
        setPage((p) => p + 1)
      }
    })()
  }, [urlQuery])

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/admins?page=${page}`, {
        params: {
          search: urlQuery?.busca,
        },
      })

      setUsers([...users, ...response?.data?.list])
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Erro ao buscar a lista de sócios, por favor, recarregue a página.',
      )
    } finally {
      setLoading(false)
      setPage(page + 1)
    }
  }, [page, users, urlQuery])

  const handleSearch = React.useCallback(
    (e) => {
      e.preventDefault()
      const value = searchRef?.current?.value?.trim()

      const data = Object.assign({}, value ? { busca: value } : null)

      history.push({
        pathname: path,
        search: new URLSearchParams(data).toString(),
      })
    },
    [history, path],
  )

  async function removeItem() {
    try {
      await api.delete(`/admins/${itemToDelete}`)
      setUsers((oldState) => {
        return oldState.filter((item) => item?._id !== itemToDelete)
      })
      handleClose()
      toast.success('Admin deletado com sucesso!')
    } catch (err) {
      toast.error(
        err?.response?.data?.erro || 'Não é possível ter menos que dois admin.',
      )
      handleClose()
    }
  }

  async function handleDelete(id) {
    try {
      setItemToDelete(id)
      handleShow()
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Erro ao remover o Admin.')
    }
  }

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className="wrapper bg-verm">
        <div className="container mt-3 p-0 pt-3 pb-4">
          <div className="col-12 mb-4">
            <div className="card border-0 p-4">
              <Link
                to={`${path}/cadastrar`}
                className="text-decoration-none btn-adicionar"
              >
                <Icon icon="plus" color="#fff" />
              </Link>
              <form className="form-pesquisa" onSubmit={handleSearch}>
                <CustomInput
                  value={urlQuery?.busca}
                  id="input-pesquisa"
                  placeholder="Pesquisar por nome, email ou CPF"
                  icon="search"
                  sendIcon="paper-plane"
                  inputRef={searchRef}
                />
              </form>
            </div>
          </div>

          <div className="list-users">
            <div className="col-12">
              <div className="header-users pt-4 px-4 border-0 d-lg-flex d-none">
                <p>Nome</p>
                <p>Email</p>
              </div>
            </div>

            <InfiniteScroll
              dataLength={users?.length}
              next={loadMore}
              hasMore={hasMore}
              scrollableTarget="scrollableWrapper"
            >
              {users?.length > 0 ? (
                users?.map((user) => (
                  <div
                    className="col-12 position-relative item-user"
                    key={user?._id}
                  >
                    <div className="action">
                      <div className="d-flex align-items-center">
                        <div className="dropdown">
                          <button
                            className="border-0 btn pt-1 pb-1 custom-btn"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            id="dropdownMenuButton"
                          >
                            <Icon icon="ellipsis-v" color="#BDBDBD" />
                          </button>
                          <Modal
                            show={modal}
                            onHide={handleClose}
                            keyboard={false}
                            dialogClassName="modal-90w"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title className="modal-title">
                                deletar admin?
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="d-flex">
                              Tem certeza que deseja deletar este admin? Esta
                              ação não será reversível.
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="btn btn-verm"
                                type="button"
                                onClick={removeItem}
                              >
                                Confirmar
                              </button>
                            </Modal.Footer>
                          </Modal>
                          <div
                            className="dropdown-menu dropdown-menu-right z-index-99"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <Link
                              className="dropdown-item custom-dropdown color-black"
                              to={`${path}/${user?._id}/editar`}
                            >
                              <Icon icon="pen" color="#333" />
                              <span className="ml-3">Editar</span>
                            </Link>
                            <button
                              className="outline-0 dropdown-item custom-dropdown color-black"
                              onClick={() => handleDelete(user?._id)}
                            >
                              <Icon icon="times-circle" color="#de002c" />
                              <span className="ml-3">Deletar</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-user border-0 p-4 d-flex flex-column flex-lg-row">
                      <h2 className="font-weight-bold card-name name-user">
                        {nameCapitalize(user?.nome)}
                      </h2>
                      <div className="d-flex align-items-center mr-1 email-user">
                        <p className="card-email">{user?.email}</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12 position-relative item-user">
                  <div className="br-sm card-user border-0 p-4 d-flex flex-column flex-lg-row"></div>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </main>
    </>
  )
}

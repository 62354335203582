import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import Loading from '../../components/Loading'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import CompeticoesCard from '../../components/CompeticoesCard'
import AdminMenu from '../../components/AdminMenu'
import api from '../../services/api'
import Icon from '../../components/Icon'

export default function Competicoes() {
  const { path } = useRouteMatch()
  const [loading, setLoading] = React.useState(true)
  const [modal, setModal] = React.useState(false)
  const [itemToDelete, setItemToDelete] = React.useState('')
  const [competicaoList, setCompeticaoList] = React.useState([])

  async function removeItem() {
    try{
      await api.delete(`/competicoes/${itemToDelete}`)
      setCompeticaoList((oldState) => {
        return oldState.filter((item) => item?._id !== itemToDelete)
      })
      handleClose()
      toast.success('Competição deletada com sucesso!')
  } catch {
      toast.error('Competição está vinculada a um jogo')
      handleClose()
    }
  }

  const handleClose = () => setModal(false)
  const handleShow = () => setModal(true)

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get('/competicoes', {})

        setCompeticaoList(response?.data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de competições, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className="wrapper bg-verm" id="scrollableWrapper">
        <div className="container mt-3 p-0 pt-3 pb-4 position-relative">
          <Link to={`${path}/cadastrar/`} className="text-decoration-none btn-adicionar mt-3 mr-3">
            <Icon icon="plus" color="#fff" />
          </Link>
          <Modal
            show={modal}
            onHide={handleClose}
            keyboard={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">Cancelar competição?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
              Tem certeza que deseja cancelar esta competição? Esta ação não
              será reversível.
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-verm"
                type="button"
                onClick={removeItem}
              >
                Confirmar
              </button>
            </Modal.Footer>
          </Modal>

          <div className="d-flex flex-wrap row-gap-30">
            {competicaoList.length > 0 ? (
              competicaoList?.map((competicao) => (
                <CompeticoesCard
                  handleShow={handleShow}
                  setItemToDelete={setItemToDelete}
                  competicao={competicao}
                  key={competicao._id}
                />
              )) || <div></div>
            ) : (
              <div className="jogos_">Não há competições cadastradas.</div>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

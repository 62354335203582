import React from 'react';
import { Link } from 'react-router-dom';
import MainNav from '../components/MainNav';

import ImgBola from '../assets/png/Bola.png';

export default function QuatrocentosEQuatro() {
  return (
    <>
      <MainNav/>
      
      <main className="container-lg pagina-404 position-relative">
        
        <div>
          <p className="pagina-404__secundario">404 Página não encontrada</p>
          <h1 className="m-0 pagina-404__primario">BOLA FORA!</h1>
          <p className="pagina-404__info">Sentimos muito pelo inconveniente,<br/>
            <Link to="/">clique aqui</Link> para voltar.
          </p>
        </div>

        <img src={ImgBola} width="300" alt="Bola" className="position-absolute" />
      </main>
    </>
  );
}

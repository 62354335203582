import * as yup from 'yup';

export const schemaJogos = yup.object().shape({
  dataHora: yup
    .date()
    .typeError('Precisa ter data e hora validas.')
    .required('Campo é obrigatório.'),
  local: yup
    .string()
    .max(50, 'Máximo de 50 caracteres')
    .required('Campo é obrigatório.'),
  nome: yup
    .string()
    .max(30, 'Máximo de 30 caracteres')
    .required('Campo é obrigatório.'),

  emCasa: yup.bool().required('Campo é obrigatório.'),
  vagas: yup.number().when('emCasa', (emCasa, schema) => {
    if (emCasa)
      return schema
        .max(9999, 'Número máximo de 4 dígitos.')
        .required('Campo é obrigatório.')
        .min(0, 'Número igual ou maior que 0.')
        .integer('O valor deve ser inteiro.')
        .typeError('Campo é obrigatório.')
        .nullable();
    else return schema;
  }),
  dataHoraIn: yup.date().when('emCasa', (emCasa, schema) => {
    if (emCasa)
      return schema
        .typeError('Precisa ter data e hora validas.')
        .required('Campo é obrigatório.')
        .nullable();
    else return schema;
  }),
  dataHoraTe: yup.date().when('emCasa', (emCasa, schema) => {
    if (emCasa)
      return schema
        .typeError('Precisa ter data e hora validas.')
        .required('Campo é obrigatório.')
        .nullable();
    else return schema;
  }),

  amistoso: yup.bool().required('Campo é obrigatório.'),
  liga: yup.string().when('amistoso', (amistoso, schema) => {
    if (!amistoso) return schema.required('Campo é obrigatório').nullable();
    else return schema;
  }),
  fase: yup.string().when('amistoso', (amistoso, schema) => {
    if (!amistoso) return schema.required('Campo é obrigatório').nullable();
    else return schema;
  }),
});

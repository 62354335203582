import React from 'react'
import { Link , useHistory} from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers'
import Cleave from 'cleave.js/react'

import api from '../../services/api'

import { schemaEdicaoPersonal } from '../../validators/formEdicaoSocio'

import HeaderSocioDesktop from '../../components/HeaderSocioDesktop'
import CustomInput from '../../components/CustomInput'
import Loading from '../../components/Loading'

export default function AlterarDadosPessoais() {
  const [loading, setLoading] = React.useState(true)
  const history = useHistory()
  const [pessoalData, setPessoalData] = React.useState({})
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaEdicaoPersonal),
    shouldFocusError: false,
    reValidateMode: 'onBlur',
    defaultValues: pessoalData,
  })

  React.useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get('/area-socio/dados/pessoal')

        setPessoalData(response?.data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro interno, provavelmente não foi culpa sua.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  async function handleSubmitForm(data) {
    try {
      setLoading(true)
      const response = await api.patch('/area-socio/dados/pessoal', {
        ...data,
        nome: data?.nome?.trim(),
      })

      setPessoalData({ ...pessoalData, ...response?.data })

      toast.success('Dados atualizados com sucesso!')
      history.push('/area-socio/ajustes')
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro interno, provavelmente não foi culpa sua.',
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <div className="socio-wrapper-secondary">
        <HeaderSocioDesktop className="socio-header-secondary" />

        <main>
          <div className="socio-container">
            <section className="alterar-dados-form">
              <h1>Alterar dados pessoais</h1>

              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <label className="label-input" htmlFor="nome">
                  Nome completo
                  <CustomInput
                    id="nome"
                    name="nome"
                    className="login-input"
                    icon="signature"
                    iconColor="#969CB2"
                    inputRef={register}
                    value={pessoalData?.nome}
                  />
                  <span className="retorno-invalido">
                    {errors?.nome?.message}
                  </span>
                </label>

                <label className="label-input" htmlFor="cpf">
                  CPF
                  <CustomInput
                    readOnly
                    id="cpf"
                    name="cpf"
                    inputType="tel"
                    className="login-input read-only"
                    icon="id-card"
                    iconColor="#969CB2"
                    value={pessoalData?.cpf}
                  />
                </label>

                <label className="label-input" htmlFor="email">
                  E-mail
                  <CustomInput
                    id="email"
                    name="email"
                    inputType="email"
                    className="login-input"
                    icon="envelope"
                    iconColor="#969CB2"
                    inputRef={register}
                    value={pessoalData?.email}
                  />
                  <span className="retorno-invalido">
                    {errors?.email?.message}
                  </span>
                </label>

                <label className="label-input" htmlFor="telefone">
                  Celular
                  <CustomInput
                    id="telefone"
                    as={Cleave}
                    name="telefone"
                    inputType="tel"
                    className="login-input"
                    icon="mobile-alt"
                    iconColor="#969CB2"
                    htmlRef={register}
                    value={pessoalData?.telefone}
                    options={{
                      blocks: [0, 2, 5, 4],
                      delimiters: ['(', ') ', '-'],
                      numericOnly: true,
                    }}
                  />
                  <span className="retorno-invalido">
                    {errors?.telefone?.message}
                  </span>
                </label>

                <div className="buttons-group">
                  <Link to="/area-socio/ajustes" className="button-cancel">
                    Cancelar
                  </Link>
                  <button type="submit" className="button-yellow">
                    Salvar
                  </button>
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    </>
  )
}

import React from 'react';

import vampeta from '../assets/other/vampeta.jpg';

export default function Vampeta() {
  return (
      <main className="container-lg pt-8 d-flex justify-content-center">
        <img src={vampeta} alt="Vampeta" />
      </main>
  );
}

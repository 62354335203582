import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useAuth } from '../../../Contexts/AuthContext'

import CustomRadio from '../../CustomRadio'

const admin = {
  admin: true,
  socio: false,
}

export default function Method({ navigateOnStep }) {
  const [selected, setSelected] = React.useState('socio')
  const { updateLoginData } = useAuth()
  const { register, handleSubmit } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      senha: '',
    },
  })

  async function handleSubmitForm({ method }) {
    try {
      const isAdmin = admin[method]

      setTimeout(() => {
        updateLoginData({ admin: isAdmin })

        navigateOnStep(2)
      }, 50)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro de comunicação com o servidor.',
      )
    }
  }

  function handleSelect(e) {
    setSelected(e?.target?.value)
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <h1 className="login-title">Como você quer entrar?</h1>
      <h2 className="login-subtitle">
        Sua conta tem vários níveis de privilégio
      </h2>

      <div className="radios">
        <CustomRadio
          id="socio"
          name="method"
          value="socio"
          onChange={handleSelect}
          selected={selected}
          inputRef={register}
          defaultChecked
        >
          Sócio
        </CustomRadio>
        <CustomRadio
          id="admin"
          name="method"
          value="admin"
          onChange={handleSelect}
          selected={selected}
          inputRef={register}
        >
          Admin
        </CustomRadio>
      </div>

      <button type="submit" className="login-button">
        Continuar
      </button>
    </form>
  )
}

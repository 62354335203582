import React from 'react'

export default function LoadingSpinnerTable({ className, small = false }) {
  // const [delay, setDelay] = React.useState(false);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setDelay(true);
  //   }, 200);
  // });

  // if (!delay) {
  //   return null;
  // }

  return (
    <div className={className}>
      <div
        className={`spinner-border ${small && 'spinner-border-sm mr-2'}`}
        role="status"
      ></div>
    </div>
  )
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthProvider } from '../Contexts/AuthContext';
import { CadastroProvider } from '../Contexts/CadastroContext';


import CustomRoute from './CustomRoute';

import AdminRoutes from './AdminRoutes';
import SocioRoutes from './SocioRoutes';
import ClubeRoutes from './ClubeRoutes';
import FutebolRoutes from './FutebolRoutes';
import Login from '../views/Login';
import Cadastro from '../views/Cadastro';
// import Concluido from '../components/forms/cadastro/Concluido';
import Planos from '../views/Planos';
import Home from '../views/Home';
import Patrocinadores from '../views/Patrocinadores';
// import Contato from '../views/Contato';
import QuatrocentosEQuatro from '../views/404';
import Vampeta from '../views/Vampeta';
import Noticias from '../views/Noticias';
import Noticia from '../views/Noticia';

const Routes = () => (
  <AuthProvider>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/patrocinadores" component={() => <Patrocinadores name="patrocinadores" />} />
      <Route exact path="/noticias" component={Noticias} />
      <Route exact path="/noticias/:id" component={Noticia} />
      {/* <Route exact path="/contato" component={() => <Contato name="contato" />} /> */}
      <Route path="/clube" component={ClubeRoutes} />
      <Route path="/futebol" component={FutebolRoutes} />
      <CustomRoute exact path="/planos" component={() => <Planos name="planos"/>} />
      <CustomRoute exact path="/login" component={Login} />
      {/* <CustomRoute exact path="/concluido" component={Concluido} /> */}
      <CustomRoute path="/area-socio" component={SocioRoutes} isPrivate />
      <CustomRoute path="/area-admin" component={AdminRoutes} isAdmin isPrivate />
      <Route exact path="/vampeta/vampet/vampe/vamp/vam/va/v" component={Vampeta} />
      <CustomRoute exact path="/cadastro" component={() => (
        <CadastroProvider>
          <Cadastro/>
        </CadastroProvider>
      )} />
      <Route path="*" component={QuatrocentosEQuatro} />
    </Switch>
  </AuthProvider>
);

export default Routes;
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import api from '../../services/api';

import LoadingSpinner from '../LoadingSpinner';
import Icon from '../Icon';

export default function Additional() {
  const history = useHistory();
  const { id } = useParams();
  const [additionalInfo, setAdditionalInfo] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [ dependente, setDependente ] = React.useState();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/socios/${id}`, {
          params: {
            info: 'additional',
          }
        });

          const plano = response.data.plano;
          const responseDependent = await api.get(`/planos/${plano}`);
          const dependenteTF = responseDependent.data.dependente;
          setDependente(dependenteTF)
          
        const data = {
          ...response?.data,
          carteirinhaDateFormatted: response?.data?.emissao_carteirinha ? moment(response?.data?.emissao_carteirinha).format('DD/MM/YYYY') : 'NÃO EMITIDA',
        }

        if (response?.data?.dependente) {
          data.dependente = {
            ...response?.data?.dependente,
            carteirinhaDateFormatted: response?.data?.dependente?.emissao_carteirinha ? moment(response?.data?.dependente?.emissao_carteirinha).format('DD/MM/YYYY') : 'NÃO EMITIDA'
          }
        }
        setAdditionalInfo(data);
      } catch (err) {
        toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao buscar os dados do usuário.');
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  function handleEdit({ path, state }) {
    history.push(`/area-admin/socios/${id}/${path}/editar`, state);
  }

  if (loading) {
    return <LoadingSpinner className="d-flex justify-content-center" />
  }

  return (
    <>
      <div className="col-12 color-black">
        <div className="row">
          <div className="col-10">
            <h3 className="font-weight-bold">Carteirinha</h3>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-start mb-2">
            <Icon
              className="cursor-pointer"
              icon="edit"
              style={{ fontSize: 24 }}
              onClick={() => handleEdit({
                path: 'carteirinha',
                state: {
                  fields: {
                    emissao_carteirinha: additionalInfo?.carteirinhaDateFormatted,
                    carteirinha_rfid: additionalInfo?.carteirinha_rfid,
                  },
                  redirect: `/area-admin/socios/${id}/adicional`
                }
              })}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-between color-black">
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">RFID</small>
          <span className="d-block">{additionalInfo?.carteirinha_rfid || 'NÃO EMITIDA'}</span>
        </div>
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Data de emissão</small>
          <span className="d-block">{additionalInfo?.carteirinhaDateFormatted}</span>
        </div>
      </div>
{/* 
      {additionalInfo?.camiseta && (
        <>
          <div className="col-12 mt-4 color-black">
            <div className="row">
              <div className="col-10">
                <h3 className="font-weight-bold">Camiseta</h3>
              </div>
              <div className="col-2 d-flex justify-content-end align-items-start mb-2">
                <Icon
                  className="cursor-pointer"
                  icon="edit"
                  style={{ fontSize: 24 }}
                  onClick={() => handleEdit({
                    path: 'camiseta',
                    state: {
                      fields: {
                        camiseta: additionalInfo?.camiseta
                      },
                      redirect: `/area-admin/socios/${id}/adicional`
                    }
                  })}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between color-black">
            <div className="col-12 col-sm-6 mt-2">
              <small className="font-weight-bold fs-14 d-block">Tamanho</small>
              <span className="d-block">{additionalInfo?.camiseta || 'NÃO POSSUI'}</span>
            </div>
          </div>
        </>
      )} */}

      {dependente && (
        <>
          <div className="col-12 mt-4 color-black">
            <div className="row">
              <div className="col-10">
                <h3 className="font-weight-bold">Dependente</h3>
              </div>
              <div className="col-2 d-flex justify-content-end align-items-start mb-2">
                <Icon
                  className="cursor-pointer"
                  icon="edit"
                  style={{ fontSize: 24 }}
                  onClick={() => handleEdit({
                    path: 'dependente',
                    state: {
                      fields: {
                        nome: additionalInfo?.dependente?.nome,
                        cpf: additionalInfo?.dependente?.cpf,
                        carteirinha_rfid: additionalInfo?.dependente?.carteirinha_rfid,
                        emissao_carteirinha: additionalInfo?.dependente?.carteirinhaDateFormatted,
                      },
                      redirect: `/area-admin/socios/${id}/adicional`
                    }
                  })}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between color-black">
            <div className="col-12 col-sm-6 mt-2">
              <small className="font-weight-bold fs-14 d-block">Nome</small>
              <span className="d-block">{additionalInfo?.dependente?.nome || 'NÃO CADASTRADO'}</span>
            </div>
            <div className="col-12 col-sm-6 mt-2">
              <small className="font-weight-bold fs-14 d-block">CPF</small>
              <span className="d-block">{additionalInfo?.dependente?.cpf || 'NÃO CADASTRADO'}</span>
            </div>

            <div className="col-12 col-sm-6 mt-2">
              <small className="font-weight-bold fs-14 d-block">RFID</small>
              <span className="d-block">{additionalInfo?.dependente?.carteirinha_rfid || 'NÃO EMITIDA'}</span>
            </div>
            <div className="col-12 col-sm-6 mt-2">
              <small className="font-weight-bold fs-14 d-block">Data de emissão</small>
              <span className="d-block">{additionalInfo?.dependente?.carteirinhaDateFormatted || 'NÃO EMITIDA'}</span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import * as yup from 'yup'
import dateTest from '../utils/dateTest'
import moment from 'moment'

export const schemaFiltroFinanceiro = yup.object().shape({
	initialDate: yup
		.string()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.test('date-test', 'A data é inválida.', dateTest)
		.test('period-valid', 'Periodo inválido', function periodValid(value) {
			const { finalDate } = this.parent

			if (finalDate && value) {
				const initialDateM = moment(value, 'DD/MM/YYYY')
				const finalDateM = moment(finalDate, 'DD/MM/YYYY')

				if (initialDateM.isAfter(finalDateM)) {
					return false
				}

				return true
			}

			return true
		})
		.required('Data Inicial é obrigatória.')
		.nullable(),
	finalDate: yup
		.string()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.test('date-test', 'A data é inválida.', dateTest)
		.test('period-valid', 'Periodo inválido', function periodValid(value) {
			const { initialDate } = this.parent

			if (initialDate && value) {
				const finalDateM = moment(value, 'DD/MM/YYYY')
				const initialDateM = moment(initialDate, 'DD/MM/YYYY')

				if (initialDateM.isAfter(finalDateM)) {
					return false
				}

				return true
			}

			return true
		})
		.required('Data Final é obrigatória.')
		.nullable()
})

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Cleave from 'cleave.js/react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers';

import api from '../../services/api';

import { schemaEdicaoAddress } from '../../validators/formEdicao';

import fieldsToFill from '../../utils/fieldsToFill';

export default function Endereco({ state }) {
  const { id } = useParams();
  const [currentCep, setCurrentCep] = React.useState('');
  const history = useHistory();
  const { handleSubmit: handleSubmitform, register, errors, control, setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: state?.fields,
    resolver: yupResolver(schemaEdicaoAddress)
  });

  async function handleSubmit(data) {
    try {
      await api.patch(`/socios/${id}/address`, data);

      toast.success('Informações atualizadas com sucesso!');
      history.push(state?.redirect);
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao enviar suas informações.')
    }
  }

  async function changeCep(e) {
    if (/^\d{5}-\d{3}$/.test(e.target.value) && currentCep !== e.target.value) {
      try {
        setCurrentCep(e.target.value);

        const { data } = await axios.get(`https://viacep.com.br/ws/${e.target.value}/json/`);

        if (!data?.erro) {
          fieldsToFill.forEach((field) => {
            setValue(field.fieldForm, data[field.fieldResponse]);
          });

          setValue('numero', '');
        }
      } catch (err) {
        //
      }
    }
  }

  return (
    <form className="d-flex flex-wrap color-black" onSubmit={handleSubmitform(handleSubmit)}>
      <div className="form-group col-12 col-md-6">
        <label htmlFor="cep" className="control-label">CEP</label>
        <Controller
          name="cep"
          id="cep"
          className="form-control"
          as={<Cleave ref={register} />}
          control={control}
          options={{
            uppercase: true,
            blocks: [5, 3],
            delimiter: '-',
            numericOnly: true,
          }}
          placeholder="00000-000"
          onKeyUp={changeCep}
        />
        <span className="retorno-invalido">{errors?.cep?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="cidade" className="control-label">Cidade</label>
        <input id="cidade" type="text" className="form-control" name="cidade" placeholder="Nome da cidade" ref={register} />
        <span className="retorno-invalido">{errors?.cidade?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="logradouro" className="control-label">Logradouro</label>
        <input id="logradouro" type="text" className="form-control" name="logradouro" placeholder="Av. Rio Branco" ref={register} />
        <span className="retorno-invalido">{errors?.logradouro?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="numero" className="control-label">Número</label>
        <input id="numero" type="text" className="form-control" name="numero" placeholder="Número da casa" ref={register} />
        <span className="retorno-invalido">{errors?.numero?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="bairro" className="control-label">Bairro</label>
        <input id="bairro" type="text" className="form-control" name="bairro" placeholder="Bairro onde mora" ref={register} />
        <span className="retorno-invalido">{errors?.bairro?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="uf" className="control-label">UF</label>
        <Controller
          id="uf"
          name="uf"
          className="form-control"
          as={<Cleave ref={register} options={{ numericOnly: false }} />}
          control={control}
          options={{
            blocks: [2],
            uppercase: true,
          }}
          placeholder="Sigla do estado"
        />
        <span className="retorno-invalido">{errors?.uf?.message}</span>
      </div>

      <div className="form-group col-12 col-md-6">
        <label htmlFor="complemento">Complemento</label>
        <input id="complemento" type="text" className="form-control" name="complemento" ref={register} />
        <span className="retorno-invalido">{errors?.complemento?.message}</span>
      </div>


      <div className="d-flex justify-content-between col-12">
        <button type="button" className="btn btn-light border" onClick={() => history.push(state?.redirect)}>Voltar</button>
        <button type="submit" className="btn btn-verde">Avançar</button>
      </div>
    </form>
  );
}

import React from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Modal } from 'react-bootstrap';

import api from '../../services/api';

import Loading from '../../components/Loading';
import Icon from '../../components/Icon';
import AdminMenu from '../../components/AdminMenu';

export default function Dispositivos() {
  const { search } = useLocation();
  const { path } = useRouteMatch();

  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [dispositivos, setDispositivos] = React.useState([]);
  const [idToDelete, setIdToDelete] = React.useState('');
  const [modal, setModal] = React.useState(false);
  const handleClose = () => setModal(false);
  const handleShow = () => setModal(true);

  const urlQuery = React.useMemo(() => {
    const queries = new URLSearchParams(search);
    const query = {};
    const busca = queries.get('busca') || '';

    if (busca) {
      query.busca = busca;
    }

    return query;
  }, [search]);

  const hasMore = React.useMemo(() => page <= pages, [page, pages]);

  async function removeItem() {
    try {
      await api.delete(`/dispositivos/${idToDelete}`);
      setDispositivos((oldDisp) =>
        oldDisp.filter((item) => item._id !== idToDelete)
      );
      handleClose();
      toast.success('Dispositivo deletado com sucesso!');
    } catch (error) {
      handleClose();
      toast.error(error?.response?.data?.erro || 'Erro');
    }
  }

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get('/dispositivos?page=1', {
          params: {
            search: urlQuery?.busca,
          },
        });

        setDispositivos(response?.data?.list || []);
        setPages(response?.data?.pages || 1);
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de dispositivos, por favor, recarregue a página.'
        );
      } finally {
        setLoading(false);
        setPage((p) => p + 1);
      }
    })();
  }, [urlQuery]);

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/dispositivos?page=${page}`, {
        params: {
          search: urlQuery?.busca,
        },
      });

      setDispositivos([...dispositivos, ...response?.data?.list]);
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Erro ao buscar a lista de dispositivos, por favor, recarregue a página.'
      );
    } finally {
      setLoading(false);
      setPage(page + 1);
    }
  }, [page, dispositivos, urlQuery]);

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className='wrapper bg-verm'>
        <div className='container mt-3 p-0 pt-3 pb-4'>
          <div className='col-12  p-4'>
            <Link to={`${path}/cadastrar`} className='text-decoration-none btn-adicionar mr-3'>
              <Icon icon='plus' color='#fff' />
            </Link>
          </div>

          <div className='list-users'>
            <div className='col-12'>
              <div className='header-users pt-4 px-4 border-0 d-lg-flex d-none'>
                <p>Descrição</p>
                <p>MAC</p>
                <p>Status</p>
              </div>
            </div>

            <InfiniteScroll
              dataLength={dispositivos?.length}
              next={loadMore}
              hasMore={hasMore}
              scrollableTarget='scrollableWrapper'
            >
              {dispositivos?.length > 0 ? (
                dispositivos?.map((dispositivo) => (
                  <div
                    className='col-12 position-relative item-user'
                    key={dispositivo?._id}
                  >
                    <div className='card-user border-0 p-4 d-flex flex-column flex-lg-row'>
                      <h2 className='card-name dispo-id'>
                        {dispositivo.descricao}
                      </h2>
                      <h2 className='align-items-center dispo-mac'>
                        {dispositivo.dispMac}
                      </h2>
                      <div className='d-flex align-items-center email-user ativo-dispositivos'>
                        <div className='status-active-ativo'>
                          {dispositivo.ativo ? (
                            <div className='status-ativo margin-bottom-2 d-flex align-items-center'>
                              <div className='d-inline-block circle-status status-color-ativo'></div>
                              <span style={{ fontWeight: 500 }}>Ativo</span>
                            </div>
                          ) : (
                            <div className='status-inativo margin-bottom-2 d-flex align-items-center'>
                              <div className='d-inline-block circle-status status-color-inativo'></div>
                              <span style={{ fontWeight: 500 }}>Inativo</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='action'>
                        <div className='d-flex align-items-center'>
                          <div className='dropdown'>
                            <button
                              className='border-0 btn pt-1 pb-1 custom-btn'
                              data-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                              id='dropdownMenuButton'
                            >
                              <Icon icon='ellipsis-v' color='#BDBDBD' />
                            </button>
                            <Modal
                              show={modal}
                              onHide={handleClose}
                              keyboard={false}
                              dialogClassName='modal-90w'
                            >
                              <Modal.Header closeButton>
                                <Modal.Title className='modal-title'>
                                  Deletar dispositivo?
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className='d-flex'>
                                Tem certeza que deseja deletar este dispositivo?
                                Esta ação não será reversível.
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className='btn btn-verm'
                                  type='button'
                                  onClick={removeItem}
                                >
                                  Confirmar
                                </button>
                              </Modal.Footer>
                            </Modal>
                            <div
                              className='dropdown-menu dropdown-menu-right z-index-99'
                              aria-labelledby='dropdownMenuButton'
                            >
                              <Link
                                className='dropdown-item custom-dropdown color-black'
                                to={`${path}/${dispositivo?._id}/editar`}
                              >
                                <Icon icon='pen' color='#333' />
                                <span className='ml-3'>Editar</span>
                              </Link>
                              <button
                                className='outline-0 dropdown-item custom-dropdown color-black'
                                onClick={() => {
                                  try {
                                    setIdToDelete(dispositivo?._id);
                                    handleShow();
                                  } catch (error) {
                                    toast.error(
                                      'Erro ao deletar o dispositivo'
                                    );
                                  }
                                }}
                              >
                                <Icon icon='times-circle' color='#de002c' />
                                <span className='ml-3'>Deletar</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='col-12 position-relative item-user'>
                  <div className='item-user br-sm card-user border-0 p-4 d-flex flex-column flex-lg-row align-items-center'>
                    <p>Nenhum dispositivo encontrado!</p>
                  </div>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </main>
    </>
  );
}

import React from 'react'


import MainFooter from '../../components/MainFooter'
import MainNav from '../../components/MainNav'
import Breadcrumb from '../../components/Breadcrumb'

import brandLogo from '../../assets/svg/logo_fundo_claro.svg'

export default function MidiaKit({ name }) {
  return (
    <div className="min-h-100 d-flex flex-column">
      <MainNav />

      <div className="container-lg flex-fill">
        <Breadcrumb pageName={name} />
        <main className="d-flex pt-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <h1 className="fs-24 mt-0 mb-6 fw-bold text-yellow">
                Este é o nosso mídia kit
              </h1>
              <p className="fs-14 fw-light mb-6">
                Neste arquivo contém todas as informações básicas necessárias
                para manter a unidade da identidade visual do Santa Rosa Futsal.
                Em caso de dúvida, entre em contato com o nosso departamento de
                marketing.
              </p>
              <a
                href="https://drive.google.com/drive/folders/1E0ApQoQd1u_BarUdPZh2S7A2E58NFvv8?usp=sharing"
                target="_blank"
                className="button--xlarge"
                rel="noopener noreferrer"
              >
                BAIXAR AGORA
              </a>
            </div>

            <div className="col-md-6 d-none d-md-flex justify-content-end">
              <img
                className="h-100"
                src={brandLogo}
                alt="Logo Santa Rosa Futsal"
              />
            </div>
          </div>
        </main>
      </div>

      <div className="container-lg pb-5">
        <MainFooter />
      </div>
    </div>
  )
}

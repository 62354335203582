import React from 'react';
import axios from '../../../services/api';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import InputMask from 'react-input-mask';
import Cleave from 'cleave.js/react';

import { useCadastro } from '../../../Contexts/CadastroContext';

import { schemaCadastroSecondStep } from '../../../validators/formCadastroAdmin';

export default function Passo2Cadastro({ navigateOnStep, currentStep }) {
  const { formData, updateFormData } = useCadastro()
  const [ dependente, setDependente ] = React.useState();
  const { register, handleSubmit, control, errors, setError } = useForm({
    context: { isOuro: formData?.plano === 'OURO' },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schemaCadastroSecondStep),
    defaultValues: {
      ...formData?.dependente,
      camiseta: formData?.camiseta,
      emissao_carteirinha: formData?.emissao_carteirinha,
      carteirinha_rfid: formData?.carteirinha_rfid
    }
  });

  function onSubmitForm({
    emissao_carteirinha = '',
    carteirinha_rfid = '',
    camiseta = '',
    nome = '',
    cpf = ''
  }) {
    const data = {
      emissao_carteirinha,
      carteirinha_rfid,
      camiseta,
      dependente: {
        nome,
        cpf,
      }
    }
    if(data.dependente.cpf === formData.cpf) {
      setError('cpf', {message: 'Este CPF já está sendo utilizado pelo sócio.', shouldFocus: true})
      return
    }
    updateFormData(data);
    setTimeout(() => {
      navigateOnStep(currentStep + 1);
    }, 50);
  }

  React.useEffect(() => {
    (async () => {
      try {
        const plano = formData?.plano;

        const response = await axios.get(`/planos/${plano}`);
        const dependenteTF = response.data.dependente;
        setDependente(dependenteTF)
      } catch (error) {
        console.log(error)
      } finally {
      }
    })()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-4 content-step p-4 border-0 card color-black">
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <h5 className="font-weight-bold mb-3">Carteirinha</h5>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="data-carteirinha">Data de emissão</label>
            <Controller
              className="form-control"
              id="data-carteirinha"
              name="emissao_carteirinha"
              as={<Cleave ref={register} />}
              control={control}
              placeholder="dd/mm/aaaa"
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
              }}
            />
            <div className="retorno-invalido">{errors?.emissao_carteirinha?.message}</div>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="rfid">RFID</label>
            <input id="rfid" type="text" name="carteirinha_rfid" ref={register} className="form-control" />
            <div className="retorno-invalido">{errors?.carteirinha_rfid?.message}</div>
          </div>
        </div>

        {dependente && (
          <>
            {/* <h5 className="font-weight-bold mb-3 mt-3">Camiseta</h5>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="tamanho-camiseta" className="control-label">Tamanho</label>
                <Select id="tamanho-camiseta" className="custom-select" name="camiseta" options={camisetaOptions} selected={formData?.camiseta} label="tamanho" register={register} />
                <div className="retorno-invalido">{errors?.camiseta?.message}</div>
              </div>
            </div> */}

            <h5 className="font-weight-bold mb-3 mt-3">Dependente</h5>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="nome">Nome</label>
                <input
                  id="nome"
                  name="nome"
                  type="text"
                  className="form-control"
                  placeholder="Nome do dependente"
                  ref={register}
                />
                <div className="retorno-invalido">{errors?.nome?.message}</div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="cpf">CPF</label>
                <Controller
                  id="cpf"
                  name="cpf"
                  className="form-control"
                  as={InputMask}
                  control={control}
                  inputRef={register}
                  maskChar={null}
                  mask="999.999.999-99"
                  placeholder="000.000.000-00"
                />
                <div className="retorno-invalido">{errors?.cpf?.message}</div>
              </div>
            </div>
          </>
        )}

        <div className="d-flex justify-content-between">
          <button type="button" className="btn btn-light border" onClick={() => navigateOnStep(currentStep - 1)}>Voltar</button>
          <button type="submit" className="btn btn-verde">Avançar</button>
        </div>
      </form>
    </div>
  );
}

import React from 'react'

const CadastroContext = React.createContext()

const initialData = {
  plano: '',
  amigo: '',
  nome: '',
  cpf: '',
  dt_nasc: '',
  email: '',
  telefone: '',
  endereco: {
    cep: '',
    cidade: '',
    uf: '',
    logradouro: '',
    numero: '',
    bairro: '',
    complemento: '',
  },
  emissao_carteirinha: '',
  carteirinha_rfid: '',
  dependente: {
    nome: '',
    cpf: '',
  },
  camiseta: '',
  pagamento: {
    forma_pagamento: '',
    pagoem: '',
    pagoate: '',
    valor: '',
    cc: {
      numero: '',
      nome: '',
      validade: '',
      cvc: '',
    },
  },
}

function CadastroProvider({ children }) {
  const [formData, setFormData] = React.useState(initialData)
  const [dependente, setDependente] = React.useState(false)
  function updateFormData(data) {
    setFormData({ ...formData, ...data })
  }
  function updateDependente(dependete) {
    setDependente(dependete)
  }

  return (
    <CadastroContext.Provider value={{ updateFormData, formData, dependente, updateDependente }}>
      {children}
    </CadastroContext.Provider>
  )
}

function useCadastro() {
  const context = React.useContext(CadastroContext)

  if (!context) {
    throw new Error(
      '[CadastroContext]: Component must be within the Context Provider',
    )
  }

  const { updateFormData, formData, updateDependente, dependente } = context

  return { updateFormData, formData, updateDependente, dependente }
}

export { CadastroProvider, useCadastro }

import React from 'react';

import api from '../services/api';

import MainNav from '../components/MainNav';
// import HeroCarousel from '../components/HeroCarousel';
import NextGame from '../components/NextGame';
import LatestNews from '../components/LatestNews';
import SmallBanner from '../components/SmallBanner';
import LargeBanner from '../components/LargeBanner';
import YoutubeVid from '../components/YoutubeVid';
import InstagramFeed from '../components/InstagramFeed';
import FacebookFeed from '../components/FacebookFeed';
// import SponsorsCarousel from '../components/SponsorsCarousel';
import MainFooter from '../components/MainFooter';
import MainBanner from '../components/MainBanner';

export default function Home() {
  const [posts, setPosts] = React.useState([]);
  const [news, setNews] = React.useState([]);

  React.useEffect(() => {
    api.get('/social-media').then(response => {
      setPosts(response.data);
    }).catch(err => console.log(err));
    api.get('/noticias', {
      params: {
        perPage: 3,
      }
    }).then(response => {
      setNews(response?.data?.list);
    }).catch(err => console.log(err));
  }, []);

  return (
    <>

      <MainNav />

      <main className="pb-5">
        {/* <HeroCarousel /> */}

        <MainBanner />

        <div className="container-lg">
          <div className="row">
            <div className="col-md-6 d-flex flex-column">
              <NextGame />

              <SmallBanner />
            </div>

            <LatestNews news={news} />
          </div>

          <LargeBanner />

          <section className="row social-media-section">
            <div className="col-12 col-lg-6 mb-4 mb-lg-0">
              <YoutubeVid video={posts?.youtube} />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 mb-sm-0">
              <FacebookFeed posts={posts?.facebook} />
            </div>

            <div className="col-12 col-sm-6 col-lg-3">
              <InstagramFeed posts={posts?.instagram} />
            </div>
          </section>

          {/* <SponsorsCarousel /> */}
          <MainFooter />
        </div>
      </main>
    </>
  );
}

import moment from 'moment'
import React from 'react'
// import LogoFundoClaro from '../assets/svg/logo_fundo_claro.svg';
import api from '../services/api'

export default function NextGame() {
  const [nextGame, setNextGame] = React.useState(null)

  React.useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get('/proximo-jogo')

        const data = {
          ...response?.data,
          dataHoraFormatted: moment(response.data?.dataHora).format(
            'DD/MM/YYYY [às] HH:mm',
          ),
        }

        setNextGame(data)
      } catch (err) {
        // toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao buscar os dados do último jogo.');
      }
    })()
  }, [])

  return (
    <section className="next-game">
      <h1 className="next-game__title">Próximo jogo</h1>
      <div className="next-game__info">
        <div className="next-game__info__versus">
          <img
            className="next-game__info__versus__team-logo"
            height={70}
            width={70}
            src={
              nextGame?.emCasa
                ? nextGame?.timeProprio?.img?.url
                : nextGame?.timeAdversario?.img?.url
            }
            alt={
              nextGame?.emCasa
                ? nextGame?.timeProprio?.nome
                : nextGame?.timeAdversario?.nome
            }
            loading="lazy"
          />
          <span className="next-game__info__versus__icon">X</span>
          <img
            className="next-game__info__versus__team-logo"
            height={70}
            width={70}
            src={
              nextGame?.emCasa
                ? nextGame?.timeAdversario?.img?.url
                : nextGame?.timeProprio?.img?.url
            }
            alt={
              nextGame?.emCasa
                ? nextGame?.timeAdversario?.nome
                : nextGame?.timeProprio?.nome
            }
            loading="lazy"
          />
        </div>

        <div className="next-game__info__details">
          {/* <span className="next-game__info__details__league">
            Liga Gaúcha 2
          </span> */}

          <span className="next-game__info__details__teams">
            {nextGame
              ? nextGame?.emCasa
                ? `${nextGame?.timeProprio?.nome} X ${nextGame?.timeAdversario?.nome}`
                : `${nextGame?.timeAdversario?.nome} X ${nextGame?.timeProprio?.nome}`
              : 'Nenhum jogo previsto'}
          </span>

          <span className="next-game__info__details__additional">
            {nextGame?.dataHoraFormatted}
          </span>

          <span className="next-game__info__details__additional">
            {nextGame?.local}
          </span>
        </div>

        {/* <button className="button--outline--large next-game__checkin">Fazer Check-In</button>
          <button className="button--outline--large next-game__buy-ticket">Comprar Ingresso</button> */}
      </div>
    </section>
  )
}

// <section className="news col-md-6">
//   <h1 className="news__title">Últimas notícias</h1>
// </section>

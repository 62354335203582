import * as yup from 'yup'

export const schemaCompeticoes = yup.object().shape({
  liga: yup
    .string()
    .max(50, 'Máximo de 50 caracteres')
    .required('Campo é obrigatório.'),
  fases: yup
    .array()
    .of(yup.mixed())
    .required('Campo é obrigatório.')
})

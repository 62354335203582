import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../Contexts/AuthContext';

export default function CustomRoute({ isAdmin, isPrivate, component, path, exact }) {
  const { authenticated, loading, admin } = useAuth();

  if (loading) {
    return null;
  }

  if (!authenticated && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (authenticated && !isPrivate) {
    if (admin && !isAdmin) {
      return <Redirect to="/area-admin" />;
    }

    if (!admin) {
      return <Redirect to="/area-socio" />;
    }
  }

  if (authenticated && isPrivate) {
    if (admin && !isAdmin) {
      return <Redirect to="/area-admin" />;
    }

    if (!admin && isAdmin) {
      return <Redirect to="/area-socio" />;
    }
  }

  return <Route exact={exact} path={path} component={component} />
}

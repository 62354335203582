import React from 'react'

export default function Select({
  id,
  name,
  register,
  label,
  options = [],
  //selected,
  ...rest
}) {
  return (
    <select name={name} id={id} ref={register} {...rest}>
      <option className="d-none" value="">
        Selecione um {label}
      </option>
      {options.map((value) => (
        <option
          key={value?.value || value}
          value={value?.value || value}
          disabled={value?.disable}
          //selected={selected === value?.value || selected === value}
        >
          {value?.label || value}
        </option>
      ))}
    </select>
  )
}

import moment from 'moment';

export function testDate(date) {
  if (!date) {
    return true;
  }

  if (!date.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    return false;
  }

  if (!moment(date, "DD/MM/YYYY").isValid()) {
    return false;
  }

  return true;
}

export default testDate;

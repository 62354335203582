import React from 'react'
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import LogoBrand from '../assets/svg/logo_fundo_claro.svg'
import LogoGH from '../assets/other/gh_logo_alta.jpg'
import TwitterIcon from '../assets/svg/twitter_fundo_branco.svg'
import FacebookIcon from '../assets/svg/facebook_fundo_branco.svg'
import InstagramIcon from '../assets/svg/instagram_fundo_branco.svg'
import YoutubeIcon from '../assets/svg/youtube_fundo_branco.svg'
import WhatsappIcon from '../assets/svg/whatsapp_fundo_branco.svg'

import { whatsappNumber } from '../utils/numberWhatsapp'

export default function MainFooter() {
  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <>
      <hr className="main-footer__separating-line" />
      <footer className="main-footer d-flex flex-md-row">
        <nav className="main-footer__nav">
          <Link id="brand-link" to="/">
            <img
              id="brand"
              src={LogoBrand}
              width="75"
              alt="Logo Santa Rosa Futsal"
              className="main-footer__nav__brand"
              onClick={toggleHome}
            />
          </Link>
          <div id="links3" className="main-footer__nav__links">
            {/* <Link
              to="/patrocinadores"
              className="main-footer__nav__links__link"
            >
              Patrocinadores
            </Link> */}
            <Link to="/login" className="main-footer__nav__links__link">
              Sócio
            </Link>
            <a
              href="https://www.loja.santarosafutsal.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              className="main-footer__nav__links__link"
            >
              Loja
            </a>
            {/* <Link to="/contato" className="main-footer__nav__links__link">Contato</Link> */}
          </div>
          <div id="links2" className="main-footer__nav__links">
            <div className="main-footer__nav__links__category">
              <span className="main-footer__nav__links__category__label">
                Clube
              </span>
              <Link
                to="/clube/diretoria"
                className="main-footer__nav__links__category__link"
              >
                Diretoria
              </Link>
              <Link
                to="/clube/historia"
                className="main-footer__nav__links__category__link"
              >
                História
              </Link>
              <Link
                to="/clube/midia_kit"
                className="main-footer__nav__links__category__link"
              >
                Mídia Kit
              </Link>
              {/* <Link
                to="/clube/ProjetosDeCaptacao"
                className="main-footer__nav__links__category__link"
              >
                Projetos de Captação
              </Link> */}
            </div>
          </div>
          <div id="links1" className="main-footer__nav__links">
            <div className="main-footer__nav__links__category">
              <span className="main-footer__nav__links__category__label">
                Futebol
              </span>
              <Link
                to="/futebol/competicoes"
                className="main-footer__nav__links__category__link"
              >
                Competições
              </Link>
              <Link
                to="/futebol/elencos"
                className="main-footer__nav__links__category__link"
              >
                Elenco
              </Link>
              <Link
                to="/futebol/jogos"
                className="main-footer__nav__links__category__link"
              >
                Jogos
              </Link>
              <Link
                to="/futebol/tabela-classificacao"
                className="main-footer__nav__links__category__link"
              >
                Classificação
              </Link>
            </div>
          </div>

          <div id="social" className="main-footer__nav__social-join">
            <span className="main-footer__nav__social-join__text">
              Siga o Santa Rosa
            </span>
            <div className="main-footer__nav__social-join__icons">
              <a
                href="https://www.facebook.com/futsalsantarosa/"
                className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={FacebookIcon}
                  alt="Facebook"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
              <a
                href="https://twitter.com/santarosafutsal"
                className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={TwitterIcon}
                  alt="Twitter"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.instagram.com/santarosa.futsal/"
                className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={InstagramIcon}
                  alt="Facebook"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCPf4W4YnE-AYeXxTfHSUG8w"
                className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={YoutubeIcon}
                  alt="Youtube"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
              <a
                href={`https://wa.me/${whatsappNumber}`}
                className="main-footer__nav__social-join__icons__icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={WhatsappIcon}
                  alt="Whatsapp"
                  width="24"
                  height="24"
                  loading="lazy"
                />
              </a>
            </div>
            <Link to="/planos" className="button--xlarge">
              SEJA SÓCIO
            </Link>
          </div>
        </nav>
        <div className="logo-nuvoni mt-5 mt-md-0">
          <a
            href="https://agenciagh.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img title="GH Branding" width={100} src={LogoGH} alt="Logo Nuvoni" />
          </a>
        </div>
      </footer>
    </>
  )
}

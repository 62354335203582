import React from 'react';
import MainNav from '../components/MainNav';
import Breadcrumb from '../components/Breadcrumb';
import MainFooter from '../components/MainFooter';
import { whatsappNumber } from '../utils/numberWhatsapp';
import Icon from '../components/Icon';
import Loading from '../components/Loading';
import { toast } from 'react-toastify';
import api from '../services/api';
import PlanoCatalagoCard from '../components/PlanoCatalagoCard';

export default function Planos({ name }) {

    const [modal, setModal] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [planoList, setPlanoList] = React.useState([])

      const handleShow = () => setModal(true)

    React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true) 
        
        const response = await api.get('/planos')

        const planos = response.data.filter(function(v) {
            return v.public !== false
        })

        setPlanoList(planos)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de planos, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
      }
      })()
    }, [])

    return (
        <>
            <div className="min-h-100">
                
                <MainNav />
                {loading && <Loading delayTime={200} />}
                <div className="container-lg">
                    <Breadcrumb pageName={name} />
                    <main className="pt-8">
                        <div className="col-12">
                            <h2 className="golden-title">O Santa Rosa Futsal precisa de você!</h2>
                            <p className="under-golden-title">Associe-se e faça o Santa Rosa Futsal mais forte.</p>
                        </div>
                        <div>
                        {planoList.length > 0 ? (
                            <div className="row mt-5 justify-content-center">
                                {planoList.length > 0 ? (
                                    planoList?.sort(function(a, b) {
                                        if(a.valor > b.valor){
                                            return 1;}
                                        if (a.valor < b.valor){
                                            return -1;
                                        } 
                                        return 0
                                    }).map((plano) => {      
                                        return (plano._id)  ?
                                        <PlanoCatalagoCard
                                            show={modal}
                                            handleShow={handleShow}
                                            plano={plano}
                                            key={plano.id + plano._id}
                                        /> 
                                    : <></>}) || <div className="col-12 col-md-6 col-lg-4 col-sm-6 order-1 order-lg-0"></div>
                                ) : (
                                    <div className="jogos_">Não há planos cadastrados.</div>
                                )}
                                <div className="col-12 col-md-6 col-lg-4 col-sm-6 mt-4 mt-lg-0 order-2 padCards">
                                    <div className="planos-card">
                                        <div className="title-planos-card">
                                            <h2 className="plano-type plano-type-anual">Demais Planos</h2>
                                        </div>
                                        <div className="content-planos-card content-planos-anual">
                                            <ul className="list-unstyled lista-56-mb">
                                                <li>
                                                    <Icon icon="check" className="mr-2" />
                                                    Pagamento por pix 12 x R$40,00</li>
                                                <li>
                                                    <Icon icon="check" className="mr-2" />
                                                    Pagamento por pix à vista R$350,00</li>
                                                <li>
                                                    <Icon icon="check" className="mr-2" />
                                                    Entre em contato para mais informações</li>
                                            </ul>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://wa.me/${whatsappNumber}?text=Olá! Gostaria de mais informações sobre o Plano de Sócios Anual.`} className="button-planos button--large w-100 d-flex justify-content-center align-items-center fs-16 mt-auto">
                                                Entre em contato <Icon className="fs-20 ml-2" icon="angle-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ) : (
                            <div className="col-12 col-md-6 col-lg-4 col-sm-6 mt-4 mt-lg-0 order-2 padCards">
                                <div className="planos-card">
                                    <div className="title-planos-card">
                                        <h2 className="plano-type plano-type-anual">Anual</h2>
                                    </div>
                                    <div className="content-planos-card content-planos-anual">
                                        <ul className="list-unstyled lista-56-mb">
                                            <li>
                                                <Icon icon="check" className="mr-2" />
                                                Entre em contato para mais informações</li>
                                        </ul>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`https://wa.me/${whatsappNumber}?text=Olá! Gostaria de mais informações sobre o Plano de Sócios Anual.`} className="button-planos button--large w-100 d-flex justify-content-center align-items-center fs-16 mt-auto">
                                            Entre em contato <Icon className="fs-20 ml-2" icon="angle-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>)
                        } </div>
                    </main>
                    <div className="pb-5 mt-5">
                        <MainFooter />
                    </div>
                </div>
            </div>
        </>
    )
}
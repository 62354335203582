import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useCadastro } from '../../../Contexts/CadastroContext';
import { usePlanos } from '../../../Contexts/PlanosContext';

import api from '../../../services/api';

import Select from '../../Select';
import LoadingSpinner from '../../LoadingSpinner';

import { schemaCadastroThirdStep } from '../../../validators/formCadastroAdmin';

import paymentOptions from '../../../utils/paymentOptions';

export default function Passo2Cadastro({ navigateOnStep, currentStep }) {
  const history = useHistory();
  const { formData, updateFormData } = useCadastro();
  const { getPlanoValor } = usePlanos();
  const [loading, setLoading] = React.useState(false);
  const [currentDates, setCurrentDates] = React.useState({});
  const [payment, setPayment] = React.useState(formData?.pagamento?.forma_pagamento || '');
  const { register, handleSubmit, control, errors, getValues, setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schemaCadastroThirdStep),
    context: { forma_pagamento: payment },
    defaultValues: {
      ...formData?.pagamento,
      ...formData?.pagamento?.cc,
    }
  });


  const onSubmitForm = React.useCallback(async ({
    forma_pagamento = '',
    numero = '',
    nome = '',
    validade = '',
    cvc = '',
    pagoate = '',
    pagoem = '',
    valor = ''
  }) => {
    try {
      setLoading(true);

      const data = {
        pagamento: {
          forma_pagamento,
          pagoem,
          pagoate,
          valor,
          cc: {
            numero,
            nome,
            validade,
            cvc,
          }
        }
      }

      updateFormData(data);

      const socio = {
        ...formData,
        emissao_carteirinha: moment(formData?.emissao_carteirinha, 'DD-MM-YYYY').toISOString(),
        pagamento: {
          ...data?.pagamento,
          pagoem: moment(data?.pagamento?.pagoem, 'DD-MM-YYYY').toISOString(),
          pagoate: moment(data?.pagamento?.pagoate, 'DD-MM-YYYY').toISOString(),
          valor: String(data?.pagamento?.valor).replace(/([a-zA-Z][$][\s])/, ''),
        }
      }

      const response = await api.post('/socios', socio);

      if (!response?.data?.aprovado) {
        toast.error(`Pagamento não aprovado`);
      } else {
        setTimeout(() => {
          history.push('/area-admin/socios');
          toast.success('Sócio cadastrado com sucesso!');
        }, 50);
      }
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao cadastrar, tente novamente.')
    } finally {
      setLoading(false);
    }
  }, [formData, updateFormData, history]);

  const changePayment = React.useCallback((e) => {
    setPayment(e.target.value);
  }, []);

  const changeDate = React.useCallback(async (e) => {
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(e.target.value) && e.target.value !== currentDates[e.target.name]) {
      try {
        setCurrentDates({ ...currentDates, [e.target.name]: e.target.value });


        const { pagoem, pagoate } = getValues(['pagoem', 'pagoate']);

        if (/^\d{2}\/\d{2}\/\d{4}$/.test(pagoem) && /^\d{2}\/\d{2}\/\d{4}$/.test(pagoate)) {
          const { valor, valor_cart } = await getPlanoValor(formData?.plano);

          const interval = moment(pagoate, 'DD/MM/YYYY').diff(moment(pagoem, 'DD/MM/YYYY'), 'months', true);

          if (interval * valor < 0) {
            setValue('valor', '');
            return;
          }

          const value = valor_cart + interval * valor;

          const [intValue, cents = '00'] = String(value).split('.');

          const intCents = cents?.length >= 2 ? cents?.substring(0, 2) : `${cents?.substring(0, 1)}0`;

          setValue('valor', `${intValue},${intCents}`);
        }
      } catch (err) {
        toast.error(err?.message);
      }
    }
  }, [currentDates, formData, getPlanoValor, getValues, setValue]);


  return (
    <div className="mt-4 p-4 border-0 card content-step color-black">
      <form onSubmit={handleSubmit(onSubmitForm)}>

        <div className="form-group">
          <label htmlFor="forma-pagamento" className="control-label">Forma de pagamento</label>
          <Select
            id="forma-pagamento"
            className="custom-select"
            name="forma_pagamento"
            options={paymentOptions}
            selected={formData?.forma_pagamento}
            label="tipo de pagamento"
            register={register}
            onChange={changePayment}
          />
          <div className="retorno-invalido">{errors?.forma_pagamento?.message}</div>
        </div>

        {payment === 'cc' && (
          <>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="numero" className="control-label">Número do cartão</label>
                <Controller
                  className="form-control"
                  id="numero"
                  name="numero"
                  as={<Cleave ref={register} />}
                  control={control}
                  options={{
                    creditCard: true,
                  }}
                />
                <div className="retorno-invalido">{errors?.numero?.message}</div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="nome-titular" className="control-label">Nome impresso no cartão</label>
                <input
                  id="nome-titular"
                  name="nome"
                  type="text"
                  className="form-control"
                  placeholder="Nome do titular"
                  ref={register}
                />
                <div className="retorno-invalido">{errors?.nome?.message}</div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="validade" className="control-label">Data de validade do cartão</label>
                <Controller
                  className="form-control"
                  id="validade"
                  name="validade"
                  as={<Cleave ref={register} />}
                  control={control}
                  placeholder="mm/aa"
                  options={{
                    date: true,
                    datePattern: ['m', 'y'],
                  }}
                />
                <div className="retorno-invalido">{errors?.validade?.message}</div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="cvc" className="control-label">Código de segurança</label>
                <Controller
                  className="form-control"
                  id="cvc"
                  name="cvc"
                  as={<Cleave ref={register} />}
                  control={control}
                  placeholder="000"
                  options={{
                    numericOnly: true,
                    blocks: [3],
                  }}
                />
                <div className="retorno-invalido">{errors?.cvc?.message}</div>
              </div>
            </div>
          </>
        )}

        {payment !== 'cc' && payment !== '' && (
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="pagoem" className="control-label">Pago em</label>
              <Controller
                className="form-control"
                id="pagoem"
                name="pagoem"
                as={<Cleave ref={register} />}
                control={control}
                placeholder="dd/mm/aaaa"
                options={{
                  date: true,
                  datePattern: ['d', 'm', 'Y'],
                  dateMax: moment(new Date()).parseZone().format('YYYY-MM-DD')
                }}
                onKeyUp={changeDate}
              />
              <div className="retorno-invalido">{errors?.pagoem?.message}</div>
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="pagoate" className="control-label">Pago até</label>
              <Controller
                className="form-control"
                id="pagoate"
                name="pagoate"
                as={<Cleave ref={register} />}
                control={control}
                placeholder="dd/mm/aaaa"
                options={{
                  date: true,
                  datePattern: ['d', 'm', 'Y'],
                }}
                onKeyUp={changeDate}
              />
              <div className="retorno-invalido">{errors?.pagoate?.message}</div>
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="valor" className="control-label">Valor pago</label>
              <Controller
                id="valor"
                as={<Cleave ref={register} />}
                name="valor"
                control={control}
                className="form-control"
                options={{ numeral: true, numeralDecimalMark: ',', delimiter: '.', prefix: 'R$ ', numeralPositiveOnly: true }}
              />
              <div className="retorno-invalido">{errors?.valor?.message}</div>
            </div>
          </div>
        )}

        <p className="warning mb-2">{`Valor do plano ${payment !== 'cc' ? 'x quantidade de meses' : ''} + valor da carteirinha ${formData?.plano === 'OURO' ? 'x 2' : ''}`}</p>

        <div className="d-flex justify-content-between">
          <button type="button" className="btn btn-light border" onClick={() => navigateOnStep(currentStep - 1)}>Voltar</button>
          <button type="submit" disabled={loading} className="btn btn-verde d-flex align-items-center">{loading && <LoadingSpinner small={true} />} Cadastrar</button>
        </div>
      </form>
    </div>
  );
}

import * as yup from 'yup'

export const schemaElencos = yup.object().shape({
  nome: yup
    .string()
    .max(25, 'Máximo de 25 caracteres')
    .required('Campo é obrigatório.')
    .matches(/[abcdefghijklmnopqrstuvwxyz]+/, 'Não está no formato correto'),
  posicao: yup
    .string()
    .max(18, 'Máximo de 18 caracteres')
    .required('Campo é obrigatório.')
    .matches(/[abcdefghijklmnopqrstuvwxyz]+/, 'Não está no formato correto'),
  numero: yup
    .number()
    .max(9999, 'Número máximo de 4 dígitos.')
    .required('Campo é obrigatório.')
    .min(0, 'Número igual ou maior que 0.')
    .integer()
    .typeError('Campo é obrigatório.'),
  instagram: yup
    .string()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .matches(
      /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/igm,
      'Insira uma url correta!'
   )
   .nullable()
})

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Dashboard from '../views/Socio/Dashboard';
import MeusCheckins from '../views/Socio/MeusCheckins';
import Ajustes from '../views/Socio/Ajustes';
import AlterarCartao from '../views/Socio/AlterarCartao';
import AlterarDadosPessoais from '../views/Socio/AlterarDadosPessoais';
import AlterarDependente from '../views/Socio/AlterarDependente';
import AlterarEndereco from '../views/Socio/AlterarEndereco';
import QuatrocentosEQuatro from '../views/404';

export default function Socio() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      <Route exact path={`${path}/meusCheckins`} component={MeusCheckins} />
      <Route exact path={`${path}/ajustes`} component={Ajustes} />
      <Route exact path={`${path}/ajustes/cartao`} component={AlterarCartao} />
      <Route exact path={`${path}/ajustes/pessoal`} component={AlterarDadosPessoais} />
      <Route exact path={`${path}/ajustes/dependente`} component={AlterarDependente} />
      <Route exact path={`${path}/ajustes/endereco`} component={AlterarEndereco} />
      <Route path={`${path}*`} component={QuatrocentosEQuatro} />
    </Switch>
  );
}

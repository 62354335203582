import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import api from '../../services/api'
import { schemaPlanos } from '../../validators/formPlanos'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import Loading from '../../components/Loading'
import Icon from '../../components/Icon'
import Cleave from 'cleave.js/react'

export default function PlanosCadastro() {
  const { id } = useParams()
  const [, setPlanoList] = React.useState([])
  const history = useHistory()
  const [form, setForm] = React.useState()

  const [identifiers, setIdentifiers] = React.useState({
    function: 'create',
    buttonText: 'Cadastrar',
    successMessage: 'Cadastro realizado com sucesso!',
  })

  const {
    errors,
    handleSubmit: handleSubmitForm,
    register,
    control,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schemaPlanos),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: async () => form, 
    valor: 0,
    valor_cart: 0
  })

  //-------------------------------Lista de Beneficios-------------------------------
  function handleDeleteBeneficio(index) {
    var deleteBeneficio = form?.beneficios;
    deleteBeneficio.splice(index, 1);
    setForm({
      ...form,
      beneficios: deleteBeneficio

    })
  }
  const handleAddBeneficio = () => {

    if (form?.beneficios.length < 5 && getValues('adicionarBeneficios') && getValues('adicionarBeneficios') !== "") {
      setForm({
        ...form,
        beneficios: [...form?.beneficios, {
          value: getValues('adicionarBeneficios'),
          label: getValues('adicionarBeneficios'),
        }]
      });
    }
  };
  //--------------------------------------------------------------------------------

  const [loading, setLoading] = React.useState(false)
  const [disable, setDisable] = React.useState(false)

  useEffect(() => {
    const { id } = getValues()
    reset({
      ...form,
      id_: id || form?.id_,
    })
  }, [form, reset, getValues])

  useEffect(() => {
    ; (async () => {
      if (id) {
        setIdentifiers({
          function: 'edit',
          buttonText: 'Editar',
          successMessage: 'Plano editado com sucesso!',
        })

        const response = await api.get(`/planos/${id}`)
        const data = {
          ...response?.data,
          id: response?.data?._id ?? 'Insira um nome para o plano',
          valor: response?.data?.valor ? response.data.valor.toString().replace('.', ',') : 0,
          valor_cart: response.data.valor_cart ? response.data.valor_cart.toString().replace('.', ',') : 0,
          dependente: response?.data?.dependente ?? false,
          descricao: response.data.descricao ?? 'Insira uma descrição', //Nome do plano
          recorrencia: response.data.recorrencia ?? 'MENSAL',
          beneficios: response?.data?.beneficios?.map((beneficio) => ({
            value: beneficio,
            label: beneficio,
          })) || [],
        }

        setForm(data)

      }
    })()
  }, [id, reset])

  async function handleSubmit(data) {

    if (identifiers.function === 'edit') {

      try {
        setDisable(true)
        setLoading(true)
        await api.put(`/planos/${id}`, {
          _id: data?._id,
          valor: parseFloat(String(data?.valor).replace(/([a-zA-Z][$][\s])/, '').replace('.', '').replace(/[,]/, '.')),
          valor_cart: parseFloat(String(data?.valor_cart).replace(/([a-zA-Z][$][\s])/, '').replace('.', '').replace(/[,]/, '.')),
          recorrencia: data?.recorrencia,
          dependente: data?.dependente,
          descricao: data?.descricao, // Nome do Plano
          beneficios: form.beneficios?.map((beneficio) => beneficio?.value),
        })

        toast.success(identifiers.successMessage)
        setDisable(false)
        setLoading(false)
        return history.push('/area-admin/manutencao-planos')
      } catch (err) {
        setDisable(false)
        setLoading(false)
        return toast.error(
          err?.response?.data?.erro ||
          'Ocorreu um erro ao editar as informações.',
        )
      }
    }

    try {
      setDisable(true)
      setLoading(true)
      api
        .post('/planos', {
          id: data?._id,
          valor: data?.valor,
          valor_cart: data?.valor_cart,
          recorrencia: data?.recorrencia,
          dependente: data?.dependente,
          descricao: data?.descricao, // Nome do Plano
          beneficios: data.beneficios?.map((beneficio) => beneficio?.value),
        })
        .then(() => {
          toast.success(identifiers.successMessage)
          return history.push('/area-admin/manutencao-planos')
        })
        .catch((error) => {
          setDisable(false)
          setLoading(false)
          return toast.error(
            error?.response?.data?.erro ||
            'Ocorreu um erro ao criar a plano.',
          )
        })
    } catch (err) {
      setLoading(false)
      return toast.error(
        err?.response?.data?.erro || 'Ocorreu um erro ao criar o planos.',
      )
    }
  }

  React.useEffect(() => {
    ; (async () => {
      try {
        setLoading(true)

        const response = await api.get('/planos')

        setPlanoList(response?.data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
          'Erro ao buscar a lista de planos, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <AdminMenu />
      <main className="wrapper bg-verm">
        <div className="container mt-3 pt-3">
          <form
            onSubmit={handleSubmitForm(handleSubmit)}
            className="card p-4 border-0"
          >
            <div className="d-flex flex-wrap color-black">
              
              <div className="form-group col-12 col-md-6">
                <label htmlFor="id" className="control-label">
                  Plano
                </label>
                <input // Nome do Plano
                  id="descricao"
                  name="descricao"
                  className="form-control"
                  ref={register}
                />
                <span className="retorno-invalido">
                  {errors?.descricao?.message}
                </span>
              </div>
              {/* <div className="form-group col-12 col-md-6">
                <label htmlFor="id" className="control-label">
                  ID
                </label>
                <input
                  id="id"
                  name="id"
                  className="form-control"
                  ref={register}
                />
                <span className="retorno-invalido">
                  {errors?.id?.message}
                </span>
              </div> */}
              <div className="form-group col-12 col-md-6">
                <label htmlFor="valor" className="control-label">
                  Valor
                </label>
                <Controller
                  id="valor"
                  as={<Cleave ref={register} />}
                  name="valor"
                  maxLength="17"
                  control={control}
                  className="form-control"
                  options={{
                    numeral: true,
                    numeralDecimalMark: ',',
                    delimiter: '.',
                    prefix: 'R$ ',
                    numeralPositiveOnly: true
                  }}
                />
                <span className="retorno-invalido">
                  {errors?.valor?.message}
                </span>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="valor" className="control-label">
                  Valor Total das Carteirinhas
                </label>
                <Controller
                  id="valor_cart"
                  as={<Cleave ref={register} />}
                  name="valor_cart"
                  maxLength="17"
                  control={control}
                  className="form-control"
                  options={{
                    numeral: true,
                    numeralDecimalMark: ',',
                    delimiter: '.',
                    prefix: 'R$ ',
                    numeralPositiveOnly: true,
                  }}
                />
                <span className="retorno-invalido">
                  {errors?.valor_cart?.message}
                </span>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="categoria" className="control-label">
                  Recorrência
                </label>
                <select
                  id="recorrencia"
                  name="recorrencia"
                  className="form-control"
                  ref={register}
                >
                  <option hidden value="">
                    Selecione uma
                  </option>
                  <option value="ANUAL">Anual</option>
                  <option value="MENSAL">Mensal</option>
                </select>
                <span className="retorno-invalido">
                  {errors?.recorrencia?.message}
                </span>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="categoria" className="control-label">
                  Inclui Dependentes
                </label>
                <select
                  id="dependente"
                  name="dependente"
                  className="form-control"
                  ref={register}
                >
                  <option hidden value="">
                    Selecione um valor
                  </option>
                  <option value='false'>Não</option>
                  <option value='true'>Sim</option>
                </select>
                <span className="retorno-invalido">
                  {errors?.dependente?.message}
                </span>
              </div>
              <div className="form-group col-12">
                <label htmlFor="id" className="control-label">
                  Benefícios
                </label>
                <div className="form-group md-12 col-12 pt-2">
                  <div>
                    <div className='dispRow'>
                      <input
                        id="adicionarBeneficios"
                        name='adicionarBeneficios'
                        className="form-control "
                        ref={register}
                        placeholder='Digitar novo benefício...'
                        maxLength={500}
                      />
                      <button
                        type="button"
                        disabled={!!form?.beneficios?.length >= 5}
                        onClick={handleAddBeneficio}
                        className='btn btn-adicionar-pc'
                      >Adicionar</button>
                    </div>
                    {form?.beneficios?.length > 0 && <ul>
                      {form?.beneficios?.map((beneficio, index) => <li key={index}>
                        {beneficio.value}
                        {beneficio?.value && <button className=" btn-transparent" type="button" onClick={() => handleDeleteBeneficio(index)}>
                          <Icon
                            type="button"
                            className="deleteColor"
                            icon='times-circle'
                          ></Icon>
                        </button>}
                      </li>)}
                    </ul>}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between col-12">
              <button
                type="button"
                className="btn btn-light border"
                onClick={() => history.push('/area-admin/manutencao-planos')}
              >
                Voltar
              </button>
              <button
                type="submit"
                className="btn btn-verde"
                disabled={disable || loading}
              >
                {identifiers.buttonText}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  )
}

export const breadcrumbPagesPath = {
  midia_kit: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      name: 'Clube',
    },
    {
      last: true,
      name: 'Mídia Kit',
      link: '/clube/midia_kit',
    }
  ],
  historia: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      name: 'Clube',
    },
    {
      last: true,
      name: 'História',
      link: '/clube/historia',
    }
  ],
  patrocinadores: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      last: true,
      name: 'Patrocinadores',
      link: '/patrocinadores',
    }
  ],
  contato: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      last: true,
      name: 'Contato',
      link: '/contato',
    }
  ],

  tabela_classificacao: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      name: 'Futebol',
    },
    {
      last: true,
      name: 'Tabela de Classificação',
      link: '/futebol/tabela-classificação',
    }
  ],

  jogos: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      name: 'Futebol',
    },
    {
      last: true,
      name: 'Jogos',
      link: '/futebol/jogos',
    }
  ],
  elencos: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      name: 'Futebol',
    },
    {
      last: true,
      name: 'Elenco',
      link: '/futebol/elenco',
    }
  ],
  jogador: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      name: 'Futebol',
    },
    {
      hasLink: true,
      name: 'Elenco',
      link: '/futebol/elencos',
    },
    {
      last: true,
      name: 'Jogador',
    }
  ],
  competicoes: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      name: 'Futebol',
    },
    {
      last: true,
      name: 'Competições',
      link: '/futebol/competições',
    }
  ],
  diretoria: [
    {
      hasLink: true,
      name: 'Início',
      link: '/',
    },
    {
      name: 'Clube',
    },
    {
      last: true,
      name: 'Diretoria',
      link: '/clube/diretoria',
    }
  ],
  planos: [
    {
      hasLinks: true,
      name: 'Início',
      link: '/',
    },
    {
      last: true,
      name: 'Planos',
      link: '/planos'
    }
  ]
};

export default breadcrumbPagesPath;

import React from 'react'
import Chart from 'react-apexcharts'
import moment from 'moment'
import { Link, useRouteMatch } from 'react-router-dom'
// import { toast } from 'react-toastify'

import axios from '../../services/api'

import { formatPrice } from '../../utils/formatPrice'

import Loading from '../../components/Loading'
import api from '../../services/api'
import { toast } from 'react-toastify'
import DashCard from '../../components/DashCard'
import AdminMenu from '../../components/AdminMenu'

export default function Dashboard({plano}) {
  const [card, setCard] = React.useState({})
  const { path } = useRouteMatch()
  const [projectionData, setProjectionData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [planoList, setPlanoList] = React.useState([])
  const [modal, setModal] = React.useState(false)

  const handleShow = () => setModal(true)
  const options = React.useMemo(
    () => ({
      colors: ['#008ffb', '#D69947'],
      title: {
        text: 'Projeção financeira (R$)',
      },
      plotOptions: {
        bar: {
          columnWidth: '100%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      chart: {
        id: 'bar-side',
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: 'Projeção Financeira',
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: 'Projeção Financeira',
            },
            png: {
              filename: 'Projeção Financeira',
            }
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: projectionData.map((p) =>
          moment(p.date).parseZone().format('DD/MM'),
        ),
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008ffb',
          },
          labels: {
            formatter: function (value) {
              return formatPrice(value)
            },
            style: {
              colors: '#008ffb',
            },
          },
          title: {
            text: 'Recebido',
            style: {
              color: '#008ffb',
            },
          },
        },
        {
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#D69947',
          },
          labels: {
            formatter: function (value) {
              return formatPrice(value)
            },
            style: {
              colors: '#D69947',
            },
          },
          title: {
            text: 'Projeção',
            style: {
              color: '#D69947',
            },
          },
        },
      ],
    }),
    [projectionData],
  )
  React.useEffect(() => {
    ; (async () => {
      try {
        const [response, projection] = await Promise.all([
          axios.get('/dash/total'),
          axios.get('/dash/grafico'),
        ])
        const totalAtivos = response.data.ativos.reduce(
          (acc, curr) => acc + curr.count,
          0,
        )

        let ativos = {}

        response.data.ativos.forEach((item) => (ativos[item._id] = item.count))

        const data = {
          ativos,
          totalAtivos,
          inadimplentes: response.data.total - totalAtivos,
        }

        const filteredProjection = projection.data.filter(
          (p) => p.valorRecebido > 0 || p.valorEsperado > 0,
        )
        const fixProjection = moment(filteredProjection[0]?.date).isAfter(
          moment().format('YYYY-MM-DD'),
        )
          ? [
            {
              date: moment().format('YYYY-MM-DD'),
              valorRecebido: 0,
              valorEsperado: 0,
            },
            ...filteredProjection,
          ]
          : filteredProjection

        setProjectionData(fixProjection)
        setCard(data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar os dados, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  React.useEffect(() => {
    ;(async () => {
      try {
        setLoading(true) 
        
        const response = await api.get('/planos')

        setPlanoList(response?.data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de planos, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])


  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className="wrapper bg-verm">
        <div className="container pb-4">
          <div className="row mt-3">
            {
            planoList.length > 0 ? (
              planoList?.sort(function(a, b) {
                if(a.valor < b.valor){
                  return 1;}
                if (a.valor > b.valor){
                  return -1;
                } 
                  return 0
                }).map((plano) => {      
                  return (plano._id)  ?
                  <DashCard
                    show={modal}
                    handleShow={handleShow}
                    plano={plano}
                    key={plano.descricao + plano._id}
                  /> 
              : <></>}) || <div className="col-12 col-md-6 col-lg-4 col-sm-6 order-1 order-lg-0"></div>
              ) : (
                <div className="jogos_">Não há planos cadastrados.</div>
            )}
            <Link
                className="col-lg-3 col-sm-4 col-md-4 mt-3 cursor-pointer no-decoration"
                to={{
                  pathname: `${path}/socios`,
                  search: new URLSearchParams({
                    status: 'inadimplentes',
                  }).toString(),
                }}
              >
                <div className="card p-4 border-0 color-black cursor-pointer">
                  <strong className="titleCard cursor-pointer">
                    Sócios inadimplentes
                  </strong>
                  <label className="card-label cursor-pointer">
                    {card.inadimplentes || 0}
                  </label>
                </div>
              </Link>
          </div>

          <div className="col-lg-12 card p-4 border-0 mt-4">
            <Chart
              type="bar"
              width="100%"
              height={400}
              options={options}
              series={[
                {
                  name: 'Recebido',
                  data: projectionData.map((p) => p.valorRecebido),
                },
                {
                  name: 'Projeção',
                  data: projectionData.map((p) => p.valorEsperado),
                },
              ]}
            />
          </div>
        </div>
      </main>
    </>
  )
}

import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import api from '../../services/api';

import LoadingSpinner from '../LoadingSpinner';
import LabelStatus from '../LabelStatus';

import { formatPrice } from '../../utils/formatPrice';
import paymentOptions from '../../utils/paymentOptions';
import nameCapitalize from '../../utils/nameCapitalize';
import Icon from '../Icon';

export default function Payment() {
  const history = useHistory();
  const { id } = useParams();
  const [paymentInfo, setPaymentInfo] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/socios/${id}`, {
          params: {
            info: 'payment',
          }
        });

        const data = {
          ...response?.data,
          formattedPagoate: response?.data?.pagoate ? moment(response?.data?.pagoate).parseZone().format('DD/MM/YYYY') : 'N/A',
          formattedPaymentMethod: paymentOptions.find((p) => p.value === response?.data?.forma_pagamento)?.label || response?.data?.forma_pagamento || 'Cartão de crédito',
          pgtos: response?.data?.pgtos?.map((pgto) => ({
            ...pgto,
            forma_pagamento: pgto?.forma_pagamento === 'cc' || !pgto?.forma_pagamento ? 'Cartão' : nameCapitalize(pgto?.forma_pagamento),
            date: moment(pgto?.date).format('DD/MM/YYYY'),
            valor: formatPrice(pgto?.valor),
            status: pgto?.aprovado ? 'Aprovado' : 'Recusado'
          })),
        }

        setPaymentInfo(data);
      } catch (err) {
        toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao buscar os dados do usuário.');
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  function handleEdit({ path, state }) {
    history.push(`/area-admin/socios/${id}/${path}/editar`, state);
  }

  if (loading) {
    return <LoadingSpinner className="d-flex justify-content-center" />
  }

  return (
    <>
      <div className="col-12 color-black">
        <div className="row">
          <div className="col-10">
            <h3 className="font-weight-bold">Forma de pagamento</h3>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-start mb-2">
            <Icon
              className="cursor-pointer"
              icon="edit"
              style={{ fontSize: 24 }}
              onClick={() => handleEdit({
                path: 'forma_pagamento',
                state: {
                  fields: {
                    forma_pagamento: paymentInfo?.forma_pagamento,
                    formattedPaymentMethod: paymentInfo?.formattedPaymentMethod
                  },
                  redirect: `/area-admin/socios/${id}/pagamento`
                }
              })}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-between color-black">
        <div className="col-12 col-sm-6 mt-2">
          <small className="font-weight-bold fs-14 d-block">Tipo</small>
          <span className="d-block">{paymentInfo?.formattedPaymentMethod}</span>
        </div>
        <div className="col-12 col-sm-4 mt-2 pl-sm-0 pl-md-1 pl-xl-5">
          <small className="font-weight-bold fs-14 d-block">Pago até</small>
          <span className="d-block">{paymentInfo?.formattedPagoate}</span>
        </div>
      </div>

      <div className="col-12 mt-4 color-black">
        <h3 className="font-weight-bold">Recorrência</h3>
      </div>
      <section className="admin-recorrencia-section">
        <ul>
          {paymentInfo?.pgtos?.map(pgto => (
            <li key={pgto?.id}>
              <div className="socio-grid">
                <span id="date">{pgto?.date}</span>
                <span id="forma">{pgto?.forma_pagamento}</span>
                <span align="right" id="valor">{pgto?.valor}</span>
              </div>
              <LabelStatus className="socio-list-label-status" aprovado={pgto?.aprovado} mensagem={pgto?.mensagem || pgto?.descricao}>
                {pgto?.status}
              </LabelStatus>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
}

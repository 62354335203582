import * as yup from 'yup'

export const schemaDiretorias = yup.object().shape({
  nome: yup
    .string()
    .max(25, 'Máximo de 25 caracteres')
    .required('Campo é obrigatório.')
    .matches(/[abcdefghijklmnopqrstuvwxyz]+/, 'Não está no formato correto'),
  cargo: yup
  .string()
  .max(25, 'Máximo de 25 caracteres')
  .required('Campo é obrigatório.')
  .matches(/[abcdefghijklmnopqrstuvwxyz]+/, 'Não está no formato correto'),
})

import React from 'react'

export function Icon({ icon, size, prefix, className, onClick, color, cursor, m, mr, ml, mt, mb, style }) {
    return <span
        className={`icon ${prefix ?? 'fas'} ${style?.height ? 'd-flex align-items-center justify-content-center' : 'd-inline-block'} fa-${icon} ${size ? `fa-${size}` : ''} ${className || ''}`}
        onClick={onClick}
        style={{
            ...style,
            margin: m,
            marginLeft: ml,
            marginRight: mr,
            marginTop: mt,
            marginBottom: mb,
            color: color,
            cursor: cursor,
            fontSize: size
        }}
    ></span>
}

export default Icon
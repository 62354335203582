import React from 'react'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import moment from 'moment'
import { Modal } from 'react-bootstrap'

import api from '../../services/api'

import AdminMenu from '../../components/AdminMenu'
import Loading from '../../components/Loading'
import NoticiaCard from '../../components/NoticiaCard'
import { toast } from 'react-toastify'
import InfiniteScroll from 'react-infinite-scroll-component'
import CustomInput from '../../components/CustomInput'
import Icon from '../../components/Icon'

export default function Noticias() {
  const { path } = useRouteMatch()
  const history = useHistory()
  const { search } = useLocation()
  const [loading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [noticias, setNoticias] = React.useState([])
  const [modal, setModal] = React.useState(false)
  const [itemToDelete, setItemToDelete] = React.useState('')
  const searchRef = React.useRef()

  const urlQuery = React.useMemo(() => {
    const queries = new URLSearchParams(search)

    const query = {}

    const busca = queries.get('busca') || ''

    if (busca) {
      query.busca = busca
    }

    return query
  }, [search])

  const hasMore = React.useMemo(() => page <= noticias?.pages, [
    page,
    noticias.pages,
  ])

  React.useEffect(() => {
    ;(async () => {
      try {
        setPage(1)
        setLoading(true)
        const response = await api.get('/area-admin/noticias', {
          params: {
            search: urlQuery?.busca,
          },
        })

        const data = response?.data?.list?.map((item) => ({
          ...item,
          dateFormatted: moment(item?.created_at).format(
            'DD/MM/YYYY [às] HH:mm',
          ),
        }))

        setNoticias({
          list: data || [],
          pages: response?.data?.pages,
          total: response?.data?.total,
        })
        setPage((val) => val + 1)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de noticias, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [urlQuery])

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/area-admin/noticias?page=${page}`, {
        params: {
          search: urlQuery?.busca,
        },
      })

      const data = response?.data?.list?.map((item) => ({
        ...item,
        dateFormatted: moment(item?.created_at).format('DD/MM/YYYY [às] HH:mm'),
      }))

      setNoticias({ ...noticias, list: [...noticias?.list, ...data] })
      setPage(page + 1)
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Erro ao buscar a lista de sócios, por favor, recarregue a página.',
      )
    } finally {
      setLoading(false)
    }
  }, [noticias, page, urlQuery])

  async function removeItem() {
    await api.delete(`/noticias/${itemToDelete}`)
    setNoticias((oldState) => ({
      ...oldState,
      list: oldState?.list?.filter((item) => item?._id !== itemToDelete) || [],
    }))
    handleClose()
    toast.success('Notícia deletada com sucesso!')
  }

  const handleSearch = React.useCallback(
    (e) => {
      e.preventDefault()
      const value = searchRef?.current?.value?.trim()

      const data = Object.assign({}, value ? { busca: value } : null)

      history.push({
        pathname: path,
        search: new URLSearchParams(data).toString(),
      })
    },
    [history, path],
  )

  const handleClose = () => setModal(false)
  const handleShow = () => setModal(true)

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className="wrapper bg-verm" id="scrollableWrapper">
        <div className="container mt-3 p-0 pt-3 pb-4 position-relative">
          <div className="col-12 mb-4">
            <div className="card border-0 p-4">
              <Link to={`${path}/cadastrar`} className="btn-adicionar">
                <Icon icon="plus" color="#fff" />
              </Link>
              <form className="form-pesquisa" onSubmit={handleSearch}>
                <CustomInput
                  value={urlQuery?.busca}
                  id="input-pesquisa"
                  placeholder="Pesquisar por titulo"
                  icon="search"
                  sendIcon="paper-plane"
                  inputRef={searchRef}
                />
              </form>
            </div>
          </div>
          <Link to={`${path}/cadastrar/`} className="text-decoration-none btn-adicionar mt-3 mr-3">
            <Icon icon="plus" color="#fff" />
          </Link>
          <Modal
            show={modal}
            onHide={handleClose}
            keyboard={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title className='modal-title'>Remover Notícia?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
              Tem certeza que deseja remover esta notícia? Esta ação não será
              reversível.
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-verm"
                type="button"
                onClick={removeItem}
              >
                Remover
              </button>
            </Modal.Footer>
          </Modal>
          <InfiniteScroll
            className="d-flex flex-wrap row-gap-30"
            dataLength={noticias?.list?.length || noticias?.length}
            next={loadMore}
            hasMore={hasMore}
            scrollableTarget="scrollableWrapper"
          >
            {noticias?.list?.length > 0 ? (
              noticias?.list?.map((noticia) => (
                <NoticiaCard
                  handleShow={handleShow}
                  key={noticia?._id}
                  noticia={noticia}
                  setItemToDelete={setItemToDelete}
                />
              )) || <div></div>
            ) : (
              <div className="jogos_">Não há notícias cadastradas.</div>
            )}
          </InfiniteScroll>
        </div>
      </main>
    </>
  )
}

import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toast } from 'react-toastify'

import api from '../services/api'

import Icon from './Icon'
import nameCapitalize from '../utils/nameCapitalize'
export default function SocioCard({ socio, setSociosList, sociosList }) {
  const { path } = useRouteMatch()

  async function handleCancel() {
    try {
      await api.delete(`/socios/${socio?._id}/cancel`)

      setSociosList(
        sociosList?.map((s) =>
          s?._id === socio?._id ? { ...s, cancelado: !socio?.cancelado } : s,
        ),
      )
      toast.success('Sócio alterado com sucesso!')
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
        'Ocorreu um erro ao atualizar a informação.',
      )
    }
  }
  
  const checkChars = (str) => {
    const max = 16
    //replace 15 with 500
    return str.length > max ? str.substring(0, max) + '...' : str
  }

  return (
    <div className="col-lg-6 col-xl-4 position-relative color-black">
      <Link
        to={`${path}/${socio?._id}/pessoal`}
        className="text-decoration-none card border-0 p-4"
      >
        <h2 className="font-weight-bold card-name">
          {nameCapitalize(checkChars(socio?.nome))}
        </h2>
        <p className="carteirinha color-black">
          {socio?.emissao_carteirinha
            ? 'Carteirinha emitida'
            : 'Carteirinha não emitida'}
        </p>

        <div
          className={
            socio?.cancelado
              ? 'status-active-cancelado'
              : socio?.ativo
                ? 'status-active-ativo'
                : 'status-active-inativo'
          }
        >
          <div className="status-ativo margin-bottom-2 d-flex align-items-center">
            <div className="d-inline-block circle-status status-color-ativo" />
            <span className="status-text">Ativo</span>
          </div>
          <div className="status-inativo margin-bottom-2 d-flex align-items-center">
            <div className="d-inline-block circle-status status-color-inativo" />
            <span className="status-text">Inadimplente</span>
          </div>
          <div className="status-cancelado d-flex align-items-center">
            <div className="d-inline-block circle-status status-color-cancelado" />
            <span className="status-text">Cancelado</span>
          </div>
        </div>
      </Link>

      <div className="action">
        <div className="d-flex align-items-center">
          <div className="dropdown">
            <button
              className="border-0 btn pt-1 pb-1 custom-btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdownMenuButton"
            >
              <Icon icon="ellipsis-v" color="#BDBDBD" />
            </button>
            <div
              className="dropdown-menu dropdown-menu-right z-index-99"
              aria-labelledby="dropdownMenuButton"
            >
              <button
                className="outline-0 dropdown-item custom-dropdown color-black"
                onClick={handleCancel}
              >
                <Icon
                  icon={socio?.cancelado ? "check-circle" : 'times-circle'}
                  color="#333"
                />
                <span className="ml-3">
                  {socio?.cancelado ? 'Ativar sócio' : 'Cancelar sócio'}
                </span>
              </button>
              <Link
                className="dropdown-item custom-dropdown color-black"
                to={`/area-admin/socios/${socio?._id}/pessoal/editar`}
              >
                <Icon icon="pen" color="#333" />
                <span className="ml-3">Editar</span>
              </Link>
            </div>
          </div>
        </div>
      </div>


      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 100 }}
        overlay={<Tooltip>{`Plano: ${socio?.plano.descricao}`}</Tooltip>}
      >
        {/* <div className="medal">
            <Icon
                prefix="fad"
                icon="medal"
                style={{
                    '--fa-primary-color': planosIconsColors[socio?.plano],
                    '--fa-secondary-color': '#D06060',
                    '--fa-secondary-opacity': 1,
                }}
            />
        </div> */}
        <div className='medal'>
             {socio?.plano.descricao}
        </div>
        {/* <div className='medal'>
            <Icon icon={iconTypePlan['individual']}/>
        </div> */}
      </OverlayTrigger>
    </div>
  )
}

import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import Icon from './Icon'

export default function JogoCard({
  handleShow,
  patrocinador,
  setItemToDelete,
}) {
  const { path } = useRouteMatch()

  async function handleDelete() {
    try {
      setItemToDelete(patrocinador?._id)
      handleShow()
    } catch (err) {
      toast.error(
        err?.response?.data?.erro || 'Erro ao remover o patrocinador.',
      )
    }
  }

  return (
    <div className="col-lg-6 col-xl-4 position-relative color-black">
      <div className="text-decoration-none card border-0 p-4 h-100">
        <p className="font-weight-bold card-title_dois">
          <span>{patrocinador?.nome?.toUpperCase()}</span>
        </p>
        <hr className="mt-0 mb-2"></hr>
        <p className="card-date_ ">
          Nível: {patrocinador?.tipo?.toUpperCase()}
        </p>
        <p className="card-date_ ">
          Reservas por jogo: {patrocinador?.reservas?.toUpperCase()}
        </p>
        {patrocinador.img &&
          <img
            width={75}
            height={75}
            className="rounded"
            src={patrocinador?.img?.url}
            alt="Logo"
            loading="lazy"
            style={{ objectFit: "contain" }}
          />
        }
      </div >
      <div className="action">
        <div className="d-flex align-items-center">
          <div className="dropdown">
            <button
              className="border-0 btn pt-1 pb-1 custom-btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdownMenuButton"
            >
              <Icon icon="ellipsis-v" color="#BDBDBD" />
            </button>
            <div
              className="dropdown-menu dropdown-menu-right z-index-99"
              aria-labelledby="dropdownMenuButton"
            >
              <Link
                className="dropdown-item custom-dropdown color-black"
                to={`${path}/${patrocinador?._id}/editar`}
              >
                <Icon icon="pen" color="#333" />
                <span className="ml-3">Editar</span>
              </Link>

              <button
                className="outline-0 dropdown-item custom-dropdown color-black"
                onClick={handleDelete}
              >
                <Icon icon="times-circle" color="#de002c" />
                <span className="ml-3">Deletar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

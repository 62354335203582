import React from 'react'

import api from '../services/api'

const PlanosContext = React.createContext()

function PlanosProvider({ children }) {
  const [planoData, setPlanoData] = React.useState({})

  async function getPlanoValor(plano) {
    if (planoData?._id !== plano) {
      try {
        const response = await api.get(`/planos/${plano}`)

        setPlanoData(response?.data)

        return response?.data
      } catch (err) {
        return (
          err?.response?.data?.erro || 'Erro ao buscar informações do plano'
        )
      }
    }

    return planoData
  }

  return (
    <PlanosContext.Provider value={{ getPlanoValor }}>
      {children}
    </PlanosContext.Provider>
  )
}

function usePlanos() {
  const context = React.useContext(PlanosContext)

  if (!context) {
    throw new Error(
      '[PlanosContext]: Component must be within the Context Provider',
    )
  }

  const { getPlanoValor } = context

  return { getPlanoValor }
}

export { PlanosProvider, usePlanos }

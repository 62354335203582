import React from 'react'
import { useParams, Redirect, useLocation } from 'react-router-dom'

import Pessoal from '../../components/forms/Pessoal'
import Endereco from '../../components/forms/Endereco'
import Carteirinha from '../../components/forms/Carteirinha'
import Dependente from '../../components/forms/Dependente'
import FormaPagamento from '../../components/forms/FormaPagamento'
import Camiseta from '../../components/forms/Camiseta'
import AdminMenu from '../../components/AdminMenu'

const forms = {
  pessoal: Pessoal,
  endereco: Endereco,
  carteirinha: Carteirinha,
  dependente: Dependente,
  forma_pagamento: FormaPagamento,
  camiseta: Camiseta,
}

export default function SociosEdicao() {
  const { state } = useLocation()
  const { form, id } = useParams()

  const Component = React.useMemo(() => forms[form] || null, [form])

  return (
    <>
      <AdminMenu />
      <main className="wrapper bg-verm">
        <div className="container mt-3 pt-3 pb-4">
          <div className="card border-0 p-4">
            {Component ? (
              <Component state={state} />
            ) : (
              <Redirect to={`/area-admin/socios/${id}/pessoal`} />
            )}
          </div>
        </div>
      </main>
    </>
  )
}

import * as yup from 'yup'

import cpfTest from '../utils/cpfTest'

// function passTest(pass) {
//   const noSpacePass = pass.trim()

//   if (!noSpacePass) {
//     return false
//   }

//   return true
// }

export const schemaCadastroUsuario = yup.object().shape({

  nome: yup
    .string()
    .matches(/[a-zA-Zà-úÀ-Ú][ ][a-zA-Zà-úÀ-Ú]+$/, {
      excludeEmptyString: true,
      message: 'Por favor, insira o nome completo.',
    })
    .required('Nome é obrigatório.'),

  cpf: yup
    .string()
    .test('cpfTest', 'CPF inválido.', cpfTest)
    .required('CPF é obrigatório.'),

  email: yup
    .string()
    .email('Insira um e-mail válido.')
    .required('E-mail é obrigatório.'),

  senha: yup
    .string()
    .when('$id', (id, field) =>
      id ? field : field.required('Senha é obrigatória.'),
    )
    .min(6, 'Insira um valor maior que 6 caracteres.'),
})

import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers';
import api from '../../services/api';
import { usePlanos, PlanosProvider } from '../../Contexts/PlanosContext';
import { schemaEdicaoNewPayment } from '../../validators/formEdicao';
import Select from '../Select';
import LoadingSpinner from '../LoadingSpinner';
import paymentOptions from '../../utils/paymentOptions';

export default function Wrapper() {
  return (
    <PlanosProvider>
      <NovoPagamento />
    </PlanosProvider>
  );
}

function NovoPagamento() {
  const { id } = useParams();
  const history = useHistory();
  const { getPlanoValor } = usePlanos();
  const [payment, setPayment] = React.useState('');
  const [plano, setPlano] = React.useState('');
  const [currentDates, setCurrentDates] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { handleSubmit: handleSubmitForm, errors, register, control, getValues, setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    resolver: yupResolver(schemaEdicaoNewPayment),
    defaultValues: {
      forma_pagamento: '',
      pagoem: '',
      pagoate: '',
      valor: '',
    }
  });

  React.useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/socios/${id}`, {
          params: {
            info: 'personal'
          }
        });

        setPlano(response?.data?.plano);
      } catch (err) {
        toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao buscar o plano do sócio.');
      }
    })();
  }, [id]);

  async function handleSubmit(data) {
    try {
      setLoading(true);

      const planoGet = await api.get(`planos/${plano}`)
      const planoInDb = planoGet.data
      const planoAmigo = planoInDb.dependente === false && planoInDb.public === false

      const socioGet = await api.get(`/socios/${id}`, {
        params: {
          info: 'personal'
        }
      })
      const amigoSocio = socioGet.data.amigo

      if (planoAmigo && !amigoSocio) {
        toast.error('Não é possível registrar um novo pagamento se o sócio não possui amigo')
      } else {      
        const response = await api.post(`/pagamentos/${id}/new-payment`, {
          ...data,
          pagoem: moment(data?.pagoem, 'DD/MM/YYYY').toDate(),
          pagoate: moment(data?.pagoate, 'DD/MM/YYYY').toDate(),
        });

        if (!response?.data?.aprovado) {
          toast.error(`Pagamento não aprovado, motivo: ${response?.data?.mensagem}`);
        }

        history.push(`/area-admin/socios/${id}/pagamento`);
        toast.success('Informações atualizadas com sucesso!');
      }
    } catch (err) {
      toast.error(err?.response?.data?.erro || 'Ocorreu um erro ao enviar suas informações.');
    } finally {
      setLoading(false);
    }
  }

  function changePayment(e) {
    setPayment(e.target.value);
  }

  const changeDate = React.useCallback(async (e) => {
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(e.target.value) && e.target.value !== currentDates[e.target.name]) {
      try {
        setCurrentDates({ ...currentDates, [e.target.name]: e.target.value });


        const { pagoem, pagoate } = getValues(['pagoem', 'pagoate']);

        if (/^\d{2}\/\d{2}\/\d{4}$/.test(pagoem) && /^\d{2}\/\d{2}\/\d{4}$/.test(pagoate)) {
          const { valor } = await getPlanoValor(plano);

          const interval = moment(pagoate, 'DD/MM/YYYY').diff(moment(pagoem, 'DD/MM/YYYY'), 'months', true);

          if (interval * valor < 0) {
            setValue('valor', '');
            return;
          }

          const value = interval * valor;

          const [intValue, cents = '00'] = String(value).split('.');

          const intCents = cents?.length >= 2 ? cents?.substring(0, 2) : `${cents?.substring(0, 1)}0`;

          setValue('valor', `${intValue},${intCents}`);
        }
      } catch (err) {
        toast.error(err?.message);
      }
    }
  }, [currentDates, getPlanoValor, getValues, setValue, plano]);

  return (
    <form className="color-black px-15" onSubmit={handleSubmitForm(handleSubmit)}>

      <div className="form-group">
        <label htmlFor="forma-pagamento" className="control-label">Forma de pagamento</label>
        <Select
          id="forma-pagamento"
          className="custom-select"
          name="forma_pagamento"
          options={paymentOptions.filter((p) => p.value !== 'cc')}
          label="tipo de pagamento"
          selected={payment}
          register={register}
          onChange={changePayment}
        />
        <div className="retorno-invalido">{errors?.forma_pagamento?.message}</div>
      </div>

      {payment && (
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="pagoem" className="control-label">Pago em</label>
            <Controller
              className="form-control"
              id="pagoem"
              name="pagoem"
              as={<Cleave ref={register} />}
              control={control}
              placeholder="dd/mm/aaaa"
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
              }}
              onKeyUp={changeDate}
            />
            <div className="retorno-invalido">{errors?.pagoem?.message}</div>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="pagoate" className="control-label">Pago até</label>
            <Controller
              className="form-control"
              id="pagoate"
              name="pagoate"
              as={<Cleave ref={register} />}
              control={control}
              placeholder="dd/mm/aaaa"
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
              }}
              onKeyUp={changeDate}
            />
            <div className="retorno-invalido">{errors?.pagoate?.message}</div>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="valor" className="control-label">Valor pago</label>
            <Controller
              id="valor"
              as={<Cleave ref={register} />}
              name="valor"
              control={control}
              className="form-control"
              options={{ numeral: true, numeralDecimalMark: ',', delimiter: '.', prefix: 'R$ ', numeralPositiveOnly: true }}
            />
            <div className="retorno-invalido">{errors?.valor?.message}</div>
          </div>
        </div>
      )}

      <p className="warning mb-2">Valor do plano x quantidade de meses</p>

      <div className="d-flex justify-content-end">
        <button type="submit" disabled={loading || !payment} className="btn btn-verde d-flex align-items-center">{loading && <LoadingSpinner small={true} />} Enviar</button>
      </div>
    </form>
  );
}

import React from 'react'
import { Modal } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import CustomInput from '../../components/CustomInput'
import Icon from '../../components/Icon'
import Loading from '../../components/Loading'
import PatrocinadoresCard from '../../components/PatrocinadoresCard'
import api from '../../services/api'

export default function Patrocinadores() {
  const { path } = useRouteMatch()
  const history = useHistory()
  const { search } = useLocation()
  const [patrocinadoresList, setPatrocinadoresList] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [modal, setModal] = React.useState(false)
  const [itemToDelete, setItemToDelete] = React.useState('')
  const searchRef = React.useRef(null)
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(0)

  async function removeItem() {
    await api.delete(`/patrocinadores/${itemToDelete}`)
    setPatrocinadoresList((oldState) => {
      return oldState.filter((item) => item?._id !== itemToDelete)
    })
    handleClose()
    toast.success('Patrocinador deletado com sucesso!')
  }

  const handleClose = () => setModal(false)
  const handleShow = () => setModal(true)

  const urlQuery = React.useMemo(() => {
    const queries = new URLSearchParams(search)
    const query = {}
    const busca = queries.get('search') || ''

    if (busca) {
      query.search = busca
    }
    return query
  }, [search])

  const handleSearch = React.useCallback(
    (e) => {
      e.preventDefault()
      const value = searchRef?.current?.value?.trim()
      const data = Object.assign(
        {},
        urlQuery?.status ? { status: urlQuery.status } : null,
        value ? { search: value } : null,
      )
      history.push({
        pathname: path,
        search: new URLSearchParams(data).toString(),
      })
    },
    [history, urlQuery, path],
  )
  const hasMore = React.useMemo(() => page <= pages, [page, pages])

  React.useEffect(() => {
    ;(async () => {
      try {
        setPage(1)
        setLoading(true)
        const response = await api.get('/patrocinadores?page=1', {
          params: {
            search: urlQuery?.search,
            limit: 20,
          },
        })
        setPatrocinadoresList(response?.data?.list)
        setPages(response?.data?.pages || 1)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de patrocinador, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
        setPage((p) => p + 1)
      }
    })()
  }, [urlQuery])

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/patrocinadores?page=${page}`, {
        params: {
          search: urlQuery?.search,
          limit: 20,
        },
      })

      setPatrocinadoresList([...patrocinadoresList, ...response?.data?.list])
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Erro ao buscar a lista de patrocinadores, por favor, recarregue a página.',
      )
    } finally {
      setLoading(false)
      setPage(page + 1)
    }
  }, [page, patrocinadoresList, urlQuery])

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className="wrapper bg-verm" id="scrollableWrapper">
        <div className="container mt-3 p-0 pt-3 pb-4 position-relative">
          <div className="col-12 mb-4">
            <div className="card border-0 p-4">
              <Link
                to={`${path}/cadastrar`}
                className="text-decoration-none btn-adicionar"
              >
                <Icon icon="plus" color="#fff" />
              </Link>
              <form className="form-pesquisa" onSubmit={handleSearch}>
                <CustomInput
                  value={urlQuery?.busca}
                  id="input-pesquisa"
                  placeholder="Pesquisar por patrocinador"
                  icon="search"
                  sendIcon="paper-plane"
                  inputRef={searchRef}
                  hasMore={hasMore}
                  scrollableTarget="scrollableWrapper"
                />
              </form>
            </div>
          </div>
          <Link to={`${path}/cadastrar/`} className="text-decoration-none btn-adicionar mt-3 mr-3">
            <Icon icon="plus" color="#fff" />
          </Link>
          <Modal
            show={modal}
            onHide={handleClose}
            keyboard={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">Remover Patrocinador?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
              Tem certeza que deseja remover este patrocinador? Esta ação não
              será reversível.
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-verm"
                type="button"
                onClick={removeItem}
              >
                Remover
              </button>
            </Modal.Footer>
          </Modal>
          <div>
            <InfiniteScroll
              className="d-flex flex-wrap row-gap-30"
              dataLength={patrocinadoresList?.length}
              next={loadMore}
              hasMore={hasMore}
              scrollableTarget="scrollableWrapper"
            >
              {patrocinadoresList?.length > 0 ? (
                patrocinadoresList?.map((patrocinador, index) => (
                  <PatrocinadoresCard
                    handleShow={handleShow}
                    setItemToDelete={setItemToDelete}
                    patrocinador={patrocinador}
                    key={index}
                  />
                )) || <div></div>
              ) : (
                <div className="jogos_">Não há patrocinadores cadastrados.</div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </main>
    </>
  )
}

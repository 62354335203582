import React from 'react'
import Steps, { Step } from 'rc-steps'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'

import Icon from './Icon'

function Stepper({
  steps,
  showSteps,
  showCircularSteps,
  parentCurrentStep,
  parentSetCurrentStep,
}) {
  const [currentStep, setCurrentStep] = React.useState(0)

  const Component = React.useMemo(() => {
    const { component } = steps[parentCurrentStep || currentStep]

    return component
  }, [parentCurrentStep, currentStep, steps])
  const percentage =
    (100 / steps?.filter((item) => !item?.hide).length) *
    (parentCurrentStep || currentStep + 1)

  function navigateOnStep(goToStep) {
    if (goToStep > -1 && goToStep < steps.length) {
      if (parentSetCurrentStep) {
        parentSetCurrentStep(goToStep)
        return
      }

      setCurrentStep(goToStep)
    }
  }

  return (
    <>
      {showSteps && (
        <div className="card p-4 border-0">
          <Steps
            size="default"
            labelPlacement="vertical"
            type="default"
            current={parentCurrentStep || currentStep}
          >
            {steps.map((step, index) => {
              return (
                <Step
                  key={step?.title}
                  stepIcon={() =>
                    currentStep > index && (
                      <Icon
                        icon="fa-check"
                        width="1em"
                        height="1em"
                        color="#fff"
                      />
                    )
                  }
                  title={step?.title}
                  description={step?.description}
                />
              )
            })}
          </Steps>
        </div>
      )}
      {showCircularSteps && (
        <div className="d-flex align-items-center circular-stepper-cadastro">
          <div style={{ width: 100, height: 100 }}>
            <CircularProgressbarWithChildren
              value={percentage}
              strokeWidth={10}
              styles={buildStyles({
                strokeLinecap: 'butt',
                pathColor: steps[parentCurrentStep || currentStep]?.last
                  ? '#00a783'
                  : '#D89B49',
              })}
            >
              <div>
                <span
                  className={`current-step ${
                    steps[parentCurrentStep || currentStep]?.last
                      ? 'step-finished'
                      : ''
                  }`}
                >
                  {(parentCurrentStep || currentStep + 1) >
                  steps?.filter((item) => !item?.hide).length
                    ? steps?.filter((item) => !item?.hide).length
                    : parentCurrentStep || currentStep + 1}
                </span>
                <span
                  className={`steps-length ${
                    steps[parentCurrentStep || currentStep]?.last
                      ? 'step-finished'
                      : ''
                  }`}
                >
                  /{steps?.filter((item) => !item?.hide).length}
                </span>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <h2 className="steps-title">
            {steps[parentCurrentStep || currentStep]?.title}
          </h2>
        </div>
      )}
      {steps[currentStep] && (
        <Component
          navigateOnStep={navigateOnStep}
          currentStep={parentCurrentStep || currentStep}
        />
      )}
    </>
  )
}

export default Stepper

import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Cleave from 'cleave.js/react'
import { yupResolver } from '@hookform/resolvers'
import axios from 'axios'

import api from '../../services/api'

import { schemaEdicaoAddress } from '../../validators/formEdicaoSocio'

import fieldsToFill from '../../utils/fieldsToFill'

import HeaderSocioDesktop from '../../components/HeaderSocioDesktop'
import CustomInput from '../../components/CustomInput'
import Loading from '../../components/Loading'

export default function AlterarEndereco() {
  const history = useHistory()
  const [loading, setLoading] = React.useState(true)
  const [currentCep, setCurrentCep] = React.useState('')
  const [addressData, setAddressData] = React.useState({})
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaEdicaoAddress),
    shouldFocusError: false,
    reValidateMode: 'onBlur',
  })

  React.useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get('/area-socio/dados/endereco')

        setAddressData(response?.data?.endereco)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro interno, provavelmente não foi culpa sua.',
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  async function handleSubmitForm(data) {
    try {
      setLoading(true)
      const response = await api.patch('/area-socio/dados/endereco', {
        ...data,
        logradouro: data?.logradouro?.trim(),
        bairro: data?.bairro?.trim(),
        cidade: data?.cidade?.trim(),
        complemento: data?.complemento?.trim(),
      })

      setAddressData({ ...addressData, ...response?.data })

      toast.success('Dados atualizados com sucesso!')
      history.push('/area-socio/ajustes')
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Ocorreu um erro interno, provavelmente não foi culpa sua.',
      )
    } finally {
      setLoading(false)
    }
  }

  async function changeCep(e) {
    if (
      /^\d{5}-\d{3}$/.test(e?.target?.value) &&
      currentCep !== e?.target?.value
    ) {
      try {
        setCurrentCep(e?.target?.value)

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${e?.target?.value}/json/`,
        )

        if (!data?.erro) {
          fieldsToFill.forEach((field) => {
            setValue(field.fieldForm, data[field.fieldResponse])
          })

          setValue('numero', '')
        }
      } catch (err) {
        //
      }
    }
  }

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <div className="socio-wrapper-secondary">
        <HeaderSocioDesktop className="socio-header-secondary" />

        <main>
          <div className="socio-container">
            <section className="alterar-dados-form">
              <h1>Alterar endereço</h1>

              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <label className="label-input" htmlFor="cep">
                  CEP
                  <CustomInput
                    id="cep"
                    name="cep"
                    as={Cleave}
                    className="login-input"
                    icon="map-marker-alt"
                    iconColor="#969CB2"
                    htmlRef={register}
                    value={addressData?.cep}
                    options={{
                      uppercase: true,
                      blocks: [5, 3],
                      delimiter: '-',
                      numericOnly: true,
                    }}
                    placeholder="00000-000"
                    onChange={changeCep}
                  />
                  <span className="retorno-invalido">
                    {errors?.cep?.message}
                  </span>
                </label>

                <label className="label-input" htmlFor="cidade">
                  Cidade
                  <CustomInput
                    id="cidade"
                    name="cidade"
                    className="login-input"
                    icon="city"
                    iconColor="#969CB2"
                    inputRef={register}
                    value={addressData?.cidade}
                    placeholder="Ex. Santa Rosa"
                    onChange={changeCep}
                  />
                  <span className="retorno-invalido">
                    {errors?.cidade?.message}
                  </span>
                </label>

                <label className="label-input" htmlFor="logradouro">
                  Logradouro
                  <CustomInput
                    id="logradouro"
                    name="logradouro"
                    className="login-input"
                    icon="road"
                    iconColor="#969CB2"
                    inputRef={register}
                    value={addressData?.logradouro}
                    placeholder="Ex. Avenida Rio Branco"
                  />
                  <span className="retorno-invalido">
                    {errors?.logradouro?.message}
                  </span>
                </label>

                <label className="label-input" htmlFor="numero">
                  Número
                  <CustomInput
                    id="numero"
                    name="numero"
                    className="login-input"
                    icon="sort-numeric-up-alt"
                    iconColor="#969CB2"
                    inputRef={register}
                    value={addressData?.numero}
                    placeholder="00000"
                  />
                  <span className="retorno-invalido">
                    {errors?.numero?.message}
                  </span>
                </label>

                <label className="label-input" htmlFor="bairro">
                  Bairro
                  <CustomInput
                    id="bairro"
                    name="bairro"
                    className="login-input"
                    icon="map-marked-alt"
                    iconColor="#969CB2"
                    inputRef={register}
                    value={addressData?.bairro}
                    placeholder="Ex. Centro"
                  />
                  <span className="retorno-invalido">
                    {errors?.bairro?.message}
                  </span>
                </label>

                <label className="label-input" htmlFor="uf">
                  UF
                  <CustomInput
                    id="uf"
                    name="uf"
                    as={Cleave}
                    className="login-input"
                    icon="map-marked-alt"
                    iconColor="#969CB2"
                    htmlRef={register}
                    value={addressData?.uf}
                    placeholder="Ex. RS"
                    options={{
                      blocks: [2],
                      uppercase: true,
                    }}
                  />
                  <span className="retorno-invalido">
                    {errors?.uf?.message}
                  </span>
                </label>

                <label
                  className="label-input label-input-max"
                  htmlFor="complemento"
                >
                  Complemento
                  <CustomInput
                    id="complemento"
                    name="complemento"
                    className="login-input"
                    icon="info-circle"
                    iconColor="#969CB2"
                    inputRef={register}
                    value={addressData?.complemento}
                    placeholder="Ex. Apto. 02"
                  />
                  <span className="retorno-invalido">
                    {errors?.complemento?.message}
                  </span>
                </label>

                <div className="buttons-group">
                  <Link to="/area-socio/ajustes" className="button-cancel">
                    Cancelar
                  </Link>
                  <button type="submit" className="button-yellow">
                    Salvar
                  </button>
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    </>
  )
}

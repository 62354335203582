import React from 'react'

export default function CustomRadio({
  id,
  children,
  name,
  value,
  onChange,
  selected,
  inputRef,
  defaultChecked,
}) {
  const isSelected = selected === value

  return (
    <button
      type="button"
      className={`custom-radio ${isSelected ? 'radio-selected' : ''}`}
    >
      <label htmlFor={id}>
        <input
          ref={inputRef}
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          defaultChecked={defaultChecked}
        />
        <span />
        <p>{children}</p>
      </label>
    </button>
  )
}

import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import Endereco from '../components/forms/cadastro/Endereco';
import Pagamento from '../components/forms/cadastro/Pagamento';
import Pessoal from '../components/forms/cadastro/Pessoal';
import Concluido from '../components/forms/cadastro/Concluido';
import Stepper from '../components/Stepper';
import Adicional from '../components/forms/cadastro/Adicional';
import api from '../services/api';
import { toast } from 'react-toastify';
import { useCadastro } from '../Contexts/CadastroContext';

export default function Cadastro() {
  const [, setLoading] = React.useState(true)
  const [, setPlano] = React.useState([])
  const location = useLocation()
  const { dependente, updateDependente } = useCadastro()
  React.useEffect(() => {
    ; (async () => {
      try {
        setLoading(true)
        const response = await api.get(`/planos/${location.state.id}`)
        updateDependente(response.data.dependente)
        setPlano(response?.data)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
          'Erro ao buscar os planos, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
      }
    })()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className="d-flex">
      <section className="form-cadastro-section">
        <div className="cadastro-container">

          <Stepper
            showCircularSteps
            steps={[
              {
                title: 'Dados Pessoais',
                description: '',
                component: (props) => <Pessoal {...props} />,
              },
              {
                title: 'Endereço',
                description: '',
                component: (props) => <Endereco {...props} />,
              },
              {
                title: 'Adicional',
                description: '',
                hide: !dependente,
                component: (props) => <Adicional {...props} />,
              },
              {
                title: 'Pagamento',
                description: '',
                component: (props) => <Pagamento {...props} />,
              },
              {
                title: 'Concluído',
                description: '',
                last: true,
                hide: true,
                component: (props) => <Concluido {...props} />,
              }
            ]}
          />
        </div>

        {/* <a
          className="login-nuvoni-2"
          href="https://www.nuvoni.com.br"
          rel="noopener noreferrer"
          target="_blank"
        >
          NUVONI
        </a> */}
      </section>
      <section className="brand-section">
        <Link to="/">
          <img src="./assets/logo.png" alt="Santa Rosa Futsal" />
        </Link>
      </section>
    </main>
  )
}

import React from 'react'

import MainFooter from '../../components/MainFooter'
import MainNav from '../../components/MainNav'
import Breadcrumb from '../../components/Breadcrumb'

import historiaImg1 from '../../assets/other/historia1.jpg'
import historiaImg4 from '../../assets/other/historia4.jpg'
import historiaImg5 from '../../assets/other/historia5.jpg'

export default function Historia({ name }) {
  return (
    <>
      
      <MainNav />

      <div className="container-lg">
        <Breadcrumb pageName={name} />

        <main className="pt-5">
          <h1 className="fs-24 mt-0 mb-6 fw-bold text-yellow">
            O Santa Rosa Futsal
          </h1>
          <div className="row">
            <div className="col-12 col-sm-6">
              <article>
                <h2 className="fs-18 fw-bold mb-5 mt-0">
                  O Santa Rosa Futsal foi fundado em 1º de Janeiro de
                  2020, a partir da vontade de alguns apaixonados pelo esporte
                  de que a cidade e a região voltassem a ter um time disputando
                  competições estaduais, depois de quase 20 anos. Sempre pautada
                  pela responsabilidade financeira, a direção do clube, formada
                  por voluntários das mais variadas áreas, busca uma integração
                  regional, com foco no futuro e também no social.
                </h2>

                <p className="whitespace-prewrap fs-14">
                  O desafio foi grande logo no primeiro ano de existência. Em
                  função da pandemia de Covid-19, uma Liga Unificada foi criada
                  com times da Liga Gaúcha 3 e também da Liga Gaúcha 2. O Santa
                  Rosa enfrentaria algumas equipes mais qualificadas, de uma
                  divisão superior. Mas a vontade de vencer foi ainda maior.
                  Mesmo sem a presença do público no ginásio, a equipe
                  conquistou o tão sonhado acesso ao Gauchão de Futsal - Série B
                  (antiga Liga 2). Mais tarde a campanha de estreia ainda seria
                  coroada com o título da competição.{'\n'}
                  {'\n'}Em 2021, em meio à preparação para o principal objetivo
                  do ano (Gauchão de Futsal), o trabalho realizado pelo clube
                  deu novos frutos, com a conquista da Taça Farroupilha - Região
                  Noroeste. É a prova de que o Santa Rosa Futsal nasceu
                  para vencer!
                </p>

                <h2 className="fs-18 fw-bold mb-3 mt-5">
                  2022 - A PROFISSIONALIZAÇÃO
                </h2>

                <p className="whitespace-prewrap fs-14">
                  Depois de bater na trave no ano anterior, quando havia estreado no Gauchão de Futsal Série B, a primeira atitude do clube foi buscar a profissionalização. Com isso, novas rotinas de treinos foram adotadas, com maior frequência de trabalhos e acompanhamento profissional constante. O objetivo era quase uma obsessão, conquistar o tão sonhado acesso à elite do futsal gaúcho. O time santa-rosense ficou com a terceira colocação geral no Gauchão Série B, o que garantiu um tentador convite. O sonho se tornou realidade. O Santa Rosa Futsal iria disputar a Série A!
                </p>

                <h2 className="fs-18 fw-bold mb-3 mt-5">
                  PROJETO 2023/2024
                </h2>

                <p className="whitespace-prewrap fs-14">
                  Ao conquistar o tão sonhado acesso, nosso objetivo é fazer bonito na elite do futsal gaúcho.
                  Assim como tudo que foi construído desde a fundação do clube, queremos nos manter na Série A com responsabilidade, de maneira organizada. Agora que chegamos lá, não queremos sair nunca mais!
                </p>

                <h2 className="fs-18 fw-bold mb-3 mt-5">
                  PILARES DO SANTA ROSA FUTSAL
                </h2>

                <p className="whitespace-prewrap fs-14">
                  1. Disciplina! Financeira e dentro da quadra.{'\n'}
                  2. Interesses do clube acima do interesse pessoal.{'\n'}
                  3. Participação efetiva de atletas da região em quadra e
                  ex-atletas na diretoria.{'\n'}
                  4. Ter uma equipe competitiva, reconhecida como uma equipe da
                  região.{'\n'}
                  5. Cuidar do social! Incentivar o surgimento e a manutenção de
                  escolinhas de futsal na Região Noroeste.
                </p>

                <h2 className="fs-18 fw-bold mb-3 mt-5">TÍTULOS</h2>

                <p className="whitespace-prewrap fs-14">
                  2020 – Liga Unificada{'\n'}
                  2021 – Taça Farroupilha | Região Noroeste{'\n'}
                  2023 – Taça Farroupilha | Região Noroeste
                </p>
              </article>
            </div>
            <div className="col-12 col-sm-6 mt-5 mt-sm-0">
              <img className="img-history" src={historiaImg1} alt="Time reunido" />
              <img className="img-history" src={historiaImg4} alt="Time reunido" />
              <img className="img-history" src={historiaImg5} alt="Time reunido" />
            </div>
          </div>
        </main>
      </div>

      <div className="container-lg pb-5">
        <MainFooter />
      </div>
    </>
  )
}

import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import Cleave from 'cleave.js/react'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers'
import Loading from '../../components/Loading'
import api from '../../services/api'
import { schemaCadastroUsuario } from '../../validators/formAdmin'
import nameCapitalize from '../../utils/nameCapitalize'
import AdminMenu from '../../components/AdminMenu'

export default function UsuariosCadastro() {
  const history = useHistory()
  const { id } = useParams()
  const [loading, setLoading] = React.useState(false)
  const [disable, setDisable] = React.useState(false)
  const [form, setForm] = React.useState({
    nome: '',
    email: '',
    senha: '',
    cpf: '',
  })
  const {
    errors,
    handleSubmit: handleSubmitForm,
    register,
    control,
    reset,
    defaultValue = [],
    getValues,
    setValue
  } = useForm({
    resolver: yupResolver(schemaCadastroUsuario),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: async () => form,
    context: { id },
  })

  const [identifiers, setIdentifiers] = React.useState({
    function: 'create',
    buttonText: 'Cadastrar',
    successMessage: 'Cadastro realizado com sucesso!',
  })

  useEffect(() => {
    const { nome, cpf, email, senha } = getValues()
    reset({
      ...form,
      nome: nome || form?.nome,
      email: email || form?.email,
      cpf: cpf || form?.cpf,
      senha: senha || form?.senha,
    })
  }, [form, reset, getValues])

  useEffect(() => {
    ;(async () => {
      if (id) {
        setLoading(true)
        setIdentifiers({
          function: 'edit',
          buttonText: 'Editar',
          successMessage: 'Admin editado com sucesso!',
        })

        const response = await api.get(`/admins/${id}`)
        setForm(response?.data)
        setLoading(false)
      }
    })()
  }, [id, reset])

  async function handleSubmit({ nome, email, cpf, senha }) {
    if (identifiers.function === 'edit') {
      try {
        setDisable(true)
        setLoading(true)
        await api.put(`/admins/${id}`, {
          nome,
          email,
          cpf,
          senha: senha?.trim(),
        })

        toast.success(identifiers.successMessage)
        setDisable(false)
        setLoading(false)
        return history.push('/area-admin/admins')
      } catch (err) {
        setDisable(false)
        setLoading(false)
        return toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao editar as informações.',
        )
      }
    }

    try {
      setDisable(true)
      setLoading(true)
      await api
        .post('/admins', {
          nome,
          email,
          cpf,
          senha: senha?.trim(),
        })
        .then(() => {
          toast.success(identifiers.successMessage)
          return history.push('/area-admin/admins')
        })
        .catch((error) => {
          setDisable(false)
          setLoading(false)
          return toast.error(
            error?.response?.data?.erro || 'Ocorreu um erro ao criar o admin.',
          )
        })
    } catch (err) {
      setLoading(false)
      return toast.error(
        err?.response?.data?.erro || 'Ocorreu um erro ao criar o admim.',
      )
    }
  }

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <AdminMenu />
      <main className="wrapper bg-verm">
        <div className="container mt-3 pt-3">
          <form
            onSubmit={handleSubmitForm(handleSubmit)}
            className="card p-4 border-0"
          >
            <div className="d-flex flex-wrap color-black">
              <div className="form-group col-12 col-md-6">
                <label htmlFor="nome" className="control-label">
                  Nome
                </label>
                <input
                  id="nome"
                  name="nome"
                  type="text"
                  className="form-control"
                  defaultValue={defaultValue}
                  placeholder="Nome do usuário"
                  ref={register}
                  onChange={(e) => setValue('nome', nameCapitalize(e.target.value))}
                />
                <span className="retorno-invalido">
                  {errors?.nome?.message}
                </span>
              </div>

              <div className="form-group col-12 col-md-6">
                <label htmlFor="email" className="control-label">
                  E-mail
                </label>
                <input
                  id="email"
                  type="email"
                  defaultValue={defaultValue}
                  className="form-control"
                  name="email"
                  placeholder="seuemail@dominio.com"
                  ref={register}
                />
                <span className="retorno-invalido">
                  {errors?.email?.message}
                </span>
              </div>

              <div className="form-group col-12 col-md-6">
                <label htmlFor="cpf" className="control-label">
                  CPF
                </label>
                <Controller
                  id="cpf"
                  name="cpf"
                  className="form-control"
                  defaultValue=""
                  as={<Cleave disabled={id} ref={register} />}
                  control={control}
                  placeholder="000.000.000-00"
                  options={{
                    delimiters: ['.', '.', '-'],
                    blocks: [3, 3, 3, 2],
                    uppercase: true,
                    numericOnly: true,
                  }}
                />
                <span className="retorno-invalido">{errors?.cpf?.message}</span>
              </div>
              {identifiers.function === 'edit' ? (
                ''
              ) : (
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="senha" className="control-label">
                    Senha
                  </label>
                  <input
                    id="senha"
                    name="senha"
                    type="password"
                    className="form-control"
                    placeholder="Senha secreta"
                    ref={register}
                    disabled={id}
                    autoComplete="senha"
                    // value={formValues.senha}
                    // onChange={handleChange}
                  />
                  <span className="retorno-invalido">
                    {errors?.senha?.message}
                  </span>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between col-12">
              <button
                type="button"
                className="btn btn-light border"
                onClick={() => history.push('/area-admin/admins')}
              >
                Voltar
              </button>
              <button
                type="submit"
                className="btn btn-verde"
                disabled={disable || loading}
              >
                {identifiers.buttonText}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  )
}

import React from 'react'
import { Redirect, useParams } from 'react-router-dom'

import Personal from './navTabs/Personal'
import Additional from './navTabs/Additional'
import Payment from './navTabs/Payment'
import NovoPagamento from './forms/NovoPagamento'

export const tabs = {
  pessoal: Personal,
  adicional: Additional,
  pagamento: Payment,
  novopagamento: NovoPagamento,
}

export default function NavContent({ selected }) {
  const { id } = useParams()

  const Component = React.useMemo(() => tabs[selected], [selected])

  if (!Component) {
    return <Redirect to={`/area-admin/socios/${id}/pessoal`} />
  }

  return <Component />
}

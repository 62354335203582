import React from 'react'

import FacebookIcon from '../assets/png/facebook_colorful.png'

export default function FacebookFeed({ posts }) {
  return (
    <div className="facebook-feed">
      <div className="facebook-feed__header">
        <a
          href="http://facebook.com/futsalsantarosa"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={FacebookIcon}
            alt="Ícone do Facebook"
            className="facebook-feed__header__icon"
          />
        </a>
        <span className="facebook-feed__header__identifier">
          /futsalsantarosa
        </span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://facebook.com/futsalsantarosa"
          className="facebook-feed__header__button follow-button--facebook"
        >
          SEGUIR
        </a>
      </div>

      <div className="facebook-feed__list">
        {posts?.map((post) => (
          <a
            key={post?.id}
            target="_blank"
            href={post?.permalink_url || '#'}
            className="facebook-feed__list__item"
            rel="noopener noreferrer"
          >
            <span className="facebook-feed__list__item__page-name">
              {post?.from?.name || 'Santa Rosa Futsal'}
            </span>
            <pre>
              <p className="facebook-feed__list__item__description">
                {post?.message}
              </p>
            </pre>
            <img
              src={post?.full_picture}
              alt={post?.from?.name || ''}
              className="facebook-feed__list__item__img"
            />
          </a>
        ))}
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import api from '../../services/api'
import { schemaCompeticoes } from '../../validators/formCompeticoes'
import { toast } from 'react-toastify'
import AdminMenu from '../../components/AdminMenu'
import Loading from '../../components/Loading'
import CreatableSelect from 'react-select/creatable'

export default function CompeticoesCadastro() {
  const { id } = useParams()
  const history = useHistory()
  const [form, setForm] = React.useState({
    liga: '',
    fases: [],
  })

  const [identifiers, setIdentifiers] = React.useState({
    function: 'create',
    buttonText: 'Cadastrar',
    successMessage: 'Cadastro realizado com sucesso!',
  })

  const {
    errors,
    handleSubmit: handleSubmitForm,
    register,
    control,
    reset,
    defaultValue = [],
    getValues,
  } = useForm({
    resolver: yupResolver(schemaCompeticoes),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: async () => form,
  })

  const [loading, setLoading] = React.useState(false)
  const [disable, setDisable] = React.useState(false)

  useEffect(() => {
    const { liga } = getValues()
    reset({
      ...form,
      liga: liga || form?.liga,
    })
  }, [form, reset, getValues])

  useEffect(() => {
    ;(async () => {
      if (id) {
        setLoading(true)
        setIdentifiers({
          function: 'edit',
          buttonText: 'Editar',
          successMessage: 'Liga editada com sucesso!',
        })

        const response = await api.get(`/competicoes/${id}`)
        const data = {
          ...response?.data,
          fases: response?.data?.fases?.map((fase) => ({
            value: fase,
            label: fase,
          })),
        }

        setForm(data)
        setLoading(false)
      }
    })()
  }, [id, reset])

  async function handleSubmit(data) {
    if (identifiers.function === 'edit') {
      try {
        setDisable(true)
        setLoading(true)
        await api.put(`/competicoes/${id}`, {
          liga: data.liga,
          fases: data.fases?.map((fase) => fase?.value),
        })

        toast.success(identifiers.successMessage)
        setDisable(false)
        setLoading(false)
        return history.push('/area-admin/competicoes')
      } catch (err) {
        setDisable(false)
        setLoading(false)
        return toast.error(
          err?.response?.data?.erro ||
            'Ocorreu um erro ao editar as informações.',
        )
      }
    }

    try {
      setDisable(true)
      setLoading(true)
      api
        .post('/competicoes', {
          liga: data?.liga,
          fases: data.fases?.map((fase) => fase?.value),
        })
        .then(() => {
          toast.success(identifiers.successMessage)
          return history.push('/area-admin/competicoes')
        })
        .catch((error) => {
          setDisable(false)
          setLoading(false)
          return toast.error(
            error?.response?.data?.erro ||
              'Ocorreu um erro ao criar a competição.',
          )
        })
    } catch (err) {
      setLoading(false)
      return toast.error(
        err?.response?.data?.erro || 'Ocorreu um erro ao criar o competição.',
      )
    }
  }

  return (
    <>
      {loading && <Loading delayTime={200} />}
      <AdminMenu />
      <main className="wrapper bg-verm">
        <div className="container mt-3 pt-3">
          <form
            onSubmit={handleSubmitForm(handleSubmit)}
            className="card p-4 border-0"
          >
            <div className="d-flex flex-wrap color-black">
              <div className="form-group col-12 col-md-4">
                <label htmlFor="liga" className="control-label">
                  Nome da Competição
                </label>
                <input
                  id="liga"
                  defaultValue={defaultValue}
                  name="liga"
                  type="text"
                  className="form-control"
                  placeholder="Ex.: Liga Ouro"
                  ref={register}
                />
                <span className="retorno-invalido">
                  {errors?.liga?.message}
                </span>
              </div>

              <div className="form-group col-12 col-md-4">
                <label htmlFor="fases" className="control-label">
                  Nome das fases
                </label>
                <Controller
                  control={control}
                  defaultValue={defaultValue}
                  name="fases"
                  render={(props) => (
                    <CreatableSelect
                      value={form?.fases}
                      id="fases"
                      name="fases"
                      isMulti
                      placeholder="Ex.: Semi-final"
                      noOptionsMessage={() =>
                        'Comece a digitar para criar uma fase'
                      }
                      formatCreateLabel={(input) => `Criar "${input}"`}
                      onChange={(selectedOption) => {
                        setForm({
                          ...form,
                          fases: selectedOption,
                        })
                        return props?.onChange(selectedOption)
                      }}
                    />
                  )}
                />
                <span className="retorno-invalido">
                  {errors?.fases?.message}
                </span>
              </div>

              {/* <div className="form-group col-12 col-md-4">
                <label htmlFor="liga" className="control-label">
                  Competição
                </label>
                <select
                  id="liga"
                  name="liga"
                  className="form-control"
                  ref={register}
                >
                  <option hidden value="">
                    Selecione uma
                  </option>
                  <option value="Liga Classificatória">
                    Liga Classificatória
                  </option>
                  <option value="Liga Ouro">Liga Ouro</option>
                  <option value="Taça Diamante">Taça Diamante</option>
                  <option value="Taça Profissional">Taça Profissional</option>
                </select>
              </div>
              <div className="form-group col-12 col-md-4">
                <label htmlFor="fase" className="control-label">
                  Fase
                </label>
                <select
                  id="fase"
                  name="fase"
                  className="form-control"
                  ref={register}
                >
                  <option hidden value="">
                    Selecione uma
                  </option>
                  <option value="1º - Grupo A">1º - Grupo A</option>
                  <option value="1º - Grupo B">1º - Grupo B</option>
                  <option value="1º - Grupo C">1º - Grupo C</option>
                  <option value="Profissional">8º de final</option>
                  <option value="Semi-final">Semi-final</option>
                  <option value="Final">Final</option>
                </select>
              </div> */}
            </div>
            <div className="d-flex justify-content-between col-12">
              <button
                type="button"
                className="btn btn-light border"
                onClick={() => history.push('/area-admin/competicoes')}
              >
                Voltar
              </button>
              <button
                type="submit"
                className="btn btn-verde"
                disabled={disable || loading}
              >
                {identifiers.buttonText}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  )
}

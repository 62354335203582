import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { formatPrice } from '../utils/formatPrice'
import Icon from '../components/Icon'

export default function PlanoCard({
  plano,
}) {
  const { path } = useRouteMatch()

  return (
    <div className="col-md-6 position-relative color-black">
      <div className="card border-0 px-4 py-5 jogos">
        <div className="jogos__info">
          <div className="jogos__info__details">
            {/*Valores que aparecem no card*/}
            <span className="jogos__info__details__teams">
              {plano?.descricao ?? 'Edite o nome do plano' }
            </span>

            <span className="jogos__info__details__additional">
              {`Valor: ${plano?.valor ? formatPrice(plano?.valor) : formatPrice(0)}`}
            </span>

            <span className="jogos__info__details__additional">
              {`Valor do primeiro mês: ${formatPrice((plano?.valor_cart ?? 0) + (plano?.valor ?? 0))}`}
            </span>

            <span className="jogos__info__details__additional">
              {`Valor total das carteirinhas: ${plano?.valor_cart ? formatPrice(plano?.valor_cart) : formatPrice(0)}`}
            </span>

            <span className="jogos__info__details__additional">
              {`Recorrencia: ${plano?.recorrencia ?? 'MENSAL'}`}
            </span>

            <span className="jogos__info__details__additional">
              {`Inclui dependentes: ${plano?.dependente === true ? 'Sim' : 'Não'}`}
            </span>

            {/* <span className="jogos__info__details__additional">
              {`ID: ${plano?.id ?? 'Insira uma descrição'}`}
            </span> */}

             {plano.beneficios?.length > 0 && <span className="jogos__info__details__additional">
              {`Benefícios: `}
                <div className="flex-wrap">
                  {plano.beneficios?.map((beneficio, index) => <li key={index + beneficio}>{beneficio}</li>)}
                </div>
            </span>}
          </div>
        </div>
      </div>
      <div className="action">
        <div className="d-flex align-items-center">
          <div className="dropdown">
            <button
              className="border-0 btn pt-1 pb-1 custom-btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdownMenuButton"
            >
              <Icon icon="ellipsis-v" color="#BDBDBD" />
            </button>
            <div
              className="dropdown-menu dropdown-menu-right z-index-99"
              aria-labelledby="dropdownMenuButton"
            >
              <Link
                className="dropdown-item custom-dropdown color-black"
                to={`${path}/${plano?._id}/editar`}
              >
                <Icon icon="pen" color="#333" />
                <span className="ml-3">Editar</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const camisetaOptions = [
  'PP',
  'P',
  'M',
  'G',
  'GG',
  'XG'
]

export default camisetaOptions;

import React from 'react'
import { Link, useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../components/Loading'
import { toast } from 'react-toastify'

import api from '../../services/api'

import SocioCard from '../../components/SocioCard'
import CustomInput from '../../components/CustomInput'
import Filter from '../../components/Filter'
import AdminMenu from '../../components/AdminMenu'
import Icon from '../../components/Icon'

export default function Socios() {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { search } = useLocation()

  const [sociosList, setSociosList] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(0)

  const searchRef = React.useRef(null)

  const urlQuery = React.useMemo(() => {
    const queries = new URLSearchParams(search)

    const query = {}

    const busca = queries.get('busca') || ''
    const status = queries.get('status') || ''

    if (busca) {
      query.busca = busca
    }

    if (status) {
      query.status = status
    }

    return query
  }, [search])

  const hasMore = React.useMemo(() => page <= pages, [page, pages])

  React.useEffect(() => {
    ;(async () => {
      try {
        setPage(1)
        setLoading(true)
        const response = await api.get('/socios?page=1', {
          params: {
            status: urlQuery?.status,
            search: urlQuery?.busca,
          },
        })

        setSociosList(response?.data?.list)
        setPages(response?.data?.pages || 1)
      } catch (err) {
        toast.error(
          err?.response?.data?.erro ||
            'Erro ao buscar a lista de sócios, por favor, recarregue a página.',
        )
      } finally {
        setLoading(false)
        setPage((p) => p + 1)
      }
    })()
  }, [urlQuery])

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/socios?page=${page}`, {
        params: {
          status: urlQuery?.status,
          search: urlQuery?.busca,
        },
      })

      setSociosList([...sociosList, ...response?.data?.list])
    } catch (err) {
      toast.error(
        err?.response?.data?.erro ||
          'Erro ao buscar a lista de sócios, por favor, recarregue a página.',
      )
    } finally {
      setLoading(false)
      setPage(page + 1)
    }
  }, [page, sociosList, urlQuery])

  const handleSearch = React.useCallback(
    (e) => {
      e.preventDefault()
      const value = searchRef?.current?.value?.trim()

      const data = Object.assign(
        {},
        urlQuery?.status ? { status: urlQuery.status } : null,
        value ? { busca: value } : null,
      )

      history.push({
        pathname: path,
        search: new URLSearchParams(data).toString(),
      })
    },
    [history, urlQuery, path],
  )

  const handleFilter = React.useCallback(
    (filter) => {
      const data = Object.assign(
        { status: filter },
        urlQuery?.busca ? { busca: urlQuery.busca } : null,
      )

      history.push({
        pathname: path,
        search: new URLSearchParams(data).toString(),
      })
    },
    [path, history, urlQuery],
  )

  async function reportXLSX() {
		try {
      setLoading(true)

			const response = await api.get('/socios/reportXLSX')
      console.log(response)
			const bytes = new Uint8Array(response.data.data)
			const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = 'Relatório de Sócios.xlsx'

			link.click()
		} catch (err) {
			toast.error(err?.response?.data?.erro || 'Erro ao exportar os sócios, por favor, recarregue a página.')
		} finally {
			setLoading(false)
		}
	}

  return (
    <>
      <AdminMenu />
      {loading && <Loading delayTime={200} />}
      <main className="wrapper bg-verm" id="scrollableWrapper">
        <div className="container mt-3 p-0 pt-3 pb-4">
          <div className="col-12 mb-4">
            <div className="card card-orientation border-0 p-4">
              <Link
                to={`${path}/cadastrar/`}
                className="text-decoration-none btn-adicionar"
              >
                <Icon icon="plus" color="#fff" />
              </Link>

              <button onClick={reportXLSX} color='primary' className='btn btn-dour btn-report' type='button'>
                <Icon icon="download" color="#fff" />
							</button>
              
              <form className="form-pesquisa" onSubmit={handleSearch}>
                <CustomInput
                  inputRef={searchRef}
                  id="input-pesquisa"
                  value={urlQuery?.busca}
                  placeholder="Pesquisar por nome ou CPF"
                  icon="search"
                  sendIcon="paper-plane"
                />
              </form>

              <Filter
                selected={urlQuery?.status || 'todos'}
                setSelected={handleFilter}
              />
            </div>
          </div>

          <InfiniteScroll
            className="d-flex flex-wrap row-gap-30"
            dataLength={sociosList?.length}
            next={loadMore}
            hasMore={hasMore}
            scrollableTarget="scrollableWrapper"
          >
            {sociosList?.length > 0 ? (
              sociosList?.map((socio, index) => (
                <SocioCard
                  sociosList={sociosList}
                  setSociosList={setSociosList}
                  socio={socio}
                  key={index}
                />
              )) || <div></div>
            ) : (
              <div className="jogos_">Não há sócios cadastrados.</div>
            )}
          </InfiniteScroll>
        </div>
      </main>
    </>
  )
}

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import MidiaKit from '../views/Clube/MidiaKit';
import Historia from '../views/Clube/Historia';
import Diretoria from '../views/Clube/Diretoria';
import QuatrocentosEQuatro from '../views/404';

const ClubeRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/midia_kit`} component={() => <MidiaKit name="midia_kit" />} />
      <Route exact path={`${path}/historia`} component={() => <Historia name="historia" />} />
      <Route exact path={`${path}/diretoria`} component={() => <Diretoria name="diretoria" />} />
      <Route path={`${path}/*`} component={QuatrocentosEQuatro} />
    </Switch>
  );
};

export default ClubeRoutes;